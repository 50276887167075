import React from 'react'
import { useHistory } from 'react-router-dom'
const NoData = (props) => {

    const history = useHistory();

    const navigateTo = (path) => {
        history.push(path);
    };

    return (
        <React.Fragment>
            <div className="padding-20" style={{ margin: props.from === 'quickText' || props.from === 'video' && ('auto') }}>
                <div className="empty-image-container">
                    <img style={{ width: '80%' }} src={props.src} alt={props.alt} />
                    <h5 className="margin-t20" style={{ fontSize : props.from === 'quickText' || props.from === 'video' && ('15px') }}>{props.message}</h5>
                    {(props.from === 'policy' || props.from == 'client') && (
                        <button className="btn btn-label-brand btn-icon-sm margin-t20 margin-r10" onClick={() => navigateTo(props.navigate)}>
                            <i className="la la-upload"></i>
                            Batch Upload
                        </button>
                    )}
                    {props.show === 'Y' && (
                        <button className="btn btn-label-brand btn-icon-sm margin-t20" onClick={() => props.from === 'support' ? props.openSupport() : props.onOpenModal(props.from, true)}>
                            <i className="la la-plus"></i>
                            {props.btnName}
                        </button>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default NoData;