import React, { useState, useEffect } from 'react';
import swal from 'sweetalert2';
import Loading from '../global/loading';
import $ from 'jquery';
import moment from 'moment';
import * as helper from '../global/helper';
import excelLogo from '../../assets/images/ic_excel.png';
import { prevPeriods } from '../../assets/scripts/periodSelection';
import { deleteBatchPolicy, deleteBatchPolicyGroup, getBatchPolicies, updateBatchPolicy } from '../api/policyAPI';
import RightSideDetails from '../global/rightSideDetails';
import { EditBatchPolicy } from './policyUpload';
import NoData from '../global/noData';
import FileImg from '../../assets/images/no-file.svg'

const BatchPolicies = (props) => {

    const [loading,             setLoading]             = useState(false);
    const [searchString,        setSearchString]        = useState('');
    const [currentPeriod,       setCurrentPeriod]       = useState(prevPeriods[0]);
    const [fromDate,            setFromDate]            = useState(prevPeriods[0].from);
    const [toDate,              setToDate]              = useState(prevPeriods[0].to);
    const [batchPolicies,       setBatchPolicies]       = useState([]);
    const [filteredPolicies,    setFilteredPolicies]    = useState([]);
    const [selectedPolicy,      setSelectedPolicy]      = useState(null);
    const [editingPolicy,       setEditingPolicy]       = useState(null);

    useEffect(() => {
        helper.SetScrollHeight();
        $('[data-toggle="kt-popover"]').popover();
        $('[data-toggle="kt-tooltip"]').tooltip();
        $('body').on('click', function (e) {
            $('[data-toggle=kt-popover]').each(function () {
                if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                    $(this).popover('hide');
                }
            });
        });
    });

    useEffect(() => {
        if (currentPeriod.id != 6) {
            setFromDate(currentPeriod.from);
            setToDate(currentPeriod.to);
        }
    }, [currentPeriod]);

    useEffect(() => {
        fetchPolicies();
    }, [fromDate, toDate]);  

    useEffect(() => {
        if (batchPolicies.length > 0)
            filterPolicies();
        else
            setFilteredPolicies([]);

    }, [batchPolicies, searchString]); 

    useEffect(() => {  
        if (editingPolicy){
            $('#edit-batch-policy-modal').modal({
                backdrop: 'static',
            });
            $('#edit-batch-policy-modal').on('hidden.bs.modal', function () {
                setEditingPolicy(null);
            });
        }          
        else{
            $('#edit-batch-policy-modal').modal('hide');
        }
            
    }, [editingPolicy]);

    const filterSelectedPolicyHandler = async (status, search) => {

        let selectedFilterdPolicies = [];
        if (status === 'All')
            selectedFilterdPolicies = [...selectedPolicy.Policies];
        else
            selectedFilterdPolicies = selectedPolicy.Policies.filter(f => f.IsConverted === status);

        if (search === '') {
            setSelectedPolicy((d) => {
                return { ...d, FilterdPolicies: selectedFilterdPolicies, FilterStatus: status, SearchString: search };
            });
        }else{
            const matchedPolicies = selectedFilterdPolicies.filter((c) => {
                let matchString = `${c.PolicyNo} ${c.ClientName} ${c.InsuranceCompanyName} ${c.ProductName} ${moment(c.ToDate).format('DD/MM/YYYY')}`;
                let matchFound = 0;
                const searchArr = search.split(' ');
                searchArr.forEach((term) => {
                    matchFound += matchString.toLowerCase().includes(term.toLowerCase())
                        ? 1
                        : 0;
                });
                return matchFound === searchArr.length;
            });
            setSelectedPolicy((d) => {
                return { ...d, FilterdPolicies: matchedPolicies, FilterStatus: status, SearchString: search };
            });
        } 
    };

    const changePeriodHandler = ({ target }) => {
        const period = prevPeriods.find(p => p.id == target.value)
        setCurrentPeriod(period);               
    };

    const closeDrawerHandler = () => {      
        setTimeout(() => {
          setSelectedPolicy(null);
        }, 180);
    };

    const removePolicyHandler = (id, batchNo) => {
        swal.fire({
            title               : 'Are you sure?',
            text                : "You won't be able to revert this!",
            icon                : 'warning',
            showCancelButton    : true,
            buttonsStyling      : false,
            reverseButtons      : true,
            showLoaderOnConfirm : true,
            confirmButtonClass  : 'btn btn-brand',
            cancelButtonClass   : 'btn btn-secondary',
            confirmButtonText   : 'Delete',
            preConfirm: async () => {
                try {
                    await deleteBatchPolicy(id);
                    setSelectedPolicy((p) => {
                        const newA = p.Policies.filter(f => f.ID != id);
                        const newB = p.FilterdPolicies.filter(f => f.ID != id);
                        return {...p, Policies: newA, FilterdPolicies: newB }
                    });
                    setBatchPolicies((p) => {
                        const newA          = [...p];
                        const batch         = newA.find(f => f.BatchNo === batchNo);
                        const i             = newA.findIndex(f => f.BatchNo === batchNo);
                        const newB          = batch.Policies.filter(f => f.ID != id);
                        newA[i].Policies    = newB;
                        newA[i].Count       = newB.length;
                        if (newB.length > 0) return newA;        
                        else return newA.filter(f => f.BatchNo !== batchNo);                                
                    });
                    swal.fire({
                        toast             : true,
                        icon              : 'success',
                        titleText         : 'Policy deleted successfully!',
                        position          : 'bottom-end',
                        showConfirmButton : false,
                        timer             : 1500,
                        animation         : false,
                        customClass       : {
                            popup: 'margin-20',
                        },
                    });
                } catch (err) {
                    swal.fire({
                        icon                : 'error',
                        titleText           : 'Error!',
                        text                : err.message,
                        buttonsStyling      : false,
                        confirmButtonClass  : 'btn btn-brand',
                    });
                }           
            },
        });  
    };

    const updatePolicyHandler = async (policy) => {
        try {
            await updateBatchPolicy(policy);
            setSelectedPolicy((p) => {
                const newA      = [...p.Policies];
                const indA      = newA.findIndex(f => f.ID == policy.ID);
                newA[indA]      = policy;
                const newB      = [...p.FilterdPolicies];
                const indB      = newB.findIndex(f => f.ID == policy.ID);
                newB[indB]      = policy;
                return {...p, Policies: newA, FilterdPolicies: newB }
            });
            setBatchPolicies((p) => {
                const newA          = [...p];
                const batch         = newA.find(f => f.BatchNo === policy.BatchNo);
                const i             = newA.findIndex(f => f.BatchNo === policy.BatchNo);
                const newB          = [...batch.Policies];
                const ind           = newB.findIndex(f => f.ID == policy.ID);
                newB[ind]           = policy;
                newA[i].Policies    = newB;
                return newA;
            });
        } catch (err) {
            return swal.fire({ 
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
        }      
    };

    const removeFileHandler = (batchNo) => {
        swal.fire({
            title               : 'Are you sure?',
            text                : "You won't be able to revert this!",
            icon                : 'warning',
            showCancelButton    : true,
            buttonsStyling      : false,
            reverseButtons      : true,
            showLoaderOnConfirm : true,
            confirmButtonClass  : 'btn btn-brand',
            cancelButtonClass   : 'btn btn-secondary',
            confirmButtonText   : 'Delete',
            preConfirm: async () => {
                try {
                    swal.fire({
                        titleText           : 'Deleting',
                        text                : 'Please Wait...',
                        showConfirmButton   : false,
                        onOpen: () => {
                            swal.showLoading();
                        }
                    });
                    await deleteBatchPolicyGroup(batchNo);
                    setBatchPolicies((p) => {
                        const newA = p.filter(f => f.BatchNo !== batchNo);
                        return newA;  
                    });
                    swal.close();
                    swal.fire({
                        icon              : 'success',
                        titleText         : 'Policies Deleted Successfully',
                        showConfirmButton : false,
                        timer             : 1500,
                    });
                } catch (err) {
                    swal.fire({
                        icon                : 'error',
                        titleText           : 'Error!',
                        text                : err.message,
                        buttonsStyling      : false,
                        confirmButtonClass  : 'btn btn-brand',
                    });
                }           
            },
        });  
    }

    const fetchPolicies = async () => {
        try {
            setLoading(true);

            if (!fromDate || !toDate) {
                swal.fire({
                    icon                  : 'error',
                    titleText             : 'Error!',
                    text                  : "Invalid Date",
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',   
                });
                setLoading(false);
                return;
            }

            const response = await getBatchPolicies(fromDate, toDate);
            const policyList = response.filter((p) => {
                const element = response.find(f => f.BatchNo == p.BatchNo);
                if (p.ID == element.ID)
                    return true;    
            }).map((p) => ({
                ...p,
                Count: response.filter(f => f.BatchNo == p.BatchNo).length,
                Converted: response.filter(f => f.BatchNo == p.BatchNo && f.IsConverted === 'Y').length,
                Policies: response.filter(f => f.BatchNo == p.BatchNo),
            }));
            setBatchPolicies(policyList);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            return swal.fire({ 
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand'
            });
        }
    };

    const filterPolicies = async () => {

        if (searchString === '') {
            setFilteredPolicies(batchPolicies);
        }else{
            const matchedPolicies = batchPolicies.filter((c) => {
                let matchString = `${c.BatchNo}`;
                let matchFound = 0;
                const searchArr = searchString.split(' ');
                searchArr.forEach((term) => {
                    matchFound += matchString.toLowerCase().includes(term.toLowerCase())
                        ? 1
                        : 0;
                });
                return matchFound === searchArr.length;
            });
            setFilteredPolicies(matchedPolicies);
        } 

    };

    const copyText = (policyNo) => {
        navigator.clipboard.writeText(policyNo);
        const btn =  $('.copyButton')
        btn.tooltip({
            trigger: 'click',
            placement: 'bottom',
            title:"Copied",
        });
        hideTooltip(btn);
          
        function hideTooltip(btn) {
            setTimeout(function() {
                btn.tooltip('hide');
            }, 500);
        }
    }

    return (
        <React.Fragment>
            <div className="kt-form kt-form--label-right kt-margin-b-10">
                <div className="row align-items-center stickyFilter">
                    <div className="col-xl-12 order-2 order-xl-1">
                        <div className="row align-items-center">
                            <div className={`col-md-${currentPeriod.id == 6 ? '2' : '3'} kt-margin-b-20-tablet-and-mobile`}>
                                <div className="kt-form__group">
                                    <div className="kt-form__control">
                                        <select                                              
                                            className="form-control kt-selectpicker"
                                            id="ddlPeriod"
                                            value={currentPeriod.id}
                                            onChange={changePeriodHandler}>                                                        
                                            {prevPeriods.map((s) => (
                                                <option value={s.id} key={s.id}>
                                                    {s.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {currentPeriod.id == 6 && (
                                <div className="col-md-5 kt-margin-b-20-tablet-and-mobile">
                                    <div className="row align-items-center">
                                        <div className="col-md-5 margin-sm-b20">
                                            <div className="kt-form__group">
                                                <div className="kt-form__control kt-form__group--inline">
                                                    <input
                                                        id="fromDate"
                                                        value={fromDate || ''}
                                                        name="fromDate"
                                                        className="form-control"
                                                        type="date"
                                                        onChange={(event) => setFromDate(event.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-1 text-center d-none d-md-block">
                                            <div className="kt-form__group">
                                                <div className="kt-form__control kt-form__group--inline">
                                                    <label>To</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="kt-form__group">
                                                <div className="kt-form__control kt-form__group--inline">
                                                    <input
                                                        id="toDate"
                                                        value={toDate || ''}
                                                        name="toDate"
                                                        className="form-control"
                                                        type="date"
                                                        onChange={(event) => setToDate(event.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                   
                                </div>
                            )}
                            <div className="col-md-4 kt-margin-b-20-tablet-and-mobile margin-b0">
                                <div className="kt-input-icon kt-input-icon--left">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search File..."
                                        id="generalSearch"
                                        value={searchString || ''}
                                        onChange={(e) => setSearchString(e.target.value)}>
                                    </input>
                                    <span className="kt-input-icon__icon kt-input-icon__icon--left">
                                        <span>
                                            <i className="la la-search"></i>
                                        </span>
                                    </span>
                                    {searchString && (
                                        <span className="kt-input-icon__icon kt-input-icon__icon--right">
                                            <span>
                                                <i className="la la-close" onClick={() => setSearchString('')}></i>
                                            </span>
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {loading ? (<Loading/>) : (
                    <React.Fragment>
                        {filteredPolicies.length > 0 ? (
                            <div className="row">
                                {selectedPolicy && (
                                    <RightSideDetails
                                        onCloseDrawer={closeDrawerHandler}
                                        show={!!selectedPolicy}
                                        title="Batch Policies">
                                            <div className="kt-portlet margin-b10">
                                                <div className="kt-portlet__body">
                                                    <div className="kt-widget kt-widget--user-profile-3">
                                                        <div className="kt-widget__top">
                                                            <div className="kt-widget__media kt-hidden-">
                                                                <img className="img-logo-3" src={excelLogo} alt="Logo"/>
                                                            </div>
                                                            <div className="kt-widget__content">
                                                                <div className="kt-widget__head">
                                                                    <span className="kt-widget__username">
                                                                        {selectedPolicy.BatchNo}
                                                                    </span>
                                                                    <div className="kt-widget__action">
                                                                        <span className="dropdown">
                                                                            <a
                                                                                className="btn btn-sm btn-clean btn-icon btn-icon-md"
                                                                                data-toggle="dropdown"
                                                                                aria-expanded="true">
                                                                                <i className="flaticon-more-1"></i>
                                                                            </a>
                                                                            <div className="dropdown-menu dropdown-menu-right" x-placement="top-end">
                                                                                <a className="dropdown-item" onClick={() => removeFileHandler(selectedPolicy.BatchNo)}>
                                                                                    <i className="la la-trash"></i>
                                                                                    Delete File
                                                                                </a>
                                                                            </div>
                                                                        </span>                   
                                                                    </div>
                                                                </div>
                                                                <div className="kt-widget__subhead padding-t0">
                                                                    <span className="padding-r10">
                                                                        <i className="flaticon2-calendar-3"></i>
                                                                        {selectedPolicy.AgentName}
                                                                    </span>
                                                                    <br></br>
                                                                    <span className="padding-r10">
                                                                        <i className="fa fa-clock"></i>
                                                                        {moment(selectedPolicy.CreatedDate).format('DD/MM/YYYY - hh:mm A')}
                                                                    </span>
                                                                    <span className="padding-r10">
                                                                        <i className="flaticon-doc"></i>
                                                                        Policies: <strong>{selectedPolicy.Policies.length}</strong> 
                                                                        &nbsp;|&nbsp;Renewed: <strong>{selectedPolicy.Policies.filter(p => p.IsConverted === 'Y').length}</strong>
                                                                    </span>
                                                                </div>
                                                            </div>                 
                                                        </div>                                                       
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row align-items-center stickyFilter margin-lr0">
                                                <div className="col-xl-12 order-2 order-xl-1 padding-lr0">
                                                    <div className="row align-items-center">
                                                        <div className="col-md-4 margin-b0 margin-sm-b20">
                                                            <div className="kt-form__group">
                                                                <div className="kt-form__control">
                                                                    <select
                                                                        className="form-control kt-selectpicker"
                                                                        id="ddlFilterStatus"
                                                                        value={selectedPolicy.FilterStatus || 'All'}
                                                                        onChange={(event) => filterSelectedPolicyHandler(event.target.value, selectedPolicy.SearchString || '')}>                                                 
                                                                        <option value="All">All</option>
                                                                        <option value="N">Pending</option>
                                                                        <option value="Y">Renewed</option>                                                
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="kt-input-icon kt-input-icon--left">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Search Policy..."
                                                                    id="generalSearch"
                                                                    value={selectedPolicy.SearchString || ''}
                                                                    onChange={(event) => filterSelectedPolicyHandler(selectedPolicy.FilterStatus || 'All', event.target.value)}>
                                                                </input>
                                                                <span className="kt-input-icon__icon kt-input-icon__icon--left">
                                                                    <span>
                                                                        <i className="la la-search"></i>
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
                                                <table className="kt-datatable__table">
                                                    <thead className="kt-datatable__head" style={{ display: 'block' }}>
                                                        <tr className="kt-datatable__row block-row">
                                                            <th className="kt-datatable__cell text-clip" width="15%">
                                                                <span>Policy Number</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip" width="20%">
                                                                <span>Client Name</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip" width="15%">
                                                                <span>Expiry Date</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip" width="20%">
                                                                <span>Insurance Company</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip" width="15%">
                                                                <span>Product</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip text-right padding-r50" width="15%">
                                                                <span>Action</span>
                                                            </th>
                                                        </tr>
                                                    </thead>                        
                                                </table>
                                            </div>
                                            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0 scrolTable" onScroll={helper.setStickyHeader}>
                                                <table className="kt-datatable__table table-striped">
                                                    <tbody className="kt-datatable__body" style={{ display: 'block' }}>
                                                        {selectedPolicy.FilterdPolicies.map((p, i) => (
                                                            <tr key={i} className="kt-datatable__row block-row already-exists">
                                                                <td className="kt-datatable__cell text-clip" width="15%">
                                                                    <button className="btn btn-secondary copyButton" onClick={() => copyText(p.PolicyNo)} data-clipboard-text="1" style={{}}>Copy</button>
                                                                    <span title={p.PolicyNo} className="kt-font-bold">{p.PolicyNo}</span>
                                                                </td>
                                                                <td className="kt-datatable__cell text-clip" width="20%">
                                                                    <button className="btn btn-secondary copyButton" onClick={() => copyText(p.ClientName)} data-clipboard-text="1" style={{}}>Copy</button>
                                                                    <span title={p.ClientName}>{p.ClientName}</span>
                                                                </td>
                                                                <td className="kt-datatable__cell text-clip" width="15%">
                                                                    <span title={moment(p.ToDate).format('DD/MM/YYYY')} className="kt-font-bold">{moment(p.ToDate).format('DD/MM/YYYY')}</span>
                                                                </td>
                                                                <td className="kt-datatable__cell text-clip" width="20%">
                                                                    <span title={p.InsuranceCompanyName}>{p.InsuranceCompanyName}</span>
                                                                </td>
                                                                <td className="kt-datatable__cell text-clip" width="15%">
                                                                    <span title={p.ProductName}>{p.ProductName}</span>
                                                                </td>
                                                                <td className="kt-datatable__cell text-clip text-right" width="15%">
                                                                    {p.IsConverted === 'Y' && (
                                                                        <button
                                                                            type="button"
                                                                            data-toggle="kt-popover"
                                                                            data-trigger="hover click"
                                                                            data-placement="left"
                                                                            data-content="Policy Renewed"
                                                                            className='btn btn-outline-success btn-elevate btn-circle btn-icon btn-xs margin-r10'>
                                                                            <i className='fa fa-check'></i>
                                                                        </button>
                                                                    )}                                                                   
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => setEditingPolicy(p)}
                                                                        className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                                                        <i className="flaticon2-edit"></i>
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => removePolicyHandler(p.ID, p.BatchNo)}
                                                                        className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                                                        <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                                    </button>
                                                                </td>        
                                                            </tr>
                                                        ))}                                          
                                                    </tbody>
                                                </table>
                                            </div>                                                         
                                    </RightSideDetails>
                                )}
                                <div className="col-sm-12">
                                    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
                                        <table className="kt-datatable__table">
                                            <thead
                                            className="kt-datatable__head"
                                            style={{ display: 'block' }}>
                                                <tr className="kt-datatable__row block-row">
                                                    <th className="kt-datatable__cell text-clip" width="20%">
                                                        <span>Batch Number</span>
                                                    </th>
                                                    <th className="kt-datatable__cell text-clip" width="25%">
                                                        <span>Uploaded By</span>
                                                    </th>
                                                    <th className="kt-datatable__cell text-clip" width="25%">
                                                        <span>Uploaded On</span>
                                                    </th>
                                                    <th className="kt-datatable__cell--center kt-datatable__cell text-clip" width="10%"> 
                                                        <span>Policies</span>
                                                    </th>
                                                    <th className="kt-datatable__cell--center kt-datatable__cell text-clip" width="10%"> 
                                                        <span>Renewed</span>
                                                    </th>
                                                    <th className="kt-datatable__cell text-clip text-right" width="10%">
                                                        <span>Action</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>

                                    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded scrolTable" onScroll={helper.setStickyHeader}>
                                        <table className="kt-datatable__table table-striped">
                                            <tbody className="kt-datatable__body" style={{ display: 'block' }}>
                                                {filteredPolicies.map((p) => (
                                                    <tr key={p.ID} className="kt-datatable__row block-row clickable">
                                                        <td title={p.BatchNo} className="kt-datatable__cell" width="20%" onClick={() => setSelectedPolicy({...p, FilterdPolicies: p.Policies})}>
                                                            <div className="kt-notification text-clip">
                                                                <a className="kt-notification__item padding-0">
                                                                    <div className="kt-notification__item-icon margin-r10">
                                                                        <img height="30px" width="30px" src={excelLogo} alt="Logo" />
                                                                    </div>
                                                                    <div className="kt-notification__item-details text-clip">
                                                                        <div className="kt-notification__item-title kt-font-bold text-clip">
                                                                            {p.BatchNo}
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td className="kt-datatable__cell text-clip" width="25%" onClick={() => setSelectedPolicy({...p, FilterdPolicies: p.Policies})}>
                                                            <span>{p.AgentName}</span>
                                                        </td>
                                                        <td className="kt-datatable__cell text-clip" width="25%" onClick={() => setSelectedPolicy({...p, FilterdPolicies: p.Policies})}>
                                                            <span>{moment(p.CreatedDate).format('DD/MM/YYYY - hh:mm A')}</span>
                                                        </td>
                                                        <td className="kt-datatable__cell--center kt-datatable__cell text-clip" width="10%" onClick={() => setSelectedPolicy({...p, FilterdPolicies: p.Policies})}>
                                                            <span>{p.Count}</span>
                                                        </td>
                                                        <td className="kt-datatable__cell--center kt-datatable__cell text-clip" width="10%" onClick={() => setSelectedPolicy({...p, FilterdPolicies: p.Policies})}>
                                                            <span>{p.Converted}</span>
                                                        </td>
                                                        <td className="kt-datatable__cell text-clip text-right" width="10%">
                                                            <button
                                                                type="button"
                                                                onClick={() => removeFileHandler(p.BatchNo)}
                                                                className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                                                                data-toggle="kt-popover"
                                                                data-trigger="hover"
                                                                data-placement="left"
                                                                data-content="Delete File">
                                                                <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                            </button>

                                                        </td>
                                                    </tr>
                                                ))}
                                                
                                            </tbody>
                                        </table>                          
                                    </div>   
                                </div>
                            </div>
                        ) : (
                            <NoData
                                src={FileImg}
                                alt="File"
                                message="No Files Found"
                            />
                        )}
                    </React.Fragment>

                )}

            </div>

            {editingPolicy && (
                <EditBatchPolicy
                    policy={editingPolicy}
                    updatePolicy={updatePolicyHandler}
                    action="e"
                />
            )} 
        </React.Fragment>
        
    );
}

export default BatchPolicies;