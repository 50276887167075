import React, { useState, useEffect } from 'react';
import NoData from '../global/noData';
import * as helper from '../global/helper';
import Loading from '../global/loading';
import PolicyImg from '../../assets/images/no-policy.svg'
import { getPolicyHistory } from '../api/policyAPI';
import { getSubAgentPolicyHistory } from '../api/subAgencyAPI';

const PolicyHistory = (props) => {

    const [loading, setLoading]     = useState(false);
    const [policy,  setPolicy]      = useState([]);

    useEffect(() => {
        fetchPolicy();
    }, [props.refresh]);

    const fetchPolicy = async () => {
        try {
            setLoading(true)
            // const response = props.from == 'subAgentPolicyStats' ? await getSubAgentPolicyHistory(props.policyDetails.GroupID) : await getPolicyHistory(props.policyDetails.GroupID);
            const response = await getPolicyHistory(props.policyDetails.GroupID);
            setPolicy(response);
            setLoading(false);
        } catch (err) {
            swal.fire({
                icon              : 'error',
                titleText         : 'Error!',
                text              : err.message,
                buttonsStyling    : false,
                confirmButtonClass: 'btn btn-brand',
            });
        }
    }

    const openFile = async (policy) => {
        const ext = helper.getFileExt(policy.FileName);
        // if( props.from == 'subAgentPolicyStats' ){
        //     const name = (policy.InsuranceCompanyName + ' SubAgentPolicy.' + ext).replace(/ /g, '_');
        //     helper.downloadAndOpenFileHandler("SubAgentPolicy", policy.FileName, name);         
        // }else{
            const name      = (policy.InsuranceCompanyName + ' Policy.' + ext).replace(/ /g, '_');
            helper.downloadAndOpenFileHandler("Policy", policy.FileName, name);    
        // }
                                                   
    }

    const downloadFile = async (policy) => {
        const ext     = helper.getFileExt(policy.FileName);
        // if( props.from == 'subAgentPolicyStats' ){
        //     const name    = (policy.InsuranceCompanyName + ' SubAgentPolicy.' + ext).replace(/ /g, '_');
        //     helper.downloadFileToClientHandler("SubAgentPolicy", policy.FileName, name); 
        // }else{
            const name      = (policy.InsuranceCompanyName + ' Policy.' + ext).replace(/ /g, '_');
            helper.downloadFileToClientHandler("Policy", policy.FileName, name);
        // }
    
    }

    const copyText = (event, policyNo) => {
        event.stopPropagation()
        navigator.clipboard.writeText(policyNo);
        const btn =  $('.copyButton')
        btn.tooltip({
            trigger: 'click',
            placement: 'bottom',
            title:"Copied",
        });
        hideTooltip(btn);
          
        function hideTooltip(btn) {
            setTimeout(function() {
                btn.tooltip('hide');
            }, 500);
        }
    }

    return (
        <React.Fragment>
                {loading ? <Loading/> : (
                    policy.length > 0 ? (
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
                                    <table className="kt-datatable__table">
                                        <thead
                                            className="kt-datatable__head"
                                            style={{ display: 'block' }}>
        
                                            <tr className="kt-datatable__row block-row">
                                                <th className="kt-datatable__cell text-clip" width="30%">
                                                    <span className="padding-l10">Policy Number</span>
                                                </th>
                                                <th className="kt-datatable__cell text-clip" width="15%">
                                                    <span>Premium</span>
                                                </th>
                                                <th className="kt-datatable__cell text-clip" width="15%">        
                                                    <span>Sum Insured</span>
                                                </th>
                                                <th className="kt-datatable__cell text-clip" width="15%">
                                                    <span>Start Date</span>
                                                </th>
                                                <th className="kt-datatable__cell text-clip" width="15%">
                                                    <span>End Date</span>
                                                </th>
                                                <th className="kt-datatable__cell--center kt-datatable__cell text-clip" width="10%">
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                                <div 
                                    className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded scrolTable"
                                    onScroll={helper.setStickyHeader}>
                                    <table className="kt-datatable__table table-striped">
                                        <tbody
                                            className="kt-datatable__body"
                                            style={{ display: 'block', minHeight:'40vh' }}>
                                            {policy.map((p, i) => (
                                                <tr
                                                    key={i}
                                                    className="kt-datatable__row block-row clickable">
                                                    <td width="30%" className="kt-datatable__cell text-clip" onClick={() => props.setSelectedPolicy({...p, From: 'policyHistory'})}>
                                                        <div className="kt-user-card-v2">
                                                            <div className="kt-user-card-v2__pic">
                                                                <div className="img-small-profile kt-badge kt-badge--xl kt-badge--success">{p.InsuranceCompanyName.slice(0, 1)}</div>
                                                            </div>
                                                            <div className="kt-user-card-v2__details text-clip">
                                                                <button className="btn btn-secondary copyButton" type='button' onClick={(e) => copyText(e, p.PolicyNo)} data-clipboard-text="1" style={{}}>Copy</button>
                                                                <span className="kt-user-card-v2__name text-clip" title={p.PolicyNo}>
                                                                    {p.PolicyNo}                  
                                                                </span>                                                               
                                                                <span className="kt-user-card-v2__desc text-clip" title={p.InsuranceCompanyName}>{p.InsuranceCompanyName}</span>
                                                            </div>
                                                            {(p.ID == props.policyID && props.status !== 'Expired') && (
                                                                <span className='kt-badge kt-badge--success kt-badge--inline kt-badge--pill policy-badge'>
                                                                    Active
                                                                </span>  
                                                            )} 
                                                        </div>
                                                    </td>
                                                    <td width="15%" title={p.Premium} className="kt-datatable__cell text-clip" onClick={() => props.setSelectedPolicy({...p, From: 'policyHistory'})}>
                                                        <span>{p.Premium}</span>
                                                    </td>
                                                    <td width="15%" title={p.SumInsured} className="kt-datatable__cell text-clip" onClick={() => props.setSelectedPolicy({...p, From: 'policyHistory'})}>
                                                        <span>{p.SumInsured}</span>
                                                    </td>
                                                    <td width="15%" title={moment(p.FromDate).format('DD/MM/YYYY')} className="kt-datatable__cell text-clip" onClick={() => props.setSelectedPolicy({...p, From: 'policyHistory'})}>
                                                        <span>{moment(p.FromDate).format('DD/MM/YYYY')}</span>
                                                    </td>
                                                    <td width="15%" title={moment(p.ToDate).format('DD/MM/YYYY')} className="kt-datatable__cell text-clip" onClick={() => props.setSelectedPolicy({...p, From: 'policyHistory'})}>
                                                        <span>{moment(p.ToDate).format('DD/MM/YYYY')}</span>
                                                    </td>
                                                    <td width="10%" className="kt-datatable__cell">
                                                        <div className='dropdown'>
                                                            <a className='btn btn-sm btn-clean btn-icon btn-icon-md' data-toggle='dropdown' aria-expanded='true'>
                                                                <i className="flaticon-more-1"></i>
                                                            </a>
                                                            <div className='dropdown-menu dropdown-menu-right' x-placement='top-end'>
                                                                <a className='dropdown-item' onClick={() => openFile(p)}>
                                                                    <i className='la la-file-text'></i>
                                                                    View
                                                                </a>
                                                                <a className='dropdown-item' onClick={() => props.setEmailPolicy({policyId: p.ID, clientGroupID: p.ClientGroupID, type: 'policyHistory', Email: p.SubAgentEmail, FirstName: p.SubAgentName, ID: p.SubAgentID, Type: p.Type})}>
                                                                    <i className='la la-envelope'></i>
                                                                    Email
                                                                </a>
                                                                <a className='dropdown-item' onClick={() => downloadFile(p)}>
                                                                    <i className='la la-download'></i>
                                                                    Download
                                                                </a>  
                                                                <a className='dropdown-item' onClick={() => props.setEditPolicy(p)}>
                                                                    <i className='la la-edit'></i>
                                                                    Edit
                                                                </a> 
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <NoData
                            src={PolicyImg}
                            alt="Policy"
                            message="No Policies Found"
                        />
                    )
                )}
        </React.Fragment>
    );
};

export default PolicyHistory;