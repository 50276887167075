import axios from 'axios';
import cookie from "react-cookies";
import {sessionSecret} from "../../env.json";
import { Encryptor } from "../../components/global/encryptor";
import moment from "moment";
import OneSignal from 'react-onesignal';
import { getAgentSubscription } from '../../components/api/agentAPI';

const { encrypt, decrypt } = Encryptor(sessionSecret);

//Agent Signup State Setup --->
export const getTempState = form => {
    const userCookie = cookie.load('ibAgentTemp');
    if(!userCookie) return false;
    const params = JSON.parse(decrypt(userCookie))

    if(form === 'bool') return !!params.token;
    if(!!params[form]) return params[form];
    else return params
}

export const setTempState = params => {
    if(!params){
        cookie.remove('ibAgentTemp', { path: '/' });
    }else{
        const encryptedData = encrypt(JSON.stringify(params));
        let cookieOptions = { path:'/',secure:true };
        cookie.save('ibAgentTemp', encryptedData, cookieOptions);
    }
}

//Agent Login State Setup --->
export const getAuthState = form => {
    const userCookie = cookie.load('ibAgent');
    if(!userCookie) return false;
    const params = JSON.parse(decrypt(userCookie))

    // Check for 5 minutes before expiry 
    if(moment(params.expiresOn).subtract(5,'minutes').diff(moment()) < 0) return 'Expired'
    
    if(form === 'bool') return !!params.token;
    if(!!params[form]) return params[form]
    else return params
}

export const setAuthState = (authParams, isRemember) => {
    if(!authParams){ 
        OneSignal.removeExternalUserId()
        return cookie.remove('ibAgent', { path: '/' });
    }
    //Encrypt Data
    const encryptedData = encrypt(JSON.stringify(authParams));
    //Set 30days Cookie 
    let cookieOptions = !isRemember ? { path : '/', secure : true } : { path : '/', maxAge : 2592000, secure : true };
    cookie.save('ibAgent', encryptedData, cookieOptions)
}

const checkExcludedPath = () => {
    const excludedPaths = ['setup','signup','signin','forgot-password','verify/','set-password'];
    let check = true
    for (const path of excludedPaths){
        if(window.location.pathname.includes(path)){
             check = false;
        }
    }
    return check;
}
   
export const setAgentRefreshedCookie = async (config) => {
    const safeToUpdate = checkExcludedPath();
    
    if( !safeToUpdate || !config ) return;
    try {
        const { data : updatedCookie } = await axios.get('/api/agent/getAgentUpdatedToken', config);
        if(!updatedCookie) throw 'Authentication failed';
        //  Convert & Set Cookie Age (1day = 86400 sec)
        const maxAge = ( moment( updatedCookie.expiresOn ).diff( moment(), 'days') + 1 ) * 86400;

        //Encrypt Data
        const encryptedData = encrypt(JSON.stringify(updatedCookie));

        let cookieOptions = maxAge <= 86400 ? { path : '/', secure : true } : { path : '/', maxAge : maxAge, secure : true };
        cookie.save('ibAgent', encryptedData, cookieOptions);
    } catch (err) {
        throw new Error(err.message || 'Authentication failed');
    }
}

export const setSubscriptionExpiredCookie = async (from) => {
    const safeToUpdate = checkExcludedPath();
    if( !safeToUpdate ) return;
    try {
        const subData = await getAgentSubscription();
        const cookieData = getAuthState();
        if(subData){
            const { ExpiryDate, IsExtended, IsFreeTrial, SubAgency, licenseReduced } = subData;
            let optParams = {
                IsExpired: true,
                PlanExpiry: ExpiryDate,
                IsExtended, 
                IsFreeTrial,
            }
            if(from === 'license') optParams = { SubAgency };
            if(from === 'License reduced')  optParams = { licenseReduced };
            setAuthState({
                ...cookieData,
                ...optParams
                
            });
        }
    } catch (err) {
        throw new Error(err.message || 'Authentication failed');
    }
}