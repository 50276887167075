import React, { useState, useEffect } from 'react';
import { getQuotationDocuments } from '../api/policyAPI';
import * as helper from '../global/helper';
import Loading from '../global/loading';

const ShowDocuments = (props) => {

    const [documents,  setDocuments]  = useState([]);

    useEffect(() => {
        let ignore = false;
        if (!ignore) {
            $('#show-documents-modal').on('hidden.bs.modal', function () {
                $('.modal').hasClass('show') && $('body').addClass('modal-open');
                setDocuments([]);
                props.setDocumentQuoteID(null);
                $(props.from).removeClass('modal-blur');
            });
            $('#show-documents-modal').modal('toggle');
            fetchDocuments();
        }
        return () => {
          ignore = true;
        };
    }, []);

    const fetchDocuments = async () => {
      try {
        const response = await getQuotationDocuments([props.quotationID]);
        setDocuments(response);
      } catch (err) {
        swal.fire({
            icon              : 'error',
            titleText         : 'Error!',
            text              : err.message,
            buttonsStyling    : false,
            confirmButtonClass: 'btn btn-brand',
        });
      }
    }

    const openFile = async (index) => {
        const file      = documents[index];
        const ext       = helper.getFileExt(file.FileName);
        const name      = (file.DocumentName + '.' + ext).replace(/ /g, '_');
        helper.downloadAndOpenFileHandler(file.DocumentType, file.FileName, name)
    }

    return (
        <div
          className="modal fade"
          id="show-documents-modal"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true">
        
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  Documents
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close">
                </button>
              </div>
              <div className="modal-body">
                <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded">
                    <table className="kt-datatable__table table-striped">
                        <tbody className="kt-datatable__body" style={{display: 'block'}}>
                            {documents && documents.length > 0 ? (
                                documents.map((d, i) => 
                                    <tr key={d.ID} className="kt-datatable__row block-row">
                                        <td className="kt-datatable__cell text-clip">
                                            <div className="kt-notification text-clip">
                                                <a className="kt-notification__item padding-0" onClick={() => openFile(i)}>
                                                    <div className="kt-notification__item-icon margin-r10">
                                                        <img height="30px" width="30px" src={helper.getFileLogo(d.FileName)} />
                                                    </div>
                                                    <div className="kt-notification__item-details text-clip">
                                                        <div className="kt-notification__item-title kt-font-bold text-clip">
                                                            {d.DocumentName}
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            ) :(
                                <tr><td><Loading/></td></tr>
                            )}                  
                        </tbody>
                    </table>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary margin-0 margin-r5"
                  data-dismiss="modal">   
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
    );

};


export default ShowDocuments