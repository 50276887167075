import { axiosPost, axiosGet } from './axiosFunctions';
import { getTokenOrLogout } from './common';

const uploadFileUrl             = (fileType) => `/api/file/uploadFile/${fileType}`
const uploadFileListUrl         = (fileType) => `/api/file/uploadFileList/${fileType}`
const uploadProfileImageUrl     = (folderName) => `/api/file/uploadProfileImage/${folderName}`
const downloadFileUrl           = (fileType, fileName) => `/api/file/downloadFile/${fileType}/${fileName}`

const fTypes       = ['Policy', 'SubAgentPolicy', 'PolicyDocument', 'SubAgentPolicyDocument', 'Quotation', "QuotationDocument", 'AgentFile', 'Public']
const pulicFolders = ['AgentProfileImage', 'AgentCompanyLogo']

export const uploadFile = async (file, fileType) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  

    if (!fTypes.includes(fileType)) throw new Error('Unknown file type')
    const formData = new FormData()
    formData.append('file', file)
    const response = await axiosPost(
        uploadFileUrl(fileType),
        formData,
        {
            headers: {
                auth_token: `bearer ${token}`
            },
            onUploadProgress(progress) {
                console.log('file upload progress:', `${progress.loaded}/${progress.total}`)
            }
        }
    )
    return response
}

export const uploadProfileImage = async (file, folderName) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  

    if (!pulicFolders.includes(folderName)) throw new Error('Unknown file type')
    const formData = new FormData()
    formData.append('file', file)
    const response = await axiosPost(
        uploadProfileImageUrl(folderName),
        formData,
        {
            headers: {
                auth_token: `bearer ${token}`
            },
            onUploadProgress(progress) {
                console.log('file upload progress:', `${progress.loaded}/${progress.total}`)
            }
        }
    )
    return response
}


export const uploadFileList = async (files, fileType) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  
       
    if (!fTypes.includes(fileType)) throw new Error('Unknown file type')
    const formData = new FormData()
    for (const file of files) {
        formData.append('file', file)
    }
    const response = await axiosPost(
        uploadFileListUrl(fileType),
        formData,
        {
            headers: {
                auth_token: `bearer ${token}`
            },
            onUploadProgress(progress) {
                console.log('file upload progress:', `${progress.loaded}/${progress.total}`)
            }
        }
    )
    return response
}

export const downloadFile = async (fileType, fileName) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  
       
    if (!fTypes.includes(fileType)) throw new Error('Unknown file type')
    const response = await axiosGet(
        downloadFileUrl(fileType, fileName),
        {
            headers: {
                auth_token: `bearer ${token}`               
            }
        }
    )
    return response
}
