import React, { useState, useEffect } from 'react';
import swal from 'sweetalert2';
import Loading from '../global/loading';
import * as helper from '../global/helper';
import { prevPeriods } from '../../assets/scripts/periodSelection';
import { 
    getQuotationStats,
    getQuotationStatusList,
} from '../api/policyAPI';   
import RightSideDetails from '../global/rightSideDetails';
import QuotationTable from './quotationTable';
import EditQuotation from './editQuotation';
import EmailQuotation from './emailQuotation';
import ShowDocuments from './showDocuments';
import NoData from '../global/noData';
import QuotationImg from '../../assets/images/no-policy.svg'
import ConvertQuotation from './convertQuotation';


const quotationStats = (props) => {

    const [loading,             setLoading]             = useState(false);
    const [quotations,          setQuotations]          = useState([]);
    const [filteredQuotations,  setFilteredQuotations]  = useState([]);
    const [searchString,        setSearchString]        = useState('');
    const [statusList,          setStatusList]          = useState([]);  
    const [currentStatus,       setCurrentStatus]       = useState(0);
    const [currentPeriod,       setCurrentPeriod]       = useState(prevPeriods[0]);
    const [fromDate,            setFromDate]            = useState(prevPeriods[0].from);
    const [toDate,              setToDate]              = useState(prevPeriods[0].to);
    const [selectedQuotation,   setSelectedQuotation]   = useState(null);
    const [emailList,           setEmailList]           = useState([]);
    const [showEmail,           setShowEmail]           = useState(false);
    const [showEdit,            setShowEdit]            = useState(false);
    const [documentQuoteID,     setDocumentQuoteID]     = useState(null);
    const [editingQuote,        setEditingQuote]        = useState(null);
    const [convertingQuote,     setConvertingQuote]     = useState(null);
    const [refresh,             setRefresh]             = useState(false);
    const [doRefresh,           setDoRefresh]           = useState(false);

    useEffect(() => {

        let ignore = false;
        
        const fetchQuotationStatus = async () => {

            try {
                const statuses = await getQuotationStatusList();
                setStatusList(statuses);  
                setCurrentStatus(0);
                setCurrentPeriod(prevPeriods[0]);                                           
            } catch (err) {
                swal.close();
                swal.fire({
                    icon                : 'error',
                    titleText           : 'Error!',
                    text                : err.message,
                    buttonsStyling      : false,
                    confirmButtonClass  : 'btn btn-brand',
                });             
            }         
        };

        if (!ignore) {
            fetchQuotationStatus();  
        }

        return () => {
            ignore = true;
        };
    }, [props.update]);

    useEffect(() => {
        helper.SetScrollHeight();
    });

    useEffect(() => {
        if (currentPeriod.id != 6) {
            setFromDate(currentPeriod.from);
            setToDate(currentPeriod.to);
        }
    }, [currentPeriod]);   

    useEffect(() => {
        fetchQuotations();     
    }, [props.update, fromDate, toDate, refresh]);   

    useEffect(() => {
        if (quotations.length > 0)
            filterQuotations();
        else
            setFilteredQuotations([]);

    }, [quotations, currentStatus, searchString]);  

    useEffect(() => {
        if (!selectedQuotation) {
            setShowEmail(false);
            setEmailList([]);
            if (doRefresh) setRefresh((r) => !r);
            setDoRefresh(false);
        }
    }, [selectedQuotation]); 

    useEffect(() => {
        setSelectedQuotation(null);
    }, [filteredQuotations]);   

    useEffect(() => {
        if (editingQuote) setShowEdit(true);
    }, [editingQuote]);   

    useEffect(() => {
        if (!showEdit) setEditingQuote(null);
    }, [showEdit]);   

    const refetchQuotations = (task, quotation) => {
        setDoRefresh(true);
        if (task === 'email') {
            setSelectedQuotation((q) => ({ 
                ...q,
                StatusID: 2,
                Status:  'Quote Sent',
                Quotations: q.Quotations.map((a) => ({
                    ...a,
                    StatusID: emailList.find(e => e.ID == a.ID && e.IsEmail) ? 2 : a.StatusID,
                    Status: emailList.find(e => e.ID == a.ID && e.IsEmail) ? 'Quote Sent' : a.Status,
                }))
            }));
        }else if(task === 'add'){
            setSelectedQuotation((q) => { 
                const newA = [...q.Quotations];
                newA.push(quotation);
                return {...q, Quotations: newA}
            });
        }else if(task === 'update'){       
            setSelectedQuotation((q) => { 
                const newA = [...q.Quotations];
                const index = newA.findIndex(i => i.ID == quotation.ID);
                newA[index] = quotation;
                return {...q, Quotations: newA}
            });
        }else if(task === 'convert'){
            setSelectedQuotation((q) => ({ 
                ...q,
                StatusID: 3,
                Status: 'Converted',
                Quotations: q.Quotations.map((a) => ({
                    ...a,
                    StatusID: quotation.ID == a.ID ? 3 : a.StatusID,
                    Status: quotation.ID == a.ID ? 'Converted' : a.Status,
                }))
            }));
        } 
    }

    const fetchQuotations = async () => {

        try {

            setLoading(true);

            if (!fromDate || !toDate) {
                swal.fire({
                    icon                  : 'error',
                    titleText             : 'Error!',
                    text                  : "Invalid Date",
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',   
                });
                setLoading(false);
                return;
            }

            const response = await getQuotationStats(fromDate, toDate);

            const quotationList = response.filter((q) => { 
                const group = response.filter(f => f.GroupID == q.GroupID);    
                const element =  group.find(f => f.StatusID == 3) || group.find(f => f.StatusID == 2);
                if (!element) {
                    if (q.ID == group[0].ID)
                        return true;             
                }else if(q.ID == element.ID) {
                    return true;
                }
            }).map((q) => ({
                ...q,
                Count: response.filter(f => f.GroupID == q.GroupID).length,
                Quotations: response.filter(f => f.GroupID == q.GroupID)
            }));

            setQuotations(quotationList);
            setLoading(false);
        } catch (err) {
            swal.fire({
                icon              : 'error',
                titleText         : 'Error!',
                text              : err.message,
                buttonsStyling    : false,
                confirmButtonClass: 'btn btn-brand',
            });
        }

    };

    const filterQuotations = async () => {

        let quotationList = [];

        if (currentStatus != 0)
            quotationList = quotations.filter((q) => q.StatusID == currentStatus);    
        else
            quotationList = quotations;

        if (searchString === '') {
            setFilteredQuotations(quotationList);
        }else{
            const matchedQuotations = quotationList.filter((c) => {
                let matchString = `${c.ClientName} ${c.ClientCompanyName} ${c.ProductName} ${c.PolicyNo || 'New'} ${c.Type}`;
                let matchFound = 0;
                const searchArr = searchString.split(' ');
                searchArr.forEach((term) => {
                    matchFound += matchString.toLowerCase().includes(term.toLowerCase())
                        ? 1
                        : 0;
                });
                return matchFound === searchArr.length;
            });
            setFilteredQuotations(matchedQuotations);
        } 

    };

    const changePeriodHandler = ({ target }) => {
        const period = prevPeriods.find(p => p.id == target.value)
        setCurrentPeriod(period);               
    };

    const closeDrawerHandler = () => {      
        setTimeout(() => {
          setSelectedQuotation(null);
        }, 180);
    };

    const editQuotationHandler = (index) => {     
        const quotation = selectedQuotation.Quotations[index];
        setEditingQuote(quotation);
    }

    const convertQuotationHandler = (index) => {     
        const quotation = selectedQuotation.Quotations[index];
        setConvertingQuote(quotation);
    }

    const removeQuotationHandler = (index) => {

        setDoRefresh(true);
        const nA = selectedQuotation.Quotations.filter((b, i) => index !== i);
        if (nA && nA.length > 0) {
            setSelectedQuotation((q) => ({ 
                ...q, 
                Count:nA.length,
                Quotations: nA
            }));
        }else{
            closeDrawerHandler();
        }  
        
    }

    const emailQuotationHandler = (index, isEmail) => {

        const quotation = selectedQuotation.Quotations[index];

        if (isEmail) {
            setEmailList((a) => {
                const newA = [...a];
                newA.push({
                    ID: quotation.ID,
                    IsEmail: true
                });          
                return newA;
            });  
        }else{
            setEmailList((a) => {
                const nA = a.filter((q) => q.ID !== quotation.ID);
                return nA;
            })    
        }                           
    }

    return (
        <React.Fragment>
            {loading ? (<Loading />) :            
                (<div className="kt-form kt-form--label-right kt-margin-b-10">
                    <div className="row align-items-center stickyFilter">
                        <div className="col-xl-12 order-2 order-xl-1">
                            <div className="row align-items-center">
                                <div className={`col-md-${currentPeriod.id == 6 ? '2' : '3'} kt-margin-b-20-tablet-and-mobile`}>
                                    <div className="kt-form__group">
                                        <div className="kt-form__control">
                                             <select                                              
                                                className="form-control kt-selectpicker"
                                                id="ddlPeriod"
                                                value={currentPeriod.id}
                                                onChange={changePeriodHandler}>                                                        
                                                {prevPeriods.map((s) => (
                                                    <option value={s.id} key={s.id}>
                                                        {s.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {currentPeriod.id == 6 && (
                                    <div className="col-md-5 kt-margin-b-20-tablet-and-mobile">
                                        <div className="row align-items-center">
                                            <div className="col-md-5 margin-sm-b20">
                                                <div className="kt-form__group">
                                                    <div className="kt-form__control kt-form__group--inline">
                                                        <input
                                                            id="fromDate"
                                                            value={fromDate || ''}
                                                            name="fromDate"
                                                            className="form-control"
                                                            type="date"
                                                            onChange={(event) => setFromDate(event.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-1 text-center d-none d-md-block">
                                                <div className="kt-form__group">
                                                    <div className="kt-form__control kt-form__group--inline">
                                                        <label>To</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                <div className="kt-form__group">
                                                    <div className="kt-form__control kt-form__group--inline">
                                                        <input
                                                            id="toDate"
                                                            value={toDate || ''}
                                                            name="toDate"
                                                            className="form-control"
                                                            type="date"
                                                            onChange={(event) => setToDate(event.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                   
                                    </div>
                                )}
                                
                                <div className="col-md-3 kt-margin-b-20-tablet-and-mobile">
                                    <div className="kt-form__group kt-form__group--inline">
                                        <div className="kt-form__label">
                                            <label htmlFor="ddlStatus">Status:</label>
                                        </div>
                                        <div className="kt-form__control">
                                             <select
                                                className="form-control kt-selectpicker"
                                                id="ddlStatus"
                                                value={currentStatus}
                                                onChange={(e) => setCurrentStatus(e.target.value)}>                                                 
                                                <option value="0">All</option>
                                                {statusList.map((s) => (
                                                    <option value={s.ID} key={s.ID}>
                                                        {s.Status}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className={`col-md-${currentPeriod.id == 6 ? '2' : '3'} kt-margin-b-20-tablet-and-mobile`}>
                                    <div className="kt-input-icon kt-input-icon--left">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search Quotation..."
                                            id="generalSearch"
                                            value={searchString || ''}
                                            onChange={(e) => setSearchString(e.target.value)}
                                            >
                                        </input>
                                        <span className="kt-input-icon__icon kt-input-icon__icon--left">
                                            <span>
                                                <i className="la la-search"></i>
                                            </span>
                                        </span>
                                        {searchString && (
                                            <span className="kt-input-icon__icon kt-input-icon__icon--right">
                                                <span>
                                                    <i className="la la-close" onClick={() => setSearchString('')}></i>
                                                </span>
                                            </span>
                                        )}
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>

                    {filteredQuotations.length > 0 ? 
                        (<div className="row">
                            {selectedQuotation && (
                                <RightSideDetails
                                    onCloseDrawer={closeDrawerHandler}
                                    show={!!selectedQuotation}
                                    title="Quotation Details">

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="kt-portlet">
                                                <div className="kt-portlet__body">
                                                    <div className="kt-widget kt-widget--user-profile-3">
                                                        <div className="kt-widget__top">
                                                            <div
                                                                className={`kt-widget__pic kt-widget__pic--${
                                                                    selectedQuotation.Type === 'Corporate'
                                                                    ? 'success'
                                                                    : 'brand'
                                                                } kt-font-${
                                                                    selectedQuotation.Type === 'Corporate'
                                                                    ? 'success'
                                                                    : 'brand'
                                                                } kt-font-boldest kt-hidden-`}> 
                                                                {selectedQuotation.DisplayName.slice(0, 1)}     
                                                            </div>
                                                            <div style={{maxWidth:'85%'}} className="kt-widget__content">
                                                                <div title={selectedQuotation.DisplayName} className="kt-widget__head">
                                                                    <span className="kt-widget__username text-clip">
                                                                        {selectedQuotation.DisplayName}
                                                                    </span>
                                                                </div>
                                                                <div className="kt-widget__subhead padding-t0 text-clip">
                                                                    <span title={
                                                                        selectedQuotation.Type === 'Corporate'
                                                                        ? selectedQuotation.ClientCompanyName
                                                                        : selectedQuotation.PrimaryClientName
                                                                        } className="padding-r10">
                                                                        <i className="flaticon2-calendar-3"></i>
                                                                        {selectedQuotation.Type === 'Corporate' ? 'Organization' : selectedQuotation.Type}
                                                                    </span>
                                                                    <br></br>
                                                                    {selectedQuotation.ClientEmail && (
                                                                        <span title={selectedQuotation.ClientEmail} className="padding-r10">
                                                                            <i className="flaticon2-new-email"></i>
                                                                            {selectedQuotation.ClientEmail}
                                                                        </span>
                                                                    )}
                                                                    {selectedQuotation.ClientMobile && (
                                                                        <span title={selectedQuotation.ClientMobile} className="padding-r10">
                                                                         <i className="flaticon2-phone"></i>
                                                                            {selectedQuotation.ClientMobile}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="kt-portlet">
                                                <div className="kt-portlet__body padding-b8 flex-row">
                                                    <div style={{width:'85px'}} className="kt-widget kt-widget--user-profile-3 margin-b10">
                                                        <div className="kt-widget__top">
                                                            <div className="kt-widget__media kt-hidden-">
                                                                <img className="img-profile-3" src={selectedQuotation.ProductIcon} alt="product"/>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                    <div style={{maxWidth:'85%'}} className="kt-widget12 padding-l20">
                                                        <div className="kt-widget12__content">
                                                            <div className="kt-widget12__item">
                                                                <div className="kt-widget12__info text-clip">
                                                                    <div className="kt-widget12__desc padding-b0">Product</div>
                                                                    <div className="kt-widget12__value text-clip padding-r10" title={selectedQuotation.ProductName}>{selectedQuotation.ProductName}</div>
                                                                </div>
                                                                <div className="kt-widget12__info text-clip">
                                                                    <div className="kt-widget12__desc padding-b0">Policy No</div>
                                                                    <div className="kt-widget12__value text-clip" title={selectedQuotation.PolicyNo || 'New'}>{selectedQuotation.PolicyNo || 'New'}</div>
                                                                </div>
                                                            </div>
                                                            <div className="kt-widget12__item">        
                                                                <div className="kt-widget12__info">
                                                                    <div className="kt-widget12__desc padding-b0">Quotations: <strong> {selectedQuotation.Count}</strong> </div>
                                                                </div>
                                                                <div className="kt-widget12__info">
                                                                    <div className={`policy-status block-badge mr-1 badge display-inline padding-lr20 padding-tb3 badge-${selectedQuotation.StatusID == 3 ? 'success' : (selectedQuotation.StatusID == 2 ? 'primary' : 'secondary')}`}>
                                                                        <span>{selectedQuotation.Status}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="kt-portlet kt-portlet--mobile">
                                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                                            <div className="kt-portlet__head-label">
                                                <nav className="file-nav navbar navbar-top">
                                                    <a className="top-nav-active"
                                                        href="#Quotation">
                                                        Quotations
                                                    </a> 
                                                </nav>
                                            </div>
                                            <div className="kt-portlet__head-toolbar">
                                                <div className="kt-portlet__head-wrapper">
                                                    {(emailList && emailList.length > 0) && (
                                                        <div className="kt-portlet__head-actions"> 
                                                            <button
                                                                className="btn btn-label-brand btn-block margin-0"
                                                                onClick={() => setShowEmail(true)}>                     
                                                                <i className="fa fa-envelope" />
                                                                Email
                                                            </button>                    
                                                        </div>
                                                    )}
                                                    <div className="kt-portlet__head-actions">
                                                        <button className="btn btn-label-brand btn-icon-sm margin-l10"
                                                            onClick={() => setShowEdit(true)}>
                                                            <i className="la la-plus"></i>
                                                            Add Quote
                                                        </button>                      
                                                    </div>      
                                                </div>
                                            </div>
                                        </div>
                                        <div className="kt-portlet__body kt-portlet__body--fit">
                                            <QuotationTable
                                                action="e"
                                                quotations={selectedQuotation.Quotations}
                                                removeQuotation={removeQuotationHandler}
                                                emailQuotation={emailQuotationHandler} 
                                                editQuotation={editQuotationHandler}       
                                                setDocumentQuoteID={setDocumentQuoteID}     
                                                convertQuotation={convertQuotationHandler}                                
                                            />
                                        </div>
                                    </div>
                                </RightSideDetails>
                            )}
    
                            <div className="col-sm-12">
                                <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
                                    <table className="kt-datatable__table">
                                        <thead
                                            className="kt-datatable__head"
                                            style={{ display: 'block' }}>
                                        
                                            <tr className="kt-datatable__row block-row">
                                                <th className="kt-datatable__cell text-clip" width="35%">
                                                    <span className="padding-l10">Client Name</span>
                                                </th>
                                                <th className="kt-datatable__cell text-clip" width="20%">
                                                    <span className="padding-l20">Product</span>
                                                </th>
                                                <th className="kt-datatable__cell text-clip" width="20%">      
                                                    <span>Policy No</span>
                                                </th>
                                                <th className="kt-datatable__cell--center kt-datatable__cell text-clip" width="10%">
                                                    <span>Quotations</span>
                                                </th>
                                                <th className="kt-datatable__cell--center kt-datatable__cell text-clip" width="15%">
                                                    <span>Status</span>
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>

                                <div
                                    className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded scrolTable"
                                    onScroll={helper.setStickyHeader}>    
                                    <table className="kt-datatable__table table-striped">
                                        <tbody
                                            className="kt-datatable__body"
                                            style={{ display: 'block' }}>   
                                            {filteredQuotations.map((q) => (
                                                <tr
                                                    key={q.ID}
                                                    className="kt-datatable__row block-row clickable"
                                                    onClick={() => setSelectedQuotation(q)}
                                                >
                                                    <td width="35%" title={q.DisplayName} className="kt-datatable__cell text-clip">
                                                        <div className="kt-user-card-v2">
                                                            <div className="kt-user-card-v2__pic">
                                                                <div
                                                                    className={`kt-badge kt-badge--xl kt-badge--${
                                                                    q.Type === 'Corporate' ? 'success' : 'info'
                                                                    }`}>
                                                                    {q.DisplayName.slice(0, 1)}
                                                                </div>
                                                            </div>
                                                            <div className="kt-user-card-v2__details text-clip">
                                                                <span className="kt-user-card-v2__name text-clip">{q.DisplayName}</span>
                                                                <span className="kt-user-card-v2__desc text-clip">{q.Type === 'Corporate' ? 'Organization' : q.Type}</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td
                                                        className="kt-datatable__cell text-clip"
                                                        width="20%"
                                                        title={q.ProductName}>
                                                        <div className="kt-user-card-v2">
                                                            <div className="kt-user-card-v2__pic">
                                                                <img src={q.ProductIcon} alt="product"/>
                                                            </div>
                                                            <div className="kt-user-card-v2__details text-clip">
                                                                <span className="kt-user-card-v2__name text-clip">{q.ProductName}</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td
                                                        className="kt-datatable__cell text-clip"
                                                        width="20%"
                                                        title={q.PolicyNo}>
                                                        <span>{q.PolicyNo || 'New'}</span>
                                                    </td>
                                                    <td
                                                        className="kt-datatable__cell--center kt-datatable__cell text-clip"
                                                        width="10%"> 
                                                        <span>{q.Count}</span>
                                                    </td>
                                                    <td
                                                        className="kt-datatable__cell--center kt-datatable__cell text-clip"
                                                        width="15%"
                                                        title={q.Status}>
                                                        <div className={`policy-status block-badge mr-1 badge badge-${q.StatusID == 3 ? 'success' : (q.StatusID == 2 ? 'primary' : 'secondary')}`}>
                                                           <span>{q.Status}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>) : 
                        (<NoData
                            src={QuotationImg}
                            alt="Quoatation"
                            message="No Quotations Found"
                            from="quotation"
                            btnName="Add Quotation"
                            show="Y"
                            onOpenModal={props.onOpenModal}
                        />)
                    }
                    
                </div>)         
            }             
            {selectedQuotation && (
                <React.Fragment>
                    {showEdit && (
                        <EditQuotation
                            action={editingQuote ? 'e' : 'a'}
                            groupId={selectedQuotation.GroupID}
                            quotation={editingQuote}
                            show={showEdit}
                            onDismissModal={setShowEdit}
                            refetchQuotations={refetchQuotations}
                        />
                    )}
                    {showEmail && (
                        <EmailQuotation
                            action="e"
                            groupId={selectedQuotation.ClientGroupID}
                            quotationIds={emailList.map(q => q.ID)}
                            show={showEmail}
                            onDismissModal={setShowEmail}
                            refetchQuotations={refetchQuotations}
                        />
                    )}
                    {documentQuoteID && (
                        <ShowDocuments 
                            quotationID={documentQuoteID} 
                            setDocumentQuoteID={setDocumentQuoteID}
                        />
                    )}
                    {convertingQuote && (
                        <ConvertQuotation 
                            quotation={convertingQuote}
                            onDismissModal={setConvertingQuote}
                            refetchQuotations={refetchQuotations}
                        />
                    )}
                    
                </React.Fragment>               
            )}       
        </React.Fragment>
    );

};

export default quotationStats;