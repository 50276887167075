import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import 'jquery-form';
import * as helper from '../global/helper';
import swal from 'sweetalert2';
import Loading from '../global/loading';
import 'bootstrap/js/dist/popover';
import moment from 'moment';
import { expiredPeriods, periods } from '../../assets/scripts/periodSelection';
import RightSideDetails from '../global/rightSideDetails';
import EmailPolicy from './emailPolicy';
import RenewPolicy from './renewPolicy';
import PolicyDocuments from './policyDocuments';
import NoData from '../global/noData';
import PolicyImg from '../../assets/images/no-policy.svg'
import {
    deactivatePolicy,
    deleteBatchPolicy,
    deletePolicyRequest,
    getExpiredPolicyStats,
    getMarinePolicy,
    getMotorPolicy,
    getPolicyStats,
    getPolicyStatusList,
    getPolicyTimeline,
    updateBatchPolicy
} from '../api/policyAPI';
import EditPolicy from './editPolicy';
import AddDocument from './addDocument';
import PolicyHistory from './policyHistory';
import PolicyQuotation from './policyQuotation';
import EditPolicyDocument from './editDocument';
import EditQuotation from './editQuotation';
import ConvertQuotation from './convertQuotation';
import EmailQuotation from './emailQuotation';
import ShowDocuments from './showDocuments';
import { EditBatchPolicy } from '../batchUpload/policyUpload';
import { getAuthState } from '../../assets/scripts/login-util';
import { getSuperAdminByAgentCompany, resendAgentOTP } from '../api/agentAPI';
import { getSubAgentMarinePolicy, getSubAgentMotorPolicy } from '../api/subAgencyAPI';

export const PolicyDetails = (props) => {

    const [policy, setPolicy] = useState({...props.policy, ClientType: props.policy.Type});

    useEffect(() => {
        $('#policy-detail-modal').modal({
          backdrop: 'static',
        });
        $('#policy-detail-modal').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            props.onDismissModal(false);   
            $('#search-policy-modal').removeClass('modal-blur');
        });
        $('#search-policy-modal').addClass('modal-blur');
        $('#policy-detail-modal').modal('toggle');

        if (props.policy.AdditionalData)
            $('#additionalData').html(props.policy.AdditionalData)  

        if (props.policy.ExtraFields)
            fetchExtraData()
    }, [props.show]);

    const fetchExtraData = async () => {
        try {
            if (policy.ExtraFields === 'MotorPolicy') {
                const motorPolicy = await getMotorPolicy(policy.ID);
                if (motorPolicy) {
                    const { RegistrationNo, Make, Model, Type, ODPremium, TPPremium, AddOnPremium, NCB } = motorPolicy;
                    setPolicy((p) => {
                        return { ...p, RegistrationNo, Make, Model, Type };
                    });
                    if (policy.ProductID == 2 || policy.ProductID == 4 || policy.ProductID == 21) {
                        setPolicy((d) => {
                            return { ...d, RegistrationNo, Make, Model, Type, ODPremium, Premium: TPPremium, AddOnPremium, NCB };
                        });
                    } else {
                        setPolicy((d) => {
                            return { ...d, RegistrationNo, Make, Model, Type, Premium: ODPremium, TPPremium, AddOnPremium, NCB };
                        });
                    }
                }
            } else if (policy.ExtraFields === 'MarinePolicy') {
                const marinePolicy = await getMarinePolicy(policy.ID);
                if (marinePolicy) {
                    const { DeclarationBasis, PerBottomLimit, PerSendingLimit, IsAutoReminder } = marinePolicy;
                    setPolicy((p) => {
                        return { ...p, DeclarationBasis, PerBottomLimit, PerSendingLimit, IsAutoReminder }
                    })
                };
            };
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            }); 
        }
    };

    return (
        <React.Fragment>
            <div
                className="modal fade"
                id="policy-detail-modal"
                tabIndex="-1"
                role="dialog"
                aria-hidden="true">

                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Policy Details</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"                               
                                aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body grey-background">
                            <div className="kt-portlet">
                                <div className="kt-portlet__body">
                                    <div className="kt-widget kt-widget--user-profile-3">
                                        <div className="kt-widget__top">
                                            <div className="kt-widget__media kt-hidden-">
                                                <img className="img-profile-3" src={policy.ProductIcon} alt="product"/>
                                            </div>
                                            <div className="kt-widget__content">
                                                <div className="row align-items-center">
                                                    <div className="col-sm-8">
                                                        <div title={policy.PolicyNo} className="kt-widget__head">
                                                            <span className="kt-widget__username text-clip">
                                                                {policy.PolicyNo}
                                                            </span>
                                                        </div>
                                                        <div className="kt-widget__subhead padding-0 text-clip">
                                                            <span title={policy.ProductName} className="padding-r10">
                                                                {policy.ProductName}
                                                            </span>
                                                            <br></br>
                                                            <span title={policy.InsuranceCompanyName} className="padding-r10">
                                                                {policy.InsuranceCompanyName}
                                                            </span>                                          
                                                        </div>
                                                    </div>
                                                    {(policy.From !== 'policyHistory' || policy.ID == props.policyID) && (<div className="col-sm-4">
                                                        <div className={`policy-status block-badge mr-1 badge display-inline padding-lr20 padding-tb5 margin-l0 badge-${policy.Status === 'Renewal Due' ? 'secondary' : (policy.Status === 'Policy Issued' ? 'primary' : (policy.Status === 'Policy Sent' ? 'success' : ((policy.Status === 'Expired' || policy.Status === 'Renewed') ? 'label-danger' : (policy.Status === 'Lapsed' ? '' : 'warning'))))}`}>
                                                            <span>{policy.Status}</span>
                                                        </div>
                                                    </div>)}
                                                </div>                                                      
                                            </div>
                                        </div>
                                        {policy.AdditionalData && (
                                            <div className="row">
                                                <div className="col-12 padding-20 padding-b0">
                                                    <span className="kt-desc" id="additionalData"></span>
                                                </div>
                                            </div>
                                        )}         
                                        <div className={`kt-widget__bottom d-block ${policy.AdditionalData && ('margin-t0')}`}>
                                            <div className="row">
                                                {(policy.ExtraFields == 'MotorPolicy' && (policy.ProductID == 2 || policy.ProductID == 4 || policy.ProductID == 21)) && (
                                                    <div className="col-lg-4 col-sm-6">
                                                        <div className="kt-widget__item">
                                                            <div className="kt-widget__icon">
                                                                <i className="flaticon-piggy-bank"></i>
                                                            </div>
                                                            <div className="kt-widget__details">
                                                                <span className="kt-widget__title">TP Premium</span>
                                                                <span className="kt-widget__value">
                                                                    {policy.Premium}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {(policy.ExtraFields == 'MotorPolicy' && (policy.ProductID != 2 && policy.ProductID != 4 && policy.ProductID != 21)) && (
                                                    <>
                                                        <div className="col-lg-4 col-sm-6">
                                                            <div className="kt-widget__item">
                                                                <div className="kt-widget__icon">
                                                                    <i className="flaticon-piggy-bank"></i>
                                                                </div>
                                                                <div className="kt-widget__details">
                                                                    <span className="kt-widget__title">OD Premium</span>
                                                                    <span className="kt-widget__value">
                                                                        {policy.Premium}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-sm-6">
                                                            <div className="kt-widget__item">
                                                                <div className="kt-widget__icon">
                                                                    <i className="flaticon-piggy-bank"></i>
                                                                </div>
                                                                <div className="kt-widget__details">
                                                                    <span className="kt-widget__title">Add On Premium</span>
                                                                    <span className="kt-widget__value">
                                                                        {policy.AddOnPremium}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {(policy.ProductID == 1 || policy.ProductID == 3 || policy.ProductID == 20) && (
                                                            <div className="col-lg-4 col-sm-6">
                                                                <div className="kt-widget__item">
                                                                    <div className="kt-widget__icon">
                                                                        <i className="flaticon-piggy-bank"></i>
                                                                    </div>
                                                                    <div className="kt-widget__details">
                                                                        <span className="kt-widget__title">TP Premium</span>
                                                                        <span className="kt-widget__value">
                                                                            {policy.TPPremium}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                                {policy.ExtraFields != 'MotorPolicy' && (
                                                    <div className="col-lg-4 col-sm-6">
                                                        <div className="kt-widget__item">
                                                            <div className="kt-widget__icon">
                                                                <i className="flaticon-piggy-bank"></i>
                                                            </div>
                                                            <div className="kt-widget__details">
                                                                <span className="kt-widget__title">Premium</span>
                                                                <span className="kt-widget__value">
                                                                    {policy.Premium}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="col-lg-4 col-sm-6">
                                                    <div className="kt-widget__item">
                                                        <div className="kt-widget__icon">
                                                            <i className="flaticon-coins"></i>
                                                        </div>
                                                        <div className="kt-widget__details">
                                                            <span className="kt-widget__title">Sum Insured</span>
                                                            <span className="kt-widget__value">
                                                                {policy.SumInsured}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-sm-6">
                                                    <div className="kt-widget__item">
                                                        <div className="kt-widget__icon">
                                                            <i className="flaticon-refresh"></i>
                                                        </div>
                                                        <div className="kt-widget__details">
                                                            <span className="kt-widget__title">Business Type</span>
                                                            <span className="kt-widget__value">
                                                                {policy.BusinessType}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            {/* </div> */}
                                            {/* <div className="row"> */}
                                                <div className="col-lg-4 col-sm-6">
                                                    <div className="kt-widget__item">
                                                        <div className="kt-widget__icon">
                                                            <i className="flaticon-calendar-1"></i>
                                                        </div>
                                                        <div className="kt-widget__details">
                                                            <span className="kt-widget__title">Start Date</span>                                                      
                                                            <span className="kt-widget__value">
                                                                {moment(policy.FromDate).format('DD/MM/YYYY')}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-sm-6">
                                                    <div className="kt-widget__item">
                                                        <div className="kt-widget__icon">
                                                            <i className="flaticon-calendar-1"></i>
                                                        </div>
                                                        <div className="kt-widget__details">
                                                            <span className="kt-widget__title">End Date</span>
                                                            <span className="kt-widget__value">
                                                                {moment(policy.ToDate).format('DD/MM/YYYY')}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-sm-6">
                                                    <div className="kt-widget__item">
                                                        <div className="kt-widget__icon">
                                                            <i className="flaticon-time"></i>
                                                        </div>
                                                        <div className="kt-widget__details">
                                                            <span className="kt-widget__title">Issued On</span>
                                                            <span className="kt-widget__value">
                                                                {moment(policy.IssueDate).format('DD/MM/YYYY')}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {policy.ExtraFields === 'MotorPolicy' && (
                                                    <React.Fragment>
                                                        <div className="col-lg-4 col-sm-6">
                                                            <div className="kt-widget__item">
                                                                <div className="kt-widget__icon">
                                                                    <i className="flaticon-list-1"></i>
                                                                </div>
                                                                <div className="kt-widget__details">
                                                                    <span className="kt-widget__title">Registration No.</span>                                                      
                                                                    <span className="kt-widget__value">
                                                                        {policy.RegistrationNo || '--'}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-sm-6">
                                                            <div className="kt-widget__item">
                                                                <div className="kt-widget__icon">
                                                                    <i className="flaticon-settings-1"></i>
                                                                </div>
                                                                <div className="kt-widget__details">
                                                                    <span className="kt-widget__title">Make</span>                                                      
                                                                    <span className="kt-widget__value">
                                                                        {policy.Make || '--'}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-sm-6">
                                                            <div className="kt-widget__item">
                                                                <div className="kt-widget__icon">
                                                                    <i className="flaticon-car"></i>
                                                                </div>
                                                                <div className="kt-widget__details">
                                                                    <span className="kt-widget__title">Model</span>                                                      
                                                                    <span className="kt-widget__value">
                                                                        {policy.Model || '--'}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-sm-6">
                                                            <div className="kt-widget__item">
                                                                <div className="kt-widget__icon">
                                                                    <i className="flaticon-dashboard"></i>
                                                                </div>
                                                                <div className="kt-widget__details">
                                                                    <span className="kt-widget__title">Type</span>                                                      
                                                                    <span className="kt-widget__value">
                                                                        {policy.Type || '--'}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )}  
                                                {policy.ExtraFields === 'MarinePolicy' && (
                                                    <React.Fragment>
                                                        <div className="col-lg-4 col-sm-6">
                                                            <div className="kt-widget__item">
                                                                <div className="kt-widget__icon">
                                                                    <i className="flaticon-interface-4"></i>
                                                                </div>
                                                                <div className="kt-widget__details">
                                                                    <span className="kt-widget__title">Declaration Basis</span>                                                      
                                                                    <span className="kt-widget__value">
                                                                        {policy.DeclarationBasis}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {policy.PerBottomLimit && (<div className="col-lg-4 col-sm-6">
                                                            <div className="kt-widget__item">
                                                                <div className="kt-widget__icon">
                                                                    <i className="flaticon-coins"></i>
                                                                </div>
                                                                <div className="kt-widget__details">
                                                                    <span className="kt-widget__title">Per Bottom Limit</span>                                                      
                                                                    <span className="kt-widget__value">
                                                                        {policy.PerBottomLimit || '--'}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                        {policy.PerSendingLimit && (<div className="col-lg-4 col-sm-6">
                                                            <div className="kt-widget__item">
                                                                <div className="kt-widget__icon">
                                                                    <i className="flaticon-coins"></i>
                                                                </div>
                                                                <div className="kt-widget__details">
                                                                    <span className="kt-widget__title">Per Sending Limit</span>                                                      
                                                                    <span className="kt-widget__value">
                                                                        {policy.PerSendingLimit || '--'}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                    </React.Fragment>
                                                )}
                                            </div>   
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet">
                                <div className="kt-portlet__body my-auto padding-l0">
                                    <div className="kt-widget kt-widget--user-profile-3">
                                        <div className="kt-widget__top">
                                            <div
                                                className={`kt-widget__pic kt-widget__pic--${
                                                    policy.Type === 'Corporate'
                                                    ? 'success'
                                                    : 'brand'
                                                } kt-font-${
                                                    policy.Type === 'Corporate'
                                                    ? 'success'
                                                    : 'brand'
                                                } kt-font-boldest kt-hidden-`}> 
                                                {policy.DisplayName.slice(0, 1)}     
                                            </div>
                                            <div style={{maxWidth:'85%'}} className="kt-widget__content">
                                                <div className="row align-items-center">
                                                    <div className={`col-${policy.ClientID == policy.PrimaryClientID ? '12' : '6'}`}>
                                                        <div title={policy.DisplayName} className="kt-widget__head">
                                                            <span className="kt-widget__username text-clip">
                                                                {policy.DisplayName}
                                                            </span>
                                                        </div>
                                                        <div className="kt-widget__subhead padding-t0 text-clip">
                                                            <span title={policy.Type === 'Corporate' ? 'Organization' : policy.Type} className="padding-r10">
                                                                <i className="flaticon2-calendar-3"></i>
                                                                {policy.Type === 'Corporate' ? 'Organization' : policy.Type}
                                                            </span>
                                                            <br></br>
                                                            {policy.ClientEmail && (
                                                                <span title={policy.ClientEmail} className="padding-r10">
                                                                    <i className="flaticon2-new-email"></i>
                                                                    {policy.ClientEmail}
                                                                </span>
                                                            )}
                                                            {policy.ClientMobile && (
                                                                <React.Fragment>
                                                                    {policy.ClientID != policy.PrimaryClientID && (<br></br>)}
                                                                    <span title={policy.ClientMobile} className="padding-r10">
                                                                    <i className="flaticon2-phone"></i>
                                                                        {policy.ClientMobile}
                                                                    </span>
                                                                </React.Fragment>  
                                                            )}                                                      
                                                        </div>
                                                    </div>
                                                    {policy.ClientID != policy.PrimaryClientID && (
                                                        <div className="col-6 b-left">
                                                            <div title={
                                                                    policy.Type === 'Corporate'
                                                                    ? policy.ClientCompanyName
                                                                    : policy.PrimaryClientName
                                                                } className="kt-widget__head">
                                                                <span className="kt-widget__username text-clip">
                                                                    {
                                                                        policy.Type === 'Corporate'
                                                                        ? policy.ClientCompanyName
                                                                        : policy.PrimaryClientName
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className="kt-widget__subhead padding-t0 text-clip">
                                                                {policy.PrimaryClientEmail && (
                                                                    <span title={policy.PrimaryClientEmail} className="padding-r10">
                                                                        <i className="flaticon2-new-email"></i>
                                                                        {policy.PrimaryClientEmail}
                                                                    </span>
                                                                )}
                                                                <br></br>
                                                                {policy.PrimaryClientMobile && (
                                                                    <span title={policy.PrimaryClientMobile} className="padding-r10">
                                                                    <i className="flaticon2-phone"></i>
                                                                        {policy.PrimaryClientMobile}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}  
                                                </div>     
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary margin-0 margin-r5"
                                data-dismiss="modal">
                                Close
                            </button>                          
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );

};

export const ShowOTPModal = (props) => {

    const [OTP,              setOTP]              = useState('');
    const [counter,          setCounter]          = useState({m:3, s: 0});
    const [timer,            setTimer]            = useState(20);
    const [OTPTimer,         setOTPTimer]         = useState(180);
    const [intervalId,       setIntervalId]       = useState({});
    const [serverTimeStamp,  setServerTimeStamp]  = useState(props.serverTimeStamp);

    const user = getAuthState('user');

    useEffect(() => {
        $('#OTP-modal').modal({
          backdrop: 'static',
        });
        $('#OTP-modal').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            props.onDismissModal();
        });
    }, [props.show]);

    useEffect(() => {
        let ignore = false;

        if (!ignore && serverTimeStamp) {
            const OTPExpirationSeconds = moment().diff(serverTimeStamp, 'seconds')
            showTimer(180 - OTPExpirationSeconds)
        }
        return () => {
            ignore = true;
        }
    }, [serverTimeStamp]);

    useEffect(() => {
        let ignore = false;
        const updateCounter = () =>{   
            let d = OTPTimer % (60 * 60 );
            let ds = Math.ceil(d % 60);
            let t = {m : Math.floor(d/ 60), s: ds < 10 ?`0${ds}`:ds}
            setCounter(t)
        }
        if(!ignore) { updateCounter() }
        return () => {
            ignore = true;
        }
    }, [OTPTimer])

    //To clear interval's on component unmount
    useEffect(() => {
        if(intervalId.otpInterval && intervalId.resendInterval){
            return () => {
                resetTime()
            }
        }
    }, [intervalId]);

    const showTimer = (secondToExpire) => {
        if (secondToExpire < 0) {
            setOTPTimer(0);
            setTimer(0);
            return
        }

        //OTP RESEND TIMER
        setTimer(20)
        const resendInterval = setInterval(() => {
            setTimer(t => {
                if (t > 0) {
                    return (t-1)
                } else {
                    clearInterval(resendInterval)
                    return 0
                }
            })
        }, 1000);

        //OTP EXP TIMER
        let otpInterval;
        if (secondToExpire <= 180 && secondToExpire > 0) {
            setOTPTimer(secondToExpire);
            otpInterval = setInterval(() => {
                setOTPTimer(t => {
                    if (t > 0) {
                        return (t-1)
                    } else {
                        clearInterval(otpInterval)
                        return 0;
                    }
                })
            }, 1000)
        }
        //SET IntervalIds for Resetting
        setIntervalId({ resendInterval,otpInterval });        
    }

    const resetTime = () => {
        clearInterval(intervalId.resendInterval);
        clearInterval(intervalId.otpInterval);
    }

    const resendHandler = async (e) => {
        try {
            let response = await resendAgentOTP({ email : props.superAdmin.Email, reason: 'deleting the policy' });
            if(response){
                //Clear Inputs
                setOTP('');
                //Reset and start the interval process
                resetTime();
                setServerTimeStamp(response.serverTimestamp)
            }
        } catch (err) {
            swal.fire({
                icon        : 'error',
                titleText   : err.message,
                animation   : true,
                customClass : { popup: 'margin-20', },
            });
        }
    }

    const deletePolicyHandler = async (e) => {
        e.preventDefault();
        const btn = $("#delete-policy-btn");
        const form = $("#delete-policy-form");
        form.validate({
            rules: {
                code: { required: true, minlength: 6 }
            }
        })
        if (!form.valid()) return;
      
        helper.StartProcessing(btn);
        try {
            await deactivatePolicy({OTP, Email: props.superAdmin.Email, PolicyID : props.Policy.PolicyID, PolicyGroupID: props.Policy.PolicyGroupID});
            props.refetchPolicies();
            $('#OTP-modal').modal('hide');
            swal.fire({
                icon              : 'success',
                titleText         : 'Policy Delete Successfully',
                showConfirmButton : false,
                timer             : 1500,
            });
        } catch (err) {
            if(err.message === 'OTP Expired') setTimer(0)
            swal.fire({
                icon         : 'error',
                titleText    : err.message,
                animation    : true,
                customClass  : { popup: 'margin-20', },
            });
            return helper.StopProcessing(btn);
        }
    }

    return (
        <div
            className="modal fade"
            id="OTP-modal"
            tabIndex="-1"
            role="dialog"
            aria-hidden="true">

            <div className="modal-dialog modal-l" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Verify OTP</h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"                               
                            aria-label="Close">
                        </button>
                    </div>
                    <div className="modal-body">
                        <>
                            <div className="kt-login__desc text-center">
                                OTP has been sent to <b>{props.superAdmin.Email}</b>.
                                <br/> 
                                <small>( Check your spam or update folder in case you haven't received OTP )</small>
                            </div>
                            <h5 className="text-center margin-t10"> {(counter.m == 0 && counter.s == 0)?' OTP Expired ':`${counter.m}:${counter.s}`} </h5>
                        </>
                        <form id="delete-policy-form" onSubmit={(e) => {e.preventDefault()}}>
                            <div className="row">
                                <div  className="col">
                                    <input
                                        value={OTP} 
                                        type="number" 
                                        name="code" 
                                        className="form-control " 
                                        placeholder="Enter OTP" 
                                        autoComplete="off"  
                                        onChange={(e) => setOTP(e.target.value)} 
                                    />
                                </div>
                            </div>
                        </form>
                        <div className="row margin-t20">
                            <div className="col text-center">                                                
                                <span className="kt-login__account-msg">
                                    Didn't get it ?
                                </span>
                                &nbsp;&nbsp;
                                {timer > 0 ? `Please wait for ${timer} seconds to Resend OTP `
                                    :<span id="kt_login_otp_resend" className="kt-login__account-link" onClick={resendHandler} > Resend OTP </span>
                                }
                            </div>
                        </div>
                        <div className="row margin-t20">
                            <div className="col d-flex justify-content-center">
                                <button
                                    type="button"
                                    id="delete-policy-btn"
                                    onClick={deletePolicyHandler}
                                    disabled={OTPTimer === 0} 
                                    className="btn btn-brand margin-0">   
                                    Delete Policy
                                </button>                                     
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const policyStats = (props) => {

    const [loading,                 setLoading]                 = useState(false);
    const [btnLoading,              setBtnLoading]              = useState(false)
    const [navState,                setNavState]                = useState('');
    const [policies,                setPolicies]                = useState([]);
    const [filteredPolicies,        setFilteredPolicies]        = useState([]);
    const [searchString,            setSearchString]            = useState('');
    const [statusList,              setStatusList]              = useState([]);
    const [currentStatus,           setCurrentStatus]           = useState(0);
    const [currentPeriod,           setCurrentPeriod]           = useState(props.type !== 'expired' ? periods[0] : expiredPeriods[0]);
    const [fromDate,                setFromDate]                = useState(props.type !== 'expired' ? periods[0].from : expiredPeriods[0].from);
    const [toDate,                  setToDate]                  = useState(props.type !== 'expired' ? periods[0].to : expiredPeriods[0].to);
    const [selectedPolicy,          setSelectedPolicy]          = useState(null);
    const [selectedPolicyHistory,   setSelectedPolicyHistory]   = useState(null);
    const [policyTimeline,          setPolicyTimeline]          = useState([]);
    const [refresh,                 setRefresh]                 = useState(false);
    const [doRefresh,               setDoRefresh]               = useState(false);
    const [firstFetch,              setFirstFetch]              = useState(true);
    const [showPolicyDetails,       setShowPolicyDetails]       = useState(false);
    const [emailPolicy,             setEmailPolicy]             = useState(null);
    const [renewPolicy,             setRenewPolicy]             = useState(null);
    const [editPolicy,              setEditPolicy]              = useState(null);
    const [addDocument,             setAddDocument]             = useState(false);
    const [policyDocumentRefresh,   setPolicyDocumentRefresh]   = useState(false);
    const [editDocument,            setEditDocument]            = useState(null);
    const [policyHistoryRefresh,    setPolicyHistoryRefresh]    = useState(false);
    const [showPolicyHistory,       setShowPolicyHistory]       = useState(false);
    const [policyQuotationRefresh,  setPolicyQuotationRefresh]  = useState(false);
    const [showEdit,                setShowEdit]                = useState(false);
    const [editingQuote,            setEditingQuote]            = useState(null);
    const [convertingQuote,         setConvertingQuote]         = useState(null);
    const [emailList,               setEmailList]               = useState([]);
    const [showEmail,               setShowEmail]               = useState(false);
    const [documentQuoteID,         setDocumentQuoteID]         = useState(null);
    const [emailClientQuotation,    setEmailClientQuotation]    = useState(null);
    const [passwordRequest,         setPasswordRequest]         = useState(null);
    const [serverTimeStamp,         setServerTimeStamp]         = useState();
    const [superAdmin,              setSuperAdmin]              = useState();


    const user = getAuthState('user'); 

    useEffect(() => {

        let ignore = false;
      
        const fetchPolicyStatus = async () => {

            try {
                const statuses = await getPolicyStatusList();

                setStatusList([...statuses.map(s => ({
                    ID      : s.ID,
                    Status  : s.Status,
                    Index   : s.ID == 3 ? 5 : s.ID
                })), 
                    {ID: 0, Status: 'All',          Index: 0}, 
                    {ID: 4, Status: 'Overdue',      Index: 3}, 
                    {ID: 5, Status: 'Renewal Due',  Index: 5}, 
                    {ID: 6, Status: 'Expired',      Index: 6}
                ].sort((a, b) => a.Index - b.Index)); 

                setCurrentStatus(0);                                     
            } catch (err) {
                swal.close();
                swal.fire({
                    icon                : 'error',
                    titleText           : 'Error!',
                    text                : err.message,
                    buttonsStyling      : false,
                    confirmButtonClass  : 'btn btn-brand',
                });             
            }         
        };

        if (!ignore) {
            fetchPolicyStatus();  
        }

        return () => {
            ignore = true;
        };
    }, [props.update]);

    useEffect(() => {
        refetchQuotations();
    }, [props.updatePolicyQuotation])

    useEffect(() => {
        helper.SetScrollHeight();
        $('[data-toggle="kt-popover"]').popover();
    });

    useEffect(() => {
        if (props.type === 'expired') {
            setCurrentPeriod(expiredPeriods[0]);
            fetchPolicies();
        }
        else {
            setCurrentPeriod(periods[0]);
            fetchPolicies()
        }
    }, [props.type]);

    useEffect(() => {
        if (currentPeriod.id != 6) {
            setFromDate(currentPeriod.from);
            setToDate(currentPeriod.to);                        
        }
    }, [currentPeriod]);

    useEffect(() => {  
        if (!firstFetch)
            fetchPolicies();     
    }, [props.update, refresh]);

    useEffect(() => {
        if (currentPeriod.id != 6){
            fetchPolicies();
            if (firstFetch) setFirstFetch(false);
        }           
    }, [fromDate, toDate]);

    useEffect(() => {
        if (policies.length > 0)
            filterPolicies();
        else
            setFilteredPolicies([]);

    }, [policies, currentStatus, searchString]);

    useEffect(() => {
        if (editPolicy && editPolicy.IsBatch) {
            $('#edit-batch-policy-modal').modal({
                backdrop: 'static',
            });
            $('#edit-batch-policy-modal').on('hidden.bs.modal', function () {
                setEditPolicy(null);
            });
        }else{
            $('#edit-batch-policy-modal').modal('hide');
        }
    }, [editPolicy]);

    useEffect(() => {
        setSelectedPolicy(null);
    }, [filteredPolicies]);

    useEffect(() => {
        if(selectedPolicyHistory) {
            setShowPolicyHistory(true)
            setShowPolicyDetails(true);
        }
    }, [selectedPolicyHistory]);

    useEffect(() => {
        if(!showPolicyDetails) {
            setShowPolicyHistory(false)
        }
    }, [showPolicyDetails]);

    useEffect(() => {
        fetchSuperAdminByAgentCompany()
        if (selectedPolicy)
            setNavState(selectedPolicy.IsBatch ? 'Quotations' : 'History')
        else setNavState('');

        if (!selectedPolicy && doRefresh)
            fetchPolicies()

        if (!selectedPolicy && emailList.length > 0) {
            setShowEmail(false)
            setEmailList([])
        }
    }, [selectedPolicy]);

    useEffect(() => {
        if (navState === 'Activity')
            fetchPolicyTimeline();
        else
            setPolicyTimeline([]);
    }, [navState])

    useEffect(() => {
        if (editingQuote) setShowEdit(true);
    }, [editingQuote]);   

    useEffect(() => {
        if (!showEdit) setEditingQuote(null);
    }, [showEdit]);

    const shareInfo = (p) => {
        const mobile = p.ClientMobile ? p.ClientMobile : '';
        const toDate = moment(p.ToDate).format('DD-MM-YYYY');
        const fromDate = moment(p.FromDate).format('DD-MM-YYYY');
        try {
            // Construct the message to share
            const message = `
            Hi *${p.DisplayName}*,
    
Below policy expiring on *${toDate}*

Insured Name               : ${p.DisplayName}
Product                         : ${p.ProductName}
Insurance Company      : ${p.InsuranceCompanyName}
Policy Number	      : ${p.PolicyNo}
Start Date	              : ${fromDate}
End Date	                      : ${toDate}
Thank you
            `;

            if (p.ClientMobile) {
                // whatsappWebUrl = `https://web.whatsapp.com/send?phone=${mobile}&text=${encodeURIComponent(message)}`;
                window.location.href = `whatsapp://send?phone=+91${mobile}&text=${encodeURIComponent(message)}`;
            } else {
                window.location.href = `whatsapp://send?text=${encodeURIComponent(message)}`;
                // whatsappWebUrl = `https://web.whatsapp.com/send?text=${encodeURIComponent(message)}`;
            }
        } catch (error) {
            swal.fire({
                icon: 'error',
                titleText: 'Error!',
                text: error,
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-brand',
            });
        }
    };

//     const shareInfo = (p) => {
//         const mobile = p.ClientMobile ? p.ClientMobile : ''
//         const toDate = moment(p.ToDate).format('DD-MM-YYYY')
//         const fromDate = moment(p.FromDate).format('DD-MM-YYYY')
//         try {
//             // Construct the message to share
//             const message = `
//             Hi *${p.DisplayName}*,

// Below policy expiring on *${toDate}*

// Insured Name               : ${p.DisplayName}
// Product                         : ${p.ProductName}
// Insurance Company      : ${p.InsuranceCompanyName}
// Policy Number	      : ${p.PolicyNo}
// Start Date	              : ${fromDate}
// End Date	                      : ${toDate}
// Thank you
//             `
//             // Construct the WhatsApp Web URL with the pre-filled message
//             let whatsappWebUrl
//             if (p.ClientMobile) {
//                 whatsappWebUrl = `https://web.whatsapp.com/send?phone=${mobile}&text=${encodeURIComponent(message)}`;
//             } else {
//                 whatsappWebUrl = `https://web.whatsapp.com/send?text=${encodeURIComponent(message)}`;
//             }

//             // Open WhatsApp Web in a new tab
//             window.open(whatsappWebUrl);
//         } catch (error) {
//             swal.fire({
//                 icon                  : 'error',
//                 titleText             : 'Error!',
//                 text                  : error,
//                 buttonsStyling        : false,
//                 confirmButtonClass    : 'btn btn-brand',   
//             });
//         }
//     };

    const fetchSuperAdminByAgentCompany = async () =>{
        try {
            const response = await getSuperAdminByAgentCompany()
            setSuperAdmin(response)
        } catch (err) {
            swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    }
    const copyText = (event, policyNo) => {
        event.stopPropagation()
        navigator.clipboard.writeText(policyNo);
        const btn =  $('.copyButton')
        btn.tooltip({
            trigger: 'click',
            placement: 'bottom',
            title:"Copied",
        });
        hideTooltip(btn);
          
        function hideTooltip(btn) {
            setTimeout(function() {
                btn.tooltip('hide');
            }, 500);
        }
    }

    const openFile = async (policy) => {
        const ext       = helper.getFileExt(policy.FileName);
        const name      = (policy.InsuranceCompanyName + ' Policy.' + ext).replace(/ /g, '_');
        helper.downloadAndOpenFileHandler("Policy", policy.FileName, name);                                                    
    }

    const downloadFile = async (policy) => {
        const ext       = helper.getFileExt(policy.FileName);
        const name      = (policy.InsuranceCompanyName + ' Policy.' + ext).replace(/ /g, '_');
        helper.downloadFileToClientHandler("Policy", policy.FileName, name);                                                    
    }

    const fetchPolicyTimeline = async () => {
        try {    
            const timeline = await getPolicyTimeline(selectedPolicy.ID);
            setPolicyTimeline(timeline);
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            }); 
        }
    };

    const fetchPolicies = async () => {
        try {
            setLoading(true);
            if (!fromDate || !toDate) {
                swal.fire({
                    icon                  : 'error',
                    titleText             : 'Error!',
                    text                  : "Invalid Date",
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',   
                });
                setLoading(false);
                return;
            }
            const response = props.type !== 'expired' ? await getPolicyStats(fromDate, toDate) : await getExpiredPolicyStats(fromDate, toDate);
            setPolicies(response);
            setDoRefresh(false);
            setLoading(false);
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        }
    };

    const filterPolicies = async () => {

         let policyList = props.type !== 'expired' ? [] : policies;

        if (props.type !== 'expired') {
            if (currentStatus == 1 || currentStatus == 2)
                policyList = policies.filter((p) => p.StatusID == currentStatus && !p.IsRenewal && !p.IsExpired); 
            else if (currentStatus == 3)
                policyList = policies.filter((p) => p.StatusID == 3); 
            else if (currentStatus == 4)
                policyList = policies.filter((p) => p.Status === 'Overdue'); 
            else if (currentStatus == 5)
                policyList = policies.filter((p) => p.IsRenewal); 
            else if (currentStatus == 6)
                policyList = policies.filter((p) => p.IsExpired && p.StatusID != 3); 
            else
                policyList = policies;
        } 
            
        if (searchString === '') {
            setFilteredPolicies(policyList);
        }else{
            const matchedPolicies = policyList.filter((c) => {
                let matchString = `${c.PolicyNo} ${c.RegistrationNo} ${c.ClientName} ${c.PrimaryClientName} ${c.ClientCompanyName} ${c.ProductName} ${c.InsuranceCompanyName} ${c.BusinessType}`;
                let matchFound = 0;
                const searchArr = searchString.split(' ');
                searchArr.forEach((term) => {
                    matchFound += matchString.toLowerCase().includes(term.toLowerCase())
                        ? 1
                        : 0;
                });
                return matchFound === searchArr.length;
            });
            setFilteredPolicies(matchedPolicies);
        } 

    };

    const changePeriodHandler = ({ target }) => {
        const periodList = props.type === 'expired' ? expiredPeriods : periods;
        const period = periodList.find(p => p.id == target.value)
        setCurrentPeriod(period);           
    };

    const renewPolicyHandler = (p) => {
        if (p.IsBatch)
            props.onOpenModal('policy', true, '', false, { batchPolicy: p });
        else
            setRenewPolicy(p);            
    };

    const updateBatchPolicyHandler = async (p) => {
        try {
            await updateBatchPolicy(p);
            if (p.From === 'policyHistory'){
                setSelectedPolicy((a) => ({
                    ...a,
                    PolicyNo: p.PolicyNo,
                    ClientName: p.ClientName,
                    ToDate: p.ToDate,
                    InsuranceCompanyName: p.InsuranceCompanyName,
                    ProductName: p.ProductName

                }))
                setDoRefresh(true);

            }
                
            else{
                setRefresh((r) => !r);  
            }
        } catch (err) {
            return swal.fire({ 
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
        }    
    };
    

    const createQuoteHandler = (p) => {
        if (p.IsBatch)
            props.onOpenModal('quotation', true, 'policy', false, { batchPolicy: p });
        else
            props.onOpenModal('quotation', true, 'policy', false, { policy: p });              
    };

    const innerCreateQuoteHandler = (p) => {
        if (p.IsBatch)
            props.onOpenModal('quotation', true, 'policyQuotation', false, { batchPolicy: p });
        else
            props.onOpenModal('quotation', true, 'policyQuotation', false, { policy: p });              
    };

    const refetchDocuments = () => setPolicyDocumentRefresh((r) => !r);

    const refetchQuotations = () => {
        setPolicyQuotationRefresh((r) => !r);
        setDoRefresh(true);
    }

    const deletePolicyHandler = async (p) => {
        swal.fire({
            icon              : 'warning',
            title             : 'Are you sure?',
            text              : `You want to delete Policy No. ${p.PolicyNo} and won't be able to revert this!`,
            confirmButtonText : 'Yes',
            showCancelButton  : true,
        }).then(async(result) => {
            if(result.isConfirmed) {
                try {
                    if (p.IsBatch) {
                        await deleteBatchPolicy(p.ID);
                        setRefresh((r) => !r);  
                        swal.fire({
                            icon              : 'success',
                            titleText         : 'Policy Delete Successfully',
                            showConfirmButton : false,
                            timer             : 1500,
                        });
                    } else {
                        const response = await deletePolicyRequest(superAdmin.Email)
                        if(response){
                            setServerTimeStamp(response.serverTimestamp);
                            setPasswordRequest({PolicyID : p.ID, PolicyGroupID : p.GroupID});
                        }
                    }
                } catch (err) {
                    swal.fire({
                        icon              : "error",
                        titleText         : "Error!",
                        text              :  err.message,
                        buttonsStyling    : false,
                        confirmButtonClass: "btn btn-brand",
                    });
                }
            }
        })
    }

    const refreshHandler = () => {
        if (emailPolicy && emailPolicy.type === 'policyHistory'){
            if (emailPolicy.policyId == selectedPolicy.ID && selectedPolicy.StatusID == 1){
                setSelectedPolicy((p) => ({
                    ...p,
                    StatusID: 2,
                    Status: 'Policy Sent'
                }))
                setDoRefresh(true);
            }
        }         
        else{
            setRefresh((r) => !r);   
        }
            
    }

    const refetchPolicyHistoryHandler = (p) => {
        setSelectedPolicy(p);
        setPolicyHistoryRefresh((r) => !r);
        setDoRefresh(true);
    }

    const closeDrawerHandler = () => {      
        setTimeout(() => {
            setSelectedPolicy(null);
        }, 180);
    };

    return (
        <React.Fragment>
            <div className="kt-form kt-form--label-right kt-margin-b-10">
                <div className="row align-items-center stickyFilter">
                    <div className="col-xl-12 order-2 order-xl-1">
                        <div className="row align-items-center">
                            {props.type !== 'expired' ? (
                                <div className={`col-md-${currentPeriod.id == 6 ? '2' : '3'} kt-margin-b-20-tablet-and-mobile`}>
                                    <div className="kt-form__group">
                                        <div className="kt-form__control">
                                            <select                                              
                                                className="form-control kt-selectpicker"
                                                id="ddlPeriod"
                                                value={currentPeriod.id}
                                                onChange={changePeriodHandler}>                                                        
                                                {periods.map((s) => (
                                                    <option value={s.id} key={s.id}>
                                                        {s.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>) : (
                                <div className={`col-md-${currentPeriod.id == 6 ? '2' : '3'} kt-margin-b-20-tablet-and-mobile`}>
                                    <div className="kt-form__group">
                                        <div className="kt-form__control">
                                            <select                                              
                                                className="form-control kt-selectpicker"
                                                value={currentPeriod.id}
                                                onChange={changePeriodHandler}>                                                        
                                                {expiredPeriods.map((s) => (
                                                    <option value={s.id} key={s.id}>
                                                        {s.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {currentPeriod.id == 6 && (
                                <div className="col-md-5 kt-margin-b-20-tablet-and-mobile">
                                    <div className="row align-items-center">
                                        <div className="col-md-10">
                                            <div className="row">
                                                <div className="col-md-5 margin-sm-b20 padding-lr0">
                                                    <div className="kt-form__group">
                                                        <div className="kt-form__control kt-form__group--inline">
                                                            <input
                                                                id="fromDate"
                                                                value={fromDate || ''}
                                                                max={moment().format('YYYY-MM-DD')}
                                                                name="fromDate"
                                                                className="form-control"
                                                                type="date"
                                                                onChange={(event) => setFromDate(event.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2 text-center d-none d-md-block padding-lr0">
                                                    <div className="kt-form__group margin-t5">
                                                        <div className="kt-form__control kt-form__group--inline">
                                                            <label>To</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-5 margin-sm-b20 padding-lr0">
                                                    <div className="kt-form__group">
                                                        <div className="kt-form__control kt-form__group--inline">
                                                            <input
                                                                id="toDate"
                                                                value={toDate || ''}
                                                                max={moment().format('YYYY-MM-DD')}
                                                                name="toDate"
                                                                className="form-control"
                                                                type="date"
                                                                onChange={(event) => setToDate(event.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2 text-center">
                                            <button className="btn btn-brand padding-8 margin-0" onClick={fetchPolicies}>
                                                <i className="la la-search"></i>
                                            </button>
                                        </div>
                                    </div>                                   
                                </div>
                            )}

                            {props.type !== 'expired' && (<div className="col-md-3 kt-margin-b-20-tablet-and-mobile">
                                <div className="kt-form__group kt-form__group--inline">
                                    <div className="kt-form__label">
                                        <label htmlFor="ddlStatus">Status:</label>
                                    </div>
                                    <div className="kt-form__control">
                                        <select
                                            className="form-control kt-selectpicker"
                                            id="ddlStatus"
                                            value={currentStatus}
                                            onChange={(e) => setCurrentStatus(e.target.value)}>                                                 
                                            {statusList.map((s) => (
                                                <option value={s.ID} key={s.ID}>
                                                    {s.Status}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>)}
                            <div className={`col-md-${currentPeriod.id == 6 ? '2' : '3'} kt-margin-b-20-tablet-and-mobile`}>
                                <div className="kt-input-icon kt-input-icon--left">
                                    <input                                      
                                        type="text"
                                        className="form-control"
                                        placeholder="Search Policy..."
                                        id="generalSearch"
                                        value={searchString || ''}
                                        onChange={(e) => setSearchString(e.target.value)}
                                        >
                                    </input>
                                    <span className="kt-input-icon__icon kt-input-icon__icon--left">
                                        <span>
                                            <i className="la la-search"></i>
                                        </span>
                                    </span>
                                    {searchString && (
                                        <span className="kt-input-icon__icon kt-input-icon__icon--right">
                                            <span>
                                                <i className="la la-close" onClick={() => setSearchString('')}></i>
                                            </span>
                                        </span>
                                    )}
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>

                {loading ? (<Loading/>) : (
                    filteredPolicies.length > 0 ? (
                        <div className="row">
                            {selectedPolicy && (
                                <RightSideDetails
                                    onCloseDrawer={closeDrawerHandler}
                                    show={!!selectedPolicy}
                                    title="Policy Details">
    
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="kt-portlet">
                                                <div className="kt-portlet__body">
                                                    <div className="row align-items-center">
                                                        <div className="col-sm-8">
                                                            <div className="kt-widget kt-widget--user-profile-3">
                                                                <div className="kt-widget__top">
                                                                    <div className="kt-widget__media kt-hidden-">
                                                                        <img className="img-profile-3" src={selectedPolicy.ProductIcon} alt="product"/>
                                                                    </div>
                                                                    <div className="kt-widget__content">
                                                                        <button className="btn btn-secondary copyButton" type='button' onClick={(e) => copyText(e, selectedPolicy.PolicyNo)} data-clipboard-text="1" style={{}}>Copy</button>
                                                                        <div title={selectedPolicy.PolicyNo} className="kt-widget__head">
                                                                            <span className="kt-widget__username text-clip">
                                                                                {selectedPolicy.PolicyNo}
                                                                            </span>
                                                                        </div>
                                                                        <div className="kt-widget__subhead padding-t0 text-clip">
                                                                            <span title={selectedPolicy.ProductName} className="padding-r10">
                                                                                {selectedPolicy.ProductName}
                                                                            </span>
                                                                            <br></br>
                                                                            <span title={selectedPolicy.InsuranceCompanyName} className="padding-r10">
                                                                                {selectedPolicy.InsuranceCompanyName}
                                                                            </span>                                          
                                                                        </div>
                                                                        <div className={`policy-status block-badge mr-1 badge display-inline padding-lr20 padding-tb3 margin-l0 badge-${selectedPolicy.Status === 'Renewal Due' ? 'secondary' : (selectedPolicy.Status === 'Policy Issued' ? 'primary' : (selectedPolicy.Status === 'Policy Sent' ? 'success' : (selectedPolicy.Status === 'Expired' ? 'label-danger' : (selectedPolicy.Status === 'Lapsed' ? '' : 'warning'))))}`}>
                                                                            <span>{selectedPolicy.Status}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>                                                       
                                                            </div> 
                                                        </div>
                                                        {!selectedPolicy.IsBatch && (
                                                            <div className="col-sm-4 margin-sm-t20">
                                                                <button className="btn btn-label-brand btn-block"  
                                                                    onClick={() => setShowPolicyDetails(true)}>
                                                                    <i className="la la-file-text"></i>
                                                                    Show Details
                                                                </button>
                                                            </div>
                                                        )}
                                                    </div>                                                  
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div style={{minHeight:'86%'}} className="kt-portlet">
                                                <div className="kt-portlet__body my-auto">
                                                    <div className="kt-widget kt-widget--user-profile-3">
                                                        <div className="kt-widget__top">
                                                            <div
                                                                className={`kt-widget__pic kt-widget__pic--${
                                                                    selectedPolicy.Type === 'Corporate'
                                                                    ? 'success'
                                                                    : 'brand'
                                                                } kt-font-${
                                                                    selectedPolicy.Type === 'Corporate'
                                                                    ? 'success'
                                                                    : 'brand'
                                                                } kt-font-boldest kt-hidden-`}> 
                                                                {selectedPolicy.DisplayName.slice(0, 1)}     
                                                            </div>
                                                            <div style={{maxWidth:'85%'}} className="kt-widget__content">
                                                                <div className="row align-items-center">
                                                                    <div className={`col-${selectedPolicy.ClientID == selectedPolicy.PrimaryClientID ? '12' : '6'}`}>
                                                                        <div title={selectedPolicy.DisplayName} className="kt-widget__head">
                                                                            <span className="kt-widget__username text-clip">
                                                                                {selectedPolicy.DisplayName}
                                                                            </span>
                                                                        </div>
                                                                        <div className="kt-widget__subhead padding-t0 text-clip">
                                                                            <span title={selectedPolicy.Type === 'Corporate' ? 'Organization' : selectedPolicy.Type} className="padding-r10">
                                                                                <i className="flaticon2-calendar-3"></i>
                                                                                {selectedPolicy.Type === 'Corporate' ? 'Organization' : selectedPolicy.Type}
                                                                            </span>
                                                                            <br></br>
                                                                            {selectedPolicy.ClientEmail && (
                                                                                <span title={selectedPolicy.ClientEmail} className="padding-r10">
                                                                                    <i className="flaticon2-new-email"></i>
                                                                                    {selectedPolicy.ClientEmail}
                                                                                </span>
                                                                            )}
                                                                            {selectedPolicy.ClientMobile && (
                                                                                <React.Fragment>
                                                                                    {selectedPolicy.ClientID != selectedPolicy.PrimaryClientID && (<br></br>)}
                                                                                    <span title={selectedPolicy.ClientMobile} className="padding-r10">
                                                                                    <i className="flaticon2-phone"></i>
                                                                                        {selectedPolicy.ClientMobile}
                                                                                    </span>
                                                                                </React.Fragment>  
                                                                            )}
                                                                            
                                                                        </div>
                                                                    </div>
                                                                    {selectedPolicy.ClientID != selectedPolicy.PrimaryClientID && (
                                                                        <div className="col-6 b-left">
                                                                            <div title={
                                                                                    selectedPolicy.Type === 'Corporate'
                                                                                    ? selectedPolicy.ClientCompanyName
                                                                                    : selectedPolicy.PrimaryClientName
                                                                                } className="kt-widget__head">
                                                                                <span className="kt-widget__username text-clip">
                                                                                    {
                                                                                        selectedPolicy.Type === 'Corporate'
                                                                                        ? selectedPolicy.ClientCompanyName
                                                                                        : selectedPolicy.PrimaryClientName
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <div className="kt-widget__subhead padding-t0 text-clip">
                                                                                {selectedPolicy.PrimaryClientEmail && (
                                                                                    <span title={selectedPolicy.PrimaryClientEmail} className="padding-r10">
                                                                                        <i className="flaticon2-new-email"></i>
                                                                                        {selectedPolicy.PrimaryClientEmail}
                                                                                    </span>
                                                                                )}
                                                                                <br></br>
                                                                                {selectedPolicy.PrimaryClientMobile && (
                                                                                    <span title={selectedPolicy.PrimaryClientMobile} className="padding-r10">
                                                                                    <i className="flaticon2-phone"></i>
                                                                                        {selectedPolicy.PrimaryClientMobile}
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    )}  
                                                                </div>     
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div className="kt-portlet kt-portlet--mobile">
                                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                                            <div className="kt-portlet__head-label">
                                                <nav className="file-nav navbar navbar-top">
                                                    <React.Fragment>
                                                        {!selectedPolicy.IsBatch && (
                                                            <a className={navState === 'History' ? 'top-nav-active' : ''}
                                                                href="#History"
                                                                onClick={() => setNavState('History')}>
                                                                Policy History
                                                            </a>
                                                        )}
                                                        <a className={navState === 'Quotations' ? 'top-nav-active' : ''}
                                                            href="#Quotations"
                                                            onClick={() => setNavState('Quotations')}>
                                                            Quotations
                                                        </a>   
                                                        {!selectedPolicy.IsBatch && (
                                                            <React.Fragment>  
                                                                <a className={navState === 'Documents' ? 'top-nav-active' : ''}
                                                                    href="#Documents"
                                                                    onClick={() => setNavState('Documents')}>
                                                                    Documents
                                                                </a> 
                                                                <a className={navState === 'Activity' ? 'top-nav-active' : ''}
                                                                    href="#Activity"
                                                                    onClick={() => setNavState('Activity')}>
                                                                    Activity
                                                                </a> 
                                                            </React.Fragment>
                                                        )}
                                                    </React.Fragment>                          
                                                </nav>
                                            </div>
                                            <div className="kt-portlet__head-toolbar">
                                                <div className="kt-portlet__head-wrapper">
                                                    <div className="kt-portlet__head-actions">
                                                        {navState === 'Documents' && (
                                                            <button className="btn btn-label-brand btn-block margin-0" onClick={() => setAddDocument(true)}>
                                                                <i className="la la-plus"></i>
                                                                Add Documents
                                                            </button>
                                                        )}
                                                        {navState === 'Quotations' && (
                                                            <React.Fragment>
                                                                {(emailList && emailList.length > 0) && (
                                                                        <button
                                                                            className="btn btn-label-brand margin-0"
                                                                            onClick={() => setShowEmail(true)}>                     
                                                                            <i className="fa fa-envelope" />
                                                                            Email
                                                                        </button> 
                                                                )}
                                                                {(selectedPolicy.IsRenewal || (selectedPolicy.IsExpired && selectedPolicy.StatusID != 3)) && (
                                                                    <button className="btn btn-label-brand btn-icon-sm margin-l10"
                                                                        onClick={() => innerCreateQuoteHandler({...selectedPolicy, From: 'quotations'})}
                                                                    >
                                                                        <i className="la la-plus"></i>
                                                                        Add Quote
                                                                    </button> 
                                                                )}
                                                            </React.Fragment>
                                                        )}
                                                        {(navState === 'History' || selectedPolicy.IsBatch) && (
                                                            <React.Fragment>
                                                                {(selectedPolicy.IsRenewal || (selectedPolicy.IsExpired && selectedPolicy.StatusID != 3)) && (
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-success margin-0 margin-l10"
                                                                        data-dismiss="modal"
                                                                        onClick={() => renewPolicyHandler({...selectedPolicy, From: 'policyHistory'})}
                                                                        disabled={btnLoading}>
                                                                        <i className="la la-rotate-right"></i>
                                                                        Renew
                                                                    </button>
                                                                )}
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-label-brand margin-0 margin-l10"
                                                                    data-dismiss="modal"
                                                                    onClick={() => setEditPolicy({...selectedPolicy, From: 'policyHistory'})}
                                                                    disabled={btnLoading}>
                                                                    <i className="flaticon2-edit"></i>
                                                                    Edit
                                                                </button>
                                                                {user.categoryId !== 3 && (
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-label-brand margin-0 margin-l10"
                                                                        data-dismiss="modal"
                                                                        onClick={() => deletePolicyHandler(selectedPolicy)}
                                                                        disabled={btnLoading}>
                                                                        <i className="flaticon2-rubbish-bin-delete-button padding-r0"></i>
                                                                    </button>
                                                                )}
                                                            </React.Fragment>
                                                        )}                    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`kt-portlet__body ${navState === 'Activity' ? '' : 'padding-0'}`}>
                                            {(navState === 'History') && (
                                                <PolicyHistory
                                                    refresh={policyHistoryRefresh}
                                                    policyDetails={selectedPolicy}
                                                    policyID={selectedPolicy.ID}
                                                    status={selectedPolicy.Status}
                                                    setEmailPolicy={setEmailPolicy}
                                                    setEditPolicy={setEditPolicy}
                                                    setSelectedPolicy={setSelectedPolicyHistory}
                                                />
                                            )}
                                            {(navState === 'Quotations') && (
                                                <PolicyQuotation
                                                    policyID={selectedPolicy.ID}
                                                    policyGroupID={selectedPolicy.GroupID}
                                                    setDoRefresh={setDoRefresh}
                                                    isBatch={selectedPolicy.IsBatch}
                                                    refresh={policyQuotationRefresh}
                                                    setRefresh={() => setPolicyQuotationRefresh((r) => !r)}
                                                    setEditingQuote={setEditingQuote}
                                                    setDocumentQuoteID={setDocumentQuoteID}
                                                    setEmailList={setEmailList}
                                                    setEmailClientQuotation={setEmailClientQuotation}
                                                    setConvertingQuote={setConvertingQuote}
                                                />
                                            )}
                                            {navState === 'Documents' && (
                                                <PolicyDocuments 
                                                    policyDetails={selectedPolicy}
                                                    refresh={policyDocumentRefresh}    
                                                    setEditDocument={setEditDocument}
                                                />
                                            )}  
                                            {navState === 'Activity' && (
                                                <div className="kt-timeline-v2">
                                                    <div className="kt-timeline-v2__items kt-padding-top-25 kt-padding-bottom-30 scrolTable" style={{minHeight: '8vh'}}>
                                                        {policyTimeline && policyTimeline.length > 0 && (
                                                            policyTimeline.map(p => (
                                                                <div key={p.ID} className="kt-timeline-v2__item"> 
                                                                    <div className="kt-timeline-v2__item-cricle">
                                                                        <i className={`fa fa-genderless kt-font-${p.Comments === 'Policy Deleted' ? 'danger' : p.Comments === 'Policy Updated' ? 'warning' : (p.Comments === 'Policy Sent' || p.Comments === 'Quote Sent' ? 'success' : (p.Comments === 'Quote Deleted' ? 'danger' : 'brand'))}`}></i>
                                                                    </div>
                                                                    <div className="kt-timeline-v2__item-text kt-padding-top-5">
                                                                        <span className="kt-font-bolder">{p.Comments}</span> By {p.UserName} <br/><span className="kt-font-bolder">{helper.getFormatedDate(p.CreatedDate)}</span>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        )}                                                   
                                                    </div>
                                                </div>
                                            )}         
                                        </div>
                                    </div>
    
                                </RightSideDetails>                  
                            )}
    
                            <div className="col-sm-12">
                                <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
                                    <table className="kt-datatable__table">
                                        <thead
                                            className="kt-datatable__head"
                                            style={{ display: 'block' }}>
    
                                            <tr className="kt-datatable__row block-row">
                                                <th className="kt-datatable__cell text-clip" width="16%">
                                                    <span className="padding-l10">Policy Number</span>
                                                </th>
                                                <th className="kt-datatable__cell text-clip" width="16%">
                                                    <span className="padding-l10">Insured Name</span>
                                                </th>
                                                <th className="kt-datatable__cell text-clip" width="15%">        
                                                    <span>Client Name</span>
                                                </th>
                                                <th className="kt-datatable__cell text-clip" width="10%">
                                                    <span>End Date</span>
                                                </th>
                                                <th className="kt-datatable__cell text-clip" width="15%">
                                                    <span>Company</span>
                                                </th>
                                                <th className="kt-datatable__cell--center kt-datatable__cell text-clip" width="10%">
                                                    <span>Business</span>
                                                </th>
                                                <th className="kt-datatable__cell--center kt-datatable__cell text-clip" width="13%">
                                                    <span>Status</span>
                                                </th>
                                                <th className="kt-datatable__cell--center kt-datatable__cell text-clip" width="5%">
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
    
                                <div 
                                    className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded scrolTable"
                                    onScroll={helper.setStickyHeader}>
                                    <table className="kt-datatable__table table-striped">
                                        <tbody
                                            className="kt-datatable__body"
                                            style={{ display: 'block', minHeight:'40vh' }}>
                                            {filteredPolicies.map((p, i) => (
                                                <tr
                                                    key={i}
                                                    className="kt-datatable__row block-row clickable">                                                   
                                                    <td title={p.PolicyNo} width="16%" className="kt-datatable__cell text-clip" onClick={() =>  setSelectedPolicy(p)}>
                                                        <div className="kt-user-card-v2">
                                                            <div className="kt-user-card-v2__pic">
                                                                <img src={p.ProductIcon} alt="product"/>
                                                            </div>
                                                            <div className="kt-user-card-v2__details text-clip">
                                                                <button className="btn btn-secondary copyButton" onClick={(e) => copyText(e, p.PolicyNo)} data-clipboard-text="1">Copy</button>
                                                                <span className="kt-user-card-v2__name text-clip addPopover">
                                                                    {p.PolicyNo}                  
                                                                </span>                                                               
                                                                <span className="kt-user-card-v2__desc text-clip" style={{ display: 'block' }} title={p.ProductName}>{p.ProductName}</span>
                                                            </div>
                                                            {(p.IsNew || p.QuotationStatusID == 1 || p.QuotationStatusID == 2) && (
                                                                <span className='kt-badge kt-badge--success kt-badge--inline kt-badge--pill policy-badge' title={p.IsNew ? 'New Policy' : p.QuotationStatus}>
                                                                    {p.IsNew ? 'New' : (p.QuotationStatusID == 2 ? 'QS' : 'QC')}
                                                                </span>  
                                                            )}                                                      
                                                        </div>                                                       
                                                    </td>
                                                    <td width="16%" title={p.DisplayName} className="kt-datatable__cell text-clip" onClick={() =>  setSelectedPolicy(p)}>
                                                        <div className="kt-user-card-v2">
                                                            <div className="kt-user-card-v2__pic">
                                                                <div
                                                                    className={`img-small-profile kt-badge kt-badge--xl kt-badge--${
                                                                    p.Type === 'Corporate' ? 'success' : p.IsBatch ? 'info-light' : 'info'
                                                                    }`}>
                                                                    {p.DisplayName.slice(0, 1)}
                                                                </div>
                                                            </div>
                                                            <div className="kt-user-card-v2__details text-clip">
                                                                <button className="btn btn-secondary copyButton" onClick={(e) => copyText(e,p.DisplayName)} data-clipboard-text="1">Copy</button>
                                                                <span className="kt-user-card-v2__name text-clip">{p.DisplayName}</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td width="15%" title={p.Type === 'Corporate' ? p.ClientCompanyName : p.PrimaryClientName} className="kt-datatable__cell text-clip" onClick={() =>  setSelectedPolicy(p)}>
                                                        <span>{p.Type === 'Corporate' ? p.ClientCompanyName : p.PrimaryClientName}</span>
                                                    </td>
                                                    <td width="10%" title={moment(p.ToDate).format('DD/MM/YYYY')} className="kt-datatable__cell text-clip" onClick={() =>  setSelectedPolicy(p)}>
                                                        <span className="kt-font-bold">{moment(p.ToDate).format('DD/MM/YYYY')}</span>
                                                    </td>
                                                    <td width="15%" title={p.InsuranceCompanyName} className="kt-datatable__cell text-clip" onClick={() => setSelectedPolicy(p)}>
                                                        <span>{p.InsuranceCompanyName}</span>
                                                    </td>
                                                    <td width="10%" title={p.BusinessType} className="kt-datatable__cell--center kt-datatable__cell text-clip" onClick={() =>  setSelectedPolicy(p)}>
                                                        <span>{p.BusinessType}</span>
                                                    </td>
                                                    <td width="13%" title={p.Status} className="kt-datatable__cell text-clip" onClick={() => setSelectedPolicy(p)}>
                                                        <div className={`policy-status block-badge mr-1 badge badge-${p.Status === 'Renewal Due' ? 'secondary' : (p.Status === 'Policy Issued' ? 'primary' : (p.Status === 'Policy Sent' ? 'success' : (p.Status === 'Expired' ? 'label-danger' : (p.Status === 'Lapsed' ? '' : 'warning'))))}`}>
                                                            <span>{p.Status}</span>
                                                        </div>
                                                    </td>
                                                    <td width="5%" className="kt-datatable__cell">
                                                        <div className='dropdown'>
                                                            <a className='btn btn-sm btn-clean btn-icon btn-icon-md' data-toggle='dropdown' aria-expanded='true'>
                                                                <i className="flaticon-more-1"></i>
                                                            </a>
                                                            <div className='dropdown-menu dropdown-menu-right' x-placement='top-end'>
                                                                {!p.IsBatch && (
                                                                    <React.Fragment>
                                                                        <a className='dropdown-item' onClick={() => openFile(p)}>
                                                                            <i className='la la-file-text'></i>
                                                                            View
                                                                        </a>
                                                                        <a className='dropdown-item' onClick={() => downloadFile(p)}>
                                                                            <i className='la la-download'></i>
                                                                            Download
                                                                        </a>
                                                                        <a className='dropdown-item' onClick={() => setEmailPolicy({policyId: p.ID, clientGroupID: p.ClientGroupID, type: 'policy'})}>
                                                                            <i className='la la-envelope'></i>
                                                                            Email
                                                                        </a>
                                                                    </React.Fragment>
                                                                )}
                                                                <a className='dropdown-item' onClick={() => setEditPolicy(p)}>
                                                                    <i className='la la-edit'></i>
                                                                    Edit
                                                                </a>
                                                                <a className='dropdown-item' onClick={() => shareInfo(p)}>
                                                                    <i className='la la-whatsapp'></i>
                                                                    Send Reminder
                                                                </a>
                                                                {(p.IsRenewal || (p.IsExpired && p.StatusID != 3)) && (
                                                                    <React.Fragment>
                                                                        <a className='dropdown-item' onClick={() => renewPolicyHandler(p)}>
                                                                            <i className='la la-rotate-left'></i>
                                                                            Renew
                                                                        </a>
                                                                        <a className='dropdown-item' onClick={() => createQuoteHandler(p)}>
                                                                            <i className='flaticon-interface-4'></i>
                                                                            Create Quote
                                                                        </a>  
                                                                    </React.Fragment>
                                                                )}      
                                                                {user.categoryId == 1 && (
                                                                    <a className='dropdown-item' onClick={() => deletePolicyHandler(p)}>
                                                                        <i className='la la-trash'></i>
                                                                        Delete
                                                                    </a>
                                                                )}                                                           
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <NoData
                            src={PolicyImg}
                            alt="Policy"
                            message="No Policies Found"
                            from="policy"
                            navigate='/batch-upload/policies/upload'
                            btnName="Add Policy"
                            show="Y"
                            onOpenModal={props.onOpenModal}
                        />
                    )
                )}        
            </div>
            {(selectedPolicy || selectedPolicyHistory) && (
                <React.Fragment>
                    {showPolicyDetails && (
                        <PolicyDetails
                            policy={showPolicyHistory ? selectedPolicyHistory : selectedPolicy}
                            policyID={selectedPolicy.ID}
                            show={showPolicyDetails}
                            onDismissModal={setShowPolicyDetails}                   
                        />
                    )}
                </React.Fragment>               
            )}
            {passwordRequest && (
                <ShowOTPModal
                    show={passwordRequest}
                    onDismissModal={() => setPasswordRequest(null)}    
                    serverTimeStamp={serverTimeStamp}
                    Policy={passwordRequest}
                    refetchPolicies={() => setRefresh((r) => !r)}
                    superAdmin={superAdmin}
                />
            )}
            {emailPolicy && (
                <EmailPolicy
                    action="e"
                    groupId={emailPolicy.clientGroupID}
                    policyId={emailPolicy.policyId}  
                    refetchPolicies={refreshHandler}
                    onDismissModal={() => setEmailPolicy(null)}
                />
            )}
            {renewPolicy && (
                <RenewPolicy
                    policyDetails={renewPolicy}
                    refetchPolicies={() => setRefresh((r) => !r)}
                    refetchPolicyHistory={refetchPolicyHistoryHandler}
                    onDismissModal={() => setRenewPolicy(null)}                    
                />
            )}
            {editPolicy && (
                <React.Fragment>
                    {editPolicy.IsBatch ? (
                        <EditBatchPolicy
                            policy={editPolicy}
                            updatePolicy={updateBatchPolicyHandler}
                            action="e"
                        />
                    ) : (
                        <EditPolicy
                            from="Policy"
                            policyDetails={editPolicy}
                            refetchPolicies={() => setRefresh((r) => !r)}
                            refetchPolicyHistory={refetchPolicyHistoryHandler}
                            onDismissModal={() => setEditPolicy(null)}
                            onOpenModal={props.onOpenModal}
                            policyData={props.policyData}
                        />

                    )}
                </React.Fragment>          
            )}       
            {addDocument && (
                <AddDocument
                    policyID={selectedPolicy.ID}
                    addDocument={setAddDocument}
                    refetchDocuments={refetchDocuments}
                    action="e"
                />
            )}
            {showEdit &&(
                <EditQuotation
                    action="e"
                    quotation={editingQuote}
                    show={showEdit}
                    onDismissModal={setShowEdit}
                    refetchQuotations={refetchQuotations}
                />
            )}
            {convertingQuote && (
                <ConvertQuotation
                    quotation={convertingQuote}
                    onDismissModal={setConvertingQuote}
                    refetchQuotations={refetchQuotations}
                />
            )}
            {showEmail && (
                <EmailQuotation
                    from="Policy"
                    action="e"
                    groupId={selectedPolicy.IsBatch ? emailClientQuotation : selectedPolicy.ClientGroupID}
                    quotationIds={emailList.map(q => q.ID)}
                    show={showEmail}
                    onDismissModal={setShowEmail}
                    setEmailList={setEmailList}
                    refetchQuotations={refetchQuotations}
                />
            )}
            {editDocument && (
                <EditPolicyDocument
                    documentDetails={editDocument}
                    show={!!editDocument}
                    refetchDocuments={refetchDocuments}
                    onDismissModal={() => setEditDocument(null)}
                />
            )}
            {documentQuoteID && (
                <ShowDocuments
                    quotationID={documentQuoteID} 
                    setDocumentQuoteID={setDocumentQuoteID}
                />
            )}
        </React.Fragment>
    );
};

export default policyStats;