import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom'
import $ from 'jquery';
import 'jquery-form'
import 'jquery-validation'
import '../../assets/scripts/jquery-validation.init'
import * as helper from '../global/helper'
import swal from "sweetalert2";
import moment from 'moment';
import {verifyAgentEmail, resendSignupOTP, resetAgentPassword, resendAgentOTP} from "../api/agentAPI";
import { setTempState, setAuthState } from '../../assets/scripts/login-util';
import { checkVersion } from '../../assets/scripts/versionComparer';

const CHANGE_PASSWORD_STRING = 'change-password';
const VERIFY_EMAIL_STRING    = 'email';

const OTP = props => {

    const actions                          = [ VERIFY_EMAIL_STRING, CHANGE_PASSWORD_STRING ];
    const history                          = useHistory();
    const match                            = useRouteMatch();
    const location                         = useLocation();
    const { action }                       = match.params
    const [user,         setUser]          = useState({});
    const [OTP,          setOtp]           = useState('');
    const [timer,        setTimer]         = useState(20);
    const [otpTimer,     setOtpTimer]      = useState(180);
    const [counter,      setCounter ]      = useState({ m: 3, s: 0 });
    const [intervalId,   setIntervalId]    = useState({});
    const [password,     setPassword]      = useState('');
    const [showPassword, setShowPassword]  = useState(false);
   
    useLayoutEffect(() => {
        if(!actions.includes(action)) history.push('/');
    }, [])

    useLayoutEffect(()=>{
        if(!location.state) return history.push('/signin');  
        setUser(location.state)
        const OTPExpirationSeconds = moment().diff(location.state.serverTimestamp, 'seconds');
        showTimer(180 - OTPExpirationSeconds);
    }, [location.state])
    
    useEffect(()=>{
        let d  = otpTimer % (60 * 60 );
        let ds = Math.ceil(d % 60);
        let t  = { m : Math.floor(d/ 60), s: ds < 10 ?`0${ds}`:ds }
        setCounter(t)
    }, [otpTimer])

    const showTimer = (secondsToExpire) => {
        if(secondsToExpire < 0) {
            setOtpTimer(0);
            setTimer(0)
            return
        } 
        
        //OTP RESEND TIMER
        setTimer(20)
        const resendInterval = setInterval(() => {
            setTimer(t =>{
                if(t > 0){
                    return (t-1)
                }else{
                    clearInterval(resendInterval)
                    return 0
                }
            })
        }, 1000);
        
        //OTP EXP TIMER
        let otpInterval;
        if(secondsToExpire <= 180 && secondsToExpire > 0){
            setOtpTimer(secondsToExpire);
                otpInterval = setInterval(() => {
                setOtpTimer(t =>{
                    if(t > 0){
                        return (t-1)
                    }else{
                        clearInterval(otpInterval)
                        return 0
                    }
                })
            }, 1000);
        }
        //SET IntervalIds for Resetting
        setIntervalId({ resendInterval,otpInterval });        
    }

    const resetTime = () => {
        clearInterval(intervalId.resendInterval)
        clearInterval(intervalId.otpInterval);
    }

    const showPasswordHandler = () => setShowPassword(!showPassword);

    const codeChangeHandler = (e) => setOtp(e.target.value.trim());

    const resetPasswordHandler = async () => {
        const btn = $("#kt_login_otp_submit");
        const form = $("#kt_login_otp_submit").closest('form');
        form.validate({
            rules: {
                code: { required: true, minlength: 6 },
                password: { required: true, minlength: 6, maxlength: 100 }
            }
        })
        if (!form.valid()) return;

        helper.StartProcessing(btn)
        try {
           const response = await resetAgentPassword({ OTP, Email : user.Email, password });
           if(response){
                swal.fire({
                    toast               : true,
                    icon                : 'success',
                    titleText           : `Password Changed successfully!`,
                    position            : 'bottom-end',
                    showConfirmButton   : false,
                    timer               : 2500,
                    animation           : true,
                    customClass         : { popup: 'margin-20', },
                });
                history.push("/")
           }
        } catch (err) {
            if(err.message === 'OTP Expired') setTimer(0)
            swal.fire({
                icon                : 'error',
                titleText           : err.message,
                animation           : true,
                customClass         : { popup: 'margin-20', },
            });
            return helper.StopProcessing(btn);
        }
    }

    // SUBMIT HANDLER --->>
    const submitHandler = async (e) => {
        e.preventDefault();
        if (action === CHANGE_PASSWORD_STRING) return resetPasswordHandler();

        const btn = $("#kt_login_otp_submit");
        const form = $("#kt_login_otp_submit").closest('form');

        form.validate({
            rules: {
                code: {
                    required: true
                }
            }
        });

        if (!form.valid()) return;

        helper.StartProcessing(btn);
        try {
            const response = await verifyAgentEmail({ Email : user.Email, OTP, isRemember: user.isRemember, device: user.device });
            if(!response.user){
               // Create a Session Cookie 
               setTempState(response);  
               props.onLogin('created');
            }else{
                setAuthState(response, user.isRemember);
                checkVersion(response.FE_VERSION);
                props.onLogin();
            }
        } catch (err) {
            if(err.message === 'OTP Expired') setTimer(0)
            swal.fire({
                icon                : 'error',
                titleText           : err.message,
                animation           : true,
                customClass         : { popup: 'margin-20', },
            });
            return helper.StopProcessing(btn);
        }
        resetTime(); 
        swal.fire({
            toast               : true,
            icon                : 'success',
            titleText           : `Email Verified successfully!`,
            position            : 'bottom-end',
            showConfirmButton   : false,
            timer               : 2000,
            animation           : true,
            customClass         : { popup: 'margin-20', },
        });
    }

    const resendHandler = async (e) => {
        e.preventDefault();
        
        try {
            let response ;

            if(action === CHANGE_PASSWORD_STRING){
                if(!location.state.agentSignup)
                    response = await resendAgentOTP({email : user.Email, reason: 'changing your password'});    
                else
                    response  = await resendSignupOTP(user.Email);    
            }

            if (action === VERIFY_EMAIL_STRING ){
                if(!location.state.emailVerification)
                    response  = await resendSignupOTP(user.Email);
                else
                    response = await resendAgentOTP({email : user.Email, reason: 'email Verification'});
            }
            if(response){
                //Clear Inputs, Reset and Start the interval process
                setPassword('')
                setOtp('');
                resetTime();  

                if(location.state.agentSignup){
                    history.replace({ state: {Email: user.Email, serverTimestamp : response.serverTimestamp, agentSignup : true  }});
                }else if(location.state.emailVerification){
                    const stateObj = {
                        Email               : user.Email, 
                        serverTimestamp     : response.serverTimestamp, 
                        emailVerification   : true, 
                        isRemember          : location.state.isRemember,
                        device              : location.state.device,
                    } 
                    history.replace({ state: stateObj });
                }else{
                    history.replace({ state: {Email  :user.Email, serverTimestamp : response.serverTimestamp  }});
                }
            }

        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : err.message,
                animation           : true,
                customClass         : { popup: 'margin-20', },
            });
        }
    }

    return (
        <div className="kt-login__forgot">
            <div className="kt-login__head">
                <h3 className="kt-login__title">{action === CHANGE_PASSWORD_STRING ?"Reset Password": "Email Verification"}</h3>
                <div className="kt-login__desc">
                    OTP has been sent to  <b>{user.Email}</b>.<br/>
                    <small>( Check your spam or update folder in case you haven't received OTP)</small>
                </div>
                <h5 className="text-center  mt-2"> {(counter.m == 0 && counter.s == 0)?' OTP Expired ':`${counter.m}:${counter.s}`} </h5>
            </div>
            <form className="kt-form" action="">
                <div className="input-group">
                    <input value={OTP} onChange={codeChangeHandler} className="form-control form-control-lg" type="number" placeholder="Enter OTP" name="code" autoComplete="off" />
                </div>
                {
                    action === CHANGE_PASSWORD_STRING &&
                    <div className="input-group" >
                        <div className="kt-input-icon kt-input-icon--right">
                            <input 
                                name="password" 
                                value={ password }
                                className="form-control form-control-lg password-input"  
                                type={showPassword ? 'text':'password'}
                                onChange={({ target }) => setPassword(target.value.trim())} 
                                placeholder="Enter a new password"
                            />
                            <span className="kt-input-icon__icon kt-input-icon__icon--right show-password-icon" onClick={showPasswordHandler} >
                                <span>  <i className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}></i> </span>
                            </span>
                        </div>
                    </div>
                }
                <div className="kt-login__actions">
                    <button  onClick={submitHandler} id="kt_login_otp_submit" className="btn btn-theme btn-block btn-elevate kt-login__btn-primary" disabled={otpTimer === 0}  >Verify otp {action === 'change-password' ? ' and reset password' : ''}</button>
                </div>
                <div className="kt-login__account margin-t20">
                    <span className="kt-login__account-msg">
                        Didn't get it ?
                        </span>
                        &nbsp;&nbsp;
                        {timer > 0 ? `Please wait for ${timer} seconds to Resend OTP `
                            :<span id="kt_login_otp_resend" className="kt-login__account-link" onClick={resendHandler} > Resend OTP </span>
                        }
                </div>
            </form>
        </div>
    )

};

export default OTP;