import React, {useState, useEffect} from "react";
import { deleteRevenueMaster, getIncentiveTarget, getRevenueMaster } from "../api/policyAPI";
import ReportImg from '../../assets/images/no-report.svg'
import NoData from '../global/noData';
import Loading from "../global/loading";
import * as helper from '../global/helper';
import 'bootstrap/js/dist/popover';
import swal from 'sweetalert2';
import moment from 'moment';
import $ from 'jquery';
import Select from 'react-select';
import { getInsuranceCompanies, getProducts } from '../api/policyAPI';
import '../../assets/scripts/jquery-validation.init';
import 'jquery-form';
import 'jquery-validation';
import EditRevenueMaster from "./editRevenueMaster";
import { getAuthState } from '../../assets/scripts/login-util';

const ShowRevenueDetails = (props) => {

    const [revenueDetails, setRevenueDetails] = useState(props.revenueDetails);
    const [targetDetails,  setTargetDetails]  = useState([])

    useEffect(() => {
        $('#revenue-detail-modal').modal({
          backdrop: 'static',
        });
        $('#revenue-detail-modal').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            props.onDismissModal(null);
        });
        if (props.revenueDetails.IncentiveType === 'Target')
            fetchIncentiveTarget();
    }, [props.show]);

    const fetchIncentiveTarget = async () => {
        try {
            const response = await getIncentiveTarget(revenueDetails.ID);
            setTargetDetails(response);
        } catch (err) {
            swal.close();
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        }
    }

    return (
        <>
            <div
                className="modal fade"
                id="revenue-detail-modal"
                tabIndex="-1"
                role="dialog"
                aria-hidden="true">

                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Revenue Details</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"                               
                                aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body grey-background">
                            <div className="kt-portlet">
                                <div className="kt-portlet__body">
                                    <div className="kt-widget kt-widget--user-profile-3">
                                        <div className="kt-widget__top">
                                            <div className="kt-widget__media kt-hidden-">
                                                <img className="img-profile-3" src={revenueDetails.ProductIcon} alt="product"/>
                                            </div>
                                            <div className="kt-widget__content">
                                                <div className="row align-items-center">
                                                    <div className="col-sm-8">
                                                        <div title={revenueDetails.InsuranceCompanyName} className="kt-widget__head">
                                                            <span className="kt-widget__username text-clip">
                                                                {revenueDetails.InsuranceCompanyName}
                                                            </span>
                                                        </div>
                                                        <div className="kt-widget__subhead padding-0 text-clip">
                                                            <span title={revenueDetails.ProductName} className="padding-r10">
                                                                {revenueDetails.ProductName}
                                                            </span>                                           
                                                        </div>
                                                    </div>
                                                </div>                                                  
                                            </div>
                                        </div>
                                        <div className='kt-widget__bottom d-block'>
                                            <div className="row">
                                                <div className={`col-lg-${revenueDetails.IncentiveType === 'Target'? '2' : '4'} col-sm-6`}>
                                                    <div className="kt-widget__item">
                                                        <div className="kt-widget__icon">
                                                            <i className="flaticon-refresh"></i>
                                                        </div>
                                                        <div className="kt-widget__details">
                                                            <span className="kt-widget__title">Type</span>
                                                            <span className="kt-widget__value">
                                                                {revenueDetails.CommissionType}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {revenueDetails.IncentiveType === 'Target' && (
                                                    <>
                                                        <div className="col-lg-2 col-sm-6">
                                                            <div className="kt-widget__item">
                                                                <div className="kt-widget__icon">
                                                                    <i className="flaticon-coins"></i>
                                                                </div>
                                                                <div className="kt-widget__details">
                                                                    <span className="kt-widget__title">Incentive</span>
                                                                    <span className="kt-widget__value">
                                                                        {revenueDetails.IncentiveType}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-sm-6">
                                                            <div className="kt-widget__item">
                                                                <div className="kt-widget__icon">
                                                                    <i className="la la-calculator"></i>
                                                                </div>
                                                                <div className="kt-widget__details">
                                                                    <span className="kt-widget__title">TDS</span>                                                      
                                                                    <span className="kt-widget__value">
                                                                        {revenueDetails.TDS + '%'}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                <div className={`col-lg-${revenueDetails.IncentiveType === 'Target'? '3' : '4'} col-sm-6`}>
                                                    <div className="kt-widget__item">
                                                        <div className="kt-widget__icon">
                                                            <i className="flaticon-calendar-1"></i>
                                                        </div>
                                                        <div className="kt-widget__details">
                                                            <span className="kt-widget__title">Start Date</span>                                                      
                                                            <span className="kt-widget__value">
                                                                {moment(revenueDetails.FromDate).format('DD/MM/YYYY')}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`col-lg-${revenueDetails.IncentiveType === 'Target'? '3' : '4'} col-sm-6`}>
                                                    <div className="kt-widget__item">
                                                        <div className="kt-widget__icon">
                                                            <i className="flaticon-calendar-1"></i>
                                                        </div>
                                                        <div className="kt-widget__details">
                                                            <span className="kt-widget__title">End Date</span>
                                                            <span className="kt-widget__value">
                                                                {moment(revenueDetails.ToDate).format('DD/MM/YYYY')}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {revenueDetails.CommissionType === 'Standard' ? (
                                                    <>
                                                        <div className="col-lg-4 col-sm-6">
                                                            <div className="kt-widget__item">
                                                                <div className="kt-widget__icon">
                                                                    <i className="flaticon-coins"></i>
                                                                </div>
                                                                <div className="kt-widget__details">
                                                                    <span className="kt-widget__title">Commission</span>                                                      
                                                                    <span className="kt-widget__value">
                                                                        {revenueDetails.Commission + '%'}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-sm-6">
                                                            <div className="kt-widget__item">
                                                                <div className="kt-widget__icon">
                                                                    <i className="la la-calculator"></i>
                                                                </div>
                                                                <div className="kt-widget__details">
                                                                    <span className="kt-widget__title">TDS</span>                                                      
                                                                    <span className="kt-widget__value">
                                                                        {revenueDetails.TDS + '%'}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                        revenueDetails.IncentiveType === 'Flat' ? (
                                                            <>
                                                                <div className="col-lg-4 col-sm-6">
                                                                    <div className="kt-widget__item">
                                                                        <div className="kt-widget__icon">
                                                                            <i className="flaticon-coins"></i>
                                                                        </div>
                                                                        <div className="kt-widget__details">
                                                                            <span className="kt-widget__title">Incentive</span>
                                                                            <span className="kt-widget__value">
                                                                                {revenueDetails.IncentiveType}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-sm-6">
                                                                    <div className="kt-widget__item">
                                                                        <div className="kt-widget__icon">
                                                                            <i className="flaticon-piggy-bank"></i>
                                                                        </div>
                                                                        <div className="kt-widget__details">
                                                                            <span className="kt-widget__title">Percent</span>
                                                                            <span className="kt-widget__value">
                                                                                {revenueDetails.FlatPercent + '%'}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-sm-6">
                                                                    <div className="kt-widget__item">
                                                                        <div className="kt-widget__icon">
                                                                            <i className="la la-calculator"></i>
                                                                        </div>
                                                                        <div className="kt-widget__details">
                                                                            <span className="kt-widget__title">TDS</span>                                                      
                                                                            <span className="kt-widget__value">
                                                                                {revenueDetails.TDS + '%'}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            targetDetails && targetDetails.length > 0 && (
                                                                <div className="kt-widget__bottom">
                                                                    <div className="row">
                                                                        <div className="col-sm-12">
                                                                            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
                                                                                <table className="kt-datatable__table">
                                                                                    <thead
                                                                                        className="kt-datatable__head"
                                                                                        style={{ display: 'block' }}>
                                                                                        <tr className="kt-datatable__row block-row">
                                                                                            <th className="kt-datatable__cell text-clip" width="30%">
                                                                                                <span>Start Range</span>
                                                                                            </th>
                                                                                            <th className="kt-datatable__cell text-clip" width="30%">        
                                                                                                <span>End Range</span>
                                                                                            </th>
                                                                                            <th className="kt-datatable__cell text-clip" width="25%">
                                                                                                <span>Percentage</span>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                </table>
                                                                            </div>
                                                                            <div 
                                                                                className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0"
                                                                                onScroll={helper.setStickyHeader}>
                                                                                <table className="kt-datatable__table table-striped">
                                                                                    <tbody
                                                                                        className="kt-datatable__body"
                                                                                        style={{ display: 'block', minHeight: '5vh'}}>
                                                                                        {targetDetails.map((t, i) => (
                                                                                            <tr
                                                                                                key={i}
                                                                                                className="kt-datatable__row block-row"> 
                                                                                                <td width="35%" title={t.StartRange} className="kt-datatable__cell text-clip">
                                                                                                    <span><i className='la la-rupee'/>{t.StartRange}</span>
                                                                                                </td>
                                                                                                <td width="35%" title={t.EndRange} className="kt-datatable__cell text-clip">
                                                                                                    <span><i className='la la-rupee'/>{t.EndRange}</span>
                                                                                                </td>
                                                                                                <td width="30%" title={t.TargetPercent} className="kt-datatable__cell text-clip">
                                                                                                    <span>{t.TargetPercent + '%'}</span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const RevenueMasterTable = (props) => {

    const [loading,                 setLoading]                     = useState(false);
    const [searchString,            setSearchString]                = useState('');
    const [revenueMaster,           setRevenueMaster]               = useState([]); 
    const [filteredRevenueMaster,   setFilteredRevenueMaster]       = useState([]); 
    const [type,                    setType]                        = useState('All');
    const [editingRevenueMaster,    setEditingRevenueMaster]        = useState(null);
    const [insuranceCompanies,      setInsuranceCompanies]          = useState([]);
    const [products,                setProducts]                    = useState([]);
    const [currentInsuranceCompany, setCurrentInsuranceCompany]     = useState(null);
    const [currentProduct,          setCurrentProduct]              = useState(null);
    const [showRevenueDetail,       setShowRevenueDetail]           = useState(null)

    const userDetails = getAuthState();

    useEffect(() => {
        let ignore = false;

        if (!ignore) {
            fetchRevenueMaster();
            fetchInsuranceCompanies();
            fetchProducts();
        }

        return () => {
            ignore = true;
        }
    }, [props.refresh]);

    useEffect(() => {
        $('[data-toggle="kt-popover"]').popover();
        helper.SetScrollHeight();
    });

    useEffect(() => {
        if (insuranceCompanies && insuranceCompanies.length > 0)
            setCurrentInsuranceCompany(insuranceCompanies[0]);
    }, [insuranceCompanies]);

    useEffect(() => {
        if (products && products.length > 0)
            setCurrentProduct(products[0]);
    }, [products]);

    useEffect(() => {
        if(revenueMaster.length > 0)
            filterRevenueMaster();
        else    
            setFilteredRevenueMaster([])
    }, [revenueMaster, type, searchString, currentInsuranceCompany, currentProduct]);

    const filterRevenueMaster = async () => {
        let revenueMasterList = revenueMaster;
        if (type !== 'All') {
            revenueMasterList = revenueMaster.filter((f) => f.CommissionType == type);
        }
        else {
            revenueMasterList = revenueMaster;
        }
        if (currentInsuranceCompany && currentInsuranceCompany.ID != 0) revenueMasterList = revenueMasterList.filter(f => f.InsuranceCompanyID == currentInsuranceCompany.ID);
        if (currentProduct && currentProduct.ID != 0) revenueMasterList = revenueMasterList.filter(f => f.ProductID == currentProduct.ID);

        if (searchString === '') {
            setFilteredRevenueMaster(revenueMasterList)
        } else {
            const matchedRevenues = revenueMasterList.filter((c) => {
                let matchString = `${c.ProductName} ${c.InsuranceCompanyName} ${c.IncentiveType} ${c.CommissionType}`;
                let matchFound = 0;
                const searchArr = searchString.split(' ');
                searchArr.forEach((term) => {
                    matchFound += matchString.toLowerCase().includes(term.toLowerCase())
                        ? 1
                        : 0;
                });
                return matchFound === searchArr.length;
            })
            setFilteredRevenueMaster(matchedRevenues);
        }
    }

    const editRevenueMasterHandler = (ID) => {
        setEditingRevenueMaster(revenueMaster.find(r => r.ID == ID ));
    }

    const removeRevenueHandler = async (ID) => {
        try {
            const swalResult = await swal.fire({
                title               : 'Are you sure?',
                text                : `You won't be able to revert this!`,
                icon                : 'warning',
                showCancelButton    : true,
                buttonsStyling      : false,
                reverseButtons      : true,
                showLoaderOnConfirm : true,
                confirmButtonClass  : 'btn btn-brand',
                cancelButtonClass   : 'btn btn-secondary',
                confirmButtonText   : 'Delete',
                preConfirm          : async () => {
                    try {
                        await deleteRevenueMaster(ID);
                        return;
                    } catch (err) {
                        swal.fire({
                            icon                : 'error',
                            titleText           : 'Error!',
                            text                : err.message,
                            buttonsStyling      : false,
                            confirmButtonClass  : 'btn btn-brand',
                        });
                        return;
                    }
                },
            });
            if (!swalResult.value) return;
            props.refetchRevenues();
            swal.fire({
                icon              : 'success',
                titleText         : 'Revenue deleted Successfully',
                showConfirmButton : false,
                timer             : 1500
            });
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        }
    }

    const fetchInsuranceCompanies = async () => {
        try {
            const response = await getInsuranceCompanies();
            
            setInsuranceCompanies([{
                ID: 0, AgentCompanyID: 0, Name: 'All', FullName: 'All'
            }, ...response.sort((a, b) =>
                a.Name > b.Name
                ? 1
                : a.Name < b.Name
                ? -1
                : 0
            )]);   
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        }
    };

    const fetchProducts = async () => {
        try {
            const response = await getProducts();

            setProducts([{
                ID: 0, AgentCompanyID: 0, Name: 'All', FullName: 'All'
            }, ...response.sort((a, b) =>
                a.Name > b.Name
                ? 1
                : a.Name < b.Name
                ? -1
                : 0
            )]);   
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        }
    };

    const fetchRevenueMaster = async () => {
        try {
            setLoading(true);
            const response = await getRevenueMaster('master');
            setRevenueMaster(response);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            swal.close();
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        }
    }

    return (
        <>
            <div className="kt-form kt-form--label-right kt-margin-b-10">
                <div className="row align-items-center stickyFilter">
                    <div className="col-xl-12 order-2 order-xl-1">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="form-group margin-b0">
                                    <label htmlFor="ddlInsuranceCompany">Insurance Company</label>
                                    <Select
                                        value={currentInsuranceCompany && {label: currentInsuranceCompany.Name, value: currentInsuranceCompany}}
                                        onChange={(event) => setCurrentInsuranceCompany(event.value)}
                                        name="ddlInsuranceCompany"
                                        id="ddlInsuranceCompany"
                                        className="ddlInsuranceCompany"
                                        options={insuranceCompanies.map(c => ({
                                            label: c.Name,
                                            value: c
                                        }))}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group margin-b0">
                                    <label htmlFor="ddlProduct">Product</label>
                                    <Select
                                        value={currentProduct && {label: currentProduct.Name, value: currentProduct}}
                                        onChange={(event) => setCurrentProduct(event.value)}
                                        name="ddlProduct"
                                        id="ddlProduct"
                                        className="ddlProduct"
                                        options={products.map(c => ({
                                            label: c.Name,
                                            value: c
                                        }))}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group margin-b0">
                                    <label htmlFor="ddlProduct">Type</label>
                                    <select
                                        className="form-control kt-selectpicker"
                                        id="ddlType"
                                        value={type}
                                        onChange={(e) => setType(e.target.value)}>
                                        <option value="All">All</option>
                                        <option value="Standard">Standard</option>
                                        <option value="Incentive">Incentive</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-3 kt-margin-b-20-tablet-and-mobile margin-b0 margin-t25">
                                <div className="kt-input-icon kt-input-icon--left">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search Revenue..."
                                        id="generalSearch"
                                        value={searchString || ''}
                                        onChange={(e) => setSearchString(e.target.value)}>
                                    </input>
                                    <span className="kt-input-icon__icon kt-input-icon__icon--left">
                                        <span>
                                            <i className="la la-search"></i>
                                        </span>
                                    </span>
                                    {searchString && (
                                        <span className="kt-input-icon__icon kt-input-icon__icon--right">
                                            <span>
                                                <i className="la la-close" onClick={() => setSearchString('')}></i>
                                            </span>
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {loading ? (<Loading/>) : (
                    filteredRevenueMaster.length > 0 ? (
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
                                    <table className="kt-datatable__table">
                                        <thead
                                            className="kt-datatable__head"
                                            style={{ display: 'block' }}>
                                            <tr className="kt-datatable__row block-row">
                                                <th className="kt-datatable__cell text-clip" width="40%">
                                                    <span className="padding-l10">Company</span>
                                                </th>
                                                <th className="kt-datatable__cell text-clip" width="15%">        
                                                    <span>Type</span>
                                                </th>
                                                <th className="kt-datatable__cell text-clip" width="15%">
                                                    <span>Start Date</span>
                                                </th>
                                                <th className="kt-datatable__cell text-clip" width="15%">
                                                    <span>End Date</span>
                                                </th>
                                                <th className="kt-datatable__cell text-clip text-right padding-r50" width="15%">
                                                    <span>Action</span>
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                                <div
                                    className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded scrolTable"
                                    onScroll={helper.setStickyHeader}>    
                                    <table className="kt-datatable__table table-striped">
                                        <tbody
                                            className="kt-datatable__body"
                                            style={{ display: 'block' }}>   
                                            {filteredRevenueMaster.map((r, i) => (
                                                <tr
                                                    key={i}
                                                    className="kt-datatable__row block-row clickable"
                                                >
                                                    <td width="40%" className="kt-datatable__cell text-clip" onClick={() => setShowRevenueDetail(r)}>
                                                        <div className="kt-user-card-v2">
                                                            <div className="kt-user-card-v2__pic">
                                                                <img src={r.ProductIcon} alt="product"/>
                                                            </div>
                                                            <div className="kt-user-card-v2__details text-clip">
                                                                <span className="kt-user-card-v2__name text-clip" title={r.InsuranceCompanyName}>
                                                                    {r.InsuranceCompanyName}                  
                                                                </span>                                                               
                                                                <span className="kt-user-card-v2__desc text-clip" title={r.ProductName}>{r.ProductName}</span>
                                                            </div>                                                        
                                                        </div>                                                       
                                                    </td>
                                                    <td width="15%" title={r.Type} className="kt-datatable__cell text-clip" onClick={() => setShowRevenueDetail(r)}>
                                                        <span>{r.CommissionType}</span>
                                                    </td>
                                                    <td width="15%" title={moment(r.FromDate).format('DD/MM/YYYY')} className="kt-datatable__cell text-clip" onClick={() =>  setShowRevenueDetail(r)}>
                                                        <span className="kt-font-bold">{moment(r.FromDate).format('DD/MM/YYYY')}</span>
                                                    </td>
                                                    <td width="15%" title={moment(r.ToDate).format('DD/MM/YYYY')} className="kt-datatable__cell text-clip" onClick={() =>  setShowRevenueDetail(r)}>
                                                        <span className="kt-font-bold">{moment(r.ToDate).format('DD/MM/YYYY')}</span>
                                                    </td>
                                                    <td className="kt-datatable__cell text-clip text-right" width="15%">
                                                        <button 
                                                            type="button"
                                                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                                                            data-toggle="kt-popover"
                                                            data-trigger="hover"
                                                            data-placement="left"
                                                            onClick={() => editRevenueMasterHandler(r.ID)}  
                                                            data-content="Edit Revenue">                
                                                            <i className="flaticon2-edit"></i>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={() => removeRevenueHandler(r.ID)}
                                                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                                                            data-toggle="kt-popover"
                                                            data-trigger="hover"
                                                            data-placement="left"
                                                            data-content="Delete Revenue">
                                                            <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <NoData
                            src={ReportImg}
                            alt="Policy"
                            message="No Revenue Master Added"
                            btnName="Add Revenue Master"
                            show={userDetails.user.categoryId != 3 ? "Y" : "N"}
                            from="revenueMaster"
                            onOpenModal={props.setShowAddRevenueModal}
                        />
                    )
                )}
            </div>
            {editingRevenueMaster && (
                <EditRevenueMaster
                    refetchRevenues={() => props.refetchRevenues()}
                    revenue={editingRevenueMaster}
                    show={!!editingRevenueMaster}
                    onDismissModal={setEditingRevenueMaster}
                />
            )}
            {showRevenueDetail && (
                <ShowRevenueDetails
                    revenueDetails={showRevenueDetail}
                    onDismissModal={setShowRevenueDetail}
                    show={!!showRevenueDetail}
                />
            )}
        </>
    )
}

export default RevenueMasterTable;