import { getAuth, signInWithPopup } from "firebase/auth";
import { googleProvider } from "../../assets/scripts/firebaseConfig";


export const googleLogin = async () => {
    return new Promise ((resolve, reject) =>{
      const auth = getAuth();
      googleProvider.setCustomParameters({ prompt: 'select_account' });
      signInWithPopup(auth, googleProvider)
        .then( async (result) => {
          if(result.user){
            const IdToken = await auth.currentUser.getIdToken();
            resolve(IdToken)
          }
        }).catch((error) => {
          reject(new Error(error.code))
        });
    })
}