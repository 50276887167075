import React from 'react'

const Loading = () => {
    return(
        <div className="text-center padding-100">
            <span className="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--info"></span>
        </div>  
    );
};

export default Loading;