import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import 'jquery-form';
import * as helper from '../global/helper';
import swal from 'sweetalert2';
import Dropzone from '../global/dropzone';
import { updatePolicyDocument } from '../api/policyAPI';
import { uploadFile } from '../api/fileAPI';
import { updateSubAgentPolicyDocument } from '../api/subAgencyAPI';

const EditPolicyDocument = (props) => {

    const [btnLoading,      setBtnLoading]      = useState(false);
    const [documentName,    setDocumentName]    = useState(props.documentDetails.DocumentName);     
    const [fileName,        setFileName]        = useState(null);
    const [document,        setDocument]        = useState(null);

    useEffect(() => {
        $('#edit-document-modal').modal({
            backdrop: 'static',
          });
          $('#edit-document-modal').on('hidden.bs.modal', function () {
              props.onDismissModal(false);   
          });
          if (props.documentDetails) {
            const ext = helper.getFileExt(props.documentDetails.FileName)
            const name = (props.documentDetails.DocumentName + '.' + ext).replace(/ /g, '_');
            setFileName(name)
        }
    }, [props.show])

    useEffect(() => {
        if (document)
            setFileName(document.name)
    }, [document]);

    const openDocument = async () => {
        if (document)
            helper.openFileHandler(document);
        else 
            helper.downloadAndOpenFileHandler("PolicyDocument", props.documentDetails.FileName, fileName)
    }

    const documentHandler = async (d) => {
        setDocument(d);
    }

    const removeDocumentHandler = (e) => {
        e.preventDefault();
        setDocument(null);
        setFileName(null)
    }

    const editDocument = async (e) => {
        var form = $('#edit-document-form');
        form.validate().destroy();
        form.validate({
            rules: {
                documentName: {
                    required: true,
                }
            }
        });
        if (!form.valid()) {
            return;     
        }

        try {
            setBtnLoading(true);

            if (!fileName) {
                swal.fire({
                    icon                  : 'error',
                    titleText             : 'Error!',
                    text                  : "Please upload a document",
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',
                });
                setBtnLoading(false);
                return;
            }

            let newFileName = '';
            if (document)
                newFileName = await uploadFile(document, "PolicyDocument")
                // newFileName = props.from == 'subAgentPolicyStats' ? await uploadFile(document, "SubAgentPolicyDocument") : await uploadFile(document, "PolicyDocument")

            // props.from == 'subAgentPolicyStats' ? await  updateSubAgentPolicyDocument({
            //     ID              : props.documentDetails.ID,
            //     PolicyID        : props.documentDetails.SubAgentPolicyID,
            //     FileName        : props.documentDetails.FileName,
            //     NewFileName     : newFileName,
            //     DocumentName    : documentName
            // }) : 
            await updatePolicyDocument({
                ID              : props.documentDetails.ID,
                PolicyID        : props.documentDetails.PolicyID,
                FileName        : props.documentDetails.FileName,
                NewFileName     : newFileName,
                DocumentName    : documentName
            })
            setBtnLoading(false);
            props.refetchDocuments();
            $('#edit-document-modal').modal('hide');
            swal.fire({
                icon              : 'success',
                titleText         : 'Document Updated Successfully',
                showConfirmButton : false,
                timer             : 1500,
            });
        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            setBtnLoading(false);
            return;
        }
    }

    return (
        <React.Fragment>
            <div
                className="modal fade"
                id="edit-document-modal"
                tabIndex="-1"
                role="dialog"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Edit Document</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                disabled={btnLoading}>
                            </button>
                        </div>
                        <div className="modal-body padding-0">
                            <div className="client-container padding-lr20">
                                <form id="edit-document-form" onSubmit={editDocument}>
                                    <div className="row">
                                        <div className="col-12">
                                            <input
                                                id="documentName"
                                                value={documentName}
                                                name="documentName"
                                                placeholder="Document Name"
                                                className="form-control margin-b10"
                                                type="text"
                                                onChange={(event) =>
                                                    setDocumentName(event.target.value)
                                                }
                                            />
                                        </div>
                                    </div>
                                    {fileName ? 
                                        (
                                            <div className="row margin-t20">
                                                <div className="col-12">
                                                    <div className="kt-notification margin-b30" style={{backgroundColor: '#f7f8fa'}}>
                                                        <a className="kt-notification__item">
                                                            <div className="kt-notification__item-icon margin-r10" onClick={openDocument}>
                                                                <img height="30px" width="30px" src={helper.getFileLogo(fileName)} />
                                                            </div>
                                                            <div className="kt-notification__item-details" onClick={openDocument}>
                                                                <div className="kt-notification__item-title kt-font-bold">
                                                                    {fileName}
                                                                </div>
                                                            </div>
                                                            <button 
                                                                type="button"                    
                                                                onClick={removeDocumentHandler}
                                                                className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                                                <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                            </button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div> 
                                        ) : (
                                        <Dropzone fileHandler={documentHandler} />
                                    )}
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                disabled={btnLoading}
                                className="btn btn-secondary margin-0 margin-r5"
                                data-dismiss="modal">    
                                Close
                            </button>
                            <button
                                id="save-document"
                                onClick={editDocument}
                                type="button"
                                className={`btn btn-brand margin-0 ${
                                btnLoading
                                    ? 'kt-spinner kt-spinner--sm kt-spinner--light'
                                    : ''
                                }`}
                                disabled={btnLoading} >   
                                Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default EditPolicyDocument;