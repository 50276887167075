import React, { useEffect, useState } from 'react'
import $ from 'jquery';
import 'jquery-form';
import 'jquery-validation';
import '../../assets/scripts/bootstrap-modal';
import '../../assets/scripts/jquery-validation.init';
import swal from 'sweetalert2';
import Select from 'react-select';
import AddMember from '../clients/addMember';
import { getClients, getMainClients, getMainClientsByAgentID } from '../api/clientAPI';
import { addTask } from '../api/taskAPI';
import moment from 'moment';
import { getAgentsByAgentCompanyID } from '../api/agentAPI';
import * as helper from '../global/helper'
import { getAuthState } from '../../assets/scripts/login-util';

const AddTask = (props) => {
    const [btnLoading,     setBtnLoading]     = useState(false);
    const [selectedClient, setSelectedClient] = useState(null);
    const [taskDetails,    setTaskDetails]    = useState({});
    const [clients,        setClients]        = useState([]);
    const [addedClientId,  setAddedClientId]  = useState(null);
    const [addedMemberId,  setAddedMemberId]  = useState(null);
    const [agentOptions,   setAgentOptions]   = useState([]);
    const [selectedAgent,  setSelectedAgent]  = useState(null);
    const [assignList,     setAssignList]     = useState([]);
    const [taskSaved,      setTaskSaved]      = useState(false);

    const userDetails = getAuthState();

    useEffect(() => {
        $('#add-task-modal').modal({
            backdrop: 'static'
        });
        $('#add-task-modal').on('hidden.bs.modal', function () {
            props.onDismissModal('task', false);
        });
        $('#add-task-modal').modal('toggle');
        
        fetchClients();
        fetchAgents();

    }, [props.show]);

    useEffect(() => {
        if (taskSaved) {
            $('#add-task-modal').modal('hide');
            props.onDismissModal('task', false, '', taskSaved);
        }
    }, [taskSaved]);

    useEffect(() => {
        if (selectedClient) updateTaskDetails('ClientID', selectedClient.ID);
    }, [selectedClient])   

    useEffect(() => {
        if(props.taskData && props.taskData.addedClientId) {
            setAddedClientId(props.taskData.addedClientId)
        }
    }, [props.taskData])

    useEffect(() => {
        if (clients.length > 0) {

            if (addedClientId){
              const client = clients.find(c => c.ID == addedClientId);
              setSelectedClient(client);
              setAddedClientId(null);
            }             
    
            if (addedMemberId){
              const client = clients.find(c => c.ID == addedMemberId);
              setSelectedClient(client);
              setAddedMemberId(null);
            }       
        }  
    }, [clients]);

    useEffect(() => {   
        if (addedClientId || addedMemberId)
            fetchClients();        
    }, [addedClientId, addedMemberId]);

    const fetchClients = async () => {
        try {
            const response = userDetails.user.categoryId != 3 ? await getMainClients() : await getMainClientsByAgentID();
            setClients(
                response.map((c) => ({
                    ...c,
                    DisplayName:
                        c.Type === 'Corporate' && c.Category === 'Primary'
                            ? c.CompanyName
                            : `${c.FirstName} ${c.MiddleName} ${c.LastName}`
                }))
                .sort((a, b) =>
                    a.DisplayName > b.DisplayName
                    ? 1
                    : a.DisplayName < b.DisplayName
                    ? -1
                    : 0
                )
            );
        } catch (err) {
            swal.fire({
                icon              : 'error',
                titleText         : 'Error!',
                text              : err.message,
                buttonsStyling    : false,
                confirmButtonClass: 'btn btn-brand',
            });
        }
    };

    const updateDetailsHandler = ({target}) => {
        target.style.height = 'auto';
        target.style.height = (target.scrollHeight) + 'px';
        updateTaskDetails('Details', target.value);       
    };

    const fetchAgents = async () => {
        try {
            const response = await getAgentsByAgentCompanyID();
            setAgentOptions(
                response.map((a) => ({
                    label: `${a.FirstName} ${a.LastName}`,
                    value: {
                        ID          : a.ID,
                        CategoryID  : a.CategoryID,
                        AgentName   : `${a.FirstName} ${a.LastName}`,
                        ProfilePhoto: a.ProfilePhoto,
                        DisplayName : a.FirstName,
                        Email       : a.Email
                    }
                }))
            );
        } catch (err) {
            swal.fire({
                icon              : 'error',
                titleText         : 'Error!',
                text              : err.message,
                buttonsStyling    : false,
                confirmButtonClass: 'btn btn-brand',
            });  
        }
    }

    const memberAddHandler = (e) => {
        e.preventDefault();
        $('#add-task-modal').addClass('modal-blur');
        $('#add-member-modal').modal('toggle');
    }

    const clientAddHandler = (e) => {
        e.preventDefault();
        $('#add-task-modal').addClass('modal-blur');
        props.onOpenModal('client', true, 'task', '', '', {type: 'directClient'});
    }

    const updateTaskDetails = (field, value) => {
        setTaskDetails((t) => {
            return { ...t, [field]: value };
        })
    }

    const getClientName = () => {
        const foundClient = clients.find((c) => c.GroupID === selectedClient.GroupID && c.Category === 'Primary');
        if (foundClient)
          return { DisplayName: foundClient.DisplayName,  Type: foundClient.Type};
        else return { DisplayName: '',  Type: ''};
    };

    const addAgentHandler = async (e) => {
        e.preventDefault();

        var form = $('#add-task-form');
        form.validate().destroy();

        form.validate({
            rules: {
                ddlAgent: {
                    required: true
                }
            }
        });
        if (!form.valid()) {
            return;
        }

        if (selectedAgent) {
            const alreadySelected = assignList.find((a) => a.ID == selectedAgent.ID)
            if(!alreadySelected) {
                setAssignList((a) => {
                    const newA = [...a];
                    newA.push(selectedAgent);
                    return newA;
                });
            }
            
        }
    }

    const removeAgentHandler = async (ID) => {
        setAssignList((a) => {
            const nA = a.filter((f) => f.ID !== ID);
            return nA;
        });
        if (selectedAgent && selectedAgent.ID == ID)
            setSelectedAgent(null);
    }

    const addTaskHandler = async (e) => {
        e.preventDefault();

        var form = $('#add-task-form');
        form.validate().destroy();

        form.validate({
            rules: {
                title: {
                    required: true,
                },
                dueDate: {
                    required: true,
                }
            },
        })


        if (!form.valid()) {
            return;
        }

        try {
            setBtnLoading(true);
            await addTask({
                Title      : taskDetails.Title,
                Priority   : taskDetails.Priority || 'Low',
                DueDate    : taskDetails.DueDate,
                ClientID   : taskDetails.ClientID || 0,
                Details    : taskDetails.Details || '',
                AssignList : assignList.map((a) => ({
                    ID          : a.ID,
                    DisplayName : a.DisplayName,
                    Email       : a.Email
                })),
            })
            setTaskSaved(true);
            swal.fire({
                icon              : 'success',
                titleText         : 'Task Added Successfully',
                showConfirmButton : false,
                timer             : 1500
            });
        } catch (err) {
            swal.fire({
              icon                  : 'error',
              titleText             : 'Error!',
              text                  : err.message,
              buttonsStyling        : false,
              confirmButtonClass    : 'btn btn-brand',
            });
            setBtnLoading(false);
            return;
        }

    }

    return (
        <React.Fragment>
            <div 
                className="modal fade"
                id="add-task-modal"
                tabIndex="-1"
                role="dialog"
                aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Create Task</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                disabled={btnLoading}>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form id="add-task-form" className="kt-form" onSubmit={(e) => e.preventDefault()}>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <input 
                                                className="form-control"
                                                placeholder="Title"
                                                value={taskDetails.Title || ''}
                                                onChange={(event) => updateTaskDetails('Title', helper.titleCase(event.target.value))}
                                                id="title"
                                                name="title"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="ddlPriority">Priority</label>
                                            <select
                                                className="form-control kt-selectpicker"
                                                id="ddlPriority"
                                                onChange={(event) => updateTaskDetails('Priority', event.target.value)}>
                                                <option value="Low">Low</option>
                                                <option value="Medium">Medium</option>
                                                <option value="High">High</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="fromDate">Due Date</label>
                                            <input
                                                id="dueDate"
                                                value={taskDetails.DueDate || ''}
                                                min={moment().format('YYYY-MM-DD')}
                                                name="dueDate"
                                                className="form-control"
                                                type="date"
                                                onChange={(event) => updateTaskDetails('DueDate', event.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <textarea
                                                rows="3"
                                                placeholder="Details"
                                                className="form-control"
                                                value={taskDetails.Details}
                                                onChange={updateDetailsHandler}
                                                id="details"
                                                name="details"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-9 col-md-8">
                                        <label htmlFor="ddlClient">Client</label>
                                        <Select
                                            value={selectedClient && {label: selectedClient.DisplayName, value: selectedClient}}
                                            onChange={(event) => setSelectedClient(event.value)}
                                            name="ddlClient"
                                            placeholder="Select Client"
                                            id="ddlClient"
                                            className="ddlClient"
                                            options={clients.map(c => ({
                                                label: c.DisplayName,
                                                value: c
                                            }))}
                                        />
                                    </div>
                                    <div className="col-lg-3 col-md-4">
                                        {selectedClient ? (
                                            <button
                                                id="btnNewMember"
                                                className="btn btn-label-brand btn-block margin-0 margin-md-t24"
                                                onClick={memberAddHandler}
                                            >
                                                <i className="la la-plus" />
                                                New Member
                                            </button>
                                            ) : (
                                            <button
                                                id="btnNewClient"
                                                className="btn btn-brand btn-block margin-0 margin-md-t24"
                                                onClick={clientAddHandler}>                                           
                                                <i className="la la-plus" />
                                                New Client
                                            </button>
                                        )}
                                    </div>
                                </div>
                                {selectedClient && (
                                    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded">
                                        <table className="kt-datatable__table table-striped">
                                            <tbody className="kt-datatable__body" style={{ display: 'block' }}>
                                                <tr className="kt-datatable__row block-row">
                                                    <td title={selectedClient.DisplayName} className="kt-datatable__cell text-clip">
                                                        <div className="kt-user-card-v2">
                                                            <div className="kt-user-card-v2__pic">
                                                                <div className={`kt-badge kt-badge--xl kt-badge--${ selectedClient.Type === 'Corporate' ? 'success' : 'info' }`}>                                                                                 
                                                                    {selectedClient.DisplayName.slice(0, 1)}
                                                                </div>
                                                            </div>
                                                            <div className="kt-user-card-v2__details text-clip">
                                                                <span className="kt-user-card-v2__name text-clip">{selectedClient.DisplayName}</span>
                                                                <span className="kt-user-card-v2__desc text-clip">{selectedClient.Type === 'Corporate' ? 'Organization' : selectedClient.Type}</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="kt-datatable__cell text-clip">
                                                        <span>
                                                            {selectedClient.Email || ''}
                                                            {selectedClient.Mobile ? `${selectedClient.Email ? ` | ${selectedClient.Mobile}` : selectedClient.Mobile}` : ''}
                                                        </span>
                                                    </td>
                                                    <td width="10%" className="text-right padding-r20">
                                                        <button 
                                                            type="button"                    
                                                            onClick={(e) => setSelectedClient(null)}
                                                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">     
                                                            <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                        </button>                           
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                                <div className="row">
                                    <div className="col-lg-9 col-md-8">
                                        <label htmlFor="ddlAgent">Assign To</label>
                                        <Select
                                            value={selectedAgent && {label: selectedAgent.AgentName, value: selectedAgent}}
                                            onChange={(event) => setSelectedAgent(event.value)}
                                            name="ddlAgent"
                                            placeholder="Select Team"
                                            id="ddlAgent"
                                            className="ddlAgent"
                                            options={agentOptions}
                                        />
                                    </div>
                                    <div className="col-lg-3 col-md-4">
                                        <button
                                            id="btnAddAgent"
                                            className="btn btn-label-brand btn-block margin-0 margin-md-t24"
                                            onClick={addAgentHandler}
                                        >
                                            <i className="fa fa-user-check" />
                                            Assign
                                        </button>
                                    </div>
                                </div>
                                {(assignList && assignList.length > 0) && (
                                    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded">
                                    <table className="kt-datatable__table table-striped">
                                        <tbody className="kt-datatable__body" style={{display: 'block'}}>
                                            {assignList && (
                                                assignList.map((a, i) =>
                                                <tr key={i} className="kt-datatable__row block-row">
                                                    <td className="kt-datatable__cell text-clip">
                                                        <div className="kt-user-card-v2">
                                                            <div className="kt-user-card-v2__pic">
                                                                {a.ProfilePhoto ? 
                                                                    <img  src={a.ProfilePhoto} alt='profile-image' />
                                                                :   <div className={`kt-badge kt-badge--xl kt-badge--${a.CategoryID == 1 ? 'success' : 'info' }`}>
                                                                    {a.AgentName.slice(0,1)}
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="kt-user-card-v2__details">
                                                                <span className="kt-user-card-v2__name">{a.AgentName}</span>
                                                                <span className="kt-user-card-v2__desc">{a.Email}</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-right padding-r20">     
                                                    <button
                                                        type="button"
                                                        onClick={() => removeAgentHandler(a.ID)}
                                                        className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                                                        data-toggle="kt-popover"
                                                        data-trigger="hover"
                                                        data-placement="left"
                                                        data-content="Delete Recipient">
                                                        <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                    </button>
                                                    </td>
                                                </tr>)
                                            )}
                                        </tbody>
                                    </table>       
                                </div>
                                )}
                            </form>
                        </div>
                        <div  className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary margin-0 margin-r5"
                                data-dismiss="modal"
                                disabled={btnLoading}
                            >
                                Close
                            </button>
                            <button
                                id="save-btn"
                                onClick={addTaskHandler}
                                type="button"
                                className={`btn btn-brand margin-0 ${
                                    btnLoading ? 'kt-spinner kt-spinner--sm kt-spinner--light' : ''
                                }`}
                                disabled={btnLoading}>
                                Create
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {selectedClient && (
                <AddMember
                    addMemberId={setAddedMemberId}
                    groupId={selectedClient.GroupID}
                    client={getClientName()}
                    from="#add-task-modal"
                />
            )}
        </React.Fragment>
    );
};

export default AddTask;