import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { addRevenueMaster, getInsuranceCompanies, getProducts } from '../api/policyAPI';
import Select from 'react-select';
import AddCompany from '../policy/addCompany';
import swal from 'sweetalert2';
import RevenueMasterTable from './revenueMasterTable';
import '../../assets/scripts/jquery-validation.init';
import $ from 'jquery';
import 'jquery-form';
import * as helper from '../global/helper';
import 'jquery-validation';
import { getAuthState } from '../../assets/scripts/login-util';

const AddRevenueModal = (props) => {
    const [btnLoading,          setBtnLoading]          = useState(false);
    const [insuranceCompanies,  setInsuranceCompanies]  = useState([]);
    const [products,            setProducts]            = useState([]);
    const [revenueDetails,      setRevenueDetails]      = useState({TDS: 0});
    const [addModalType,        setAddModalType]        = useState('Company');
    const [addedCompanyId,      setAddedCompanyId]      = useState(null);
    const [addedProductId,      setAddedProductId]      = useState(null);
    const [multiCompanies,      setMultiCompanies]      = useState([]);
    const [commissionType,      setCommissionType]      = useState('Standard');
    const [incentiveType,       setIncentiveType]       = useState('Flat');
    const [targetDetail,        setTargetDetail]        = useState({});
    const [targetDetails,       setTargetDetails]       = useState([]);
    const [commissionList,      setCommissionList]      = useState([]);

    useEffect(() => {
        $('#add-revenue-modal').modal('toggle');
        $('#add-revenue-modal').on('hidden.bs.modal', function () {
            props.setShow(false);
        });
        fetchInsuranceCompanies();
        fetchProducts();

    }, [props.show]);

    useEffect(() => {
        if(addedCompanyId)
            fetchInsuranceCompanies();
    }, [addedCompanyId]);

    useEffect(() => {  
        if (addedProductId)
            fetchProducts();        
    }, [addedProductId]);

    useEffect(() => {
        if (insuranceCompanies.length > 0 && addedCompanyId) { 
          updateRevenueDetails('InsuranceCompanyID', addedCompanyId);
          setAddedCompanyId(null);
        }          
    }, [insuranceCompanies]);

    useEffect(() => {  
        if (products.length > 0 && addedProductId) {
          updateRevenueDetails('ProductID', addedProductId);
          setAddedProductId(null);
        }    
    }, [products]);

    const companyHandler = (options) => {
        setMultiCompanies(options)
    }

    const updateRevenueDetails = (field, value) => {
        setRevenueDetails(r => {
            return { ...r, [field]: value }
        });
    };

    useEffect(() => {
        if (commissionType === 'Standard'){
            setRevenueDetails(r => ({ ...r, Percent: 0 }))
            setTargetDetails([])
        }
        else{
            setRevenueDetails(r => ({ ...r, TDS: 0, Commission: 0 }))
            setCommissionList([])
        }

    }, [commissionType]);

    useEffect(() => {
        if (incentiveType === 'Target')
            setRevenueDetails(r => ({ ...r, Percent: 0 }))
    }, [incentiveType]);

    const companyAddHandler = (e) => {
        e.preventDefault();
        setAddModalType('Company');
        $('#add-revenue-modal').addClass('modal-blur');
        $('#add-company-modal').modal('toggle');
    };

    const productAddHandler = (e) => {
        e.preventDefault();
        setAddModalType('Product');
        $('#add-revenue-modal').addClass('modal-blur');
        $('#add-company-modal').modal('toggle');
    };

    const fetchInsuranceCompanies = async () => {
        try {
            const response = await getInsuranceCompanies();
            setInsuranceCompanies(
            response
            .sort((a, b) =>
                    a.Name > b.Name
                        ? 1
                        : a.Name < b.Name
                        ? -1
                        : 0
                )
            );    
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        }
    };

    const fetchProducts = async () => {
        try {
            const response = await getProducts();
            setProducts(
            response
            .sort((a, b) =>
                    a.Name > b.Name
                    ? 1
                    : a.Name < b.Name
                    ? -1
                    : 0
                )
            );    
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        } 
    };

    const updateTargetDetails = (field, value) => {

        setTargetDetail(t => {
            return {
                ...t,
                [field]: value
            }
        })
 
    };

    const rangeAddHandler = async (e) => {
        e.preventDefault();
        const form = $('#add-revenue-form');
    
        form.validate().destroy();
        form.validate({
            rules: {
                startRange: {
                    required: true,
                },
                endRange: {
                    required: true,
                },
                percent: {
                    required: true,
                },
            },
        });

        if (!form.valid()) {
        return;
        }
        if (parseInt(targetDetail.StartRange) > parseInt(targetDetail.EndRange)) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : "Invalid Range",
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return
        } else if (targetDetails.length == 0) {
            setTargetDetails((t) => [...t, targetDetail])
            setTargetDetail({})
        } else {
            const t = targetDetails.find(t => {
                if((parseInt(targetDetail.StartRange) >= parseInt(t.StartRange) && parseInt(targetDetail.EndRange) <= parseInt(t.EndRange)) 
                    || (parseInt(targetDetail.StartRange) <= parseInt(t.StartRange) && parseInt(targetDetail.EndRange) >= parseInt(t.StartRange) && parseInt(targetDetail.EndRange) <= parseInt(t.EndRange))
                    || (parseInt(targetDetail.StartRange) >= parseInt(t.StartRange) && parseInt(targetDetail.StartRange) <= parseInt(t.EndRange))
                    || (parseInt(targetDetail.StartRange) <= parseInt(t.StartRange) && parseInt(targetDetail.EndRange) >= parseInt(t.EndRange)))
                {
                    return t
                }
            });
            if (t) {
                swal.fire({
                    icon                  : 'error',
                    titleText             : 'Error!',
                    text                  : "Range already Exist",
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',   
                });
                return;
            }

            const invalidPercent = targetDetails.find(t => {
                if (targetDetail.Percent <= t.Percent)
                    return t
            });

            if (invalidPercent) {
                swal.fire({
                    icon                  : 'error',
                    titleText             : 'Error!',
                    text                  : "Percent should greater than previous percent",
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',   
                });
                return;
            }

            setTargetDetails((td) => [...td, targetDetail])
            setTargetDetail({})
        }
    }

    const removeRangeHandler = (index) => {
        setTargetDetails((a) => {
            const nA = a.filter((b, i) => index !== i);
            return nA;
        })
    }

    const removeHandler = (index) => {
        setCommissionList((a) => {
            const nA = a.filter((b, i) => index !== i);
            return nA;
        })
    }

    const addHandler = async () => {

        const form = $('#add-revenue-form');
        form.validate().destroy();

        form.validate({
            rules: {
                ddlInsuranceCompany: {
                    required: true,
                },
                ddlProduct: {
                    required: true,
                },
                fromDate: {
                    required: true,
                },
                toDate: {
                    required: true,
                },
                commissionStandard: {
                    required: true,
                },
            },
        });
        

        if (!form.valid()) {
            return;
        }


        const prevCommission = commissionList.filter( c => {
            const fromDate = moment(revenueDetails.FromDate).format('YYYY-MM-DD')
            const toDate = moment(revenueDetails.ToDate).format('YYYY-MM-DD')
            return (moment(c.FromDate).isBetween(fromDate, toDate, '', []) || moment(c.ToDate).isBetween(fromDate, toDate, '', []) 
            || (moment(c.FromDate).isBefore(fromDate) && moment(c.ToDate).isAfter(toDate)))
        })
        if (prevCommission.length > 0) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : "Date Conflict",
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return;
        } else {
            setCommissionList(c => [...c, revenueDetails])
        }
    }

    const addRevenueHandler = async (e) => {
        e.preventDefault();

        const form = $('#add-revenue-form');
        form.validate().destroy();

        form.validate({
            rules: {
                ddlInsuranceCompany: {
                    required: true,
                },
                ddlProduct: {
                    required: true,
                },
                fromDate: {
                    required: true,
                },
                toDate: {
                    required: true,
                },
                startRange: {
                    required: {
                        depends: function (element) {
                            if (targetDetails.length > 0)
                                return false;
                            else 
                                return true;
                        }
                    }
                },
                endRange: {
                    required: {
                        depends: function (element) {
                            if (targetDetails.length > 0)
                                return false;
                            else 
                                return true;
                        }
                    }
                },
                percent: {
                    required: {
                        depends: function (element) {
                            if (targetDetails.length > 0) return false;
                            else return true;
                        }
                    }
                },
                commissionStandard: {
                    required: true,
                },
            },
        });
        

        if (!form.valid()) {
            return;
        }

        try {
            setBtnLoading(true);
            if (commissionType === 'Incentive' && incentiveType === 'Target' && targetDetails.length == 0) {
                swal.fire({
                    icon                  : 'error',
                    titleText             : 'Error!',
                    text                  : "Please add atleast one range",
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',   
                });
                setBtnLoading(false);
                return;
            }
            if (commissionType === 'Standard' && commissionList.length == 0) {
                swal.fire({
                    icon                  : 'error',
                    titleText             : 'Error!',
                    text                  : "Please add atleast one commission",
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',   
                });
                setBtnLoading(false);
                return;
            }
            await addRevenueMaster({
                CommissionList  : commissionList,
                CompaniesList   : multiCompanies,
                ProductID       : revenueDetails.ProductID,
                CommissionType  : commissionType,
                FromDate        : revenueDetails.FromDate,
                ToDate          : revenueDetails.ToDate,
                IncentiveType   : incentiveType,
                Commission      : revenueDetails.Commission,
                TDS             : revenueDetails.TDS,
                FlatPercent     : revenueDetails.Percent,
                TargetDetails   : targetDetails
            })
            $('#add-revenue-modal').modal('hide');
            props.refetchRevenues();
            swal.fire({
                icon              : 'success',
                titleText         : 'Revenue Added Successfully',
                showConfirmButton : false,
                timer             : 1500
            });
        }  catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            setBtnLoading(false);
            return;
        }
    }

    return (
        <>
            <div
                className="modal fade"
                id="add-revenue-modal"
                tabIndex="-1"
                role="dialog"
                aria-hidden="true">
            
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Revenue</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                disabled={btnLoading}>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form id="add-revenue-form" className="kt-form" onSubmit={(e) => e.preventDefault()}>
                                <div className="row">
                                    <div className="col-10">
                                        <div className="form-group">
                                            <label htmlFor="ddlInsuranceCompany">Insurance Company</label>       
                                            <Select
                                                value={multiCompanies}
                                                onChange={companyHandler}
                                                isMulti
                                                name="ddlInsuranceCompany"
                                                id="ddlInsuranceCompany"
                                                placeholder="Select Company"
                                                className="multi-custom"
                                                classNamePrefix  = 'multi'
                                                options={insuranceCompanies.map(c => ({
                                                    label: c.Name,
                                                    value: c.ID
                                                }))}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <button
                                            id="btnNewCompany"
                                            className="btn btn-label-brand btn-block margin-0 margin-t24"
                                            data-toggle="kt-popover"
                                            data-trigger="hover"
                                            data-placement="bottom"
                                            data-content="Add Insurance Company"
                                            onClick={companyAddHandler}>
                                            <i className="la la-plus" />
                                            New
                                        </button>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-10">
                                                <div className="form-group">
                                                    <label htmlFor="ddlProduct">Product</label>
                                                    <Select
                                                        value={revenueDetails && revenueDetails.ProductID && {label: products.find(c => c.ID == revenueDetails.ProductID).Name, value: revenueDetails.ProductID}}
                                                        onChange={(event) => updateRevenueDetails('ProductID', event.value)}
                                                        name="ddlProduct"
                                                        id="ddlProduct"
                                                        placeholder="Select Product"
                                                        options={products.map(c => ({
                                                            label: c.Name,
                                                            value: c.ID
                                                        }))}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <button
                                                    id="btnNewProduct"
                                                    className="btn btn-label-brand btn-block margin-0 margin-t24"
                                                    data-toggle="kt-popover"
                                                    data-trigger="hover"
                                                    data-placement="bottom"
                                                    data-content="Add Product"
                                                    onClick={productAddHandler}>           
                                                    <i className="la la-plus" />
                                                    New
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className="form-group">
                                            <label htmlFor="type">Commission Type</label> 
                                            <select
                                                className="form-control kt-selectpicker"
                                                id="ddlType"
                                                value={commissionType || ''}
                                                onChange={(event) => setCommissionType(event.target.value)}>                                                 
                                                <option value="Standard">Standard</option>
                                                <option value="Incentive">Incentive</option>
                                            </select>
                                        </div>
                                    </div> 
                                    <div className='col-3'>
                                        <div className="form-group">
                                            <label htmlFor="type">Start Date</label>
                                            <input
                                                id="fromDate"
                                                value={revenueDetails.FromDate || ''}
                                                name="fromDate"
                                                className="form-control margin-b10"
                                                type="date"
                                                onChange={(event) => updateRevenueDetails('FromDate', event.target.value)}
                                            /> 
                                        </div>
                                    </div> 
                                    <div className='col-3'>
                                        <div className="form-group">
                                            <label htmlFor="type">End Date</label>
                                            <input
                                                id="toDate"
                                                value={revenueDetails.ToDate || ''}
                                                name="toDate"
                                                className="form-control margin-b10"
                                                type="date"
                                                onChange={(event) => updateRevenueDetails('ToDate', event.target.value)}
                                            /> 
                                        </div>
                                    </div> 
                                </div>
                                {commissionType === 'Incentive' ? (
                                    <div className='row'>
                                        <div className={`${incentiveType === 'Target' ? 'col-2' : 'col-4'}`}>
                                            <label htmlFor="tds">TDS</label>
                                            <div className='input-group'>
                                                <input
                                                    name="tds"
                                                    id="tds"
                                                    value={revenueDetails.TDS}
                                                    max='100'
                                                    className="form-control"
                                                    type="number"
                                                    onChange={(event) => updateRevenueDetails('TDS', event.target.value)}                                                   
                                                />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">%</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${incentiveType === 'Target' ? 'col-2' : 'col-4'}`}>
                                            <label htmlFor="type">Incentive Type</label> 
                                            <select
                                                className="form-control kt-selectpicker"
                                                id="ddlType"
                                                value={incentiveType || ''}
                                                onChange={(event) => setIncentiveType(event.target.value)}>                                                 
                                                <option value="Flat">Flat</option>
                                                <option value="Target">Target</option>
                                            </select>
                                        </div> 
                                        {incentiveType === 'Target' && (
                                            <>
                                                <div className='col-2'>
                                                    <label htmlFor="type">Start Range</label>
                                                    <input
                                                        id="startRange"
                                                        value={targetDetail.StartRange || ''}
                                                        name="startRange"
                                                        className="form-control margin-b10"
                                                        type="number"
                                                        onChange={(event) => updateTargetDetails('StartRange', event.target.value)}                                                   
                                                    />
                                                </div>
                                                <div className='col-2'>
                                                    <label htmlFor="type">End Range</label>
                                                    <input
                                                        id="endRange"
                                                        value={targetDetail.EndRange || ''}
                                                        name="endRange"
                                                        className="form-control margin-b10"
                                                        type="number"
                                                        onChange={(event) => updateTargetDetails('EndRange', event.target.value)}                                                   
                                                    />
                                                </div>
                                            </>
                                        )}
                                        <div className={`col-${incentiveType === 'Flat' ? '4' : '2'}`}>
                                            <label htmlFor="type">Percent</label>
                                            <div className="input-group revenue-percent">
                                                <input 
                                                    id="percent"
                                                    value={incentiveType === 'Flat' ? revenueDetails.Percent || '' : targetDetail.Percent || ''}
                                                    name="percent"
                                                    max='100'
                                                    className="form-control"
                                                    type="number"
                                                    onChange={(event) => incentiveType === 'Flat' ? updateRevenueDetails('Percent', event.target.value) : updateTargetDetails('Percent', event.target.value)}                                                   
                                                />
                                                <div className="input-group-append revenue-icon">
                                                    <span className="input-group-text">%</span>
                                                </div>
                                            </div>
                                        </div>
                                        {incentiveType === 'Target' && (
                                            <div className='col-2'>
                                                <button 
                                                    className="btn btn-label-brand btn-icon-sm btn-block margin-t24"
                                                    onClick={rangeAddHandler}
                                                >
                                                    <i className="la la-plus"></i>
                                                    Add
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className='row'>
                                        <div className='col-5'>
                                            <label htmlFor="commissionStandard">Commission</label>
                                            <div className='input-group'>
                                                <input
                                                    id="commissionStandard"
                                                    value={revenueDetails.Commission || ''}
                                                    name="commissionStandard"
                                                    className="form-control"
                                                    max='100'
                                                    type="number"
                                                    onChange={(event) => updateRevenueDetails('Commission', event.target.value)}                                                   
                                                />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">%</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-5'>
                                            <label htmlFor="tds">TDS</label>
                                            <div className='input-group'>
                                                <input
                                                    name="tds"
                                                    id="tds"
                                                    value={revenueDetails.TDS}
                                                    max='100'
                                                    className="form-control"
                                                    type="number"
                                                    onChange={(event) => updateRevenueDetails('TDS', event.target.value)}                                                   
                                                />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">%</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-2'>
                                            <button
                                                id="btnAdd"
                                                className="btn btn-label-brand btn-block margin-0 margin-t25"
                                                onClick={addHandler}
                                            >
                                                <i className="la la-plus" />
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {commissionList && commissionList.length > 0 && (
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
                                                <table className="kt-datatable__table">
                                                    <thead
                                                        className="kt-datatable__head"
                                                        style={{ display: 'block' }}>
                                                        <tr className="kt-datatable__row block-row">
                                                            <th className="kt-datatable__cell text-clip" width="20%">
                                                                <span>Start Date</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip" width="20%">
                                                                <span>End Date</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip" width="20%">
                                                                <span>Commission</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip" width="20%">
                                                                <span>TDS</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-center text-clip" width="20%">
                                                                <span>Action</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </div>
                                            <div 
                                                className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0"
                                                onScroll={helper.setStickyHeader}>
                                                <table className="kt-datatable__table table-striped">
                                                    <tbody
                                                        className="kt-datatable__body"
                                                        style={{ display: 'block', minHeight: '5vh'}}>
                                                        {commissionList.map((t, i) => (
                                                            <tr
                                                                key={i}
                                                                className="kt-datatable__row block-row"> 
                                                                <td width="20%" title={t.FromDate} className="kt-datatable__cell text-clip">
                                                                    <span>{t.FromDate}</span>
                                                                </td>
                                                                <td width="20%" title={t.ToDate} className="kt-datatable__cell text-clip">
                                                                    <span>{t.ToDate}</span>
                                                                </td>
                                                                <td width="20%" title={t.Commission} className="kt-datatable__cell text-clip">
                                                                    <span>{t.Commission + '%'}</span>
                                                                </td>
                                                                <td width="20%" title={t.TDS} className="kt-datatable__cell text-clip">
                                                                    <span>{t.TDS + '%'}</span>
                                                                </td>
                                                                <td width="20%" className='text-center'>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => removeHandler(i)}
                                                                        className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                                                                    >
                                                                        <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {targetDetails && targetDetails.length > 0 && (
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
                                                <table className="kt-datatable__table">
                                                    <thead
                                                        className="kt-datatable__head"
                                                        style={{ display: 'block' }}>
                                                        <tr className="kt-datatable__row block-row">
                                                            <th className="kt-datatable__cell text-clip" width="30%">
                                                                <span>Start Range</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip" width="30%">        
                                                                <span>End Range</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip" width="25%">
                                                                <span>Percentage</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip text-center" width="15%">
                                                                <span>Action</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </div>
                                            <div 
                                                className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0"
                                                onScroll={helper.setStickyHeader}>
                                                <table className="kt-datatable__table table-striped">
                                                    <tbody
                                                        className="kt-datatable__body"
                                                        style={{ display: 'block', minHeight: '5vh'}}>
                                                        {targetDetails.map((t, i) => (
                                                            <tr
                                                                key={i}
                                                                className="kt-datatable__row block-row"> 
                                                                <td width="30%" title={t.StartRange} className="kt-datatable__cell text-clip">
                                                                    <span><i className='la la-rupee'/>{t.StartRange}</span>
                                                                </td>
                                                                <td width="30%" title={t.EndRange} className="kt-datatable__cell text-clip">
                                                                    <span><i className='la la-rupee'/>{t.EndRange}</span>
                                                                </td>
                                                                <td width="25%" title={t.Percent} className="kt-datatable__cell text-clip">
                                                                    <span>{t.Percent + '%'}</span>
                                                                </td>
                                                                <td width="15%" className='text-center'>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => removeRangeHandler(i)}
                                                                        className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                                                                    >
                                                                        <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary margin-0 margin-r5"
                                data-dismiss="modal"
                                disabled={btnLoading}>
                                Close
                            </button>
                            <button
                                id="save-btn"
                                onClick={addRevenueHandler}
                                type="button"
                                className={`btn btn-brand margin-0 ${
                                btnLoading
                                    ? 'kt-spinner kt-spinner--sm kt-spinner--light'
                                    : ''
                                }`}
                                disabled={btnLoading} >                  
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <AddCompany
                type={addModalType}
                addInsuranceCompanyId={setAddedCompanyId}
                addProductId={setAddedProductId}
                from="#add-revenue-modal"
            />
        </>
    )
}

const RevenueUplaod = (props) => {
    const [showAddRevenueModal, setShowAddRevenueModal] = useState(false);
    const [refresh,             setRefresh]             = useState(false);

    const history = useHistory();

    const userDetails = getAuthState();

    useEffect(() => {
        props.title('Revenue Master')
    }, []);

    const navigateTo = (path) => {
        history.push(path);
    }

    const refetchRevenues = () => setRefresh((r) => !r);

    return (
        <>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                <div className="kt-portlet kt-portlet--mobile">
                    <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                        <div className="kt-portlet__head-label">
                            <nav className="file-nav navbar navbar-top">
                                <NavLink
                                    activeClassName="top-nav-active"
                                    to="/revenue-upload/revenue">         
                                    Revenue
                                </NavLink>
                            </nav>
                        </div>
                        <div className="kt-portlet__head-toolbar">
                            <div className="kt-portlet__head-wrapper">
                                <div className="kt-portlet__head-actions">
                                    <button className="btn btn-label-brand btn-icon-sm margin-l10" style={{display: 'inline'}}  onClick={() => navigateTo('/reports/revenueReports')}>
                                        <i className="la la-arrow-left"></i>
                                        Revenue Report
                                    </button>
                                    {userDetails.user.categoryId != 3 && (
                                        <button className="btn btn-brand btn-icon-sm text-white margin-l10" style={{display: 'inline'}}  onClick={() => setShowAddRevenueModal(true)}>
                                            <i className="la la-plus"></i>
                                            Revenue Master
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="kt-portlet__body kt-portlet__body--fit">
                        <RevenueMasterTable 
                            refresh={refresh}
                            refetchRevenues={() => refetchRevenues()}
                            setShowAddRevenueModal={setShowAddRevenueModal}
                        />
                    </div>  
                </div>
            </div>
            {showAddRevenueModal && (
                <AddRevenueModal
                    setShow={setShowAddRevenueModal}
                    show={showAddRevenueModal}
                    refetchRevenues={() => refetchRevenues()}
                />
            )}
        </>
    );
};

export default RevenueUplaod;
