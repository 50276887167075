
import { getAuthState , setAuthState } from '../../assets/scripts/login-util';
import swal from 'sweetalert2';


export const redirectToLogout = () => {
  swal.fire({
    icon               : 'warning',
    titleText          : 'Session Expired',
    text               : 'Kindly Login',
    timer              : 2000,
    showConfirmButton  : false,
  });
  setAuthState();
  return window.location.reload();
}


export const getTokenOrLogout = () => {
  const token = getAuthState('token');
  if(token === 'Expired' )
    redirectToLogout();
  else
    return token;
}

export const sleep = (ms) => {
  return new Promise(r => setTimeout(r, ms));
}


export const displayErrorSwal = (key) => {
  
  swal.close()
  switch(key){
    case'Email not verified':
      swal.fire({
        icon               : 'warning',
        titleText          : 'Email Change Detected ..!',
        text               : 'Kindly Login',
        timer              : 2000,
        showConfirmButton  : false,
      });
      break;
    case'User not found':
      swal.fire({
        icon               : 'warning',
        titleText          : 'User not found ..!',
        text               : 'Kindly Login',
        timer              : 2000,
        showConfirmButton  : false,
      });
      break;
    default:
      swal.fire({
        icon               : 'warning',
        titleText          : 'Unauthorized User ..!',
        text               : 'Kindly Login',
        timer              : 2000,
        showConfirmButton  : false,
      });
      break;
  }
}
