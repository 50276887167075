import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import 'jquery-form';
import 'jquery-validation';
import '../../assets/scripts/bootstrap-modal';
import '../../assets/scripts/jquery-validation.init';
import * as helper from '../global/helper';
import Loading from '../global/loading';
import { getClientsByGroupIDAndCompanyID } from '../api/clientAPI';
import { sendPolicyEmail, getPolicyDocuments} from '../api/policyAPI';
import swal from 'sweetalert2';
import Select from 'react-select';
import { getAgentsByAgentCompanyID } from '../api/agentAPI';
import { getAuthState } from '../../assets/scripts/login-util';
import { getSubAgentPolicyDocuments, getSubClientsByGroupID } from '../api/subAgencyAPI';

const EmailPolicy = (props) => {

    const [btnLoading,             setBtnLoading]             = useState(false); 
    const [clients,                setClients]                = useState([]);
    const [documents,              setDocuments]              = useState([]);
    const [userList,               setUserList]               = useState([]);
    const [documentList,           setDocumentList]           = useState([]);
    const [type,                   setType]                   = useState('Recipient');
    const [agents,                 setAgents]                 = useState([]);
    const [multiCC,                setMultiCC]                = useState([]);
    const [multiBCC,               setMultiBCC]               = useState([]);

    const user = getAuthState('user')

    useEffect(() => {
      let ignore = false;
  
      $('#email-policy-modal').modal({
        backdrop: 'static',
      });
  
      $('#email-policy-modal').on('hidden.bs.modal', function () {
          $('.modal').hasClass('show') && $('body').addClass('modal-open');   
          if (props.action === 'e') props.onDismissModal(); 
          $('#search-policy-modal').removeClass('modal-blur');       
      });
      $('#search-policy-modal').addClass('modal-blur');
  
      const fetchClients = async () => {
        try {
          setBtnLoading(true);
          // const response = props.from == 'subAgentPolicyStats' ? await getSubClientsByGroupID(props.groupId) : await getClientsByGroupIDAndCompanyID(props.groupId)
          const response = await getClientsByGroupIDAndCompanyID(props.groupId)
          setClients(response.filter(c => c.Email));
        } catch (err) {
          swal.fire({
            icon                  : 'error',
            titleText             : 'Error!',
            text                  : err.message,
            buttonsStyling        : false,
            confirmButtonClass    : 'btn btn-brand',
          });
          setBtnLoading(false);
          return;
        }
       
      }
  
      const fetchDocuments = async () => {
        try {
          // const response = props.from == 'subAgentPolicyStats' ? await getSubAgentPolicyDocuments(props.policyId) : await getPolicyDocuments(props.policyId);
          const response = await getPolicyDocuments(props.policyId);
          setDocuments(response);
          setBtnLoading(false);
        } catch (err) {
          swal.fire({
            icon                  : 'error',
            titleText             : 'Error!',
            text                  : err.message,
            buttonsStyling        : false,
            confirmButtonClass    : 'btn btn-brand',
          });
          return;
        }
      }
      if (!ignore) {        
        fetchClients();
        fetchDocuments();
        fetchAgents();
      }
      return () => {
        ignore = true;
      };
  
    }, []);
  
    useEffect(() => {
      if (clients.length > 0) {
        const client = clients.find(c => c.Category === 'Primary');
        setUserList([{
            id      : client.ID, 
            name    : client.FirstName, 
            email   : client.Email
        }]);
      }
    }, [clients]);
  
    const selectRecipientHandler = (clientId, checked, type) => {
      const client = type == 'client' ? clients.find(c => c.ID == clientId ): props.subAgentPolicyDetail;
      if (checked) {
        setUserList((a) => {
          const newA = [...a];
          newA.push({
            id      : client.ID, 
            name    : client.FirstName, 
            email   : client.Email
          });        
          return newA;
        });
      }else{
        setUserList((a) => {
          const nA = a.filter((c) => c.id != client.ID);
          return nA;
        })   
      }    
    }
  
    const selectDocumentHandler = (documentId, checked) => {
      if (checked) {    
        setDocumentList((a) => {
          const newA = [...a];
          newA.push(documentId);          
          return newA;
        });
      }else{
        setDocumentList((a) => {
          const nA = a.filter((d) => d != documentId);
          return nA;
        })   
      }    
    }
    const fetchAgents = async () => {
      try {
        const response = await getAgentsByAgentCompanyID();
        setAgents([...response, {ID: user.id, FirstName : user.firstName, LastName: user.lastName, Email: user.email}])
      } catch (err) {
        swal.fire({
          icon                  : 'error',
          titleText             : 'Error!',
          text                  : err.message,
          buttonsStyling        : false,
          confirmButtonClass    : 'btn btn-brand',
        });
        return;
      }
    }

    const CCHandler =(options) => {
      setMultiCC(options)
    }

    const BCCHandler =(options) => {
      setMultiBCC(options)
    }
  
    const openFile = async (index) => {
      const file      = documents[index];
      const ext       = helper.getFileExt(file.FileName);
      const name      = (file.DocumentName + '.' + ext).replace(/ /g, '_');
      helper.downloadAndOpenFileHandler(file.DocumentType, file.FileName, name)
    }
  
    const skipHandler = async (e) => {
      e.preventDefault();
      if (props.action === 'a') {
        if (props.from == 'subAgentPolicyStats') $('#add-policy-modal').modal('hide')
        props.refetchPolicies(1);   
        
      } 
      $('#email-policy-modal').modal('hide');        
    }
  
    const sendPolicy = async (e) => {
      e.preventDefault();
  
      try {
        setBtnLoading(true);   
        await sendPolicyEmail(props.policyId, userList, documentList, multiCC.map(c => c.value.Email), multiBCC.map(b => b.value.Email), props.from == 'subAgentPolicyStats' ? 'subAgent' : 'policy');
        props.refetchPolicies(2);
        props.from == 'subAgentPolicyStats' && $('#add-policy-modal').modal('hide');
        $('#email-policy-modal').modal('hide');
        swal.fire({
            icon              : 'success',
            titleText         : 'Policy Sent Successfully',
            showConfirmButton : false,
            timer             : 1500,
        });
          
      } catch (err) {
        swal.fire({
            icon                  : 'error',
            titleText             : 'Error!',
            text                  : err.message,
            buttonsStyling        : false,
            confirmButtonClass    : 'btn btn-brand',
        });
        setBtnLoading(false);
      }  
    }
  
    return (
      <div
        className="modal fade"
        id="email-policy-modal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true">
      
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{type === 'Document' ? 'Attach Document(s)' : 'Select Recipient(s)'}</h5>
              <button
                type="button"
                className="close"
                onClick={skipHandler}
                disabled={btnLoading}
                aria-label="Skip">
              </button>
            </div>
            <div className="modal-body">
                <div className="client-container">
                    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded">
                        <table className="kt-datatable__table table-striped">
                            <tbody className="kt-datatable__body" style={{display: 'block'}}>
                              {type === 'Document' ? (
                                documents && documents.length > 0 ? (
                                  documents.map((d, i) => 
                                    <tr key={d.ID} className="kt-datatable__row block-row">
                                      <td className="kt-datatable__cell text-clip">
                                        <div className="kt-notification text-clip">
                                            <a className="kt-notification__item padding-0" onClick={() => openFile(i)}>
                                                <div className="kt-notification__item-icon margin-r10">
                                                    <img height="30px" width="30px" src={helper.getFileLogo(d.FileName)} />
                                                </div>
                                                <div className="kt-notification__item-details text-clip">
                                                    <div className="kt-notification__item-title kt-font-bold text-clip">
                                                        {d.DocumentName}
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                      </td>
                                      <td className="text-right padding-r20">
                                          <label className="kt-checkbox kt-checkbox--brand">
                                              <input type="checkbox" onClick={(event) => selectDocumentHandler(d.ID, event.target.checked)}/>
                                              &nbsp;<span></span>
                                          </label>
                                      </td>
                                  </tr>
                                  )
                                ) : (<tr><td><Loading/></td></tr>)
                              ) : (
                                clients && clients.length > 0 && (
                                  clients.map((c) =>
                                    <tr key={c.ID} className="kt-datatable__row block-row">
                                        <td className="kt-datatable__cell text-clip">
                                            <div className="kt-user-card-v2">
                                                <div className="kt-user-card-v2__pic">
                                                    <div className={`kt-badge kt-badge--xl kt-badge--${c.Type === 'Corporate' ? 'success' : 'info' }`}>
                                                        {c.FirstName.slice(0, 1)}
                                                    </div>
                                                </div>
                                                <div className="kt-user-card-v2__details">
                                                    <span className="kt-user-card-v2__name">{`${c.FirstName} ${c.MiddleName ? c.MiddleName : ''} ${c.LastName}`}</span>
                                                    <span className="kt-user-card-v2__desc">{c.Email}</span>
                                                </div>
                                            </div>
                                        </td>
                                        {props.from == 'subAgentPolicyStats' && (
                                          <td className="text-right padding-r40">
                                            <label className="kt-checkbox kt-checkbox--brand">
                                              Sub Client
                                            </label>
                                          </td>
                                        )}
                                        <td className="text-right padding-r20">
                                            <label className="kt-checkbox kt-checkbox--brand">
                                                <input type="checkbox" defaultChecked={c.Category === 'Primary'}  onClick={(event) => selectRecipientHandler(c.ID, event.target.checked, 'client')}/>
                                                &nbsp;<span></span>
                                            </label>
                                        </td>
                                    </tr>
                                  )
                                )
                                // : (<tr><td><Loading/></td></tr>)
                              )}
                              {props.from == 'subAgentPolicyStats' && props.subAgentPolicyDetail &&
                                (
                                  <tr  className="kt-datatable__row block-row">
                                      <td className="kt-datatable__cell text-clip">
                                          <div className="kt-user-card-v2">
                                              <div className="kt-user-card-v2__pic">
                                                  <div className={`kt-badge kt-badge--xl kt-badge--${props.subAgentPolicyDetail.Type === 'Corporate' ? 'success' : 'info' }`}>
                                                      {props.subAgentPolicyDetail.FirstName.slice(0, 1)}
                                                  </div>
                                              </div>
                                              <div className="kt-user-card-v2__details">
                                                  <span className="kt-user-card-v2__name">{props.subAgentPolicyDetail.FirstName}</span>
                                                  <span className="kt-user-card-v2__desc">{props.subAgentPolicyDetail.Email}</span>
                                              </div>
                                          </div>
                                      </td>
                                      <td className="text-right padding-r40">
                                            <label className="kt-checkbox kt-checkbox--brand">
                                              Sub Agent
                                            </label>
                                        </td>
                                      <td className="text-right padding-r20">
                                          <label className="kt-checkbox kt-checkbox--brand">
                                              <input type="checkbox"  onClick={(event) => selectRecipientHandler(props.subAgentPolicyDetail.ID, event.target.checked, 'subAgent')}/>
                                              &nbsp;<span></span>
                                          </label>
                                      </td>
                                  </tr>
                                )
                              }
                            </tbody>
                        </table>
                        <div className="row margin-t20">
                          <div className="col-12">
                              <div className="form-group">
                                  <label htmlFor="ddlCC">CC Team</label>       
                                  <Select
                                      value={multiCC}
                                      onChange={CCHandler}
                                      isMulti
                                      name="ddlCC"
                                      id="ddlCC"
                                      placeholder="Select Team Member"
                                      className="multi-custom"
                                      classNamePrefix  = 'multi'
                                      options={agents.map(c => ({
                                          label: `${c.FirstName} ${c.LastName}`,
                                          value: c
                                      }))}
                                  />
                              </div>
                          </div>
                      </div>              
                      <div className="row">
                          <div className="col-12">
                              <div className="form-group">
                                  <label htmlFor="ddlBCC">BCC Team</label>       
                                  <Select
                                      value={multiBCC}
                                      onChange={BCCHandler}
                                      isMulti
                                      name="ddlBCC"
                                      id="ddlBCC"
                                      placeholder="Select Team Member"
                                      className="multi-custom"
                                      classNamePrefix  = 'multi'
                                      options={agents.map(c => ({
                                          label: `${c.FirstName} ${c.LastName}`,
                                          value: c
                                      }))}
                                  />
                              </div>
                          </div>
                      </div>
                    </div>            
                </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary margin-0 margin-r5"
                onClick={skipHandler}
                disabled={btnLoading}>    
                {props.action === 'a' ? 'Skip Email' : 'Close'}
              </button>
              {type === 'Document' || documents.length == 0 ? (
                <button
                  id="save-document"
                  onClick={sendPolicy}
                  type="button"
                  className={`btn btn-brand margin-0 ${
                      btnLoading
                          ? 'kt-spinner kt-spinner--sm kt-spinner--light'
                          : ''
                      }`}
                  disabled={btnLoading} >   
                  Send Email
                </button>
              ) : (
                <button
                  onClick={() => setType('Document')}
                  type="button"
                  className={`btn btn-brand margin-0 ${
                      btnLoading
                          ? 'kt-spinner kt-spinner--sm kt-spinner--light'
                          : ''
                      }`}
                  disabled={btnLoading} >   
                  Proceed
                </button>
              )}      
            </div>
          </div>
        </div>
      </div>
    );   
    
};

export default EmailPolicy;