import React, { useEffect, useState } from 'react';
import '../../containers/App.css'
import ReactApexChart from "react-apexcharts";
import { addInsuranceCompany, getBusinessReports, getBusinessReportsByAgentID, getPolicyCountByAgentCompany } from '../api/policyAPI';
import { NavLink } from 'react-router-dom'
import Loading from '../global/loading';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { completeTask, getTask } from '../api/taskAPI';
import NoData from '../global/noData';
import TaskImg from '../../assets/images/no-task.svg'
import PolicyImg from '../../assets/images/no-policy.svg'
import swal from 'sweetalert2';
const moment = extendMoment(Moment);
import { getAuthState } from '../../assets/scripts/login-util';

const Dashboard = props => {

    const [loading,                         setLoading]                         = useState(false);
    const [taskLoading,                     setTaskLoading]                     = useState(false);
    const [productChartData,                setProductChartData]                = useState(null);
    const [defaultProductChartData,         setDefaultProductChartData]         = useState(null);
    const [productData,                     setProductData]                     = useState(null);
    const [policyPremiumData,               setPolicyPremiumData]               = useState(null);
    const [companyPremiumData,              setCompanyPremiumData]              = useState(null);
    const [policyPremiumAverage,            setPolicyPremiumAverage]            = useState([]);
    const [companyPremiumAverage,           setCompanyPremiumAverage]           = useState([]);
    const [policyChartData,                 setPolicyChartData]                 = useState(null);
    const [defaultPolicyChartData,          setDefaultPolicyChartData]          = useState(null);
    const [currentPolicyData,               setCurrentPolicyData]               = useState(null);
    const [prevPolicyData,                  setPrevPolicyData]                  = useState(null);
    const [businessReportChart,             setBusinessReportChart]             = useState(null);
    const [newPolicyBusinessChart,          setNewPolicyBusinessChart]          = useState(null);
    const [renewalPolicyBusinessChart,      setRenewalPolicyBusinessChart]      = useState(null);
    const [policyCountChart,                setPolicyCountChart]                = useState(null);
    const [policies,                        setPolicies]                        = useState([]);
    const [previousPolicies,                setPreviousPolicies]                = useState([]);
    const [businessReport,                  setBusinessReport]                  = useState(0);
    const [businessReportPremium,           setBusinessReportPremium]           = useState(0);
    const [newBusiness,                     setNewBusiness]                     = useState(0);
    const [renewalBusiness,                 setRenewalBusiness]                 = useState(0);
    const [prevBusinessReport,              setPrevBusinessReport]              = useState(0);
    const [prevNewBusiness,                 setPrevNewBusiness]                 = useState(0);
    const [prevRenewalBusiness,             setPrevRenewalBusiness]             = useState(0);
    const [businessAverage,                 setBusinessAverage]                 = useState(0);
    const [policyCountAverage,              setPolicyCountAverage]              = useState(0);
    const [newBusinessAverage,              setNewBusinessAverage]              = useState(0);
    const [renewalBusinessAverage,          setRenewalBusinessAverage]          = useState(0);
    const [policiesCount,                   setPoliciesCount]                   = useState(0);
    const [prevPoliciesCount,               setPrevPoliciesCount]               = useState(0);
    const [currentDate,                     setCurrentDate]                     = useState(null);
    const [chartlength,                     setChartLength]                     = useState(null);
    const [currentYearBusiness,             setCurrentYearBusiness]             = useState(null);
    const [previousYearBusiness,            setPreviousYearBusiness]            = useState(null);
    const [businessChartData,               setBusinessChartData]               = useState(null);
    const [newBusinessChartData,            setNewBusinessChartData]            = useState(null);
    const [policyCountChartData,            setPolicyCountChartData]            = useState(null);
    const [renewalBusinessChartData,        setRenewalBusinessChartData]        = useState(null);
    const [prevBusinessChartData,           setPrevBusinessChartData]           = useState(null);
    const [prevNewBusinessChartData,        setPrevNewBusinessChartData]        = useState(null);
    const [prevRenewalBusinessChartData,    setPrevRenewalBusinessChartData]    = useState(null);
    const [prevPolicyCountChartData,        setPrevPolicyCountChartData]        = useState(null);
    const [businessAvg,                     setBusinessAvg]                     = useState(null);
    const [newBusinessAvg,                  setNewBusinessAvg]                  = useState(null);
    const [renewalBusinessAvg,              setRenewalBusinessAvg]              = useState(null);
    const [countAvg,                        setCountAvg]                        = useState(null);
    const [policyLength,                    setPolicyLength]                    = useState(null);
    const [tasks,                           setTasks]                           = useState([]);
    const [refresh,                         setRefresh]                         = useState(false);
    const [filterBusiness,                  setFilterBusiness]                  = useState('product');

    const userDetails = getAuthState();

    useEffect(() => {
        props.title('Dashboard', 'menuDashboard');
        
        setDefaultPolicyChartData({
            series: [{
                name: 'Previous Month',  
                data:  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                }, {
                name: 'Current Month',
                data:  [0]                          
            }],
            options: {
                colors: ['#d2ebfe', '#d3faed'],      
                chart: {
                    zoom: {
                        enabled: false,
                    },
                    type: 'area',
                    toolbar: {
                        show: false
                    }
                },
                tooltip: {
                    enabled: true,
                    fillSeriesColor: true,
                },
                legend: {
                    show: true,
                    height:40,
                    markers: {
                        fillColors: ['#066cb9', '#00e396'],
                    },
                },
                markers: {
                    colors: ['#066cb9', '#00e396'],
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: 'smooth',
                    colors: ['#066cb9', '#00e396'],
                    dashArray: [3, 0],
                    width: 2
                },
                xaxis: {
                    type: 'category',
                    labels: {
                        rotate: 0,
                    },
                    tickAmount: 5,
                },       
            },
        })

        fetchPolicies();
    }, []);

    useEffect(() => {
        fetchTask();
    }, [refresh]);

    useEffect(() => {
        $('[data-toggle="kt-popover"]').popover({ html: true});
        $('[data-toggle="kt-tooltip"]').tooltip();
        $('body').on('click', function (e) {
            $('[data-toggle="kt-popover"]').each(function () {
                // hide any open popovers when the anywhere else in the body is clicked
                if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                    $(this).popover('hide');
                }
            });
        }); 
    })

    useEffect(() => {
        if (props.currentPeriod.id != 6) {
            fetchPolicies();
        }
    }, [props.fromDate, props.toDate]);

    useEffect(() => {
        if (policies) {
            setPoliciesCount(policies.length)
            //Pie chart for product data start
            const products = policies.length > 0 ? policies.filter((p, i, arr) => i == arr.findIndex(f => f.ProductID == p.ProductID)).map(p => ({
                ProductName: p.ProductName + ' Policies',
                Occurrence: policies.filter(a => a.ProductID == p.ProductID).length
            })).sort((a, b) =>  b.Occurrence - a.Occurrence)
            : policies;

            if (products.length > 6)
                setProductData([...products.slice(0, 5), { ProductName: 'Others', Occurrence: products.slice(5).map(o => o.Occurrence).reduce((prev, curr) => prev + curr)}]);
            else
                setProductData([...products])
            //Pie chart for product data end

            //Pie chart for Policy start
            const totalPremium = policies.length > 0 ? policies.filter((p, i, arr) => i == arr.findIndex(f => f.ProductID == p.ProductID)).map(p => ({
                ProductIcon : p.ProductIcon,
                ProductName : p.ProductName,
                TotalPremium: policies.filter(a => a.ProductID == p.ProductID).map(p => p.Premium).reduce((prev, curr) => prev + curr)
            })).sort((a, b) => b.TotalPremium - a.TotalPremium)
            : policies;

            if (totalPremium.length > 6)
                setPolicyPremiumData([...totalPremium.slice(0, 5), { ProductIcon : 'https://insureboxdev.s3.ap-south-1.amazonaws.com/Public/Misc_Insurance.png', ProductName: 'Others', TotalPremium: totalPremium.slice(5).map(o => o.TotalPremium).reduce((prev, curr) => prev + curr)}]);
            else
                setPolicyPremiumData([...totalPremium])
            //Pie chart for Policy end

            const distinctCompanies = policies.length > 0 ? policies.filter((p, i, arr) => i == arr.findIndex(f => f.InsuranceCompanyID == p.InsuranceCompanyID)).map(p => ({
                CompanyFirstLetter   : p.InsuranceCompanyName.slice(0, 1),
                InsuranceCompanyName : p.InsuranceCompanyName,
                TotalPremium         : policies.filter(a => a.InsuranceCompanyID == p.InsuranceCompanyID).map(p => p.Premium).reduce((prev, curr) => prev + curr)
            })).sort((a, b) => b.TotalPremium - a.TotalPremium)
            : policies;

            if (distinctCompanies.length > 6)
                setCompanyPremiumData([...distinctCompanies.slice(0, 5), { CompanyFirstLetter : 'O', InsuranceCompanyName: 'Others', TotalPremium: distinctCompanies.slice(5).map(o => o.TotalPremium).reduce((prev, curr) => prev + curr)}]);
            else
                setCompanyPremiumData([...distinctCompanies])

            //Policy chart start
            const currentPolicies = policies.sort((a, b) => new Date(a.IssueDate) - new Date(b.IssueDate)).map(p => ({Premium: p.Premium, IssueDate: p.IssueDate, BusinessType: p.BusinessType}))
            const sortedNewPolicies = policies.sort((a, b) => new Date(a.IssueDate) - new Date(b.IssueDate)).filter(b => b.BusinessType === 'New').map(p => ({Premium: p.Premium, IssueDate: p.IssueDate, BusinessType: p.BusinessType}))
            const sortedRenewalPolicies = policies.sort((a, b) => new Date(a.IssueDate) - new Date(b.IssueDate)).filter(b => b.BusinessType === 'Renewal').map(p => ({Premium: p.Premium, IssueDate: p.IssueDate, BusinessType: p.BusinessType}))
            const sortedPoliciesCount = currentPolicies.map(p => ({IssueDate: p.IssueDate, Count: 1}));
            const finalCurrentPolicies = currentPolicies.reduce((acc, curr) => {
                let item = acc.find(p => p.IssueDate === curr.IssueDate);
                if (item) item.Premium += curr.Premium;
                else acc.push(curr)
                return acc;
            }, []);
            //Adding Premium of same date for newBusiness report
            const currentNewPolicies = sortedNewPolicies.reduce((acc, curr) => {
                let item = acc.find(p => p.IssueDate === curr.IssueDate);
                if (item) item.Premium += curr.Premium;
                else acc.push(curr)
                return acc;
            }, []);

            //Adding Premium of same date for renewalBusiness report
            const currentRenewalPolicies = sortedRenewalPolicies.reduce((acc, curr) => {
                let item = acc.find(p => p.IssueDate === curr.IssueDate);
                if (item) item.Premium += curr.Premium;
                else acc.push(curr)
                return acc;
            }, []);

            //Adding policy of same date
            const currentPoliciesCount = sortedPoliciesCount.reduce((acc, curr) => {
                let item = acc.find(p => p.IssueDate === curr.IssueDate);
                if (item) item.Count += 1;
                else acc.push(curr)
                return acc;
            }, []);

            //Getting dates
            const range = moment.range(moment(props.fromDate).format('YYYY-MM-DD'), props.currentPeriod.id == 1 ? moment().format('YYYY-MM-DD') : moment(props.toDate).format('YYYY-MM-DD'))
            const dates = Array.from(range.by('days'));

            const currentPolicy = dates.map(d => {
                const matchDate = finalCurrentPolicies.find(f => moment(f.IssueDate).format('YYYY-MM-DD') === d.format('YYYY-MM-DD'))
                let premium = 0;
                if (matchDate) premium = matchDate.Premium;
                return {Premium: premium, IssueDate: d.format('YYYY-MM-DD')}
            });
            const finalNewPolicy = dates.map(d => {
                const matchDate = currentNewPolicies.find(f => moment(f.IssueDate).format('YYYY-MM-DD') === d.format('YYYY-MM-DD'))
                let premium = 0;
                if (matchDate) premium = matchDate.Premium;
                return {Premium: premium, IssueDate: d.format('YYYY-MM-DD')}
            });

            const finalRenewalPolicy = dates.map(d => {
                const matchDate = currentRenewalPolicies.find(f => moment(f.IssueDate).format('YYYY-MM-DD') === d.format('YYYY-MM-DD'))
                let premium = 0;
                if (matchDate) premium = matchDate.Premium;
                return {Premium: premium, IssueDate: d.format('YYYY-MM-DD')}
            });

            const currentPolicyCount = dates.map(d => {
                const matchDate = currentPoliciesCount.find(f => moment(f.IssueDate).format('YYYY-MM-DD') === d.format('YYYY-MM-DD'))
                let count = 0;
                if (matchDate) count = matchDate.Count;
                return {Count: count, IssueDate: d.format('YYYY-MM-DD')}
            });

            setCurrentYearBusiness(dates.map(d => {
                const matchDate = finalCurrentPolicies.find(f => moment(f.IssueDate).format('YYYY-MM-DD') === d.format('YYYY-MM-DD'))
                let premium = 0;
                let businessType = 'New';
                if (matchDate) {
                    premium = matchDate.Premium
                    businessType = matchDate.BusinessType
                }
                return {Premium: premium, IssueDate: d.format('YYYY-MM-DD'), BusinessType: businessType}
            }))

            //Getting how much size required to split
            const CurrentPolicyLength = currentPolicy.length
            setPolicyLength(currentPolicy.length)
            let size = 1;
            if (props.currentPeriod.id == 7 || props.currentPeriod.id == 9) {
                size = 4;
            } else if (props.currentPeriod.id == 6 && CurrentPolicyLength > 93) {
                size = Math.ceil(CurrentPolicyLength / 93);
            }
            let policy = [];
            if (props.currentPeriod.id == 7 || props.currentPeriod.id == 9 || props.currentPeriod.id == 6) {
                const chunk = [];
                //spliting array into small chunks
                for (let i = 0; i <= CurrentPolicyLength; i+=size) {
                    chunk.push([...currentPolicy].splice(i, size))
                }
                setChartLength(chunk.length);
                policy = chunk.filter(c => c.length != 0).map(p => {
                    let diff = false;
                    let yearDiff = false;
                    if (p.length > 1){
                        diff = moment(p[p.length-1].IssueDate).format('MMM') == moment(p[0].IssueDate).format('MMM');
                        yearDiff = moment(p[p.length-1].IssueDate).format('YYYY') == moment(p[0].IssueDate).format('YYYY');
                    }
                    return {
                        Premium: p.map(c => c.Premium).reduce((prev, curr) => prev + curr, 0),
                        IssueDate:
                        p.length > 1 ? 
                        `${(props.currentPeriod.id == 6 && !yearDiff) ? moment(p[0].IssueDate).format('DD MMM YYYY') : !diff ? moment(p[0].IssueDate).format('DD MMM') : moment(p[0].IssueDate).format('DD')}-${props.currentPeriod.id == 6 ?  moment(p[p.length-1].IssueDate).format('DD MMM YYYY') : moment(p[p.length-1].IssueDate).format('DD MMM')}` 
                        : moment(p[0].IssueDate).format('DD MMM')
                    }
                })
                setCurrentPolicyData(policy)
            } else {
                setCurrentPolicyData(currentPolicy);
            }

            //Get current month dates
            const currentMonthDates = Array.from({length: moment().daysInMonth()}, (x, i) => moment().startOf('month').add(i, 'days'));
            setCurrentDate(currentMonthDates.map(c => c.format('DD-MMM')));

            //Business chart data
            if (props.currentPeriod.id == 1 || props.currentPeriod.id == 3 || props.currentPeriod.id == 5) {
                const topChartArray = currentPolicy.map(c => c.Premium)
                const length = topChartArray.length;
                const chunk = [];
                const size = Math.ceil(length / 11)
                for (let i = 0; i <= length; i+=size) {
                    chunk.push([...topChartArray].splice(i, size))
                }
                setBusinessChartData(chunk.map(p => p.reduce((prev, curr) => prev + curr, 0)))
            } else if (props.currentPeriod.id == 7) {
                const topChartArray = currentPolicy.map(c => c.Premium).slice(0, moment().format('DDD'))
                const length = topChartArray.length;
                const chunk = [];
                const size = Math.ceil(length / 11)
                for (let i = 0; i <= length; i+=size) {
                    chunk.push([...topChartArray].splice(i, size))
                }
                setBusinessChartData(chunk.map(p => p.reduce((prev, curr) => prev + curr, 0)))
            }else {
                const topChartArray = policy.map(c => c.Premium);
                const length = topChartArray.length;
                const chunk = []
                const size = Math.ceil(length / 11)
                for (let i = 0; i <= length; i+=size) {
                    chunk.push([...topChartArray].splice(i, size))
                }
                setBusinessChartData(chunk.map(p => p.reduce((prev, curr) => prev + curr, 0)))
            }

            //NewBusiness chart data
            if (props.currentPeriod.id == 7) {
                const topChartArray = finalNewPolicy.map(c => c.Premium).slice(0, moment().format('DDD'));
                const length = topChartArray.length;
                const chunk = [];
                const size = Math.ceil(length / 11)
                for (let i = 0; i <= length; i+=size) {
                    chunk.push([...topChartArray].splice(i, size))
                }
                setNewBusinessChartData(chunk.map(p => p.reduce((prev, curr) => prev + curr, 0)))
            } else {
                const newBusinessChartArray = finalNewPolicy.map(c => c.Premium)
                const newBusinessLength = newBusinessChartArray.length;
                const newBusinessChunk = [];
                const size = Math.ceil(newBusinessLength / 11);
                for (let i = 0; i <= newBusinessLength; i+=size) {
                    newBusinessChunk.push([...newBusinessChartArray].splice(i, size))
                }
                setNewBusinessChartData(newBusinessChunk.map(p => p.reduce((prev, curr) => prev + curr, 0)))
            }

            //RenewalBusiness chart data
            if (props.currentPeriod.id == 7) {
                const topChartArray = finalRenewalPolicy.map(c => c.Premium).slice(0, moment().format('DDD'));
                const length = topChartArray.length;
                const chunk = [];
                const size = Math.ceil(length / 11)
                for (let i = 0; i <= length; i+=size) {
                    chunk.push([...topChartArray].splice(i, size))
                }
                setRenewalBusinessChartData(chunk.map(p => p.reduce((prev, curr) => prev + curr, 0)))
            } else {
                const renewalBusinessChartArray = finalRenewalPolicy.map(c => c.Premium)
                const renewalBusinessLength = renewalBusinessChartArray.length;
                const renewalBusinessChunk = [];
                const size = Math.ceil(renewalBusinessLength / 11);
                for (let i = 0; i <= renewalBusinessLength; i+=size) {
                    renewalBusinessChunk.push([...renewalBusinessChartArray].splice(i, size))
                }
                setRenewalBusinessChartData(renewalBusinessChunk.map(p => p.reduce((prev, curr) => prev + curr, 0)))
            }

            //Policy count chart data
            if (props.currentPeriod.id == 7) {
                const topChartArray = currentPolicyCount.map(c => c.Count).slice(0, moment().format('DDD'));
                const length = topChartArray.length;
                const chunk = [];
                const size = Math.ceil(length / 11)
                for (let i = 0; i <= length; i+=size) {
                    chunk.push([...topChartArray].splice(i, size))
                }
                setPolicyCountChartData(chunk.map(p => p.reduce((prev, curr) => prev + curr, 0)))
            } else {
                const policyCountChartArray = currentPolicyCount.map(c => c.Count)
                const policyCountLength = policyCountChartArray.length;
                const policyCountChunk = [];
                const size = Math.ceil(policyCountLength / 11);
                for (let i = 0; i <= policyCountLength; i+=size) {
                    policyCountChunk.push([...policyCountChartArray].splice(i, size))
                }
                setPolicyCountChartData(policyCountChunk.map(p => p.reduce((prev, curr) => prev + curr, 0)))
            }
        }
       
    }, [policies]);

    useEffect(() => {
        if (previousPolicies) {

            //Policy chart start
            const prev = previousPolicies.sort((a, b) => new Date(a.IssueDate) - new Date(b.IssueDate)).map(p => ({Premium: p.Premium, IssueDate: p.IssueDate, BusinessType: p.BusinessType}))
            const prevSortedNewPolicies = prev.filter(b => b.BusinessType === 'New')
            const prevSortedRenewalPolicies = prev.filter(b => b.BusinessType === 'Renewal')
            const prevSortedPoliciesCount = prev.map(p => ({IssueDate: p.IssueDate, Count: 1}))
            
            //Adding Premium of same date for business report
            const finalPrev = prev.reduce((acc, curr) => {
                let item = acc.find(p => p.IssueDate === curr.IssueDate);
                if (item) item.Premium += curr.Premium;
                else acc.push(curr)
                return acc;
            }, []);

            //Adding Premium of same date for newBusiness report
            const prevNewPolicies = prevSortedNewPolicies.reduce((acc, curr) => {
                let item = acc.find(p => p.IssueDate === curr.IssueDate);
                if (item) item.Premium += curr.Premium;
                else acc.push(curr)
                return acc;
            }, []);

            //Adding Premium of same date for renewalBusiness report
            const prevRenewalPolicies = prevSortedRenewalPolicies.reduce((acc, curr) => {
                let item = acc.find(p => p.IssueDate === curr.IssueDate);
                if (item) item.Premium += curr.Premium;
                else acc.push(curr)
                return acc;
            }, []);

            //Adding Policy count of same date
            const prevPoliciesCount = prevSortedPoliciesCount.reduce((acc, curr) => {
                let item = acc.find(p => p.IssueDate === curr.IssueDate);
                if (item) item.Count += 1;
                else acc.push(curr)
                return acc;
            }, []);

            const previousDate = filterDate(props.fromDate, props.toDate, props.currentPeriod.id == 6 ? true : false);
            const range = moment.range(previousDate.prevFrom, previousDate.prevTo)
            const dates = Array.from(range.by('days'));

            const previousPolicy = dates.map(d => {
                const matchDate = finalPrev.find(f => moment(f.IssueDate).format('YYYY-MM-DD') === d.format('YYYY-MM-DD'))
                let premium = 0;
                if (matchDate) premium = matchDate.Premium;
                return {Premium: premium, IssueDate: d.format('YYYY-MM-DD')}
            });

            const finalNewPolicy = dates.map(d => {
                const matchDate = prevNewPolicies.find(f => moment(f.IssueDate).format('YYYY-MM-DD') === d.format('YYYY-MM-DD'))
                let premium = 0;
                if (matchDate) premium = matchDate.Premium;
                return {Premium: premium, IssueDate: d.format('YYYY-MM-DD')}
            });

            const finalRenewalPolicy = dates.map(d => {
                const matchDate = prevRenewalPolicies.find(f => moment(f.IssueDate).format('YYYY-MM-DD') === d.format('YYYY-MM-DD'))
                let premium = 0;
                if (matchDate) premium = matchDate.Premium;
                return {Premium: premium, IssueDate: d.format('YYYY-MM-DD')}
            });

            const previousPolicyCount = dates.map(d => {
                const matchDate = prevPoliciesCount.find(f => moment(f.IssueDate).format('YYYY-MM-DD') === d.format('YYYY-MM-DD'))
                let count = 0;
                if (matchDate) count = matchDate.Count;
                return {Count: count, IssueDate: d.format('YYYY-MM-DD')}
            });
            if (props.currentPeriod.id == 1 || props.currentPeriod.id == 7)
                setPrevPoliciesCount(previousPolicyCount.slice(0, moment().format('DDD')).filter(p => p.Count > 0).length);
            else 
                setPrevPoliciesCount(previousPolicies.length)

            setPreviousYearBusiness(dates.map(d => {
                const matchDate = finalPrev.find(f => moment(f.IssueDate).format('YYYY-MM-DD') === d.format('YYYY-MM-DD'))
                let premium = 0;
                let businessType = 'New';
                if (matchDate) {
                    premium = matchDate.Premium
                    businessType = matchDate.BusinessType
                }
                return {Premium: premium, IssueDate: d.format('YYYY-MM-DD'), BusinessType: businessType}
            }));

            //Getting how much size required to split
            const PreviousPolicyLength = previousPolicy.length
            let size = 1;
            if (props.currentPeriod.id == 7 || props.currentPeriod.id == 9) {
                size = 4;
            } else if (props.currentPeriod.id == 6 && PreviousPolicyLength > 93) {
                size = Math.ceil(PreviousPolicyLength / 93);
            }
            let prevPolicy = [];
            if (props.currentPeriod.id == 7 || props.currentPeriod.id == 9 || props.currentPeriod.id == 6) {
                //spliting array into small chunks
                const chunk = [];
                for (let i = 0; i < PreviousPolicyLength; i+=size) {
                    chunk.push([...previousPolicy].splice(i, size))
                }
                prevPolicy = chunk.filter(c => c.length != 0).map(p => {
                    let diff = false;
                    let yearDiff = false;
                    if (p.length > 1) {
                        diff = moment(p[p.length-1].IssueDate).format('MMM') == moment(p[0].IssueDate).format('MMM');
                        yearDiff = moment(p[p.length-1].IssueDate).format('YYYY') == moment(p[0].IssueDate).format('YYYY');
                    }
                    return {
                        Premium: p.map(c => c.Premium).reduce((prev, curr) => prev + curr, 0),
                        IssueDate: 
                        p.length > 1 ? 
                        `${(props.currentPeriod.id == 6 && !yearDiff) ? moment(p[0].IssueDate).format('DD MMM YYYY') : !diff ? moment(p[0].IssueDate).format('DD MMM') : moment(p[0].IssueDate).format('DD')}-${props.currentPeriod.id == 6 ?  moment(p[p.length-1].IssueDate).format('DD MMM YYYY') : moment(p[p.length-1].IssueDate).format('DD MMM')}` 
                        : moment(p[0].IssueDate).format('DD MMM')
                    }
                })
                setPrevPolicyData(prevPolicy);
            } else {
                setPrevPolicyData(previousPolicy);
            }

            if (policyLength) {
                //Business chart data
                if (policyLength && (props.currentPeriod.id == 1 || props.currentPeriod.id == 3 || props.currentPeriod.id == 5)) {
                    const topChartArray = previousPolicy.map(c => c.Premium).slice(0, policyLength)
                    const length = topChartArray.length;
                    const chunk = [];
                    const size = Math.ceil(length / 11);
                    for (let i = 0; i <= length; i+=size) {
                        chunk.push([...topChartArray].splice(i, size))
                    }
                    setPrevBusinessChartData(chunk.map(p => p.reduce((prev, curr) => prev + curr, 0)))
                }else if (props.currentPeriod.id == 7) {
                    const topChartArray = previousPolicy.map(c => c.Premium).slice(0, moment().format('DDD'))
                    const length = topChartArray.length;
                    const chunk = [];
                    const size = length / 11;
                    for (let i = 0; i <= length; i+=size) {
                        chunk.push([...topChartArray].splice(i, size))
                    }
                    setPrevBusinessChartData(chunk.map(p => p.reduce((prev, curr) => prev + curr, 0)))
                } else {
                    const topChartArray = prevPolicy.map(c => c.Premium).slice(0, policyLength);
                    const length = topChartArray.length;
                    const chunk = []
                    const size = Math.ceil(length / 11);
                    for (let i = 0; i <= length; i+=size) {
                        chunk.push([...topChartArray].splice(i, size))
                    }
                    setPrevBusinessChartData(chunk.map(p => p.reduce((prev, curr) => prev + curr, 0)))
                }

                //NewBusiness chart data
                if (props.currentPeriod.id == 7) {
                    const topChartArray = finalNewPolicy.map(c => c.Premium).slice(0, moment().format('DDD'));
                    const length = topChartArray.length;
                    const chunk = [];
                    const size = Math.ceil(length / 11);
                    for (let i = 0; i <= length; i+=size) {
                        chunk.push([...topChartArray].splice(i, size))
                    }
                    setPrevNewBusinessChartData(chunk.map(p => p.reduce((prev, curr) => prev + curr, 0)))
                } else {
                    const newBusinessChartArray = finalNewPolicy.map(c => c.Premium).slice(0, policyLength)
                    const newBusinessLength = newBusinessChartArray.length;
                    const newBusinessChunk = [];
                    const size = Math.ceil(newBusinessLength / 11);
                    for (let i = 0; i <= newBusinessLength; i+=size) {
                        newBusinessChunk.push([...newBusinessChartArray].splice(i, size))
                    }
                    setPrevNewBusinessChartData(newBusinessChunk.map(p => p.reduce((prev, curr) => prev + curr, 0)))
                }

                //RenewalBusiness chart data
                if (props.currentPeriod.id == 7) {
                    const topChartArray = finalRenewalPolicy.map(c => c.Premium).slice(0, moment().format('DDD'));
                    const length = topChartArray.length;
                    const chunk = [];
                    const size = Math.ceil(length / 11);
                    for (let i = 0; i <= length; i+=size) {
                        chunk.push([...topChartArray].splice(i, size))
                    }
                    setPrevRenewalBusinessChartData(chunk.map(p => p.reduce((prev, curr) => prev + curr, 0)))
                } else {
                    const renewalBusinessChartArray = finalRenewalPolicy.map(c => c.Premium).slice(0, policyLength)
                    const renewalBusinessLength = renewalBusinessChartArray.length;
                    const renewalBusinessChunk = [];
                    const size = Math.ceil(renewalBusinessLength / 11);
                    for (let i = 0; i <= renewalBusinessLength; i+=size) {
                        renewalBusinessChunk.push([...renewalBusinessChartArray].splice(i, size))
                    }
                    setPrevRenewalBusinessChartData(renewalBusinessChunk.map(p => p.reduce((prev, curr) => prev + curr, 0)))
                }

                //Policy count chart data
                if (props.currentPeriod.id == 7) {
                    const topChartArray = previousPolicyCount.map(c => c.Count).slice(0, moment().format('DDD'));
                    const length = topChartArray.length;
                    const chunk = [];
                    const size = Math.ceil(length / 11);
                    for (let i = 0; i <= length; i+=size) {
                        chunk.push([...topChartArray].splice(i, size))
                    }
                    setPrevPolicyCountChartData(chunk.map(p => p.reduce((prev, curr) => prev + curr, 0)))
                } else {
                    const policyCountChartArray = previousPolicyCount.map(c => c.Count).slice(0, policyLength)
                    const policyCountLength = policyCountChartArray.length;
                    const policyCountChunk = [];
                    const size = Math.ceil(policyCountLength / 11);
                    for (let i = 0; i <= policyCountLength; i+=size) {
                        policyCountChunk.push([...policyCountChartArray].splice(i, size))
                    }
                    setPrevPolicyCountChartData(policyCountChunk.map(p => p.reduce((prev, curr) => prev + curr, 0)))
                }
            }
        }
    }, [previousPolicies, policyLength]);

    useEffect(() => {
        startCounter('renewalBusiness', renewalBusiness);
        if ((renewalBusiness || renewalBusiness == 0) && (prevRenewalBusiness || prevRenewalBusiness == 0)) {
            if (renewalBusiness == prevRenewalBusiness) {
                setRenewalBusinessAverage(0);
            }else if(renewalBusiness == 0){
                setRenewalBusinessAverage(-100);
            }else if(prevRenewalBusiness == 0){
                setRenewalBusinessAverage(100);
            }else{
                const cbusinessAverage = ((renewalBusiness / prevRenewalBusiness) * 100) - 100;
                setRenewalBusinessAverage(Math.ceil(cbusinessAverage))
            } 
        }
    }, [renewalBusiness, prevRenewalBusiness])

    useEffect(() => {
        startCounter('newBusiness', newBusiness);
        if ((newBusiness || newBusiness == 0) && (prevNewBusiness || prevNewBusiness == 0)) {
            if (newBusiness == prevNewBusiness) {
                setNewBusinessAverage(0);
            }else if(newBusiness == 0){
                setNewBusinessAverage(-100);
            }else if(prevNewBusiness == 0){
                setNewBusinessAverage(100);
            }else{
                const cbusinessAverage = ((newBusiness / prevNewBusiness) * 100) - 100;
                setNewBusinessAverage(Math.ceil(cbusinessAverage))
            } 
        }
    }, [newBusiness, prevNewBusiness])

    useEffect(() => {
        if ((businessReport || businessReport == 0) && (prevBusinessReport || prevBusinessReport == 0)) {
            startCounter('businessReport', businessReport);
            if (businessReport == prevBusinessReport) {
                setBusinessAverage(0);
            }else if(businessReport == 0){
                setBusinessAverage(-100);
            }else if(prevBusinessReport == 0){
                setBusinessAverage(100);
            }else{
                const cbusinessAverage = ((businessReport / prevBusinessReport) * 100) - 100;
                setBusinessAverage(Math.ceil(cbusinessAverage))
            }          
        }
        if(businessReport) setBusinessReportPremium(businessReport)
    }, [businessReport, prevBusinessReport]);  

    useEffect(() => {
        if (businessReportPremium && policyPremiumData) {
            setPolicyPremiumAverage(
                policyPremiumData.map(p => {
                    return {
                        ...p,
                        PremiumAverage: Math.round((p.TotalPremium / businessReportPremium) * 100)
                    }
                })
            )
        }
        if (businessReportPremium && companyPremiumData) {
            setCompanyPremiumAverage(
                companyPremiumData.map(p => {
                    return {
                        ...p,
                        PremiumAverage: Math.round((p.TotalPremium / businessReportPremium) * 100)
                    }
                })
            )
        }
    }, [businessReportPremium, policyPremiumData, companyPremiumData]);

    useEffect(() => {
        if ((policiesCount || policiesCount == 0) && (prevPoliciesCount || prevPoliciesCount == 0)) {
            startCounter('policiesCount', policiesCount);
            if (policiesCount == prevPoliciesCount) {
                setPolicyCountAverage(0);
            }else if(policiesCount == 0){
                setPolicyCountAverage(-100);
            }else if(prevPoliciesCount == 0){
                setPolicyCountAverage(100);
            }else{
                const policyCountAvg = ((policiesCount / prevPoliciesCount) * 100) - 100;
                setPolicyCountAverage(Math.ceil(policyCountAvg))
            }          
        }
    }, [policiesCount, prevPoliciesCount])

    useEffect(() => {
        if (currentYearBusiness && previousYearBusiness) {
            if (props.currentPeriod.id == 1) {
                    setBusinessReport(currentYearBusiness.map(p => p.Premium).reduce((prev, curr) => prev + curr, 0))
                    setNewBusiness(currentYearBusiness.filter(b => b.BusinessType === 'New').map(p => p.Premium).reduce((prev, curr) => prev + curr, 0))
                    setRenewalBusiness(currentYearBusiness.filter(b => b.BusinessType === 'Renewal').map(p => p.Premium).reduce((prev, curr) => prev + curr, 0))
    
                    setPrevBusinessReport(previousYearBusiness.slice(0, currentYearBusiness.length).map(p => p.Premium).reduce((prev, curr) => prev + curr, 0))
                    setPrevNewBusiness(previousYearBusiness.slice(0, currentYearBusiness.length).filter(b => b.BusinessType === 'New').map(p => p.Premium).reduce((prev, curr) => prev + curr, 0))
                    setPrevRenewalBusiness(previousYearBusiness.slice(0, currentYearBusiness.length).filter(b => b.BusinessType === 'Renewal').map(p => p.Premium).reduce((prev, curr) => prev + curr, 0))
            }else if (props.currentPeriod.id == 7) {
                    setBusinessReport(currentYearBusiness.slice(0, moment().format('DDD')).map(p => p.Premium).reduce((prev, curr) => prev + curr, 0))
                    setNewBusiness(currentYearBusiness.slice(0, moment().format('DDD')).filter(b => b.BusinessType === 'New').map(p => p.Premium).reduce((prev, curr) => prev + curr, 0))
                    setRenewalBusiness(currentYearBusiness.slice(0, moment().format('DDD')).filter(b => b.BusinessType === 'Renewal').map(p => p.Premium).reduce((prev, curr) => prev + curr, 0))
    
                    setPrevBusinessReport(previousYearBusiness.slice(0, moment().format('DDD')).map(p => p.Premium).reduce((prev, curr) => prev + curr, 0))
                    setPrevNewBusiness(previousYearBusiness.slice(0, moment().format('DDD')).filter(b => b.BusinessType === 'New').map(p => p.Premium).reduce((prev, curr) => prev + curr, 0))
                    setPrevRenewalBusiness(previousYearBusiness.slice(0, moment().format('DDD')).filter(b => b.BusinessType === 'Renewal').map(p => p.Premium).reduce((prev, curr) => prev + curr, 0))
            }else {
                setBusinessReport(policies.map(p => p.Premium).reduce((prev, curr) => prev + curr, 0))
                setNewBusiness(policies.filter(b => b.BusinessType === 'New').map(p => p.Premium).reduce((prev, curr) => prev + curr, 0))
                setRenewalBusiness(policies.filter(b => b.BusinessType === 'Renewal').map(p => p.Premium).reduce((prev, curr) => prev + curr, 0))
    
                setPrevBusinessReport(previousPolicies.map(p => p.Premium).reduce((prev, curr) => prev + curr, 0))
                setPrevNewBusiness(previousPolicies.filter(b => b.BusinessType === 'New').map(p => p.Premium).reduce((prev, curr) => prev + curr, 0))
                setPrevRenewalBusiness(previousPolicies.filter(b => b.BusinessType === 'Renewal').map(p => p.Premium).reduce((prev, curr) => prev + curr, 0))
            }
        }
    }, [currentYearBusiness, previousYearBusiness])

    useEffect(() => {
        if (businessChartData && prevBusinessChartData) {
            let avg = []
            for (let i = 0; i < businessChartData.length; i++) {
                if (i == 0) {
                    avg.push(businessChartData[i] - prevBusinessChartData[i])
                } else {
                    const sum1 = businessChartData.slice(0, i + 1).reduce((curr, prev) => curr + prev, 0)
                    const sum2 = prevBusinessChartData.slice(0, i + 1).reduce((curr, prev) => curr + prev, 0)
                    avg.push(sum1 - sum2)
                } 
            }
            const diff = Math.min(...avg);
            const data = diff < 0 ? avg.map(a => a - (diff * 2)) : avg;
            businessChartData.length == 1 ? setBusinessAvg([prevBusinessChartData[0], businessChartData[0]]) : setBusinessAvg(data)
        }

    }, [businessChartData, prevBusinessChartData])

    useEffect(() => {
        if (businessAvg && (businessAverage || businessAverage == 0)) {
            setBusinessReportChart({
                series: [{
                    data: businessAvg,
                }],
                options: {
                    colors: [businessAverage > 0 ? '#00e396' : businessAverage >= -10 ? '#1f89e5' : businessAverage >= -20 ? '#feb019' : '#ff4560' ],
                    tooltip: {
                        enabled: false,
                    },
                    chart: {
                        zoom: {
                            enabled: false,
                        },
                        type: 'area',
                        toolbar: {
                            show: false
                        }
                    },
                    legend: {
                        height:40,
                        width: '100px'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth',
                        width: 0.8
                    },
                    grid: {
                        show: false
                    },
                    yaxis: {
                        show: false
                    },
                    xaxis: {
                        labels: {
                            show: false
                        },
                        axisTicks: {
                            show: false
                        },
                        axisBorder: {
                            show: false,
                        },
                    },
                }
            });
        }
    }, [businessAverage, businessAvg])

    useEffect(() => {
        if (policyCountChartData && prevPolicyCountChartData) {

            let avg = []
            for (let i = 0; i < policyCountChartData.length; i++) {
                if (i == 0) {
                    avg.push(policyCountChartData[i] - prevPolicyCountChartData[i])
                } else {
                    const sum1 = policyCountChartData.slice(0, i + 1).reduce((curr, prev) => curr + prev, 0)
                    const sum2 = prevPolicyCountChartData.slice(0, i + 1).reduce((curr, prev) => curr + prev, 0)
                    avg.push(sum1 - sum2)
                }
            }
            const diff = Math.min(...avg);
            const data = diff < 0 ? avg.map(a => a - (diff * 2)) : avg;
            policyCountChartData.length == 1 ? setCountAvg([prevPolicyCountChartData[0], policyCountChartData[0]]) : setCountAvg(data)
        }

    }, [policyCountChartData, prevPolicyCountChartData])

    useEffect(() => {
        if (countAvg && (policyCountAverage || policyCountAverage == 0)) {
            setPolicyCountChart({
                series: [{
                    data : countAvg,         
                }],
                options: {
                    colors: [policyCountAverage > 0 ? '#00e396' : policyCountAverage >= -10 ? '#1f89e5' : policyCountAverage >= -20 ? '#feb019' : '#ff4560' ],
                    tooltip: {
                        enabled: false,
                    },
                    chart: {
                        zoom: {
                            enabled: false,
                        },
                        type: 'area',
                        toolbar: {
                            show: false
                        }
                    },
                    legend: {
                        height:40,
                        width: '100px'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth',
                        width: 0.8
                    },
                    grid: {
                        show: false
                    },
                    yaxis: {
                        show: false
                    },
                    xaxis: {
                        labels: {
                            show: false
                        },
                        axisTicks: {
                            show: false
                        },
                        axisBorder: {
                            show: false,
                        },
                    },
                }
            })
        }
    }, [policyCountAverage, countAvg])

    useEffect(() => {
        if (newBusinessChartData && prevNewBusinessChartData) {
            let avg = []
            for (let i = 0; i < newBusinessChartData.length; i++) {
                if (i == 0) {
                    avg.push(newBusinessChartData[i] - prevNewBusinessChartData[i])
                } else {
                    const sum1 = newBusinessChartData.slice(0, i + 1).reduce((curr, prev) => curr + prev, 0)
                    const sum2 = prevNewBusinessChartData.slice(0, i + 1).reduce((curr, prev) => curr + prev, 0)
                    avg.push(sum1 - sum2)
                }  
            }
            const diff = Math.min(...avg);
            const data = diff < 0 ? avg.map(a => a - (diff * 2)) : avg;
            newBusinessChartData.length == 1 ? setNewBusinessAvg([prevNewBusinessChartData[0], newBusinessChartData[0]]) : setNewBusinessAvg(data)
        }

    }, [newBusinessChartData, prevNewBusinessChartData])

    useEffect(() => {
        if (newBusinessAvg && (newBusinessAverage || newBusinessAverage == 0)) {
            setNewPolicyBusinessChart({
                series: [{
                    data: newBusinessAvg         
                }],
                options: {
                    colors: [newBusinessAverage > 0 ? '#00e396' : newBusinessAverage >= -10 ? '#1f89e5' : newBusinessAverage >= -20 ? '#feb019' : '#ff4560' ],      
                    tooltip: {
                        enabled: false,
                    },
                    chart: {
                        zoom: {
                            enabled: false,
                        },
                        type: 'area',
                        toolbar: {
                            show: false
                        }
                    },
                    legend: {
                        height:40,
                        width: '100px'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth',
                        width: 0.8
                    },
                    grid: {
                        show: false
                    },
                    yaxis: {
                        show: false
                    },
                    xaxis: {
                        labels: {
                            show: false
                        },
                        axisTicks: {
                            show: false
                        },
                        axisBorder: {
                        show: false,
                        },
                    },
                }
            });
        }
    }, [newBusinessAverage, newBusinessAvg])

    useEffect(() => {
        if (renewalBusinessChartData && prevRenewalBusinessChartData) {
            let avg = []
            for (let i = 0; i < renewalBusinessChartData.length; i++) {
                if (i == 0) {
                    avg.push(renewalBusinessChartData[i] - prevRenewalBusinessChartData[i])
                } else {
                    const sum1 = renewalBusinessChartData.slice(0, i + 1).reduce((curr, prev) => curr + prev, 0)
                    const sum2 = prevRenewalBusinessChartData.slice(0, i + 1).reduce((curr, prev) => curr + prev, 0)
                    avg.push(sum1 - sum2)
                }  
            }
            const diff = Math.min(...avg);
            const data = diff < 0 ? avg.map(a => a - (diff * 2)) : avg;
            renewalBusinessChartData.length == 1 ? setRenewalBusinessAvg([prevRenewalBusinessChartData[0], renewalBusinessChartData[0]]) : setRenewalBusinessAvg(data)
        }
    }, [renewalBusinessChartData, prevRenewalBusinessChartData])

    useEffect(() => {
        if (renewalBusinessAvg && (renewalBusinessAverage || renewalBusinessAverage == 0)) {
            setRenewalPolicyBusinessChart({
                series: [{     
                    data: renewalBusinessAvg   
                }],
                options: {
                    colors: [renewalBusinessAverage > 0 ? '#00e396' : renewalBusinessAverage >= -10 ? '#1f89e5' : renewalBusinessAverage >= -20 ? '#feb019' : '#ff4560' ],
                    tooltip: {
                        enabled: false,
                    },
                    chart: {
                        zoom: {
                            enabled: false,
                        },
                        type: 'area',
                        toolbar: {
                            show: false
                        }
                    },
                    legend: {
                        height:40,
                        width: '100px'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth',
                        width: 0.8
                    },
                    grid: {
                        show: false
                    },
                    yaxis: {
                        show: false
                    },
                    xaxis: {
                        labels: {
                            show: false
                        },
                        axisTicks: {
                            show: false
                        },
                        axisBorder: {
                            show: false,
                        },
                    },
                }
            });
        }
    }, [renewalBusinessAverage, renewalBusinessAvg])

    useEffect(() => {
        const prev = filterDate(props.fromDate, props.toDate, props.currentPeriod.id == 6 ? true : false);
        if ((prevPolicyData && prevPolicyData.length > 0) && (currentPolicyData && currentPolicyData.length > 0)) {
            setPolicyChartData({
                series: [{
                        name: props.currentPeriod.id == 3 ? moment(props.fromDate).format('MMMM') : (props.currentPeriod.id == 5 || props.currentPeriod.id == 6) ? `${moment(props.fromDate).format('DD MMM YYYY')} To ${moment(props.toDate).format('DD MMM YYYY')}` : props.currentPeriod.id == 7 ? 'Current Year' :  props.currentPeriod.id == 9 ? moment(props.fromDate).format('YYYY') : 'Current Month',
                        data:  currentPolicyData.map(i => i.Premium)   
                    },
                    {
                        name: props.currentPeriod.id == 3 ? moment(prev.prevFrom).format('MMMM') : (props.currentPeriod.id == 5 || props.currentPeriod.id == 6) ? `${moment(prev.prevFrom).format('DD MMM YYYY')} To ${moment(prev.prevTo).format('DD MMM YYYY')}` : props.currentPeriod.id == 7 ? 'Previous Year' :  props.currentPeriod.id == 9 ? moment(prev.prevFrom).format('YYYY') : 'Previous Month',  
                        data:  prevPolicyData.map(i => i.Premium)
                    }],
                options: {
                    colors: ['#d3faed','#d2ebfe'],      
                    chart: {
                        zoom: {
                            enabled: false,
                        },
                        type: 'area',
                        toolbar: {
                            show: false
                        }
                    },
                    tooltip: {
                        enabled: true,
                        fillSeriesColor: true,
                        x: {
                            formatter: (value, { seriesIndex, dataPointIndex, w}) => {
                                let date = [];
                                if (seriesIndex == 0) {
                                    if (w.globals.initialSeries[0].data[dataPointIndex] === undefined) {
                                        const previousDate = prevPolicyData.map(i => moment(i.IssueDate).format('DD-MMM'))
                                        date = previousDate[dataPointIndex]
                                    } else {
                                        date = w.globals.categoryLabels[dataPointIndex]
                                    }
                                } else {
                                    const previousDate = (props.currentPeriod.id == 1 || props.currentPeriod.id == 3) ? prevPolicyData.map(i => moment(i.IssueDate).format('DD-MMM')) : props.currentPeriod.id == 6 ?  prevPolicyData.map(i => moment(i.IssueDate).format('DD-MM-YYYY')) : prevPolicyData.map(i => moment(i.IssueDate).format('DD-MMM'))
                                    date = previousDate[dataPointIndex]
                                }
                                return date
                            }
                        },
                        y: {
                            title: {
                                formatter: () => {
                                    return 'Premium:'
                                }
                            }
                        },
                    },
                    legend: {
                        show: true,
                        height:40,
                        markers: {
                            fillColors: ['#00e396', '#066cb9'],
                        },
                    },
                    markers: {
                        colors: ['#00e396', '#066cb9'],
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    stroke: {
                        curve: 'smooth',
                        colors: ['#00e396', '#066cb9'],
                        dashArray: [0, 3],
                        width: 2
                    },
                    xaxis: {
                        type: 'category',
                        labels: {
                            rotate: 0,
                        },
                        crosshairs: {
                            show: true,
                            width: 1,
                            position: 'back',
                            opacity: 0.9, 
                            stroke: {
                                color: '#b6b6b6',
                                width: 1,
                                dashArray: 0,
                            },   
                        },  
                        categories: props.currentPeriod.id == 1 ? currentDate : (props.currentPeriod.id == 3 || props.currentPeriod.id == 5) ? currentPolicyData.map(i => moment(i.IssueDate).format('DD-MMM')) : currentPolicyData.map(i => i.IssueDate),
                        tickAmount: 5,
                        max: (props.currentPeriod.id == 1 || props.currentPeriod.id == 3) ? 31 : props.currentPeriod.id == 5 ? 93 : chartlength > 93 ? 93 : chartlength
                    },       
                },
            })
        }
    }, [prevPolicyData, currentPolicyData]);

    useEffect(() => {
        if (productData && productData.length == 0) {
            setDefaultProductChartData({
                series: [1],
                options: {
                    labels: ['No Products'],
                    colors: ['#7fbdff'],
                    plotOptions: {
                        pie: {
                            expandOnClick: true,
                            donut: {
                                size: '50%',
                            },
                            customScale: 1
                        }
                    },
                    legend: {
                        show: true,
                        position: 'bottom',
                        height: 40,
                        itemMargin: {
                            horizontal: 7,
                            vertical: 0
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontWeight: '100px',
                        }
                    },
                    states: {
                        hover: {
                            filter: {
                                type: 'darken'
                            }
                        }
                    }
                },
            });
        } else if (productData && productData.length > 0) {
            setProductChartData({
                series: productData.map(p => p.Occurrence),
                options: {
                    labels: productData.map(p => p.ProductName),
                    colors: ['#00e396', '#008ffb', '#feb019', '#7fbdff', '#ff4560', '#775dd0'],
                    plotOptions: {
                        pie: {
                            expandOnClick: true,
                            background: 'transparent',
                            donut: {
                                size: '50%',
                            },
                            customScale: 1
                        }
                    },
                    legend: {
                        show: true,
                        position: 'bottom',
                        height: 40,
                        itemMargin: {
                            horizontal: 7,
                            vertical: 0
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontWeight: '100px',
                        }
                    },
                    states: {
                        hover: {
                            filter: {
                                type: 'darken',
                                value: 0.5
                            }
                        }
                    },
                }
            });
        }      
    }, [productData])

    useEffect(()=> {
        if(props.fetchPoliciesInHeader){
            fetchPolicies();
            props.setCustomPolicies(false)
        }
    }, [props.fetchPoliciesInHeader])

    const completeTaskHandler = async (id) => {
        try {
            const  swalResult = await swal.fire({
                title               : 'Are you sure?',
                text                : "You want mark this task as complete!",
                icon                : 'warning',
                showCancelButton    : true,
                buttonsStyling      : false,
                reverseButtons      : true,
                showLoaderOnConfirm : true,
                confirmButtonClass  : 'btn btn-brand',
                cancelButtonClass   : 'btn btn-secondary',
                confirmButtonText   : 'Complete',
                preConfirm          : async () => {
                    try {
                        await completeTask(id);
                        return;
                    } catch (err) {
                        swal.fire({
                            icon                : 'error',
                            titleText           : 'Error!',
                            text                : err.message,
                            buttonsStyling      : false,
                            confirmButtonClass  : 'btn btn-brand',
                        });
                        return;
                    }
                },
            });
            if (!swalResult.value) return;
            setRefresh(true);
            swal.fire({
                icon              : 'success',
                titleText         : 'Task Complete Successfully',
                showConfirmButton : false,
                timer             : 1500
            });
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });   
        }
    }

    const fetchPolicies = async () => {
       try{
            setLoading(true);

            if (!props.fromDate || !props.toDate) {
                swal.fire({
                    icon                  : 'error',
                    titleText             : 'Error!',
                    text                  : "Invalid Date",
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',   
                });
                setLoading(false);
                return;
            }

            const response = await getBusinessReports(props.fromDate, props.toDate)
            setPolicies(response);
            const prev = filterDate(props.fromDate, props.toDate, props.currentPeriod.id == 6 ? true : false);
            const prevPolicies = await getBusinessReports(prev.prevFrom, prev.prevTo)
            setPreviousPolicies(prevPolicies);
            setLoading(false);
        }catch(err){
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        }
    };

    const startCounter = (id, value) => {
        $(`#${id}`).prop('Counter', 0).animate({Counter: value.toString()}, {
            duration: 1500,
            easing: 'swing',
            step: function (now) {
                $(this).text(Math.ceil(now));
            }
        });
    }

    const fetchTask = async () => {

        try {
            setTaskLoading(true);
            const response = await getTask();
            const task = response.filter(r => r.Status != 'Completed').slice(0, 5);
            setTasks(task);
            setRefresh(false)
            setTaskLoading(false);
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        }

        
    }

    const filterDate = (fromDate, toDate, isCustom) => {
        if (isCustom) {
            const diff      =  moment(toDate).diff(fromDate, 'days') + 1;
            const prevFrom  =  moment(fromDate).subtract(diff, 'days').format('YYYY-MM-DD')
            const prevTo    =  moment(toDate).subtract(diff, 'days').format('YYYY-MM-DD')
            return { prevFrom, prevTo }
        } else {
            const diff      =  moment(toDate).diff(fromDate, 'months') + 1;
            const prevFrom  =  moment(fromDate).subtract(diff, 'months').startOf('month').format('YYYY-MM-DD')
            const prevTo    =  moment(toDate).subtract(diff, 'months').endOf('month').format('YYYY-MM-DD')
            return { prevFrom, prevTo }
        }
    }
    
    return (
        <React.Fragment>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row margin-t20">
                    <div className="col-md-3">
                        <div className="kt-portlet" style={{ height: '150px' }}>
                            <div className="kt-portlet__head justify-content-center dashboard-header-title">
                                <div className="kt-portlet__head-label">
                                    <h6 className="kt-portlet__head-title padding-t75 dashboard-header-content">BUSINESS REPORT</h6>
                                </div>
                            </div>
                            <div className="kt-portlet__head justify-content-center dashboard-header-title">
                                <div className="kt-portlet__head-label">
                                    <span className="kt-portlet__head-title padding-t150" style={{ fontFamily: 'arial,sans-serif', fontSize: '2.5rem' }}>₹ </span><h6 id='businessReport' className="kt-portlet__head-title padding-t150 counter" style={{ fontSize: '2.5rem' }}>{businessReport}</h6>
                                </div>
                            </div>
                            <div className="kt-portlet__head justify-content-center dashboard-header-title">
                                <div className="kt-portlet__head-label">
                                    <h6 className="kt-portlet__head-title padding-t225" style={{ fontSize: '0.9rem' }}><i className={`${businessReport > prevBusinessReport ? 'fa fa-caret-up margin-r5' : 'fa fa-caret-down margin-r5' }`} style={{ color: businessReport > prevBusinessReport ? 'green' : 'red' }}></i>{businessAverage}%</h6>
                                </div>
                            </div>
                            {businessReportChart && (
                                <ReactApexChart 
                                    options={businessReportChart.options}
                                    series={businessReportChart.series}
                                    type="area"
                                    height='153px'

                                />
                            )}
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="kt-portlet" style={{ height: '150px' }}>
                            <div className="kt-portlet__head justify-content-center dashboard-header-title">
                                <div className="kt-portlet__head-label">
                                    <h6 className="kt-portlet__head-title padding-t75 dashboard-header-content">NEW BUSINESS</h6>
                                </div>
                            </div>
                            <div className="kt-portlet__head justify-content-center dashboard-header-title">
                                <div className="kt-portlet__head-label">
                                    <span className="kt-portlet__head-title padding-t150" style={{ fontFamily: 'arial,sans-serif', fontSize: '2.5rem' }}>₹ </span><h6 id='newBusiness' className="kt-portlet__head-title padding-t150 counter" style={{ fontSize: '2.5rem' }}>{newBusiness}</h6>
                                </div>
                            </div>
                            <div className="kt-portlet__head justify-content-center dashboard-header-title">
                                <div className="kt-portlet__head-label">
                                    <h6 className="kt-portlet__head-title padding-t225" style={{ fontSize: '0.9rem' }}><i className={`${newBusiness > prevNewBusiness ? 'fa fa-caret-up margin-r5' : 'fa fa-caret-down margin-r5' }`} style={{ color: newBusiness > prevNewBusiness ? 'green' : 'red' }}></i>{newBusinessAverage}%</h6>
                                </div>
                            </div>
                            {newPolicyBusinessChart && (
                                <ReactApexChart 
                                    options={newPolicyBusinessChart.options}
                                    series={newPolicyBusinessChart.series}
                                    type="area"
                                    height='153px'
                                />
                            )}
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="kt-portlet" style={{ height: '150px' }}>
                            <div className="kt-portlet__head justify-content-center dashboard-header-title">
                                <div className="kt-portlet__head-label">
                                    <h6 className="kt-portlet__head-title padding-t75 dashboard-header-content">RENEWAL BUSINESS</h6>
                                </div>
                            </div>
                            <div className="kt-portlet__head justify-content-center dashboard-header-title">
                                <div className="kt-portlet__head-label">
                                    <span className="kt-portlet__head-title padding-t150" style={{ fontFamily: 'arial,sans-serif', fontSize: '2.5rem' }}>₹ </span><h6 id='renewalBusiness' className="kt-portlet__head-title padding-t150 counter" style={{ fontSize: '2.5rem' }}>{renewalBusiness}</h6>
                                </div>
                            </div>
                            <div className="kt-portlet__head justify-content-center dashboard-header-title">
                                <div className="kt-portlet__head-label">
                                    <h6 className="kt-portlet__head-title padding-t225" style={{ fontSize: '0.9rem' }}><i className={`${renewalBusiness > prevRenewalBusiness ? 'fa fa-caret-up margin-r5' : 'fa fa-caret-down margin-r5' }`} style={{ color: renewalBusiness > prevRenewalBusiness ? 'green' : 'red' }}></i>{renewalBusinessAverage}%</h6>
                                </div>
                            </div>
                            {renewalPolicyBusinessChart && (
                                <ReactApexChart 
                                    options={renewalPolicyBusinessChart.options}
                                    series={renewalPolicyBusinessChart.series}
                                    type="area"
                                    height='153px'
                                />
                            )}
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="kt-portlet" style={{ height: '150px' }}>
                            <div className="kt-portlet__head justify-content-center dashboard-header-title">
                                <div className="kt-portlet__head-label">
                                    <h6 className="kt-portlet__head-title padding-t75 dashboard-header-content">POLICY COUNT</h6>
                                </div>
                            </div>
                            <div className="kt-portlet__head justify-content-center dashboard-header-title">
                                <div className="kt-portlet__head-label">
                                    <h6 id='policiesCount' className="kt-portlet__head-title padding-t150 counter" style={{ fontSize: '2.5rem' }}>{policiesCount}</h6>
                                </div>
                            </div>
                            <div className="kt-portlet__head justify-content-center dashboard-header-title">
                                <div className="kt-portlet__head-label">
                                    <h6 className="kt-portlet__head-title padding-t225" style={{ fontSize: '0.9rem' }}><i className={`${policiesCount > prevPoliciesCount ? 'fa fa-caret-up margin-r5' : 'fa fa-caret-down margin-r5' }`} style={{ color: policiesCount > prevPoliciesCount ? 'green' : 'red' }}></i>{policyCountAverage}%</h6>
                                </div>
                            </div>
                            {policyCountChart && (
                                <ReactApexChart 
                                    options={policyCountChart.options}
                                    series={policyCountChart.series}
                                    type="area"
                                    height='153px'
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8">
                        <div className="kt-portlet" style={{ height: '400px' }}>
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">Business Stats</h3>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                {loading ? <Loading/> : (
                                    <div className="mixed-chart">
                                        {defaultPolicyChartData && (
                                            <ReactApexChart 
                                                options={policyChartData ? policyChartData.options : defaultPolicyChartData.options}
                                                series={policyChartData ? policyChartData.series : defaultPolicyChartData.series}
                                                type="area"
                                                height='320px'
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="kt-portlet" style={{ height: '400px' }}>
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">Product Stats</h3>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                {loading ? <Loading /> : (
                                    <div className="mixed-chart">
                                        {(defaultProductChartData && productData.length == 0) && (
                                            <ReactApexChart 
                                                options={defaultProductChartData.options}
                                                series={defaultProductChartData.series}
                                                type="donut"
                                                height={340}
                                            />
                                        )}
                                        {(productChartData && productData.length > 0) && (
                                            <ReactApexChart 
                                                options={productChartData.options}
                                                series={productChartData.series}
                                                type="donut"
                                                height={340}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8">
                        <div className="kt-portlet kt-portlet--tabs kt-portlet--height-fluid" style={{ height: '425px' }}>
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">Tasks</h3>
                                </div>
                                <div className="kt-portlet__head-toolbar">
                                    {/*<nav className="file-nav navbar navbar-top" >
                                        <NavLink activeClassName="top-nav-active" to="#">All</NavLink>
                                        <NavLink activeClassName="top-nav-active" to="#">High</NavLink>
                                        <NavLink activeClassName="top-nav-active" to="#" >Medium</NavLink>
                                        <NavLink activeClassName="top-nav-active" to="#" >Low</NavLink>
                                    </nav>*/}
                                    {/*<ul className="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-brand" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-toggle="tab" href="#kt_widget2_tab1_content" role="tab">
                                                All
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#kt_widget2_tab1_content" role="tab">
                                                High
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#kt_widget2_tab1_content" role="tab">
                                                Medium
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#kt_widget2_tab1_content" role="tab">
                                                Low
                                            </a>
                                        </li>
                                    </ul>*/}
                                </div>
                            </div>
                            {taskLoading ? <Loading/> : (
                                tasks.length > 0 ? (
                                    <div 
                                        className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded">
                                        <table className="kt-datatable__table table-striped">
                                            <tbody
                                                className="kt-datatable__body"
                                                style={{ display: 'block' }}>
                                                {tasks.map((t) => 
                                                    <tr key={t.ID} className="kt-datatable__row block-row" onClick={t.onRowClick}>
                                                        <td width="65%" title={t.Title} className="kt-datatable__cell text-clip padding-l0 padding-15">
                                                            <div className="kt-widget2">
                                                                <div className={`kt-widget2__item kt-widget2__item--${t.Priority === 'Low' ? 'primary' : (t.Priority === 'Medium' ? 'warning' : 'danger')}`}>
                                                                    <div className="kt-widget2__info padding-l30 text-clip">
                                                                        <span className="kt-widget2__title text-clip">{t.Title}</span>
                                                                        <span className="kt-widget2__username">By {t.CreatedByName}</span>
                                                                    </div> 
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td width="15%" className="kt-datatable__cell text-clip" title={moment(t.DueDate).format('DD-MMM')}>
                                                            <div className="kt-user-card-v2">
                                                                <div className="kt-user-card-v2__details">
                                                                    <span className="kt-user-card-v2__desc">Due Date:</span>
                                                                    <span className="kt-user-card-v2__name">{moment(t.DueDate).format('DD-MMM')}</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="kt-datatable__cell text-right" width="20%">
                                                            {t.Details !== '' && <button
                                                                type="button"
                                                                className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                                                                data-toggle="kt-popover"
                                                                data-trigger="hover click"
                                                                data-placement="left"
                                                                data-original-title="Details"
                                                                data-content={t.Details}>                           
                                                                <i className="fa fa-info"></i>
                                                            </button>}
                                                            <button 
                                                                type="button"
                                                                className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"             
                                                                onClick={() => completeTaskHandler(t.TaskID)}
                                                                data-toggle="kt-popover"
                                                                data-trigger="hover click"
                                                                data-placement="left"
                                                                data-content="Mark As Completed">
                                                                <i className="fa fa-check"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <NoData
                                        src={TaskImg}
                                        alt="Tasks"
                                        message="No Tasks Found"
                                        from="dashboard"
                                    />
                                )
                            )}
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="kt-portlet" style={{ height: '425px' }}>
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">Business</h3>
                                </div>
                                <div className="kt-portlet__head-toolbar">
                                    <div className="kt-form__control">
                                        <select
                                            className="form-control kt-selectpicker"
                                            id="ddlStatus"
                                            value={filterBusiness}
                                            onChange={(e) => setFilterBusiness(e.target.value)}
                                        >    
                                        <option value='product'>Products</option>
                                        <option value='insuranceCompany'>Insurance Company</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {(!policyPremiumAverage || !companyPremiumAverage) ? <Loading /> : (
                                (filterBusiness == 'product' ? policyPremiumAverage.length > 0 : companyPremiumAverage.length > 0) ? (
                                    <div 
                                    className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded">
                                    <table className="kt-datatable__table table-striped">
                                        <tbody
                                            className="kt-datatable__body"
                                            style={{ display: 'block' }}>
                                            {filterBusiness == 'product' ? (
                                                policyPremiumAverage.map((p, i) => 
                                                <tr key={i} className="kt-datatable__row block-row">
                                                    <td width="70%" className="kt-datatable__cell text-clip">
                                                        <div className="kt-user-card-v2">
                                                            <div className="kt-user-card-v2__pic">
                                                                <img src={p.ProductIcon} alt="product"/>
                                                            </div>
                                                            <div className="kt-user-card-v2__details text-clip">
                                                                <span className="kt-user-card-v2__name text-clip" title={p.ProductName}>
                                                                    {p.ProductName}                  
                                                                </span>                                                               
                                                                <span className="kt-user-card-v2__desc text-clip" title={p.TotalPremium}>Premium: <span style={{ fontFamily: 'arial,sans-serif'}}>₹ </span>{p.TotalPremium}</span>
                                                            </div>         
                                                        </div>                                                       
                                                    </td>
                                                    <td width="30%" className="kt-datatable__cell text-clip text-right">
                                                        <span className="kt-font-bold">{p.PremiumAverage}%</span>
                                                    </td>
                                                </tr>
                                            )) : (
                                                companyPremiumAverage.map((p, i) => 
                                                <tr key={i} className="kt-datatable__row block-row">
                                                    <td width="70%" className="kt-datatable__cell text-clip">
                                                        <div className="kt-user-card-v2">
                                                            <div className="kt-user-card-v2__pic">
                                                                <div
                                                                    className='img-small-profile kt-badge kt-badge--xl kt-badge--info'>
                                                                    {p.CompanyFirstLetter}
                                                                </div>
                                                            </div>
                                                            <div className="kt-user-card-v2__details text-clip">
                                                                <span className="kt-user-card-v2__name text-clip" title={p.InsuranceCompanyName}>
                                                                    {p.InsuranceCompanyName}                  
                                                                </span>                                                               
                                                                <span className="kt-user-card-v2__desc text-clip" title={p.TotalPremium}>Premium: <span style={{ fontFamily: 'arial,sans-serif'}}>₹ </span>{p.TotalPremium}</span>
                                                            </div>         
                                                        </div>                                                       
                                                    </td>
                                                    <td width="30%" className="kt-datatable__cell text-clip text-right">
                                                        <span className="kt-font-bold">{p.PremiumAverage}%</span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                ) : (
                                    <NoData
                                        src={PolicyImg}
                                        alt="Policy"
                                        message="No Policies Found"
                                    />
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

}

export default Dashboard;
