import { axiosPost, axiosGet, axiosPatch } from './axiosFunctions';
import { getTokenOrLogout } from './common';

const getFileCategoryUrl     = '/api/fileManagement/getFileCategory';
const addFileCategoryUrl     = '/api/fileManagement/addFileCategory';
const addAgentFileUrl        = '/api/fileManagement/addAgentFile';
const getAgentFileUrl        = '/api/fileManagement/getAgentFile';
const deactivateAgentFileUrl = (agentFileId) => `/api/fileManagement/deactivateAgentFile/${agentFileId}`;
const sendFileEmailUrl       = '/api/fileManagement/sendFileEmail';
const updateAgentFileUrl     = '/api/fileManagement/updateAgentFile';

export const getFileCategory = async () => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  
    
    const response = await axiosGet(getFileCategoryUrl, {
        headers: { auth_token: `bearer ${token}` },
    });
    return response;
};

export const getAgentFile = async () => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  

    const response = await axiosGet(getAgentFileUrl, {
        headers: { auth_token: `bearer ${token}` },
    });
    return response;
};

export const addFileCategory = async (Name) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  
    
    const response = await axiosPost(
        addFileCategoryUrl,
        { Name },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const addAgentFile = async (file) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  

    const response = await axiosPost(
        addAgentFileUrl,
        { file },
        {headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const updateAgentFile = async (file) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  

    const response = await axiosPost(
        updateAgentFileUrl,
        { file },
        {headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const deactivateAgentFile = async (agentFileId) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  

    const response = await axiosPatch(
        deactivateAgentFileUrl(agentFileId),
        null,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
}

export const sendFileEmail = async (users, fileIds, cc, bcc, comments) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  
    
    const response = await axiosPost(
        sendFileEmailUrl,
        { users, fileIds, cc, bcc, comments },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};