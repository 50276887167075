import React from 'react';

import './rightSideDetails.css';

const { $ } = window;

const RightSideDetails = (props) => {
  const closeDrawer = () => {
    $('#panel-right').addClass('closing');   
    $('#right-panel-backdrop').removeClass('show');  
    setTimeout(() => {       
      $('#panel-right').removeClass('show');   
      $('#panel-right').removeClass('closing');
    }, 200);
    if (props.onCloseDrawer) props.onCloseDrawer();
  };

  return (
    <div>
      <div
        id="right-panel-backdrop"
        className={`backdrop ${props.show ? 'show' : ''}`}
        onClick={closeDrawer}
      />
      <div
        id="panel-right"
        className={`panel-right ${props.show ? 'show' : ''}`}
        style={props.style}>
      
        <div className="kt-header" style={{left: '0px'}}>
          <div className="kt-header-menu-wrapper">
            <div className="kt-header-menu kt-header-menu-mobile  kt-header-menu--layout-default ">
              <h3 className="header-title">{props.title}</h3>
            </div>
          </div>

          <div className="kt-header__topbar">
            <button onClick={closeDrawer} type="button" className="btn btn-close"> <i className="flaticon2-delete kt-icon-sm" data-dismiss="alert"></i></button>
          </div>

        </div>
        <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-b0">
          <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightSideDetails;
