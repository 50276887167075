import React, { useState, useEffect} from 'react';

import NoData from './noData';
import FileImg from '../../assets/images/no-task.svg'
import './rightSideDetails.css';
import { getNotificationIcon } from './helper';
import moment from 'moment';
import swal from 'sweetalert2';
import { getAuthState } from "../../assets/scripts/login-util";
import { deleteAllNotificationLogs, updateNotificationLogs, deleteNotificationLog } from "../api/notificationAPI";
import { useHistory } from 'react-router-dom';

const { $ } = window;

const NotificationPanel = (props) => {
	const { NotificationList  } = props;
	
	const history 							  = useHistory();
	const [Notifications,	setNotifications] = useState([]);

	useEffect(() =>{
		let ignore = false;
		const markAllNotifications = async() => {
			try {
				const user = getAuthState('user');
				if(user) await updateNotificationLogs(user.id);
			} catch (err) {
				swal.fire({
					icon                : 'error',
					titleText           : 'Error!',
					text                : err.message,
					buttonsStyling      : false,
					confirmButtonClass  : 'btn btn-brand',
				});   	
			}
		};
		if(!ignore) {
			$('#root').addClass('no-scroll');
			markAllNotifications();
		}
		return () => {
			ignore = true;
		}
	},[])	

	useEffect(() => {
		setNotifications(NotificationList)
	},[NotificationList])

	const onDismissAllNotification = async() => {
		try {
			await deleteAllNotificationLogs();
			closeDrawer();
			swal.fire({
				toast               : true,
				icon                : 'success',
				titleText           : `Notifications Cleared`,
				position            : 'bottom-end',
				showConfirmButton   : false,
				timer               : 1500,
				animation           : true,
				customClass         : { popup: 'margin-20', },
			});
			
		} catch (err) {
			swal.fire({
				icon              : "error",
				titleText         : "Error!",
				text              :  err.message,
				buttonsStyling    : false,
				confirmButtonClass: "btn btn-brand",
			});
		}
	}

	const onDismissNotification = async(alertId) => {
		try {
			await deleteNotificationLog(alertId);
			const filteredNotification = Notifications.filter( n => n.ID !==  alertId);
			setNotifications(filteredNotification);
			swal.fire({
				toast               : true,
				icon                : 'success',
				titleText           : `Notification Cleared`,
				position            : 'bottom-end',
				showConfirmButton   : false,
				timer               : 1500,
				animation           : true,
				customClass         : { popup: 'margin-20', },
			});
		} catch (err) {
			swal.fire({
				icon              : "error",
				titleText         : "Error!",
				text              :  err.message,
				buttonsStyling    : false,
				confirmButtonClass: "btn btn-brand",
			});
		}
	}

	const onNotificationRedirect = (url) => {
		closeDrawer();
		history.push(url);
	}

	const closeDrawer = () => {
		$('#panel-right').addClass('closing');   
		$('#right-panel-backdrop').removeClass('show');  
		$('#root').removeClass('no-scroll');
		setTimeout(() => {       
		$('#panel-right').removeClass('show');   
		$('#panel-right').removeClass('closing');
		}, 200);
		if (props.onCloseDrawer) props.onCloseDrawer();
	};

    return (
        <div>
			<div id="right-panel-backdrop" className={`backdrop ${props.show ? 'show' : ''}`}
				onClick={closeDrawer}
			/>
			<div id="panel-right" className={`panel-right ${props.show ? 'show' : ''}`} style={{width: '450px'}}>
				<div className="kt-header" style={{left: '0px'}}>
					<div className="kt-header-menu-wrapper">
						<div className="kt-header-menu kt-header-menu-mobile  kt-header-menu--layout-default ">
							<h3 className="header-title mr-3">Notifications</h3>    
						</div>
					</div>
				
					<div className="kt-header__topbar">
						<button onClick={closeDrawer} type="button" className="btn btn-close"> <i className="flaticon2-delete kt-icon-sm" data-dismiss="alert"></i></button>
					</div>
				</div>
				<div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-b0 pt-0 ">
					<div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid bg-white">

						<div className="tab-content">
							<div className="tab-pane active" id="kt_widget6_tab1_content" aria-expanded="true">
								<div className="kt-notification py-2">

									{Notifications && Notifications.length > 0 ? (
										Notifications.map((n, i) => 
										<span key={i} className="kt-notification__item custom-item clickable">
											<div className="kt-notification__item-icon">
												{ getNotificationIcon(n.Group) }
											</div>
											<div className="kt-notification__item-details" onClick={() => onNotificationRedirect(n.Url)}>
												<div className="kt-notification__item-title">
												{ n.Message }
												</div>
												<div className="kt-notification__item-time">
												{ moment( n.Timestamp ).fromNow() }
												</div>
											</div>
											<div className="kt-notification__item-icon notification-action ">
												<i className="la la-times" onClick={()=> onDismissNotification(n.ID)}></i>
											</div>
										</span>
									)) : (
										<NoData
											src={FileImg}
											from="Notifications"
											alt="Notifications"
											message="No notifications"
											show="N"
										/>
									)}   
								</div>
							</div>
						</div>
					</div>
				</div>
				{Notifications.length > 0 && (
					<div className="kt-footer kt-notification-panel-footer" >
						<div className="row">
							<div className="col-12 text-right">
								<button type="button" className="btn btn-sm btn-label-brand mr-2" onClick={onDismissAllNotification}> 
									<i className="fa fa-trash"></i> Dismiss all
								</button>
							</div>
						</div>
					</div>
				)}
			</div>
        </div>
    );

};

export default NotificationPanel;