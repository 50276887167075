import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import swal from "sweetalert2";
import asyncComponent from '../hoc/asyncComponent';
import SideMenu from '../components/global/sideMenu';
import Dashboard from '../components/dashboard/dashboard';
import Policy from '../components/policy/policy';
import Header from '../components/global/header';
import AddClient from '../components/clients/addClient';
import AddPolicy from '../components/policy/addPolicy';
import AddQuotation from '../components/policy/addQuotation';
import AddTask from '../components/tasks/addTask';
import AddFile from '../components/fileManagement/addFile';
import { dashboardPeriods } from '../../src/assets/scripts/periodSelection';
import { deviceDetect } from 'react-device-detect';
import { io } from 'socket.io-client';
import { getAuthState } from '../assets/scripts/login-util';
import { getNotificationLogs } from '../components/api/notificationAPI';

import EmailVerificationModal from '../components/login/emailVerificationModal';
import SubscriptionModal from '../components/global/subscriptionModal';
import SearchPolicy from '../components/policy/searchPolicy';
import blockMobile from "../assets/images/blockMobile.png"
import RevenueUplaod from '../components/reports/revenueUpload';
import Tools from '../components/tools/tools';
import LicenseChangeReminder from '../components/global/LicenseChangeReminder';
//import Footer from '../components/global/footer';

const Clients         = asyncComponent(() => import('../components/clients/clients'));
const Tasks           = asyncComponent(() => import('../components/tasks/tasks'));
const FileManagement  = asyncComponent(() => import('../components/fileManagement/fileManagement'));
const Report          = asyncComponent(() => import('../components/reports/report'));
const SubAgency       = asyncComponent(() => import('../components/subAgency/subAgency'));
const BatchUpload     = asyncComponent(() => import('../components/batchUpload/batchUpload'));
const Support         = asyncComponent(() => import('../components/settings/support'));
// const Team            = asyncComponent(() => import('../components/settings/team'));
const Team            = asyncComponent(() => import('../components/team/teams'));
const Profile         = asyncComponent(() => import('../components/settings/profile'));

const Admin = (props) => {
	const [headerTitle,                   setHeaderTitle]                      = useState('Dashboard');
	const [menuID,                        setMenuID]                           = useState('menuDashboard');
	const [showAddPolicy,                 setShowAddPolicy]                    = useState(false);
	const [showAddClient,                 setShowAddClient]                    = useState(false);
	const [clientFrom,                    setClientFrom]                       = useState('client');
	const [quotationFrom,                 setQuotationFrom]                    = useState('quotation');
	const [policyFrom,                    setPolicyFrom]                       = useState('policy');
	const [showAddQuotation,              setShowAddQuotation]                 = useState(false);
	const [showAddFile,                   setShowAddFile]                      = useState(false);
	const [showAddTask,                   setShowAddTask]                      = useState(false);
	const [showSearchPolicy,              setShowSearchPolicy]                 = useState(false);
	const [updatePolicyTable,             setUpdatePolicyTable]                = useState(false); // just change the boolean value to update.
	const [updatePolicyQuotation,         setUpdatePolicyQuotation]            = useState(false); 
	const [updateQuotationTable,          setUpdateQuotationTable]             = useState(false); // just change the boolean value to update.
	const [updateClientsTable,            setUpdateClientsTable]               = useState(false); // just change the boolean value to update.
	const [updateFilesTable,              setUpdateFilesTable]                 = useState(false); // just change the boolean value to update.
	const [updateTaskTable,               setUpdateTaskTable]                  = useState(false); // just change the boolean value to update.
	const [policyModalDetails,            setPolicyModalDetails]               = useState(null);
	const [quotationModalDetails,         setQuotationModalDetails]            = useState(null);
	const [clientModalDetails,            setClientModalDetails]               = useState(null);
	const [taskModalDetails,              setTaskModalDetails]                 = useState(null);
	const [searchPolicyModalDetails,      setSearchPolicyModalDetails]         = useState(null);
	const [showEmailVerificationModal,    setEmailVerificationModal]           = useState(false); 
	const [emailVerificationModalDetails, setEmailVerificationModalDetails]    = useState(null);
	const [currentPeriod,                 setCurrentPeriod]                    = useState(dashboardPeriods[0]);
	const [fromDate,                      setFromDate]                         = useState(dashboardPeriods[0].from);
	const [toDate,                        setToDate]                           = useState(dashboardPeriods[0].to);
	const [fetchPolicies,                 setFetchPolicies]                    = useState(false)
	const [notificationList,  			  setNotificationList] 				   = useState([]);
	const [refetchNotifications,  		  setrefetchNotifications] 		 	   = useState(false);
	const [showSubscriptionModal,  		  setShowSubscriptionModal] 		   = useState(false);
	const [showLicenseChangeReminder,  	  setShowLicenseChangeReminder]        = useState(false);
	const [clientType,				  	  setClientType]					   = useState({});
	const [updateClientQuotationTable,	  setUpdateClientQuotationTable]	   = useState(false);
	const [extraInfo, 					  setExtraInfo]						   = useState(null);
	//Redirect mobile & tablet devices 
	useEffect(() => {
		const resizeWindow =() => {
			const isSwal = swal.isVisible();	
			if( window.innerWidth <= 1024){ 
				if(!isSwal){
				const deviceData = deviceDetect();
				if(deviceData.isMobile && deviceData.os === 'Android'){
					return swal.fire({
							imageUrl    : blockMobile,
							imageWidth  : 400,
							imageHeight : 200,
							imageAlt    : 'Custom image',
							allowOutsideClick: false,
							allowEscapeKey:false,
							confirmButtonText: 'Switch To Mobile App',
							customClass :{
								content: 'mt-1 mb-2',
								image:'mb-1'
							}
					}).then(result => {
						if(result.value || result.isConfirmed){ 
							window.location.replace('ibbusiness://') ;
							setTimeout(()=> window.location.replace('market://details?id=com.insurebox.ibbusiness'), 250)
						}
					})
				}
				//for desktop & ios
				swal.fire({
					title       : 'Mobile App Launching Soon ..!',
					text        : 'Kindly Login From Desktop Device For Better Experience',
					imageUrl    : blockMobile,
					imageWidth  : 400,
					imageHeight : 200,
					imageAlt    : 'Custom image',
					allowOutsideClick: false,
					allowEscapeKey:false,
					showConfirmButton:false,
					customClass :{
					content: 'mt-1 mb-2',
					title  :'mt-1',
					image:'mb-1'
					}
				})
				}	
			}else{
				swal.close();
			}
		}
		window.addEventListener('resize', resizeWindow);
		resizeWindow();
	},[]);

	useEffect(() => {
		if (currentPeriod.id != 6) {
			setFromDate(currentPeriod.from);
			setToDate(currentPeriod.to);                        
		}
	}, [currentPeriod]);

	//Check for Cookie to Setup SocketIO Connection
	useEffect(() => {
		let ignore = false;
		const  createConection = async() => {
			try {
				const path = window.location.hostname === 'localhost' ? 'http://localhost:8080' : `${window.location.protocol}//${window.location.hostname}`;
        		const {token, user} = getAuthState();

				if(user){
					await fetchNotifications(user.id);
					// Connect to Socket io Server on node backend 
					const socket = io( path,  );
				
					socket.on('connect', () => {
            			//Emit user Connected Event to server
						socket.emit('user-connected', { socketID: socket.id, token: token })
					});
					
					//Listen to events from server
					socket.on('OnNotify', (data) => {
						if(data){
							setNotificationList(d => [...d, {...data} ] )
							swal.fire({
								toast               : true,
								icon                : 'success',
								titleText           : data.Message,
								position            : 'top-end',
								showConfirmButton   : false,
								timer               : 3000,
								animation           : true,
								customClass         : { popup: 'margin-20', },
							});
						}
					})
				}
			} catch (err) {
				swal.fire({
					icon                : 'error',
					title             	: "Something went wrong..!",
					text              	: 'Please Reload...',
					buttonsStyling      : false,
					confirmButtonClass  : 'btn btn-brand',
				});  
			}
		}
		if(!ignore) createConection();
		return () => {
			ignore = true;
		}
	},[])
	
	useEffect(() => {
        if(refetchNotifications){
			const user = getAuthState('user');
			if(user) fetchNotifications(user.id)
			setrefetchNotifications(false)
        }
    },[refetchNotifications])


	const fetchNotifications = async(userId) =>{
		try {
			const response = await getNotificationLogs(userId);
			setNotificationList(response);
		} catch (err) {
			swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });    
		}
	}

	const toggleState = (title, menuId) => {
		setHeaderTitle(title);
		setMenuID(menuId);
	};

	const showModalHandler = (
		modalName,
		modalStage,
		from = '',
		updateDone = false,
		meta = null,
		clientType,
		extraInfo,
	) => {
		switch (modalName) {
		case 'policy':
			setShowAddPolicy(modalStage);
			setPolicyFrom(from);
			if (!modalStage) setPolicyModalDetails(null);
			if (!modalStage && updateDone) setUpdatePolicyTable((t) => !t);
			if (modalStage && !!meta) setPolicyModalDetails(meta);
			break;
		case 'client':
			setShowAddClient(modalStage);
			setClientType(clientType)
			setClientFrom(from);
			if (!modalStage && updateDone) {
			setUpdateClientsTable((t) => !t);
			if (from === "policy") {
				setPolicyModalDetails((d) => ({
				...d,
				addedClientId: meta.addedClientId,
				}));
			}else if (from === "quotation"){
				setQuotationModalDetails((d) => ({
				...d,
				addedClientId: meta.addedClientId,
				}));
			}else if (from === "task") {
				setTaskModalDetails((d) => ({
				...d,
				addedClientId: meta.addedClientId,
				}));
			}             
			}
			break;
		case 'quotation':
			setShowAddQuotation(modalStage);
			setQuotationFrom(from);
			if (from == 'client') {
				setExtraInfo(extraInfo)
			}
			if (!modalStage) setQuotationModalDetails(null);
			if (!modalStage && updateDone){
			if (from === "policy" || from === "searchPolicy") {
				setUpdatePolicyTable((t) => !t);
			}
			if (from === "client") {
				setUpdateClientQuotationTable(t => !t)
			}
			if (from === "policyQuotation") {
				setUpdatePolicyQuotation((t) => !t);
			}
			setUpdateQuotationTable((t) => !t);
			} 
			if (modalStage && !!meta) setQuotationModalDetails(meta);
			break;
		case 'file':
			setShowAddFile(modalStage);
			if (!modalStage && updateDone) setUpdateFilesTable((t) => !t);
			break;
		case 'task':
			setShowAddTask(modalStage);
			if (!modalStage) setTaskModalDetails(null);
			if (!modalStage && updateDone) setUpdateTaskTable((t) => !t)
			if (modalStage && !!meta) setTaskModalDetails(meta);
			break;
		case 'searchPolicy':
			setShowSearchPolicy(modalStage);
			if(!modalStage) setSearchPolicyModalDetails(null);
			if (modalStage && !!meta) setSearchPolicyModalDetails(meta);
			break;
		case 'verifyEmail':
			setEmailVerificationModal(modalStage);
			if(!modalStage) setEmailVerificationModalDetails(null);
			if(modalStage && !!meta) setEmailVerificationModalDetails(meta);
		break;
		case 'subscription':
			setShowSubscriptionModal(modalStage);
		break;
		case 'LicenseChangeReminder':
			setShowLicenseChangeReminder(modalStage);
		break;
		default:
			break;
		}
	};

	return (  
		<div className="kt-grid kt-grid--hor kt-grid--root">
			
			{showSearchPolicy && (
				<SearchPolicy
				show={showSearchPolicy}
				update={updatePolicyTable}
				onDismissModal={showModalHandler} 
				onOpenModal={showModalHandler}
				data={searchPolicyModalDetails}
				/>
			)}
			{showAddPolicy && (
				<AddPolicy
				show={showAddPolicy}
				from={policyFrom}
				onDismissModal={showModalHandler}
				onOpenModal={showModalHandler}
				policyData={policyModalDetails}
				/>
			)}
			{showAddClient && (
				<AddClient
				clientData={clientModalDetails}
				clientType={clientType}
				show={showAddClient}
				from={clientFrom}
				onDismissModal={showModalHandler}
				onOpenModal={showModalHandler}    
				/>
			)}
			{showAddQuotation && (
				<AddQuotation
				show={showAddQuotation}
				from={quotationFrom}
				onDismissModal={showModalHandler}
				onOpenModal={showModalHandler}
				extraInfo={extraInfo}
				quotationData={quotationModalDetails}
				/>
			)}
			{showAddFile && (
				<AddFile 
				show={showAddFile} 
				onDismissModal={showModalHandler} 
				onOpenModal={showModalHandler} 
				/>
			)}    
			{showAddTask && (
				<AddTask
				show={showAddTask}
				onDismissModal={showModalHandler}
				onOpenModal={showModalHandler}
				taskData={taskModalDetails}
				/>
			)}
			{showEmailVerificationModal && (
				<EmailVerificationModal
					show={showEmailVerificationModal}
					onDismissModal={showModalHandler}
					data={emailVerificationModalDetails}
				/>
			)}
			{showSubscriptionModal && (
				<SubscriptionModal
					show={showSubscriptionModal}
					onDismissModal={showModalHandler}
				/>
			)}
			{showLicenseChangeReminder && (
				<LicenseChangeReminder
					show={showLicenseChangeReminder}
					onDismissModal={showModalHandler}
				/>
			)}

			<div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
				<SideMenu menuID={menuID} />
				<div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">     
					<Header
						title={headerTitle}
						onLogout={props.onAuthStateChange}
						type="half"
						onOpenModal={showModalHandler}
						setCurrentPeriod={setCurrentPeriod}
						setFromDate={setFromDate}
						setToDate={setToDate}
						currentPeriod={currentPeriod}
						fromDate={fromDate}
						toDate={toDate}
						fetchPolicies = {v => setFetchPolicies(v)}
						notificationsList = {notificationList}
						setrefetchNotifications = {v => setrefetchNotifications(v)}
					/>
					<div style={{ paddingTop: '0px' }} id="kt_content"
						className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-b0">
						<Switch>
							<Route path="/dashboard" exact>
								<Dashboard 
								title={toggleState} 
								setCurrentPeriod={setCurrentPeriod}
								setFromDate={setFromDate}
								setToDate={setToDate}
								currentPeriod={currentPeriod}
								fromDate={fromDate}
								toDate={toDate}
								fetchPoliciesInHeader={fetchPolicies}
								setCustomPolicies = {v => setFetchPolicies(v)}
								/>
							</Route>
							<Route path="/policy-stats/:typeOfPolicy">
								<Policy
									title={toggleState}
									onOpenModal={showModalHandler}
									update={updatePolicyTable}
									updateQuotation={updateQuotationTable}
									updatePolicyQuotation={updatePolicyQuotation}
									policyData={policyModalDetails}
								/>
							</Route>
							<Route path="/batch-upload/:document/:type">
								<BatchUpload title={toggleState} />
							</Route>
							<Route path='/revenue-upload/revenue'>
								<RevenueUplaod title={toggleState}/>
							</Route>
							<Route path="/clients/:clientType">
								<Clients
								title={toggleState}
								onOpenModal={showModalHandler}
								update={updateClientsTable}
								updateClientQuotationTable={updateClientQuotationTable}
								/>
							</Route>
							<Route path="/files/:fileType" exact>
								<FileManagement
								title={toggleState}
								onOpenModal={showModalHandler}
								update={updateFilesTable}
								/>
							</Route>
							<Route path="/tasks/:taskType" exact>
								<Tasks
									title={toggleState}
									onOpenModal={showModalHandler}
									onDismissModal={showModalHandler}
									update={updateTaskTable}
									taskData={taskModalDetails}
								/>
							</Route>
							<Route path="/report" exact>
								<Support title={toggleState} />
							</Route>
							<Route path="/reports/:typeOfReport" exact>
								<Report title={toggleState} />
							</Route>
							<Route path="/team/:type" exact>
								<Team 
									title={toggleState} 
									onOpenModal={showModalHandler}
								/>
							</Route>
							<Route path="/sub-agency/:type" exact>
								<SubAgency 
									onOpenModal={showModalHandler}
									title={toggleState}
									policyData={policyModalDetails}
									setPolicyModalDetails={setPolicyModalDetails}
								/>
							</Route>
							<Route path="/tools" exact>
								<Tools title={toggleState} />
							</Route>
							<Route path="/profile" exact>
								<Profile title={toggleState}  onOpenModal={showModalHandler} />
							</Route>
							<Redirect to="/dashboard" />
						</Switch>
					</div>
					{/* <Footer /> */}
				</div>
			</div>
		</div>
	);
};
 
export default withRouter(Admin);
