import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import 'jquery-form';
import 'jquery-validation';
import moment from 'moment';
import '../../assets/scripts/bootstrap-modal';
import '../../assets/scripts/jquery-validation.init';
import * as helper from '../global/helper';
import swal from 'sweetalert2';
import Select from 'react-select';
import CustomEditor from '../global/editor';
import AddQuickText from '../global/quickText';
import Loading from '../global/loading';
import AddMember from '../clients/addMember';
import { getClients, getMainClients, getMainClientsByAgentID } from '../api/clientAPI';
import {
    getInsuranceCompanies,
    getProducts,
    getPolicyStatsForQuotation,
    postQuotations,
    getInsuranceCompaniesByAgentID,
    getProductsByAgentID
} from '../api/policyAPI';
import { uploadFileList } from '../api/fileAPI';
import QuotationTable from './quotationTable';
import EmailQuotation from './emailQuotation';
import AddCompany from './addCompany';
import AddDocument from './addDocument';
import { getAuthState } from '../../assets/scripts/login-util';
  
const AddQuotation = (props) => {

    const [btnLoading,          setBtnLoading]          = useState(false);  
    const [loading,             setLoading]             = useState(false);
    const [clients,             setClients]             = useState([]);
    const [selectedClient,      setSelectedClient]      = useState(null);
    const [selectedProduct,     setSelectedProduct]     = useState(null);
    const [addedClientId,       setAddedClientId]       = useState(null);
    const [addedMemberId,       setAddedMemberId]       = useState(null);
    const [insuranceCompanies,  setInsuranceCompanies]  = useState([]);
    const [products,            setProducts]            = useState([]);
    const [addedCompanyId,      setAddedCompanyId]      = useState(null);
    const [addedProductId,      setAddedProductId]      = useState(null)
    const [addModalType,        setAddModalType]        = useState('Company');
    const [quotationDetails,    setQuotationDetails]    = useState({});
    const [showAddQuotation,    setShowAddQuotation]    = useState(false);
    const [quotationList,       setQuotationList]       = useState([]);
    const [policyList,          setPolicyList]          = useState([]);
    const [selectedPolicy,      setSelectedPolicy]      = useState(null);
    const [navState,            setNavState]            = useState('New Quotation');
    const [editProduct,         setEditProduct]         = useState(true);
    const [editingXid,          setEditingXid]          = useState(null);
    const [emailList,           setEmailList]           = useState([]);
    const [quotationSaved,      setQuotationSaved]      = useState(false);
    const [addQuickText,        setAddQuickText]        = useState(false);
    const [fillQuickText,       setFillQuickText]       = useState('');
    const [documents,           setDocuments]           = useState([]);
    const [addDocument,         setAddDocument]         = useState(false);

    const userDetails = getAuthState();

    useEffect(() => {
        $('#add-quotation-modal').modal({
          backdrop: 'static',
        });
        $('#add-quotation-modal').on('hidden.bs.modal', function () {
          props.onDismissModal('quotation', false, props.from);
          if (props.from === 'searchPolicy') $('#search-policy-modal').removeClass('modal-blur');       
        });
        $('#add-quotation-modal').modal('toggle');
        if (props.from === 'searchPolicy') $('#search-policy-modal').addClass('modal-blur');       
        
        fetchClients();    
        fetchInsuranceCompanies();
        fetchProducts();

    }, [props.show]);

    useEffect(() => {
        $('[data-toggle="kt-popover"]').popover();
    })  

    useEffect(() => {    
        if (props.quotationData && props.quotationData.policy)
            setSelectedPolicy(props.quotationData.policy);
    }, [props.quotationData]);

    useEffect(() => {

        if(clients.length > 0) {

            if (addedClientId){
                const client = clients.find(c => c.ID == addedClientId);
                setSelectedClient(client);
                setAddedClientId(null);
            }             
      
            if (addedMemberId){
                const client = clients.find(c => c.ID == addedMemberId);
                setSelectedClient(client);
                setAddedMemberId(null);
            } 
        }
    }, [clients])
    
    useEffect(() => {

        if (selectedPolicy && selectedPolicy.ProductID)
            setSelectedProduct({ID: selectedPolicy.ProductID, Name: selectedPolicy.ProductName, Icon: selectedPolicy.ProductIcon});                  
        else 
            setSelectedProduct(null);          

    }, [selectedPolicy]);

    useEffect(() => {   

        setPolicyList([]);          
        if (selectedClient && !props.quotationData)       
            fetchPolicyByClient();     
                      
    }, [selectedClient]);

    useEffect(() => {   

        if (!props.quotationData || !props.quotationData.policy)
            setSelectedProduct(null);   

        if (policyList && policyList.length > 0)
            setNavState('Select Policy');
        else setNavState('New Quotation');
           
             
    }, [policyList]);

    useEffect(() => {       
        if (!props.quotationData || !props.quotationData.policy)
            setSelectedProduct(null);          
    }, [navState]);

    useEffect(() => {   

        if (selectedProduct) {
            setEditProduct(false);
            setShowAddQuotation(true);
        }else{
            setEditProduct(true);
            setShowAddQuotation(false);
            setQuotationDetails({});
            setQuotationList([]); 
        }     
             
    }, [selectedProduct]);

  
    useEffect(() => {   
        if (addedClientId || addedMemberId)
            fetchClients();        
    }, [addedClientId, addedMemberId]);

    useEffect(() => {       
        if (addedCompanyId)
            fetchInsuranceCompanies();  
    }, [addedCompanyId]);

    useEffect(() => {  
        if (addedProductId)
            fetchProducts();        
    }, [addedProductId]);

    useEffect(() => {    
        if (props.quotationData && props.quotationData.addedClientId)
            setAddedClientId(props.quotationData.addedClientId);     
    }, [props.quotationData]);

    useEffect(() => {
        if (insuranceCompanies.length > 0 && addedCompanyId) { 
            const company = insuranceCompanies.find(c => c.ID == addedCompanyId);
            changeCompanyHandler({
                label: company.Name,
                value: company.ID
            });         
        }        
    }, [insuranceCompanies]);

    useEffect(() => {  
        
        if (products.length > 0 && addedProductId) {
            const product = products.find(p => p.ID == addedProductId);
            setSelectedProduct(product);
            setAddedProductId(null); 
        }   

    }, [products]);

    useEffect(() => {

        if (documents.length > 0)
            updateQuotationDetails('Documents', documents);
        else
            updateQuotationDetails('Documents', []);     
        
    }, [documents]);

    useEffect(() => {
        if (quotationSaved) {
            $('#add-quotation-modal').modal('hide');
            props.onDismissModal('quotation', false, props.from, quotationSaved);
        }
    }, [quotationSaved]);

    useEffect(() => {       
        if (!quotationDetails.InsuranceCompanyID && (quotationList && quotationList.length > 0))
            setShowAddQuotation(false); 
    }, [quotationDetails]);

    useEffect(() => {       
        if (selectedProduct && (quotationList && quotationList.length == 0))
            setShowAddQuotation(true); 
    }, [quotationList]);

    const changeCompanyHandler = (e) => {
        updateQuotationDetails('InsuranceCompanyID', e.value);
        updateQuotationDetails('InsuranceCompanyName', e.label);
        setAddedCompanyId(null);
    }

    const refetchQuotations = () => setQuotationSaved(true);

    const addQuotationHandler = async (e) => {
        e.preventDefault();

        if (quotationList && quotationList.length > 0) {

            try {
                setBtnLoading(true);

                const docs = quotationList.filter((d) => d.Documents && d.Documents.length > 0).map(f => f.Documents.filter(s => s.DocumentType === 'Upload').map(m => m.DocumentFile));
                
                let docList = [];
                docs.forEach(doc => {
                    docList.push(...doc);
                });

                let documentList = [];
                if (docList.length > 0) {
                    documentList = await uploadFileList(docList, "QuotationDocument");
                }
                        
                const postQuotationList = [];
                quotationList.forEach(quotation => {
                    postQuotationList.push({
                        ClientID            : selectedPolicy ? selectedPolicy.ClientID : selectedClient.ID,
                        ProductID           : selectedProduct.ID,
                        InsuranceCompanyID  : quotation.InsuranceCompanyID,
                        PolicyID            : selectedPolicy ? selectedPolicy.ID : ((props.quotationData && props.quotationData.batchPolicy) ? props.quotationData.batchPolicy.ID : 0),
                        IsBatch             : (props.quotationData && props.quotationData.batchPolicy) ? 'Y' : 'N',
                        Premium             : quotation.Premium,
                        SumInsured          : quotation.SumInsured,
                        AdditionalData      : quotation.AdditionalData || '',
                        ExpiryDate          : quotation.ExpiryDate,
                        IsEmail             : quotation.IsEmail,
                        Documents           : quotation.Documents ? quotation.Documents.map(d => ({
                            FileName        : d.DocumentType === 'Upload' ? documentList.find(f => f.originalname === d.DocumentFile.name).filename : d.DocumentFile.name,
                            DocumentName    : d.DocumentName,
                            DocumentType    : d.DocumentType === 'Upload' ? 'QuotationDocument' : d.DocumentType
                        })) : []
                    });
                });

                const response = await postQuotations(postQuotationList);
                const emailQuotations = response.filter((q) => q.IsEmail);

                if (emailQuotations.length > 0) {
                    setEmailList(emailQuotations);
                    $('#add-quotation-modal').addClass('modal-blur');                   
                }else{
                    setQuotationSaved(true);             
                }
                swal.fire({
                    icon              : 'success',
                    titleText         : 'Quotation Added Successfully',
                    showConfirmButton : false,
                    timer             : 1500,
                });
    
            } catch (err) {
                setBtnLoading(false);
                swal.fire({
                    icon                : 'error',
                    titleText           : 'Error!',
                    text                : err.message,
                    buttonsStyling      : false,
                    confirmButtonClass  : 'btn btn-brand',
                });
            }
            
        }else{
            swal.fire({
                icon                : 'error',
                titleText           : 'Add at least one quote',
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        }
    };

    const fetchClients = async () => {
        try {
            let response = [];
            if (props.from === 'client') {
                response = props.extraInfo
            } else {
                response = userDetails.user.categoryId != 3 ? await getMainClients() : await getMainClientsByAgentID();
            }

            setClients(
                response.map((c) => ({
                    ...c,
                    DisplayName:
                        c.Type === 'Corporate' && c.Category === 'Primary'
                            ? c.CompanyName
                            : `${c.FirstName} ${c.MiddleName} ${c.LastName}`
                }))
                .sort((a, b) =>
                    a.DisplayName > b.DisplayName
                    ? 1
                    : a.DisplayName < b.DisplayName
                    ? -1
                    : 0
                )
            );
        } catch (err) {
            swal.fire({
                icon              : 'error',
                titleText         : 'Error!',
                text              : err.message,
                buttonsStyling    : false,
                confirmButtonClass: 'btn btn-brand',
            });
        }
    };

    const fetchProducts = async () => {
        try {
            const response = userDetails.user.categoryId != 3 ? await getProducts() : await getProductsByAgentID();
            setProducts(
            response
            .sort((a, b) =>
                a.Name > b.Name
                ? 1
                : a.Name < b.Name
                ? -1
                : 0
            )
            );     
        } catch (err) {
            swal.fire({
                icon              : 'error',
                titleText         : 'Error!',
                text              : err.message,
                buttonsStyling    : false,
                confirmButtonClass: 'btn btn-brand',
            });
        }
    };

    const fetchInsuranceCompanies = async () => {
        try {
            const response = userDetails.user.categoryId != 3 ? await getInsuranceCompanies() : await getInsuranceCompaniesByAgentID();
            setInsuranceCompanies(
            response
            .sort((a, b) =>
                a.Name > b.Name
                ? 1
                : a.Name < b.Name
                ? -1
                : 0
            )
            );     
        } catch (err) {
            swal.fire({
                icon              : 'error',
                titleText         : 'Error!',
                text              : err.message,
                buttonsStyling    : false,
                confirmButtonClass: 'btn btn-brand',
            });
        }
    };

    const fetchPolicyByClient = async () => {
        try {
            setLoading(true);
            const response = await getPolicyStatsForQuotation(selectedClient.ID);
            setPolicyList(response);
            setLoading(false);
        } catch (err) {
            swal.fire({
                icon              : 'error',
                titleText         : 'Error!',
                text              : err.message,
                buttonsStyling    : false,
                confirmButtonClass: 'btn btn-brand',
            });
        }
    };

    const updateQuotationDetails = (field, value) => {
        setQuotationDetails((d) => {
            return { ...d, [field]: value };
       });
    };

    const setEditorData = (html) => {
        updateQuotationDetails("AdditionalData", html);
    }

    const memberAddHandler = (e) => {
        e.preventDefault();
        $('#add-quotation-modal').addClass('modal-blur');
        $('#add-member-modal').modal('toggle');
    };

    const clientAddHandler = (e) => {
        e.preventDefault();
        $('#add-quotation-modal').addClass('modal-blur');
        props.onOpenModal('client', true, 'quotation', '', '', {type: 'directClient'});
    };

    const getClientName = () => {
        const foundClient = clients.find((c) => c.GroupID === selectedClient.GroupID && c.Category === 'Primary');
        if (foundClient)
          return { DisplayName: foundClient.DisplayName,  Type: foundClient.Type};
        else return { DisplayName: '',  Type: ''};
    };

    const setDocumentHandler = (d) => {
        setDocuments((a) => {
            const newA = [...a];
            newA.push(d);
            return newA;
        });    
    };

    const removeDocumentHandler = (index) => {
        setDocuments((a) => {
            const nA = a.filter((b, i) => index !== i);
            return nA;
        })  
    }

    const openDocumentHandler = (index) => {
        const document = documents[index];
        const { DocumentFile } = document;

        if (document.DocumentType === 'AgentFile') {

            const ext       = helper.getFileExt(DocumentFile.name);
            const name      = (document.DocumentName + '.' + ext).replace(/ /g, '_');
            helper.downloadAndOpenFileHandler("AgentFile", DocumentFile.name, name)
    
        }else{       
            helper.openFileHandler(DocumentFile);
        }
    }

    const productAddHandler = (e) => {
        e.preventDefault();
        setAddModalType('Product');
        $('#add-quotation-modal').addClass('modal-blur');
        $('#add-company-modal').modal('toggle');
    };

    const companyAddHandler = (e) => {
        e.preventDefault();
        setAddModalType('Company');   
        $('#add-quotation-modal').addClass('modal-blur');
        $('#add-company-modal').modal('toggle');
    };

    const quotationAddHandler = (e) => {
        e.preventDefault();

        var form = $('#add-quotation-form');
        form.validate().destroy();

        form.validate({
            rules: {
              ddlInsuranceCompany: {
                required: true
              },
              sumInsured: {
                required: true
              },
              premium: {
                required: true
              },
            }
        }); 

        if (!form.valid()) {
            return;
        }

        setQuotationList((a) => {
            const newA = [...a];
            if (editingXid) {
                const foundIndex = newA.findIndex(({ XID }) => XID === editingXid);
                newA[foundIndex] = {
                    ...newA[foundIndex],
                    ...quotationDetails
                  };
                  setEditingXid(null);
            }else{
                newA.push({
                    ...quotationDetails,
                    XID: Math.random(),
                    IsEmail: true
                });
            }            
            return newA;
        });
    
        setQuotationDetails({});
        setDocuments([]);

        form.clearForm();
        form.validate().resetForm();

    };
 
    const removeQuotationHandler = (index) => {

        const removingQuote = quotationList[index];
        const { XID } = removingQuote;

        if (XID === editingXid) {
            setQuotationDetails({});
            setDocuments([]);
            setEditingXid(null);
        }

        setQuotationList((a) => {
            const nA = a.filter((b, i) => index !== i);
            return nA;
        })       
    }

    const editQuotationHandler = (index) => {

        const quotation = quotationList[index];
        setQuotationDetails(quotation);
        setShowAddQuotation(true);
        setFillQuickText(quotation.AdditionalData);

        if (quotation.Documents)
            setDocuments(quotation.Documents);
        else setDocuments([])

        setEditingXid(quotation.XID);
                             
    }

    const emailQuotationHandler = (index, isEmail) => {
        setQuotationList((a) => {
            const newA = [...a];
            newA[index] = {
                ...newA[index],
                IsEmail: isEmail
            };           
            return newA;
        });                         
    }

    return (
        <React.Fragment>
            <div
                className="modal fade"
                id="add-quotation-modal"
                tabIndex="-1"
                role="dialog"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add New Quotation</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"                               
                                aria-label="Close"
                                disabled={btnLoading}>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                {(props.quotationData && props.quotationData.batchPolicy) && (
                                    <React.Fragment>
                                        <div className='kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded'>
                                            <table className='kt-datatable__table table-striped'>
                                                <tbody className='kt-datatable__body' style={{ display: 'block' }}>
                                                    <tr className='kt-datatable__row block-row'>
                                                        <td className="kt-datatable__cell text-clip" width="35%">
                                                            <div className="kt-user-card-v2">
                                                                <div className="kt-user-card-v2__pic">
                                                                    <img src={props.quotationData.batchPolicy.ProductIcon} alt="product"/>
                                                                </div>
                                                                <div className="kt-user-card-v2__details">
                                                                    <span className="kt-user-card-v2__name" title={props.quotationData.batchPolicy.PolicyNo}>{props.quotationData.batchPolicy.PolicyNo}</span>
                                                                    <span className="kt-user-card-v2__desc">
                                                                        <span title={props.quotationData.batchPolicy.InsuranceCompanyName}>{props.quotationData.batchPolicy.InsuranceCompanyName}</span><br/>
                                                                        <span title={props.quotationData.batchPolicy.ProductName}>{props.quotationData.batchPolicy.ProductName}</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="kt-datatable__cell text-clip" title={props.quotationData.batchPolicy.ClientName}>
                                                            <div className="kt-user-card-v2">
                                                                <div className="kt-user-card-v2__details">
                                                                    <span className="kt-user-card-v2__desc">Insured Name:</span>
                                                                    <span className="kt-user-card-v2__name">{props.quotationData.batchPolicy.ClientName}</span>
                                                    
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="kt-datatable__cell text-clip" title={moment(props.quotationData.batchPolicy.ToDate).format('DD-MMM-YYYY')}>
                                                                <div className="kt-user-card-v2">
                                                                <div className="kt-user-card-v2__details">
                                                                    <span className="kt-user-card-v2__desc">End Date:</span>
                                                                    <span className="kt-user-card-v2__name">{moment(props.quotationData.batchPolicy.ToDate).format('DD/MM/YYYY')}</span>
                                                    
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="kt-datatable__cell text-clip" title={props.quotationData.batchPolicy.Status}>
                                                            <div className={`policy-status block-badge mr-1 badge badge-${props.quotationData.batchPolicy.Status === 'Renewal Due' ? 'secondary' : (props.quotationData.batchPolicy.Status === 'Expired' ? 'label-danger' : 'warning')}`}>
                                                                <span>{props.quotationData.batchPolicy.Status}</span>
                                                            </div>
                                                        </td> 
                                                    </tr>                                                                                                       
                                                </tbody>
                                            </table>
                                        </div>
                                    </React.Fragment>   
                                )}
                                {selectedPolicy ? (
                                    <React.Fragment>
                                        <div className='kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded'>
                                            <table className='kt-datatable__table table-striped'>
                                                <tbody className='kt-datatable__body' style={{ display: 'block' }}>
                                                    <tr className='kt-datatable__row block-row'>
                                                        <td className="kt-datatable__cell text-clip" width="35%">
                                                            <div className="kt-user-card-v2">
                                                                <div className="kt-user-card-v2__pic">
                                                                    <img src={selectedPolicy.ProductIcon} alt="product"/>
                                                                </div>
                                                                <div className="kt-user-card-v2__details">
                                                                    <span className="kt-user-card-v2__name" title={selectedPolicy.PolicyNo}>{selectedPolicy.PolicyNo}</span>
                                                                    <span className="kt-user-card-v2__desc">
                                                                        <span title={selectedPolicy.InsuranceCompanyName}>{selectedPolicy.InsuranceCompanyName}</span><br/>
                                                                        <span title={selectedPolicy.ProductName}>{selectedPolicy.ProductName}</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="kt-datatable__cell text-clip" title={selectedPolicy.ClientName}>
                                                            <div className="kt-user-card-v2">
                                                                <div className="kt-user-card-v2__details">
                                                                    <span className="kt-user-card-v2__desc">Insured Name:</span>
                                                                    <span className="kt-user-card-v2__name">{selectedPolicy.ClientName}</span>
                                                    
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="kt-datatable__cell text-clip" title={moment(selectedPolicy.ToDate).format('DD-MMM-YYYY')}>
                                                                <div className="kt-user-card-v2">
                                                                <div className="kt-user-card-v2__details">
                                                                    <span className="kt-user-card-v2__desc">End Date:</span>
                                                                    <span className="kt-user-card-v2__name">{moment(selectedPolicy.ToDate).format('DD/MM/YYYY')}</span>
                                                    
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="kt-datatable__cell text-clip" title={selectedPolicy.Status}>
                                                            <div className={`policy-status block-badge mr-1 badge badge-${selectedPolicy.Status === 'Renewal Due' ? 'secondary' : (selectedPolicy.Status === 'Expired' ? 'label-danger' : 'warning')}`}>
                                                                <span>{selectedPolicy.Status}</span>
                                                            </div>
                                                        </td> 
                                                        <td className="text-right padding-r20" width="10%">
                                                            {(!props.quotationData || !props.quotationData.policy) && (
                                                                <button 
                                                                    type="button"                    
                                                                    onClick={() => setSelectedPolicy(null)}
                                                                    className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">     
                                                                    <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                                </button>  
                                                            )}                                                                                  
                                                        </td>
                                                    </tr>                                                                                                       
                                                </tbody>
                                            </table>
                                        </div>
                                    </React.Fragment>                                                                                                 
                                ) : (
                                    <React.Fragment>
                                        <div className="row">
                                            <div className={`col-lg-${(props.quotationData && props.quotationData.batchPolicy) ? '12' : '9'}`}>
                                                <div className="row">
                                                    <div className={`col-md-${(props.quotationData && props.quotationData.batchPolicy) ? '9' : '8'}`}>
                                                        <div className="form-group">
                                                            <label htmlFor="ddlClient">Client</label>
                                                            <Select
                                                                value={selectedClient && {label: selectedClient.DisplayName, value: selectedClient}}
                                                                onChange={(event) => setSelectedClient(event.value)}
                                                                name="ddlClient"
                                                                placeholder="Select Client"
                                                                id="ddlClient"
                                                                className="ddlClient"
                                                                options={clients.map(c => ({
                                                                label: c.DisplayName,
                                                                value: c
                                                                }))}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={`col-md-${(props.quotationData && props.quotationData.batchPolicy) ? '3' : '4'}`}>
                                                        {selectedClient ? (
                                                            <button
                                                                id="btnNewMember"
                                                                className="btn btn-label-brand btn-block margin-0 margin-md-t24"
                                                                onClick={memberAddHandler}>                          
                                                                <i className="la la-plus" />
                                                                New Member
                                                            </button>
                                                            ): (
                                                            <button
                                                                id="btnNewClient"
                                                                className="btn btn-brand btn-block margin-0 margin-md-t24"
                                                                onClick={clientAddHandler}>                                           
                                                                <i className="la la-plus" />
                                                                New Client
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            {(!props.quotationData || !props.quotationData.batchPolicy) && (
                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                        <label htmlFor="expiryDate">Expiry Date</label>
                                                        <input
                                                            id="expiryDate"
                                                            value={quotationDetails.ExpiryDate || ''}
                                                            name="expiryDate"
                                                            className="form-control margin-b10"
                                                            type="date"
                                                            min={moment().format('YYYY-MM-DD')}
                                                            onChange={(event) => updateQuotationDetails('ExpiryDate', event.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        {selectedClient && (
                                            <React.Fragment>
                                                <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded">
                                                    <table className="kt-datatable__table table-striped">
                                                        <tbody className="kt-datatable__body" style={{ display: 'block' }}>
                                                            <tr className="kt-datatable__row block-row">
                                                                <td title={selectedClient.DisplayName} className="kt-datatable__cell text-clip">
                                                                    <div className="kt-user-card-v2">
                                                                        <div className="kt-user-card-v2__pic">
                                                                            <div className={`kt-badge kt-badge--xl kt-badge--${ selectedClient.Type === 'Corporate' ? 'success' : 'info' }`}>                                                                                 
                                                                                {selectedClient.DisplayName.slice(0, 1)}
                                                                            </div>
                                                                        </div>
                                                                        <div className="kt-user-card-v2__details text-clip">
                                                                            <span className="kt-user-card-v2__name text-clip">{selectedClient.DisplayName}</span>
                                                                            <span className="kt-user-card-v2__desc text-clip">{selectedClient.Type === 'Corporate' ? 'Organization' : selectedClient.Type}</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="kt-datatable__cell text-clip">
                                                                    <span>
                                                                        {selectedClient.Email || ''}
                                                                        {selectedClient.Mobile ? `${selectedClient.Email ? ` | ${selectedClient.Mobile}` : selectedClient.Mobile}` : ''}
                                                                    </span>
                                                                </td>
                                                                <td width="10%" className="text-right padding-r20">
                                                                    <button 
                                                                        type="button"                    
                                                                        onClick={(e) => setSelectedClient(null)}
                                                                        className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">     
                                                                        <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                                    </button>                           
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div> 
                                                {loading && <Loading/>}                                                
                                                {(policyList && policyList.length > 0) && (
                                                    <React.Fragment>
                                                        <div className="kt-portlet kt-portlet--mobile">
                                                            <div className="kt-portlet__head kt-portlet__head--lg padding-lr0" style={{minHeight: 0}}>
                                                                <div className="kt-portlet__head-label w-100">
                                                                    <nav className="file-nav navbar navbar-top w-100">
                                                                        <div className="row w-100">
                                                                            <div className="col-6 text-center">
                                                                                <a className={`d-block w-100 text-clip ${navState === 'Select Policy' ? 'top-nav-active' : ''}`} onClick={() => setNavState('Select Policy')}>
                                                                                    Select Policy
                                                                                </a>

                                                                            </div>
                                                                            <div className="col-6 text-center">
                                                                                <a className={`d-block w-100 text-clip ${navState === 'New Quotation' ? 'top-nav-active' : ''}`} onClick={() => setNavState('New Quotation')}>                                                                                                                                                                                                                                               
                                                                                    New Quotation
                                                                                </a>
                                                                            </div>
                                                                        </div>                                                                              
                                                                    </nav>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {navState === 'Select Policy' && 
                                                            (<div className='kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded scrolTable' onScroll={helper.setStickyHeader}>
                                                                <table className='kt-datatable__table table-striped'>
                                                                    <thead className='kt-datatable__head' style={{ display: 'block' }}>
                                                                        <tr className='kt-datatable__row block-row'>
                                                                            <th className='kt-datatable__cell text-clip'>
                                                                                <span>Policy Number</span>
                                                                            </th>
                                                                            <th className='kt-datatable__cell text-clip'>
                                                                                <span>Insured Name</span>
                                                                            </th>
                                                                            <th className='kt-datatable__cell text-clip'>
                                                                                <span>End Date</span>
                                                                            </th>
                                                                            <th className='kt-datatable__cell text-clip'>
                                                                                <span>Company</span>
                                                                            </th>
                                                                            <th className='kt-datatable__cell text-clip'>
                                                                                <span>Product</span>
                                                                            </th>
                                                                            <th className='kt-datatable__cell text-clip'>
                                                                                <span>Status</span>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className='kt-datatable__body' style={{ display: 'block' }} >
                                                                        {policyList.map((p, i) => (
                
                                                                            <tr className='kt-datatable__row block-row clickable' key={i} onClick={() => setSelectedPolicy(p)}>
                                                                                <td className="kt-datatable__cell text-clip" title={p.PolicyNo}><span>{p.PolicyNo}</span></td>
                                                                                <td className="kt-datatable__cell text-clip" title={p.DisplayName}><span className="kt-font-bold">{p.DisplayName}</span></td>
                                                                                <td className="kt-datatable__cell text-clip" title={moment(p.ToDate).format('DD-MMM-YYYY')}><span>{moment(p.ToDate).format('DD/MM/YYYY')}</span></td>
                                                                                <td className="kt-datatable__cell text-clip" title={p.InsuranceCompanyName}><span>{p.InsuranceCompanyName}</span></td>
                                                                                <td className="kt-datatable__cell text-clip" title={p.ProductName}><span>{p.ProductName}</span></td>
                                                                                <td className="kt-datatable__cell text-clip" title={p.Status}>
                                                                                    <div className={`policy-status block-badge mr-1 badge badge-${p.Status === 'Renewal Due' ? 'secondary' : (p.Status === 'Expired' ? 'label-danger' : 'warning')}`}>
                                                                                        <span>{p.Status}</span>
                                                                                    </div>
                                                                                </td>   
                                                                            </tr>
                                                                            
                                                                        ))}
                                                                        
                                                                    </tbody>
                                                                </table>
                                                            </div>)
                                                        }
                                                        
                                                    </React.Fragment>
                                                    
                                                )}

                                                {navState === 'New Quotation' && (
                                                    <React.Fragment>
                                                        {editProduct ? (
                                                            <div className="row">
                                                                <div className={`${userDetails.user.categoryId != 3 ? 'col-8' : 'col-12'}`}>
                                                                    <div className="form-group">
                                                                        <label htmlFor="ddlProduct">Product</label>
                                                                        <Select
                                                                            value={selectedProduct && {label: selectedProduct.Name, value: selectedProduct}}
                                                                            onChange={(event) => setSelectedProduct(event.value)}
                                                                            name="ddlProduct"
                                                                            id="ddlProduct"
                                                                            placeholder="Select Product"
                                                                            options={products.map(p => ({
                                                                            label: p.Name,
                                                                            value: p
                                                                            }))}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {userDetails.user.categoryId != 3 && (
                                                                    <div className="col-4">
                                                                        <button
                                                                            id="btnNewProduct"
                                                                            className="btn btn-label-brand btn-block margin-0 margin-md-t24"
                                                                            data-toggle="kt-popover"
                                                                            data-trigger="hover"
                                                                            data-placement="bottom"
                                                                            data-content="Add Product"
                                                                            onClick={productAddHandler}>           
                                                                            <i className="la la-plus" />
                                                                            New
                                                                        </button>
                                                                    </div> 
                                                                )}                 
                                                            </div>  
                                                        ) : (
                                                            <React.Fragment>
                                                                {selectedProduct && (
                                                                    <div className='kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded'>
                                                                        <table className='kt-datatable__table table-striped'>
                                                                            <tbody className='kt-datatable__body' style={{ display: 'block' }}>
                                                                                <tr className='kt-datatable__row block-row'>
                                                                                    <td className="kt-datatable__cell text-clip">
                                                                                        <div className="kt-user-card-v2">
                                                                                            <div className="kt-user-card-v2__pic">
                                                                                                <img src={selectedProduct.Icon} alt="product"/>
                                                                                            </div>
                                                                                            <div className="kt-user-card-v2__details">
                                                                                                <span className="kt-user-card-v2__desc">Product:</span>
                                                                                                <span className="kt-user-card-v2__name">{selectedProduct.Name}</span>
                                                                                
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="text-right padding-r20" width="10%">
                                                                                        <button 
                                                                                            type="button"                    
                                                                                            onClick={() => setEditProduct(true)}
                                                                                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">     
                                                                                            <i className="flaticon2-edit"></i>
                                                                                        </button>                           
                                                                                    </td>
                                                                                </tr>     
                                                                                                                                
                                                                            </tbody>
                                                                        </table>
                                                                    </div>      
                                                                )}
                                                            </React.Fragment>
                                                        )}                                                  
                                                    </React.Fragment>                                                  
                                                )}                                            
                                            </React.Fragment>                                            
                                        )}
                                    </React.Fragment>                                   
                                )} 
                            </form>
                            <form id="add-quotation-form" className="kt-form" onSubmit={(e) => e.preventDefault()}>
                                {selectedProduct && 
                                (
                                    <React.Fragment>
                                        
                                        {(quotationList && quotationList.length > 0) && (
                                            <QuotationTable 
                                                action="a"
                                                quotations={quotationList}
                                                removeQuotation={removeQuotationHandler}
                                                editQuotation={editQuotationHandler}
                                                emailQuotation={emailQuotationHandler}
                                            />
                                        )}  
                                        
                                        {showAddQuotation ? (
                                            <div className="row margin-t10">
                                                <div className="col-lg-6">
                                                    <div className="row">
                                                    <div className={`${userDetails.user.categoryId != 3 ? 'col-8' : 'col-12'}`}>
                                                            <div className="form-group">
                                                                <label htmlFor="ddlInsuranceCompany">Insurance Company</label>       
                                                                <Select
                                                                    value={quotationDetails && quotationDetails.InsuranceCompanyID && {label: quotationDetails.InsuranceCompanyName, value: quotationDetails.InsuranceCompanyID}}
                                                                    onChange={changeCompanyHandler}
                                                                    name="ddlInsuranceCompany"
                                                                    id="ddlInsuranceCompany"
                                                                    placeholder="Select Company"
                                                                    options={insuranceCompanies.map(c => ({
                                                                    label: c.Name,
                                                                    value: c.ID
                                                                    }))}
                                                                />
                                                            </div>  
                                                        </div>
                                                        {userDetails.user.categoryId != 3 && (
                                                            <div className="col-4">
                                                                <button
                                                                    id="btnNewCompany"
                                                                    className="btn btn-label-brand btn-block margin-0 margin-t24"
                                                                    data-toggle="kt-popover"
                                                                    data-trigger="hover"
                                                                    data-placement="bottom"
                                                                    data-content="Add Insurance Company"
                                                                    onClick={companyAddHandler}>
                                                                    <i className="la la-plus" />
                                                                    New
                                                                </button>
                                                            </div>
                                                        )}
                                                    </div>  
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <label htmlFor="sumInsured">Sum Insured</label>
                                                                <input
                                                                    id="sumInsured"
                                                                    value={quotationDetails.SumInsured || ''}
                                                                    name="sumInsured"
                                                                    className="form-control margin-b10"
                                                                    type="number"
                                                                    onChange={(event) => updateQuotationDetails('SumInsured', event.target.value)}                                                   
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <label htmlFor="premium">Premium</label>
                                                                <input
                                                                    id="premium"
                                                                    value={quotationDetails.Premium || ''}
                                                                    name="premium"
                                                                    className="form-control margin-b10"
                                                                    type="number"
                                                                    onChange={(event) => updateQuotationDetails('Premium', event.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        ) : (

                                            <div className="row text-center margin-t10">
                                                <div className="col-12">
                                                    <button
                                                        id="btnAddMoreQuote"
                                                        className="btn btn-brand d-inline margin-0"
                                                        onClick={() => setShowAddQuotation(true)}>
                                                        <i className="la la-plus" />
                                                        Add More Quote
                                                    </button>
                                                </div>
                                            </div>

                                        )}


                                        {quotationDetails.InsuranceCompanyID && (
                                            <React.Fragment>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <CustomEditor
                                                                placeholder="Additional Data"
                                                                addQuickText={setAddQuickText}
                                                                fillQuickText={fillQuickText}
                                                                setFillQuickText={setFillQuickText}
                                                                setData={setEditorData}
                                                            />   
                                                        </div>
                                                    </div>
                                                </div>

                                                {documents && documents.length > 0 && (                        
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="kt-notification margin-b30">
                                                                {
                                                                    documents.map((d, i) =>
                                                                    <a key={i} className="kt-notification__item">
                                                                        <div className="kt-notification__item-icon margin-r10" onClick={() => openDocumentHandler(i)}>
                                                                        <img height="30px" width="30px" src={helper.getFileLogo(d.DocumentFile.name)} />
                                                                        </div>
                                                                        <div className="kt-notification__item-details" onClick={() => openDocumentHandler(i)}>
                                                                            <div className="kt-notification__item-title kt-font-bold">
                                                                                {d.DocumentName}
                                                                            </div>
                                                                        </div>
                                                                        <button 
                                                                            type="button"                    
                                                                            onClick={() => removeDocumentHandler(i)}
                                                                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                                                            <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                                        </button>
                                                                    </a>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}  

                                                <div className="row text-center">
                                                    <div className="col-12">
                                                        <button
                                                            className="btn btn-label-brand d-inline margin-0 margin-r10"
                                                            onClick={() => setAddDocument(true)}>
                                                            <i className="flaticon-doc" />
                                                            Attach Documents
                                                        </button>
                                                        {editingXid ? (
                                                            <div className="btn-group" role="group">
                                                                <button
                                                                    id="btnUpdateQuote"
                                                                    className="btn btn-brand d-inline margin-0"
                                                                    style={{ maxWidth: '70%' }}
                                                                    onClick={quotationAddHandler}>                                                 
                                                                    Update
                                                                </button>
                                                                <button
                                                                    style={{ maxWidth: '30%' }}
                                                                    className="btn btn-secondary d-inline margin-0 padding-0"
                                                                    onClick={() => {
                                                                        setEditingXid(null);
                                                                        setQuotationDetails({});
                                                                        setDocuments([]);
                                                                        $('#ddlInsuranceCompany').val(0).trigger('change');
                                                                    }}>                                                      
                                                                    <i
                                                                        className="la la-times"
                                                                        style={{ fontSize: '1rem', marginLeft: '3px' }}
                                                                    />
                                                                </button>
                                                            </div>

                                                        ) : (
                                                            <button
                                                                id="btnAddQuote"
                                                                className="btn btn-brand d-inline margin-0"
                                                                onClick={quotationAddHandler}>
                                                                Save Quote
                                                            </button>
                                                        )}                                                      
                                                    </div>
                                                </div>

                                            </React.Fragment>)
                                        }
                                          
                                    </React.Fragment>                                                                                                                   
                                )} 
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary margin-0 margin-r5"
                                data-dismiss="modal"
                                disabled={btnLoading}>
                                Close
                            </button>
                            {
                                (quotationList && quotationList.length > 0) && (
                                    <button
                                        id="save-btn"
                                        onClick={addQuotationHandler}
                                        type="button"
                                        className={`btn btn-brand margin-0 ${
                                        btnLoading
                                            ? 'kt-spinner kt-spinner--sm kt-spinner--light'
                                            : ''
                                        }`}
                                        disabled={btnLoading} >                  
                                        Save { quotationList.find(q => q.IsEmail) && `& Email` }
                                    </button>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            {selectedClient && (
                <React.Fragment>
                    <AddMember
                        addMemberId={setAddedMemberId}
                        groupId={selectedClient.GroupID}
                        client={getClientName()}
                        from="#add-quotation-modal"
                    />
                    <AddCompany
                        type={addModalType}
                        addInsuranceCompanyId={setAddedCompanyId}
                        addProductId={setAddedProductId}
                        from="#add-quotation-modal"
                    />
                </React.Fragment>
            )} 
            {emailList && emailList.length > 0 && (
                <EmailQuotation
                    action="a"
                    groupId={selectedPolicy ? selectedPolicy.ClientGroupID : selectedClient.GroupID}
                    quotationIds={emailList.map(q => q.ID)}
                    show={emailList && emailList.length > 0}
                    refetchQuotations={refetchQuotations}
                />
            )} 

            {addQuickText && (
                <AddQuickText
                    fillQuickText={setFillQuickText}
                    addQuickText={setAddQuickText}
                    from="#add-quotation-modal"
                />
            )}

            {addDocument && (
                <AddDocument
                    setDocumentHandler={setDocumentHandler}
                    addDocument={setAddDocument}
                    from="#add-quotation-modal"  
                />
            )}  
            

        </React.Fragment>
    );

};


export default AddQuotation;