import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import 'jquery-form';
import 'jquery-validation'
import moment from 'moment';
import BatchDropzone from '../global/batchDropzone';
import * as helper from '../global/helper';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import swal from 'sweetalert2';
import Loading from '../global/loading';
import BatchPolicies from './batchPolicies';
import { apiUrl } from '../../axios';
import { verifyPolicyNo, postBatchPolicies } from '../api/policyAPI';
import VideoTutorials from '../global/videoTutorials';

const Uploader = (props) => {

    const fileHandler = async (f) => {

        props.setLoading(true);

        const reader = new FileReader();
        reader.readAsBinaryString(f);
        reader.onload = async () => {
            const { result } = reader;
            const data = helper.extractExcel(result);

            const rows = data.split('\n').filter((r) => r.split(',').length > 1).slice(0, 1001);
            if (rows.length < 2) {
                props.setLoading(false);
                return swal.fire({
                    icon                  : 'warning',
                    titleText             : 'Warning!',
                    text                  : 'No data found',
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',
                });
            }
            const headings = rows[0].split(',');
            if (headings[0].trim().toLowerCase()     !== 'policy number' 
                || headings[1].trim().toLowerCase()  !== 'client name' 
                || (headings[2].trim().toLowerCase() !== 'expiry date' && headings[2].trim().toLowerCase() !== 'expiry date (dd/mm/yyyy)') 
                || headings[3].trim().toLowerCase()  !== 'insurance company' 
                || headings[4].trim().toLowerCase()  !== 'product') {
                props.setLoading(false);
                return swal.fire({ 
                    icon                  : 'error',
                    titleText             : 'Error!',
                    text                  : 'Unknown file. Please upload the file in given format.',
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',
                });
            }
            let duplicates = false, datesValid = true, policyNoValid = true;
            const policyNumbers = [];
            const policies = rows.slice(1).filter((r) => r.split(',').length >= 5).map((r) => {
                const [PolicyNo, ClientName, ToDate, InsuranceCompanyName, ProductName] = r.split(',');
                const duplicate = policyNumbers.includes(PolicyNo.trim());
                let dataValid = true;
                if (duplicate) duplicates = true;
                if (PolicyNo.trim().length > 0) policyNumbers.push(PolicyNo.trim());
                if (ClientName.trim().length < 1) dataValid = false;
                if (InsuranceCompanyName.trim().length < 1) dataValid = false;
                if (ProductName.trim().length < 1) dataValid = false;
                const date = moment(ToDate.trim(), 'DD/MM/YYYY').format('YYYY-MM-DD')
                return {
                    PolicyNo                : PolicyNo.trim(),
                    ClientName              : helper.camelCase(ClientName.trim()),
                    ToDate                  : date == 'Invalid date' ? ToDate : date,
                    InsuranceCompanyName    : helper.camelCase(InsuranceCompanyName.trim()),
                    ProductName             : helper.camelCase(ProductName.trim()),
                    dateValid               : date == 'Invalid date' ? false : true,  
                    duplicate,
                    dataValid
                };
            }).filter((p) => {
                const { PolicyNo, ClientName, ToDate, InsuranceCompanyName, ProductName } = p;
                const [yyy, mm, dd] = ToDate.split('-'), day = parseInt(dd, 10), month = parseInt(mm, 10);
                let condition = true;

                // if (PolicyNo.trim().length < 1 && ClientName.trim().length < 1 && InsuranceCompanyName.trim().length < 1 && ProductName.trim().length < 1)
                //     return false;

                // if (PolicyNo.trim().length < 1){
                //     policyNoValid = false;
                //     condition = false;
                // } 
                if (!/[0-9][0-9]?\/[0-9][0-9]?\/[0-9]{4}/.test(moment(ToDate).format('DD/MM/YYYY')) || day > 31 || month > 12){
                    datesValid = false;
                    // condition = false
                } 
                return condition;
            });
            // if (!policyNoValid && !datesValid) {
            //     swal.fire({
            //         icon                  : 'warning',
            //         titleText             : 'Warning!',
            //         text                  : 'Some policies are removed due to no policy number and invalid date format.',
            //         buttonsStyling        : false,
            //         confirmButtonClass    : 'btn btn-brand',
            //     });
            // }else if (!policyNoValid){
            //     swal.fire({
            //         icon                  : 'warning',
            //         titleText             : 'Warning!',
            //         text                  : 'Some policies are removed due to no policy number.',
            //         buttonsStyling        : false,
            //         confirmButtonClass    : 'btn btn-brand',
            //     });
            // }else 
            if (!datesValid){
                swal.fire({
                    icon                  : 'warning',
                    titleText             : 'Warning!',
                    text                  : 'Some policies have invalid date format.',
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',
                });
            }

            try {
                const existingNumbers = await verifyPolicyNo(policyNumbers);

                existingNumbers.forEach((e) => {
                    const ind = policies.findIndex(
                      (p) => p.PolicyNo === e.PolicyNo
                    );
                    if (ind >= 0) {
                      policies[ind].alreadyExists = true;
                      duplicates = true;
                    }
                });         
                
            } catch (err) {
                props.setLoading(false);
                return swal.fire({ 
                    icon                  : 'error',
                    titleText             : 'Error!',
                    text                  : 'Could not connect to the server. Please try again.',
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',
                });
            }

            if (duplicates){
                swal.fire({
                    icon                  : 'warning',
                    titleText             : 'Warning!',
                    text                  : 'There are some duplicate policy numbers. Consider updating them.',
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',
                });
            }
            const sortedPolicies = policies.sort((a, b) => {
                if(!a.dataValid || !a.dateValid || a.duplicate) return -1
                if(!b.dataValid || !b.dateValid || a.duplicate) return 1
                return 0
            })       
            props.onPoliciesCreated(sortedPolicies);
            props.setLoading(false);
        };        
        
    };

    return(
        <React.Fragment>
            <div className="row margin-t50 margin-b10">
                <div className="col-12">
                    <div className="btn-holder text-center">
                        <a href={`${apiUrl}/files/formats/policies.csv`} className="btn btn-label-brand">
                            <i className="fa fa-download" />
                            Download Template for Policies
                        </a>
                    </div>
                </div>
            </div>
            <div className="row margin-b50">
                <div className="col-12">
                    <BatchDropzone fileHandler={fileHandler}/>
                </div>         
            </div>
        </React.Fragment>       
    );
};

export const EditBatchPolicy = (props) => {

    const [policy, setPolicy] = useState(props.policy);

    const updatePolicyDetails = (field, value) => {
        setPolicy((d) => {
            return { ...d, [field]: value };
        });
    };

    const updatePolicy = async (e) => {
        e.preventDefault();
    
        var form = $('#edit-batch-policy-form');
        form.validate().destroy();
    
        form.validate({
          rules: {
            PolicyNo: {
              required: true,
            },
            ClientName: {
              required: true,
            },
            InsuranceCompanyName: {
              required: true,
            },
            ProductName: {
              required: true,
            },
            ToDate: {
                required: true,
            }
          },
        });
    
        if (!form.valid()) {
          return;
        }

        helper.StartProcessing($('#save-btn'));

        if (props.action === 'a') {
            try {        
                const duplicate = props.duplicateCheck(policy.PolicyNo, props.index);
                if (duplicate) {
                    helper.StopProcessing($('#save-btn'));
                    return swal.fire({ 
                        icon                  : 'error',
                        titleText             : 'Error!',
                        text                  : 'Policy number already exist!',
                        buttonsStyling        : false,
                        confirmButtonClass    : 'btn btn-brand',
                    });
                }else{
                    const existingNumbers = await verifyPolicyNo([policy.PolicyNo]);
                    if (existingNumbers.length > 0) {
                        helper.StopProcessing($('#save-btn'));
                        return swal.fire({ 
                            icon                  : 'error',
                            titleText             : 'Error!',
                            text                  : 'Policy number already exist!',
                            buttonsStyling        : false,
                            confirmButtonClass    : 'btn btn-brand',
                        });
                    }else {
                        props.updatePolicy({...policy, dateValid: policy.ToDate ? true : false}, props.index);            
                    }  
                }             
            } catch (err) {
                helper.StopProcessing($('#save-btn'));
                return swal.fire({ 
                    icon                  : 'error',
                    titleText             : 'Error!',
                    text                  : err.message,
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',
                });
            } 
        }else{
            await props.updatePolicy(policy); 
        }
      
        helper.StopProcessing($('#save-btn'));
        $('#edit-batch-policy-modal').modal('hide');
        
        swal.fire({
          toast             : true,
          icon              : 'success',
          titleText         : `Policy Updated successfully!`,
          position          : 'bottom-end',
          showConfirmButton : false,
          timer             : 1500,
          animation         : false,
          customClass       : {
            popup: 'margin-20',
          },
        });
    };


    return (
        <div
          className="modal fade"
          id="edit-batch-policy-modal"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true">        
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Policy</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close">
                </button>
              </div>
              <div className="modal-body">
                <form id="edit-batch-policy-form" onSubmit={updatePolicy}>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                            <label htmlFor="PolicyNo">Policy Number</label>
                                <input
                                    id="PolicyNo"
                                    value={policy.PolicyNo || ''}
                                    name="PolicyNo"
                                    className="form-control margin-b10"
                                    type="text"
                                    onChange={(event) => updatePolicyDetails('PolicyNo', event.target.value)}                                                   
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="ClientName">Client Name</label>
                                <input
                                    id="ClientName"
                                    value={policy.ClientName || ''}
                                    name="ClientName"
                                    className="form-control margin-b10"
                                    type="text"
                                    onChange={(event) => updatePolicyDetails('ClientName', event.target.value)}                                                   
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="ToDate">Expiry Date</label>
                                <input
                                    id="ToDate"
                                    value={moment(policy.ToDate).format('YYYY-MM-DD') || ''}
                                    name="ToDate"
                                    className="form-control margin-b10"
                                    type="date"
                                    onChange={(event) => updatePolicyDetails('ToDate', event.target.value)}                                                   
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="InsuranceCompanyName">Insurance Company</label>
                                <input
                                    id="InsuranceCompanyName"
                                    value={policy.InsuranceCompanyName || ''}
                                    name="InsuranceCompanyName"
                                    className="form-control margin-b10"
                                    type="text"
                                    onChange={(event) => updatePolicyDetails('InsuranceCompanyName', event.target.value)}                                                   
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="ProductName">Product</label>
                                <input
                                    id="ProductName"
                                    value={policy.ProductName || ''}
                                    name="ProductName"
                                    className="form-control margin-b10"
                                    type="text"
                                    onChange={(event) => updatePolicyDetails('ProductName', event.target.value)}                                                   
                                />
                            </div>
                        </div>
                    </div>
                  </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary margin-0 margin-r5"
                  data-dismiss="modal">       
                  Close
                </button>
                <button
                  id="save-btn"
                  onClick={updatePolicy}
                  type="button"
                  className="btn btn-brand margin-0">          
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
    );
};

const BatchTable = (props) => {

    const [editingPolicy, setEditingPolicy] = useState(null);
    const [editingPolicyIndex, setEditingPolicyIndex] = useState(-1);

    useEffect(() => {
        helper.SetScrollHeight();
    });

    useEffect(() => {
        $('[data-toggle="kt-popover"]').popover();
        $('[data-toggle="kt-tooltip"]').tooltip();
        $('body').on('click', function (e) {
            $('[data-toggle=kt-popover]').each(function () {
                if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                    $(this).popover('hide');
                }
            });
        });
    }, []);

    useEffect(() => {  
        if (editingPolicy){
            $('#edit-batch-policy-modal').modal({
                backdrop: 'static',
            });
            $('#edit-batch-policy-modal').on('hidden.bs.modal', function () {
                setEditingPolicy(null);
                setEditingPolicyIndex(-1);    
            });
        }          
        else{
            $('#edit-batch-policy-modal').modal('hide');
            setEditingPolicyIndex(-1);
        }    
    }, [editingPolicy]);

    const editPolicyHandler = (index) => {
        setEditingPolicy(props.policies[index])
        setEditingPolicyIndex(index);
    };

    const duplicateCheckHandler = (policyNo, index) => {
        const duplicate =  props.policies.find((p, i) => p.PolicyNo === policyNo.trim() && i != index);
        if (duplicate) return true;
        else return false;
    };

    return(
        <React.Fragment>
            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
                <table className="kt-datatable__table">
                    <thead className="kt-datatable__head" style={{ display: 'block' }}>
                        <tr className="kt-datatable__row block-row">
                            <th className="kt-datatable__cell text-clip" width="15%">
                                <span>Policy Number</span>
                            </th>
                            <th className="kt-datatable__cell text-clip" width="20%">
                                <span>Client Name</span>
                            </th>
                            <th className="kt-datatable__cell text-clip" width="15%">
                                <span>Expiry Date</span>
                            </th>
                            <th className="kt-datatable__cell text-clip" width="20%">
                                <span>Insurance Company</span>
                            </th>
                            <th className="kt-datatable__cell text-clip" width="15%">
                                <span>Product</span>
                            </th>
                            <th className="kt-datatable__cell--center kt-datatable__cell text-clip" width="15%">
                                <span>Action</span>
                            </th>
                        </tr>
                    </thead>                        
                </table>
            </div>
            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0 scrolTable" onScroll={helper.setStickyHeader}>
                <table className="kt-datatable__table table-striped">
                    <tbody className="kt-datatable__body" style={{ display: 'block' }}>
                        {props.policies.map((p, i) => (
                            <tr key={i} className="kt-datatable__row block-row already-exists">
                                <td className="kt-datatable__cell text-clip" width="15%">
                                    <span className="kt-font-bold">{p.PolicyNo}</span>
                                </td>
                                <td className="kt-datatable__cell text-clip" width="20%">
                                    <span>{p.ClientName}</span>
                                </td>
                                <td className="kt-datatable__cell text-clip" width="15%">
                                    <span className="kt-font-bold">{!p.dateValid ? p.ToDate : moment(p.ToDate).format('DD/MM/YYYY')}</span>
                                </td>
                                <td className="kt-datatable__cell text-clip" width="20%">
                                    <span>{p.InsuranceCompanyName}</span>
                                </td>
                                <td className="kt-datatable__cell text-clip" width="15%">
                                    <span>{p.ProductName}</span>
                                </td>
                                <td className="kt-datatable__cell--center kt-datatable__cell text-clip" width="15%">
                                    <button
                                        type="button"
                                        data-toggle="kt-popover"
                                        data-trigger="hover click"
                                        data-placement="left"
                                        data-content={p.duplicate ? 'Policy Number Duplicated!' : p.alreadyExists ? 'Policy Number Already Exist!' : !p.dateValid ? 'Invalid Date Format!' : !p.dataValid ? 'Mandetory fields not provided!' : 'Policy Verified Successfully'}
                                        className={`btn btn-outline-${p.alreadyExists || p.duplicate || !p.dataValid || !p.dateValid ? 'danger' : 'success'} btn-elevate btn-circle btn-icon btn-xs margin-r10`}>
                                        <i className={`fa fa-${p.alreadyExists || p.duplicate || !p.dataValid || !p.dateValid ? 'exclamation' : 'check'}`}></i>
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => editPolicyHandler(i)}
                                        className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                        <i className="flaticon2-edit"></i>
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => props.removePolicy(i)}
                                        className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                        <i className="flaticon2-rubbish-bin-delete-button"></i>
                                    </button>
                                </td>        
                            </tr>
                        ))}                                          
                    </tbody>
                </table>
            </div>
            <div className="btn-holder text-center">
                <button onClick={() => props.reset()} className="btn btn-label-brand">
                    <i className="fa fa-times" />
                    Cancel
                </button>
                <button onClick={() => props.save()} className="btn btn-primary margin-lr10">
                    <i className="fa fa-upload" />
                    Upload
                </button>
            </div>
            {editingPolicy && (
                <EditBatchPolicy
                    policy={editingPolicy}
                    index={editingPolicyIndex}
                    updatePolicy={props.editPolicy}
                    duplicateCheck={duplicateCheckHandler}
                    action="a"
                />
            )}
        </React.Fragment>
    );
};

const Policies = (props) => {

    const [loading, setLoading] = useState(false);
    const [policies, setPolicies] = useState([]);
    const [showVideoTutorials,  setShowVideoTutorials]  = useState(false);
    const param = useParams();
    const history = useHistory();

    useEffect(() => {
        $('[data-toggle="kt-popover"]').popover();
    });

    const removePolicyHandler = (index) => {
        swal.fire({
            title               : 'Are you sure?',
            text                : "You won't be able to revert this!",
            icon                : 'warning',
            showCancelButton    : true,
            buttonsStyling      : false,
            reverseButtons      : true,
            showLoaderOnConfirm : true,
            confirmButtonClass  : 'btn btn-brand',
            cancelButtonClass   : 'btn btn-secondary',
            confirmButtonText   : 'Delete',
            preConfirm: async () => {
                setPolicies((p) => {
                    const nA = p.filter((b, i) => index !== i);
                    return nA;
                });
                swal.fire({
                    toast             : true,
                    icon              : 'success',
                    titleText         : 'Policy deleted successfully!',
                    position          : 'bottom-end',
                    showConfirmButton : false,
                    timer             : 1500,
                    animation         : false,
                    customClass       : {
                      popup: 'margin-20',
                    },
                });
            },
        });     
    }

    const editPolicyHandler = async (policy, index) => {
        setPolicies((p) => {
            const newA = [...p];
            policy.alreadyExists = false;
            policy.duplicates = false;
            policy.dataValid = true;
            if (policy.ClientName.trim().length < 1) policy.dataValid = false;
            if (policy.InsuranceCompanyName.trim().length < 1) policy.dataValid = false;
            if (policy.ProductName.trim().length < 1) policy.dataValid = false;
            newA[index] = policy;

            newA.forEach((element, ind) => {
                if (element.duplicate) {
                    const p = newA.find((p, i) => p.PolicyNo === element.PolicyNo && i != ind);
                    if (!p) element.duplicate = false;                
                }                           
            });
            return newA;
        });   
    }

    const resetHandler = () => {
        setPolicies([]);
        $('.kt-portlet__head').removeClass('scroll-down');          
    }

    const saveHandler = () => {
        const policyList = policies.filter(p => !p.alreadyExists && !p.duplicate && p.dataValid && p.dateValid).map((p) => ({
            PolicyNo            : p.PolicyNo,
            ClientName          : p.ClientName || '',
            InsuranceCompanyName: p.InsuranceCompanyName || '',
            ProductName         : p.ProductName || '',
            ToDate              : p.ToDate
        }));

        if (policyList.length == 0) {
            return swal.fire({ 
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : 'There are no valid policies to upload!',
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
        }

        if (policies.length > policyList.length) {
            swal.fire({
                title               : 'Are you sure?',
                text                : "There are some invalid policies. which will be ignored during upload.",
                icon                : 'warning',
                showCancelButton    : true,
                buttonsStyling      : false,
                reverseButtons      : true,
                showLoaderOnConfirm : true,
                confirmButtonClass  : 'btn btn-brand',
                cancelButtonClass   : 'btn btn-secondary',
                confirmButtonText   : 'Upload anyway',
                preConfirm: () => {
                    uploadHandler(policyList);
                },
            });   
        }else{
            uploadHandler(policyList);
        }
                  
    }

    const uploadHandler = async (policyList) => {
        try {
            swal.fire({
                titleText           : 'Uploading',
                text                : 'Please Wait...',
                showConfirmButton   : false,
                onOpen: () => {
                    swal.showLoading();
                }
            });
            const res = await postBatchPolicies(policyList);
            swal.close();
            swal.fire({
                icon                : 'success',
                titleText           : `${res.length} Policies Uploaded Successfully Out of ${policies.length}`,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
            resetHandler();
            history.push('/batch-upload/policies/files');
        } catch (err) {
            swal.close();
            return swal.fire({ 
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
        }

    }

    const closeDrawerHandler = () => {      
        setTimeout(() => {
            setShowVideoTutorials(false);
        }, 180);
    };

    return(
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
            <div className="kt-portlet kt-portlet--mobile">
                <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                    <div className="kt-portlet__head-label">
                        <nav className="file-nav navbar navbar-top">
                            <NavLink
                                activeClassName="top-nav-active"
                                to="/batch-upload/policies/upload" >         
                                Upload
                            </NavLink>
                            <NavLink activeClassName="top-nav-active" to="/batch-upload/policies/files">
                                Uploaded Files
                            </NavLink>
                        </nav>
                    </div>
                    <div className="kt-portlet__head-toolbar">
                        <div className="kt-portlet__head-wrapper">
                            <div className="kt-portlet__head-actions">
                                <button 
                                    className="btn btn-label-brand btn-icon-sm padding-8" 
                                    onClick={() => setShowVideoTutorials(true)}
                                    data-toggle="kt-popover"
                                    data-trigger="hover"
                                    data-placement="left"
                                    data-content="Video Tutorials">
                                    <i className="socicon-youtube la-2x padding-l5"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="kt-portlet__body kt-portlet__body--fit">
                   {loading ? (<Loading />) : param.type === 'upload' ? (
                        policies.length > 0 ? (
                            <BatchTable
                                policies={policies}
                                setLoading={setLoading}
                                removePolicy={removePolicyHandler}
                                editPolicy={editPolicyHandler}
                                reset={resetHandler}
                                save={saveHandler}
                            />
                        ) : (<Uploader setLoading={setLoading} onPoliciesCreated={setPolicies}/>)
                    ) : (
                        <BatchPolicies />                              
                    )}
                    {showVideoTutorials && (
                        <VideoTutorials
                            onCloseDrawer={closeDrawerHandler}
                            show={showVideoTutorials}
                            playlistId="PLMOvcHEgzkJuS-ZB5hhPTV06GtP6nGTn4"
                            title="Video Tutorials">
                        </VideoTutorials>
                    )}   
                </div>
            </div>
        </div>
    );

};

export default Policies;