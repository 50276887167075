import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import 'jquery-form';
import 'jquery-validation';
import '../../assets/scripts/bootstrap-modal';
import '../../assets/scripts/jquery-validation.init';
import * as helper from '../global/helper';
import CustomEditor from '../global/editor';
import AddQuickText from '../global/quickText';
import swal from 'sweetalert2';
import Select from 'react-select';
import Dropzone from '../global/dropzone';
import EmailPolicy from './emailPolicy';
import { 
  postMotorPolicy,
  postMarinePolicy,
  addPolicyDocuments, 
  renewPolicy,
  addPolicy,
  getMotorPolicy,
  getMarinePolicy,
  getPolicy,
  convertQuotationStatus} from '../api/policyAPI';
import { uploadFile, uploadFileList } from '../api/fileAPI';
import AddDocument from './addDocument';
import { extractData } from './helperFunctions';

const ConvertQuotation = (props) => {

    const [btnLoading,          setBtnLoading]          = useState(false);
    const [prevPolicy,          setPrevPolicy]          = useState(null);
    const [policyDetails,       setPolicyDetails]       = useState({SumInsured: props.quotation.SumInsured, Premium: props.quotation.Premium});
    const [file,                setFile]                = useState(null);
    const [documents,           setDocuments]           = useState([]);
    const [addDocument,         setAddDocument]         = useState(false);
    const [emailPolicyId,       setEmailPolicyId]       = useState(null);
    const [addQuickText,        setAddQuickText]        = useState(false);
    const [fillQuickText,       setFillQuickText]       = useState('');

    useEffect(() => {
        let ignore = false;
    
        $('#convert-quotation-modal').modal({
          backdrop: 'static'
        });
    
        $('#convert-quotation-modal').on('hidden.bs.modal', function () {
          $('.modal').hasClass('show') && $('body').addClass('modal-open');
          props.onDismissModal(null);        
        });  
    
        if (!ignore) { 
            if (props.quotation.PolicyID != 0 && props.quotation.IsBatch === 'N')
                fetchPolicy();
        }
        return () => {
          ignore = true;
        };
    
    }, []);

    useEffect(() => {  
        if (prevPolicy)
            fetchExtraData(); 
    }, [prevPolicy]);

    const fetchPolicy = async () => {
        try {
            const policy = await getPolicy(props.quotation.PolicyID);
            setPrevPolicy(policy);
        } catch (err) {
            swal.fire({
                icon              : 'error',
                titleText         : 'Error!',
                text              : err.message,
                buttonsStyling    : false,
                confirmButtonClass: 'btn btn-brand',
            });
        }
    }

    const fetchExtraData = async () => {
        try {
            if (prevPolicy.ExtraFields === 'MotorPolicy') {
            const motorPolicy = await getMotorPolicy(prevPolicy.ID);
            if (motorPolicy) {
                const { RegistrationNo, Make, Model, Type } = motorPolicy;
                setPolicyDetails((d) => {
                return { ...d, 
                    RegistrationNo, 
                    Make, 
                    Model, 
                    Type, 
                    MakeEditable  : Make ? true : false, 
                    ModelEditable : Model ? true : false, 
                    TypeEditable  : Type ? true : false
                };
                });
            };    
            }else if (prevPolicy.ExtraFields === 'MarinePolicy'){
            const marinePolicy = await getMarinePolicy(prevPolicy.ID);
            if (marinePolicy) {
                const { DeclarationBasis, PerBottomLimit, PerSendingLimit, IsAutoReminder } = marinePolicy;
                setPolicyDetails((d) => {
                return { ...d, DeclarationBasis, PerBottomLimit, PerSendingLimit, IsAutoReminder };
                });
            };
            }
        } catch (err) {
            swal.fire({
                icon              : 'error',
                titleText         : 'Error!',
                text              : err.message,
                buttonsStyling    : false,
                confirmButtonClass: 'btn btn-brand',
            });
        }
    }

    const fileHandler = async (f) => {
        setFile(f);
        if (f.type === 'application/pdf') {
            try {
              swal.fire({
                titleText           : 'Extracting Data',
                text                : 'Please Wait...',
                showConfirmButton   : false,
                onOpen: () => {
                    swal.showLoading();
                }
              });
              const data = await extractData(f);
              if (data) {
                if (data.PolicyNo && !policyDetails.PolicyNo)
                  updatePolicyDetails("PolicyNo", data.PolicyNo);
                if (data.FromDate && !policyDetails.FromDate)
                  updatePolicyDetails("FromDate", data.FromDate);
                if (data.ToDate && !policyDetails.ToDate)
                  updatePolicyDetails("ToDate", data.ToDate);
                if (data.IssueDate && !policyDetails.IssueDate)
                  updatePolicyDetails("IssueDate", data.IssueDate);

                if (props.quotation.ExtraFields === 'MotorPolicy') {
                    if (data.RegistrationNo && !policyDetails.RegistrationNo)
                        updatePolicyDetails("RegistrationNo", data.RegistrationNo);
                    if (data.Make && !policyDetails.Make)
                        updatePolicyDetails("Make", data.Make);
                    if (data.Model && !policyDetails.Model)
                        updatePolicyDetails("Model", data.Model);
                    if (data.Type && !policyDetails.Type)
                        updatePolicyDetails("IssueDate", data.Type);
                }
                  
              }
              await swal.close();
            } catch (err) {
                await swal.close();
                console.error(err);
            }
        }
    }; 

    const removeFileHandler = (e) => {
        e.preventDefault();
        setFile(null);
        setPolicyDetails({SumInsured: props.quotation.SumInsured, Premium: props.quotation.Premium});
    }
    
    const updatePolicyDetails = (field, value) => {
        setPolicyDetails((d) => {
            return { ...d, [field]: value };
        });
    };
    
    const setEditorData = (html) => {
        updatePolicyDetails("AdditionalData", html) ;
    };

    const setDocumentHandler = (d) => {
        setDocuments((a) => {
          const newA = [...a];
          newA.push(d);
          return newA;
        });    
    };
    
    const openDocumentHandler = (index) => {
        const document = documents[index];
        const { DocumentFile } = document;

        if (document.DocumentType === 'AgentFile') {

            const ext       = helper.getFileExt(DocumentFile.name);
            const name      = (document.DocumentName + '.' + ext).replace(/ /g, '_');
            helper.downloadAndOpenFileHandler("AgentFile", DocumentFile.name, name)
        
        }else{       
            helper.openFileHandler(DocumentFile);
        }
    
    };
    
    const removeDocumentHandler = (index) => {
        setDocuments((a) => {
            const nA = a.filter((b, i) => index !== i);
            return nA;
        })
    };

    const refetchHandler = () => {
        props.refetchQuotations('convert', props.quotation);
        $('#convert-quotation-modal').modal('hide');   
    };

    const convertQuotationHandler = async (e) => {
        e.preventDefault();
  
        var form = $('#convert-quotation-form');
        form.validate().destroy();

        form.validate({
            rules: {
              policyNo: {
                required: true,
              },
              fromDate: {
                required: true,
              },
              toDate: {
                required: true,
              },
              issueDate: {
                required: true,
              },
              registrationNo: {
                required: true,
              },
              make: {
                required: true,
              },
              model: {
                required: true,
              },
            },
        });

        if (!form.valid()) {
            return;
        }

        try {
            setBtnLoading(true);

            if (!file) {
                swal.fire({
                  icon                  : 'error',
                  titleText             : 'Error!',
                  text                  : "Please upload a policy",
                  buttonsStyling        : false,
                  confirmButtonClass    : 'btn btn-brand',
                });
                setBtnLoading(false);
                return;
            }

            const policyFileName = await uploadFile(file, "Policy");
            let policyID = 0;

            if (prevPolicy) {
                policyID = await renewPolicy({
                    PreviousPolicyID      : prevPolicy.ID,
                    InsuranceCompanyID    : props.quotation.InsuranceCompanyID,
                    PolicyNo              : policyDetails.PolicyNo,
                    IssueDate             : policyDetails.IssueDate,
                    FromDate              : policyDetails.FromDate,
                    ToDate                : policyDetails.ToDate,
                    Premium               : parseInt(policyDetails.Premium),
                    SumInsured            : parseInt(policyDetails.SumInsured),
                    AdditionalData        : policyDetails.AdditionalData || '',
                    FileName              : policyFileName
                });   
            }else{
                policyID = await addPolicy({
                    InsuranceCompanyID    : props.quotation.InsuranceCompanyID,
                    ProductID             : props.quotation.ProductID,
                    ClientID              : props.quotation.ClientID,
                    PolicyNo              : policyDetails.PolicyNo,
                    BusinessType          : 'New',
                    IssueDate             : policyDetails.IssueDate,
                    FromDate              : policyDetails.FromDate,
                    ToDate                : policyDetails.ToDate,
                    Premium               : parseInt(policyDetails.Premium),
                    SumInsured            : parseInt(policyDetails.SumInsured),
                    AdditionalData        : policyDetails.AdditionalData || '',
                    FileName              : policyFileName,
                    BatchPolicyID         : (props.quotation.IsBatch === 'Y' && props.quotation.PolicyID) ? props.quotation.PolicyID : 0
                });
            }

            if (props.quotation.ExtraFields === 'MotorPolicy') {
                await postMotorPolicy({
                  PolicyID          : policyID,
                  RegistrationNo    : policyDetails.RegistrationNo,
                  Make              : policyDetails.Make,
                  Model             : policyDetails.Model,
                  Type              : policyDetails.Type || 'Petrol',
                });
              }else if (props.quotation.ExtraFields === 'MarinePolicy') {
                await postMarinePolicy({
                  PolicyID          : policyID,
                  DeclarationBasis  : policyDetails.DeclarationBasis,
                  PerBottomLimit    : parseInt(policyDetails.PerBottomLimit),
                  PerSendingLimit   : parseInt(policyDetails.PerSendingLimit),
                  IsAutoReminder    : policyDetails.IsAutoReminder,
                });
            }

            if (documents && documents.length > 0) {
  
                const uploadList = documents.filter(f => f.DocumentType === 'Upload');
                let fileList = [];
        
                if (uploadList.length > 0)
                    fileList = await uploadFileList(uploadList.map((d) => d.DocumentFile), "PolicyDocument");
        
                const postDocuments = [];
                documents.forEach(document => {
                  postDocuments.push({
                    PolicyID        : policyID,
                    FileName        : document.DocumentType === 'Upload' ? fileList.find(f => f.originalname === document.DocumentFile.name).filename : document.DocumentFile.name,
                    DocumentName    : document.DocumentName,
                    DocumentType    : document.DocumentType === 'Upload' ? 'PolicyDocument' : document.DocumentType
                  });
                });
                await addPolicyDocuments(postDocuments);     
            }
            await convertQuotationStatus(props.quotation.ID, policyID);
            setEmailPolicyId(policyID);
            $('#convert-quotation-modal').addClass('modal-blur');   
            swal.fire({
                icon              : 'success',
                titleText         : `Policy ${prevPolicy ? 'Renewed' : 'Added'} Successfully`,
                showConfirmButton : false,
                timer             : 1500,
            });
        } catch (err) {
            swal.fire({
              icon                  : 'error',
              titleText             : 'Error!',
              text                  : err.message,
              buttonsStyling        : false,
              confirmButtonClass    : 'btn btn-brand',
            });
            setBtnLoading(false);
            return;
        }
    };


    return(
        <React.Fragment>
            <div
                className="modal fade"
                id="convert-quotation-modal"
                tabIndex="-1"
                role="dialog"
                aria-hidden="true">

                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{prevPolicy ? 'Renew Policy' : 'Convert To Policy'}</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                disabled={btnLoading}>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form id="convert-quotation-form" className="kt-form" onSubmit={(e) => e.preventDefault()}>
                                {prevPolicy && (
                                    <div className='kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded'>
                                        <table className='kt-datatable__table table-striped'>
                                            <tbody className='kt-datatable__body' style={{ display: 'block' }}>
                                                <tr className='kt-datatable__row block-row'>
                                                    <td className="kt-datatable__cell text-clip" width="35%">
                                                        <div className="kt-user-card-v2">
                                                            <div className="kt-user-card-v2__pic">
                                                            <img src={prevPolicy.ProductIcon} alt="product"/>
                                                            </div>
                                                            <div className="kt-user-card-v2__details">
                                                                <span className="kt-user-card-v2__name" title={prevPolicy.PolicyNo}>{prevPolicy.PolicyNo}</span>
                                                                <span className="kt-user-card-v2__desc">
                                                                    <span title={prevPolicy.InsuranceCompanyName}>{prevPolicy.InsuranceCompanyName}</span><br/>
                                                                    <span title={prevPolicy.ProductName}>{prevPolicy.ProductName}</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="kt-datatable__cell text-clip" title={prevPolicy.ClientName}>
                                                        <div className="kt-user-card-v2">
                                                            <div className="kt-user-card-v2__details">
                                                                <span className="kt-user-card-v2__desc">Insured Name:</span>
                                                                <span className="kt-user-card-v2__name">{prevPolicy.ClientName}</span>
                                                
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="kt-datatable__cell text-clip" title={moment(prevPolicy.ToDate).format('DD-MMM-YYYY')}>
                                                            <div className="kt-user-card-v2">
                                                            <div className="kt-user-card-v2__details">
                                                                <span className="kt-user-card-v2__desc">End Date:</span>
                                                                <span className="kt-user-card-v2__name">{moment(prevPolicy.ToDate).format('DD/MM/YYYY')}</span>
                                                
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="kt-datatable__cell text-clip" title={prevPolicy.Status}>
                                                        <div className={`policy-status block-badge mr-1 badge badge-${prevPolicy.Status === 'Renewal Due' ? 'secondary' : (prevPolicy.Status === 'Expired' ? 'label-danger' : 'warning')}`}>
                                                            <span>{prevPolicy.Status}</span>
                                                        </div>
                                                    </td> 
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                                <div className='kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded'>
                                    <table className='kt-datatable__table table-striped'>
                                        <tbody className='kt-datatable__body' style={{ display: 'block' }}>
                                            <tr className='kt-datatable__row block-row'>
                                                <td className="kt-datatable__cell text-clip" width='35%'>
                                                    <div className="kt-user-card-v2">
                                                        <div className="kt-user-card-v2__pic">
                                                        <img src={props.quotation.ProductIcon} alt="product"/>
                                                        </div>
                                                        <div className="kt-user-card-v2__details">
                                                            <span className="kt-user-card-v2__name" title={props.quotation.InsuranceCompanyName}>{props.quotation.InsuranceCompanyName}</span>
                                                            <span className="kt-user-card-v2__desc">
                                                                <span title={props.quotation.ProductName}>{props.quotation.ProductName}</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="kt-datatable__cell text-clip" title={props.quotation.DisplayName}>
                                                    <div className="kt-user-card-v2">
                                                        <div className="kt-user-card-v2__details">
                                                            <span className="kt-user-card-v2__desc">Insured Name:</span>
                                                            <span className="kt-user-card-v2__name">{props.quotation.DisplayName}</span>
                                            
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="kt-datatable__cell text-clip" title={policyDetails.SumInsured}>
                                                    <div className="kt-user-card-v2">
                                                        <div className="kt-user-card-v2__details">
                                                            <span className="kt-user-card-v2__desc">Sum Insured:</span>
                                                            <span className="kt-user-card-v2__name">{policyDetails.SumInsured}</span>
                                            
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="kt-datatable__cell text-clip" title={policyDetails.Premium}>
                                                    <div className="kt-user-card-v2">
                                                        <div className="kt-user-card-v2__details">
                                                            <span className="kt-user-card-v2__desc">Net Premium:</span>
                                                            <span className="kt-user-card-v2__name">{policyDetails.Premium}</span>
                                            
                                                        </div>
                                                    </div>
                                                </td>                                             
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                
                                {file ? (
                                    <React.Fragment>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="kt-notification margin-b30" style={{backgroundColor: '#f7f8fa'}}>
                                                    <a className="kt-notification__item">
                                                        <div className="kt-notification__item-icon margin-r10" onClick={() => helper.openFileHandler(file)}>
                                                            <img height="30px" width="30px" src={helper.getFileLogo(file.name)} />
                                                        </div>
                                                        <div className="kt-notification__item-details" onClick={() => helper.openFileHandler(file)}>
                                                            <div className="kt-notification__item-title kt-font-bold">
                                                                {file.name}
                                                            </div>
                                                        </div>
                                                        <button 
                                                            type="button"                    
                                                            onClick={removeFileHandler}
                                                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                                            <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                        </button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4 margin-b0">
                                                <div className="form-group">
                                                    <label htmlFor="policyNo">Policy No.</label>
                                                    <input
                                                        id="policyNo"
                                                        value={policyDetails.PolicyNo || ''}
                                                        name="policyNo"
                                                        className="form-control margin-b10"
                                                        type="text"
                                                        onChange={(event) => updatePolicyDetails('PolicyNo', event.target.value)}               
                                                    />                     
                                                </div>
                                            </div>
                                            <div className="col-md-4 margin-b0">
                                                <div className="form-group">
                                                    <label htmlFor="sumInsured">Sum Insured</label>
                                                    <input
                                                        id="sumInsured"
                                                        value={policyDetails.SumInsured || ''}
                                                        name="sumInsured"
                                                        className="form-control margin-b10"
                                                        type="number"
                                                        onChange={(event) => updatePolicyDetails('SumInsured', event.target.value)}                                                   
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 margin-b0">
                                                <div className="form-group">
                                                    <label htmlFor="premium">Net Premium (Excluding tax)</label>
                                                    <input
                                                        id="premium"
                                                        value={policyDetails.Premium || ''}
                                                        name="premium"
                                                        className="form-control margin-b10"
                                                        type="number"
                                                        onChange={(event) => updatePolicyDetails('Premium', event.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4 margin-b0">
                                                <div className="form-group">
                                                    <label htmlFor="fromDate">Start Date</label>
                                                    <input
                                                        id="fromDate"
                                                        value={policyDetails.FromDate || ''}
                                                        name="fromDate"
                                                        className="form-control margin-b10"
                                                        type="date"
                                                        onChange={(event) => updatePolicyDetails('FromDate', event.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 margin-b0">
                                                <div className="form-group">
                                                    <label htmlFor="toDate">End Date</label>
                                                    <input
                                                        id="toDate"
                                                        value={policyDetails.ToDate || ''}
                                                        name="toDate"
                                                        className="form-control margin-b10"
                                                        type="date"
                                                        onChange={(event) => updatePolicyDetails('ToDate', event.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 margin-b0">
                                                <div className="form-group">
                                                    <label htmlFor="issueDate">Issued On</label>
                                                    <input
                                                        id="issueDate"
                                                        value={policyDetails.IssueDate || ''}
                                                        name="issueDate"
                                                        className="form-control margin-b10"
                                                        type="date"
                                                        onChange={(event) => updatePolicyDetails('IssueDate', event.target.value)}                                            
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {props.quotation.ExtraFields === 'MotorPolicy' && (
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label htmlFor="registrationNo">Registration No.</label>
                                                        <input
                                                            id="registrationNo"
                                                            value={policyDetails.RegistrationNo || ''}
                                                            name="registrationNo"
                                                            className="form-control margin-b10"
                                                            type="text"
                                                            onChange={(event) => updatePolicyDetails('RegistrationNo', event.target.value)}               
                                                        />                     
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label htmlFor="make">Make</label>
                                                        <input
                                                            id="make"
                                                            value={policyDetails.Make || ''}
                                                            name="make"
                                                            className="form-control margin-b10"
                                                            type="text"
                                                            readOnly={policyDetails.MakeEditable}
                                                            onChange={(event) => !policyDetails.MakeEditable && updatePolicyDetails('Make', event.target.value)}   
                                                        />                     
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label htmlFor="model">Model</label>
                                                        <input
                                                            id="model"
                                                            value={policyDetails.Model || ''}
                                                            name="model"
                                                            className="form-control margin-b10"
                                                            type="text"
                                                            readOnly={policyDetails.ModelEditable}
                                                            onChange={(event) => !policyDetails.ModelEditable && updatePolicyDetails('Model', event.target.value)}  
                                                        />                     
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label htmlFor="ddlType">Type</label>
                                                        <select
                                                            className="form-control kt-selectpicker"
                                                            id="ddlType"
                                                            value={policyDetails.Type || 'Petrol'}
                                                            readOnly={policyDetails.TypeEditable}
                                                            disabled={policyDetails.TypeEditable}
                                                            onChange={(event) => !policyDetails.TypeEditable && updatePolicyDetails('Type', event.target.value)}>                                            
                                                            <option value="Petrol">Petrol</option>
                                                            <option value="Diesel">Diesel</option>
                                                            <option value="Electric">Electric</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {props.quotation.ExtraFields === 'MarinePolicy'  && (
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="ddlDeclarationBasis">Declaration Basis</label>
                                                        <select
                                                            className="form-control kt-selectpicker"
                                                            id="ddlDeclarationBasis"
                                                            onChange={(event) => updatePolicyDetails('DeclarationBasis', event.target.value)}>                                                 
                                                            <option value="Monthly">Monthly</option>
                                                            <option value="Certificate Issuance">Certificate Issuance</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="perBottomLimit">Per Bottom Limit</label>
                                                        <input
                                                            id="perBottomLimit"
                                                            value={policyDetails.PerBottomLimit || ''}
                                                            name="perBottomLimit"
                                                            className="form-control margin-b10"
                                                            type="number"
                                                            onChange={(event) => updatePolicyDetails('PerBottomLimit', event.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="perBottomLimit">Per Sending Limit</label>
                                                        <input
                                                            id="perSendingLimit"
                                                            value={policyDetails.PerSendingLimit || ''}
                                                            name="perSendingLimit"
                                                            className="form-control margin-b10"
                                                            type="number"
                                                            onChange={(event) => updatePolicyDetails('PerSendingLimit', event.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div className="row">
                                            <div className="col-md-12 margin-b20">
                                                <CustomEditor
                                                    placeholder="Additional Details"
                                                    addQuickText={setAddQuickText}
                                                    fillQuickText={fillQuickText}
                                                    setFillQuickText={setFillQuickText}
                                                    setData={setEditorData}
                                                />     
                                            </div>
                                        </div>
                                        {documents && documents.length > 0 && (
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="kt-notification margin-b30">
                                                        {
                                                            documents.map((d, i) =>
                                                            <a key={i} className="kt-notification__item">
                                                                <div className="kt-notification__item-icon margin-r10" onClick={() => openDocumentHandler(i)}>
                                                                <img height="30px" width="30px" src={helper.getFileLogo(d.DocumentFile.name)} />
                                                                </div>
                                                                <div className="kt-notification__item-details" onClick={() => openDocumentHandler(i)}>
                                                                    <div className="kt-notification__item-title kt-font-bold">
                                                                        {d.DocumentName}
                                                                    </div>
                                                                </div>
                                                                <button 
                                                                    type="button"                    
                                                                    onClick={() => removeDocumentHandler(i)}
                                                                    className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                                                    <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                                </button>
                                                            </a>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <Dropzone fileHandler={fileHandler} prompt="Drop policy file here or click to select"/>
                                )}
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary margin-0 margin-r5"
                                data-dismiss="modal"
                                disabled={btnLoading}>    
                                Close
                            </button>
                            {file && (
                                <button
                                    onClick={() => setAddDocument(true)}
                                    type="button"
                                    className="btn btn-label-brand margin-0 margin-r5"
                                    disabled={btnLoading} >                 
                                    <i className="flaticon-doc"></i> 
                                    Attach Documents
                                </button>
                            )}
                            <button
                                onClick={convertQuotationHandler}
                                type="button"
                                className={`btn btn-brand margin-0 ${
                                btnLoading
                                    ? 'kt-spinner kt-spinner--sm kt-spinner--light'
                                    : ''
                                }`}
                                disabled={btnLoading}> 
                                {prevPolicy ? 'Renew' : 'Convert'}
                            </button>
                        </div>
                    </div>               
                </div>       
            </div>

            {(file && addDocument) && (
                <AddDocument
                    setDocumentHandler={setDocumentHandler}
                    addDocument={setAddDocument}
                    from="#convert-quotation-modal"  
                />
            )}

            {emailPolicyId && (
                <EmailPolicy
                    action="a"
                    groupId={props.quotation.ClientGroupID}
                    policyId={emailPolicyId}
                    refetchPolicies={refetchHandler}
                />
            )}
        
            {addQuickText && (
                <AddQuickText
                    fillQuickText={setFillQuickText}
                    addQuickText={setAddQuickText}
                    from="#convert-quotation-modal"
                />
            )} 

        </React.Fragment>
    );    
};

export default ConvertQuotation;