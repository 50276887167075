import React, { useEffect, useState } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import $ from 'jquery'
import 'jquery-form'
import 'jquery-validation'
import '../../assets/scripts/jquery-validation.init'
import * as helper from '../global/helper'
import swal from "sweetalert2";
import { getAgentByTempPassword, confirmAgentAccount } from "../api/agentAPI";

const setPassword = () => {

    const match                            = useRouteMatch()
    const history                          = useHistory()
    const { uid }                          = match.params;
    const [user,         setUser]          = useState({});
    const [password,     setPasswordValue] = useState('')
    const [showPassword, setShowPassword]  = useState(false);

    useEffect(()=>{
        let ignore = false;
        const fetchUser = async() => {
            try {
                // fetch the user by uid 
                const response = await getAgentByTempPassword( uid );
                if(response) setUser( response );   
            } catch (err) {
                swal.fire({
                    toast               : true,
                    icon                : 'error',
                    titleText           : err.message,
                    position            : 'bottom-end',
                    showConfirmButton   : false,
                    timer               : 2500,
                    animation           : true,
                    customClass         : { popup: 'margin-20', },
                });
                history.push("/")
            }
        }
        if(!ignore)fetchUser();
        return()=>{
            ignore = true
        }
    } ,[uid])


    const showPasswordHandler = () => setShowPassword(!showPassword);

    const submitHandler = async event => {
        event.preventDefault()
        const btn = $('#submit')
        const form = $('.kt-form')
        form.validate({ rules: { password: { required: true, minlength: 6, maxlength: 50 } } })
        if (!form.valid()) return;

        helper.StartProcessing(btn)
        try {
            await confirmAgentAccount({ ...user, uid, password });
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : err.message,
                animation           : true,
                customClass         : { popup: 'margin-20', },
            });
            return helper.StopProcessing(btn);
        }

        //SHOW SUCCESS MSG AND REDIRECT TO LOGIN
        swal.fire({
            icon                : 'success',
            title               : 'Password Created Succesfully',
            text                : 'Kindly Login',  
            timer               : 2000,
            animation           : true,
            customClass         : { popup: 'margin-20', },
        });
        history.push("/")
    }

    return (
        <div className="kt-login__signin" >
            <div className="kt-login__head">
                <h4 className="kt-login__title">Hi, {user.FirstName ? user.FirstName : 'User'}</h4>
                <div className="kt-login__desc">Confirm your account linked to <b>{user.Email}</b>. <br/> Please set you password to continue.</div>
            </div>
            <form className="kt-form" onSubmit={submitHandler} >
                <div className="input-group">
                    <div className="kt-input-icon kt-input-icon--right">
                        <input 
                            name="password" id="password" 
                            className="form-control password-input form-control-lg" 
                            onChange={({ target }) => setPasswordValue( target.value.trim() )} 
                            type={showPassword ? 'text':'password'}
                            placeholder=" Create Password" />

                        <span className="kt-input-icon__icon kt-input-icon__icon--right show-password-icon" onClick={ showPasswordHandler } >
                            <span>  <i className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}></i> </span>
                        </span>
                    </div>
                </div>
               
                <button style={{ width: '100%', marginTop: '20px' }} className="btn btn-primary" id="submit" >Confirm account</button>
            </form>
        </div>
    )
}

export default setPassword