import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import 'jquery-form';
import 'jquery-validation';
import '../../assets/scripts/bootstrap-modal';
import '../../assets/scripts/jquery-validation.init';
import * as helper from '../global/helper';
import swal from 'sweetalert2';
import { addFileCategory } from '../api/fileManagementAPI';

const AddFileCategory = (props) => {

    const [name, setName] = useState('');

    useEffect(() => {
        let ignore = false;

        if (!ignore) {
            $('#add-fileCategory-modal').on('hidden.bs.modal', function () {
                $('.modal').hasClass('show') && $('body').addClass('modal-open');
                $(props.from).removeClass('modal-blur');
                var form = $('#add-fileCategory-form');
                form.clearForm();
                form.validate().resetForm();
                setName('');
            });
        }

        return () => {
            ignore = true;
        };
    }, []);

    const addFileCategories = async (e) => {
        e.preventDefault();

        var form = $('#add-fileCategory-form');
        form.validate().destroy();

        form.validate({
            rules: {
                name: {
                    required: true,
                }
            },
        });

        if(!form.valid()) {
            if ($('#name-error').text() !== '') {
                $('#name').addClass('is-invalid');
            }
            return;
        }

        try {
            helper.StartProcessing($('#save-fileCategory'));
            
            const ID = await addFileCategory(name);
            props.addFileCategoryId(ID);

            helper.StopProcessing($('#save-fileCategory'));

            $('#add-fileCategory-modal').modal('hide');
                swal.fire({
                toast               : true,
                icon                : 'success',
                titleText           : `File Type Added successfully!`,
                position            : 'bottom-end',
                showConfirmButton   : false,
                timer               : 1500,
                animation           : false,
                customClass         : {
                    popup: 'margin-20',
                },
            });

        } catch (err) {
            swal.fire({
              icon              : 'error',
              titleText         : 'Error!',
              text              : err.message,
              buttonsStyling    : false,
              confirmButtonClass: 'btn btn-brand',
            });
            helper.StopProcessing($('#save-fileCategory'));
            return;
        }
    };

    return (
        <div
            className="modal fade"
            id="add-fileCategory-modal"
            tabIndex="-1"
            role="dialog"
            aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">
                            Add File Type
                        </h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close">
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="client-container">
                            <form id="add-fileCategory-form" onSubmit={addFileCategories}>
                                <div className="row">
                                    <div className="col-12">
                                        <input
                                            id="name"
                                            value={name || ''}
                                            name="name"
                                            placeholder="File Type Name"
                                            className="form-control margin-b10"
                                            type="text"
                                            onChange={({ target: { value } }) => 
                                                setName(helper.capitalize(value))}
                                            />  
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary margin-0 margin-r5"
                            data-dismiss="modal">   
                            Close
                        </button>
                        <button
                            id="save-fileCategory"
                            onClick={addFileCategories}
                            type="button"
                            className="btn btn-brand margin-0">   
                            Save
                        </button>
                    </div>
                </div>
            </div>

        </div>
    )

}

export default AddFileCategory;