import { axiosGet, axiosDelete, axiosPatch } from "./axiosFunctions";
import { getTokenOrLogout } from "./common";

const getNotificationLogsUrl  = '/api/notificationlog/getNotificationLogByAgentId';
const updateNotificationLogUrl  = '/api/notificationlog/updateNotificationLogByAgentId';
const deleteAllNotificationLogsUrl  = '/api/notificationlog/deleteAllNotificationLog';
const deleteNotificationLogUrl  = '/api/notificationlog/deleteNotificationLog';


export const getNotificationLogs = async (agentId) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    const response = await axiosGet(`${getNotificationLogsUrl}/${agentId}`,{
        headers: { auth_token: `bearer ${token}` }
    })

    return response;
}

export const updateNotificationLogs = async () => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);

    const response = await axiosPatch(updateNotificationLogUrl,{},{
        headers: { auth_token: `bearer ${token}` }
    })

    return response;
}

export const deleteAllNotificationLogs = async () => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);

    const response = await axiosDelete(deleteAllNotificationLogsUrl,{
        headers: { auth_token: `bearer ${token}` }
    })

    return response;
}

export const deleteNotificationLog = async (alertId) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);

    const response = await axiosDelete(`${deleteNotificationLogUrl}/${alertId}`,{
        headers: { auth_token: `bearer ${token}` }
    })
    
    return response;
}


