import { initializeApp } from "firebase/app";
import { GoogleAuthProvider } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC-5DEhw-4Dk3WbPQSvjRfzW-5CsdAC_MI",
    authDomain: "auth.insurebox.co",
    projectId: "insurebox-93438",
    appId: "1:704008598075:web:2c55a1ec6003655808a21e"
};

// Initialize Firebase
initializeApp(firebaseConfig);

export const googleProvider = new GoogleAuthProvider();