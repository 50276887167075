import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import 'jquery-form';
import 'jquery-validation';
import '../../assets/scripts/jquery-validation.init';
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import { prevYearPeriods } from '../../assets/scripts/periodSelection';
import Loading from '../global/loading';
import { getSearchPolicyStats, updateBatchPolicy } from '../api/policyAPI';
import NoData from '../global/noData';
import PolicyImg from '../../assets/images/no-policy.svg'
import { PolicyDetails } from './policyStats';
import EmailPolicy from './emailPolicy';
import { EditBatchPolicy } from '../batchUpload/policyUpload';
import EditPolicy from './editPolicy';
import RenewPolicy from './renewPolicy';

const SearchPolicy = (props) => {
    const [loading,             setLoading]             = useState(false);
    const [currentPeriod,       setCurrentPeriod]       = useState(prevYearPeriods[0]);
    const [fromDate,            setFromDate]            = useState(prevYearPeriods[0].from);
    const [toDate,              setToDate]              = useState(prevYearPeriods[0].to);
    const [searchString,        setSearchString]        = useState('');
    const [policies,            setPolicies]            = useState([]);
    const [selectedPolicy,      setSelectedPolicy]      = useState(null);
    const [showPolicyDetails,   setShowPolicyDetails]   = useState(false);
    const [emailPolicy,         setEmailPolicy]         = useState(null);
    const [refresh,             setRefresh]             = useState(false);
    const [editPolicy,          setEditPolicy]          = useState(null);
    const [renewPolicy,         setRenewPolicy]         = useState(null);
    const [firstFetch,          setFirstFetch]          = useState(true);

    useEffect(() => {
        $('#search-policy-modal').modal({ backdrop: 'static'});

        $('#search-policy-modal').on('hidden.bs.modal', function () {
            props.onDismissModal('searchPolicy', false);
        });
    }, [props.show]);

    useEffect(() => {
        if (!firstFetch)
            fetchPolicies();
    }, [props.update, refresh])

    useEffect(() => {
        setShowPolicyDetails(true);
    }, [selectedPolicy])

    useEffect(() => {
        if(!showPolicyDetails)
            setSelectedPolicy(null)
    }, [showPolicyDetails]);

    useEffect(() => {
        if (editPolicy && editPolicy.IsBatch) {
            $('#edit-batch-policy-modal').modal({
                backdrop: 'static',
            });
            $('#edit-batch-policy-modal').on('hidden.bs.modal', function () {
                setEditPolicy(null);
            });
            $('#search-policy-modal').addClass('modal-blur');  
        }else if (!editPolicy) {
            $('#edit-batch-policy-modal').modal('hide');
            $('#search-policy-modal').removeClass('modal-blur');  
        }
    }, [editPolicy]);

    const shareInfo = (p) => {
        const mobile = p.ClientMobile ? p.ClientMobile : '';
        const toDate = moment(p.ToDate).format('DD-MM-YYYY');
        const fromDate = moment(p.FromDate).format('DD-MM-YYYY');
        try {
            // Construct the message to share
            const message = `
            Hi *${p.DisplayName}*,
    
Below policy expiring on *${toDate}*

Insured Name               : ${p.DisplayName}
Product                         : ${p.ProductName}
Insurance Company      : ${p.InsuranceCompanyName}
Policy Number	      : ${p.PolicyNo}
Start Date	              : ${fromDate}
End Date	                      : ${toDate}
Thank you
            `;

            if (p.ClientMobile) {
                // whatsappWebUrl = `https://web.whatsapp.com/send?phone=${mobile}&text=${encodeURIComponent(message)}`;
                window.location.href = `whatsapp://send?phone=${mobile}&text=${encodeURIComponent(message)}`;
            } else {
                window.location.href = `whatsapp://send?text=${encodeURIComponent(message)}`;
                // whatsappWebUrl = `https://web.whatsapp.com/send?text=${encodeURIComponent(message)}`;
            }
        } catch (error) {
            swal.fire({
                icon: 'error',
                titleText: 'Error!',
                text: error,
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-brand',
            });
        }
    };

    const changePeriodHandler = ({ target }) => {
        const periodList = prevYearPeriods;
        const period = periodList.find(p => p.id == target.value)
        setCurrentPeriod(period);   
        setFromDate(period.from);
        setToDate(period.to)        
    };

    const openFile = async (policy) => {
        const ext       = helper.getFileExt(policy.FileName);
        const name      = (policy.InsuranceCompanyName + ' Policy.' + ext).replace(/ /g, '_');
        helper.downloadAndOpenFileHandler("Policy", policy.FileName, name);                                                    
    }

    const downloadFile = async (policy) => {
        const ext       = helper.getFileExt(policy.FileName);
        const name      = (policy.InsuranceCompanyName + ' Policy.' + ext).replace(/ /g, '_');
        helper.downloadFileToClientHandler("Policy", policy.FileName, name);                                                    
    }

    const updateBatchPolicyHandler = async (p) => {
        try {
            await updateBatchPolicy(p);
            setRefresh((r) => !r);  
        } catch (err) {
            return swal.fire({ 
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
        }    
    };
  
    const renewPolicyHandler = (p) => {
        if (p.IsBatch){
            props.onOpenModal('policy', true, 'searchPolicy', false, { batchPolicy: p });
        }   
        else setRenewPolicy(p);            
    };

    const createQuoteHandler = (p) => {
        if (p.IsBatch)
            props.onOpenModal('quotation', true, 'searchPolicy', false, { batchPolicy: p });
        else
            props.onOpenModal('quotation', true, 'searchPolicy', false, { policy: p });              
    };

    const searchPolicyHandler = () => {
        var form = $('#search-policy-form');
        form.validate().destroy();

        form.validate({
            rules: {
                generalSearch: {
                    required: true,
                },
            },
        })

        if (!form.valid()) {
            return;
        }

        fetchPolicies();
    }

    const fetchPolicies = async () => {
        try {
            setLoading(true);
            helper.StartProcessing($('#btnSearchPolicy'));
            const response = await getSearchPolicyStats(fromDate, toDate, searchString)
            setPolicies(response);
            helper.StopProcessing($('#btnSearchPolicy'));
            setLoading(false);
            setFirstFetch(false);
        } catch (err) {
            swal.fire({
              icon                  : 'error',
              titleText             : 'Error!',
              text                  : err.message,
              buttonsStyling        : false,
              confirmButtonClass    : 'btn btn-brand',
            });
            helper.StopProcessing($('#btnSearchPolicy'));
            setLoading(false);
            return;
        }
    }

    const copyText = (event, policyNo) => {
        event.stopPropagation()
        navigator.clipboard.writeText(policyNo);
        const btn =  $('.copyButton')
        btn.tooltip({
            trigger: 'click',
            placement: 'bottom',
            title:"Copied",
        });
        hideTooltip(btn);
          
        function hideTooltip(btn) {
            setTimeout(function() {
                btn.tooltip('hide');
            }, 500);
        }
    }

    return (
        <React.Fragment>
            <div
                className="modal fade"
                id="search-policy-modal"
                tabIndex="-1"
                role="dialog"
                aria-hidden="true">
                
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Search Policy
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                            </button>
                        </div>
                        <div className="modal-body">
                            <form id="search-policy-form" className="kt-form">
                                <div className="kt-form kt-form--label-right kt-margin-b-10">
                                    <div className="row align-items-center stickyFilter">
                                        <div className="col-xl-12 order-2 order-xl-1">
                                            <div className="row">
                                                <div className={`col-md-${currentPeriod.id == 6 ? '3' : '6'} kt-margin-b-20-tablet-and-mobile`}>
                                                    <div className="kt-input-icon kt-input-icon--left">
                                                        <input                                      
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Search Policy..."
                                                            id="generalSearch"
                                                            name="generalSearch"
                                                            value={searchString || ''}
                                                            onChange={(e) => setSearchString(e.target.value)}
                                                            >
                                                        </input>
                                                        <span className="kt-input-icon__icon kt-input-icon__icon--left policy-search-input-icon">
                                                            <span>
                                                                <i className="la la-search"></i>
                                                            </span>
                                                        </span>
                                                        {searchString && (
                                                            <span className="kt-input-icon__icon kt-input-icon__icon--right">
                                                                <span>
                                                                    <i className="la la-close" onClick={() => setSearchString('')}></i>
                                                                </span>
                                                            </span>
                                                        )}
                                                    </div>
                                                </div> 
                                                <div className={`col-md-${currentPeriod.id == 6 ? '3' : '5'} kt-margin-b-20-tablet-and-mobile`}>
                                                    <div className="kt-form__group">
                                                        <div className="kt-form__control">
                                                            <select                                              
                                                                className="form-control kt-selectpicker"
                                                                value={currentPeriod.id}
                                                                onChange={changePeriodHandler}>                                                        
                                                                {prevYearPeriods.map((s) => (
                                                                    <option value={s.id} key={s.id}>
                                                                        {s.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {currentPeriod.id == 6 && (
                                                    <div className="col-md-5 kt-margin-b-20-tablet-and-mobile">
                                                        <div className="row align-items-center">
                                                            <div className="col-md-12">
                                                                <div className="row">
                                                                    <div className="col-md-5 margin-sm-b20 padding-lr0">
                                                                        <div className="kt-form__group">
                                                                            <div className="kt-form__control kt-form__group--inline">
                                                                                <input
                                                                                    id="fromDate"
                                                                                    value={fromDate || ''}
                                                                                    max={moment().format('YYYY-MM-DD')}
                                                                                    name="fromDate"
                                                                                    className="form-control"
                                                                                    type="date"
                                                                                    onChange={(event) => setFromDate(event.target.value)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 text-center d-none d-md-block padding-lr0">
                                                                        <div className="kt-form__group margin-t5">
                                                                            <div className="kt-form__control kt-form__group--inline">
                                                                                <label>To</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-5 margin-sm-b20 padding-lr0">
                                                                        <div className="kt-form__group">
                                                                            <div className="kt-form__control kt-form__group--inline">
                                                                                <input
                                                                                    id="toDate"
                                                                                    value={toDate || ''}
                                                                                    max={moment().format('YYYY-MM-DD')}
                                                                                    name="toDate"
                                                                                    className="form-control"
                                                                                    type="date"
                                                                                    onChange={(event) => setToDate(event.target.value)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>                                   
                                                    </div>
                                                )} 
                                                <div className="col-md-1 text-center">
                                                    <button className="btn btn-brand btn-block"
                                                        id="btnSearchPolicy"
                                                        onClick={searchPolicyHandler}
                                                    >
                                                        <i className="la la-search"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {loading ? (<Loading/>) : (
                                        policies.length > 0 ? (
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
                                                        <table className="kt-datatable__table">
                                                            <thead
                                                                className="kt-datatable__head"
                                                                style={{ display: 'block' }}>
                        
                                                                <tr className="kt-datatable__row block-row">
                                                                    <th className="kt-datatable__cell text-clip" width="16%">
                                                                        <span className="padding-l10">Policy Number</span>
                                                                    </th>
                                                                    <th className="kt-datatable__cell text-clip" width="16%">
                                                                        <span className="padding-l10">Insured Name</span>
                                                                    </th>
                                                                    <th className="kt-datatable__cell text-clip" width="15%">        
                                                                        <span>Client Name</span>
                                                                    </th>
                                                                    <th className="kt-datatable__cell text-clip" width="10%">
                                                                        <span>End Date</span>
                                                                    </th>
                                                                    <th className="kt-datatable__cell text-clip" width="15%">
                                                                        <span>Company</span>
                                                                    </th>
                                                                    <th className="kt-datatable__cell--center kt-datatable__cell text-clip" width="10%">
                                                                        <span>Business</span>
                                                                    </th>
                                                                    <th className="kt-datatable__cell--center kt-datatable__cell text-clip" width="13%">
                                                                        <span>Status</span>
                                                                    </th>
                                                                    <th className="kt-datatable__cell--center kt-datatable__cell text-clip" width="5%">
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                        </table>
                                                    </div>
                                                    <div 
                                                        className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded scrolTable" >
                                                        <table className="kt-datatable__table table-striped">
                                                            <tbody
                                                                className="kt-datatable__body"
                                                                style={{ display: 'block', minHeight:'40vh' }}>
                                                                {policies.map((p, i) => (
                                                                    <tr
                                                                        key={i}
                                                                        className={`kt-datatable__row block-row ${!p.IsBatch && 'clickable'}`}>                                                   
                                                                        <td width="16%" className="kt-datatable__cell text-clip" onClick={() => !p.IsBatch && setSelectedPolicy(p)}>
                                                                            <div className="kt-user-card-v2">
                                                                                <div className="kt-user-card-v2__pic">
                                                                                    <img src={p.ProductIcon} alt="product"/>
                                                                                </div>
                                                                                <div className="kt-user-card-v2__details text-clip">
                                                                                    <button className="btn btn-secondary copyButton" type="button" onClick={(e) => copyText(e, p.PolicyNo)} data-clipboard-text="1" style={{}}>Copy</button>
                                                                                    <span className="kt-user-card-v2__name text-clip" title={p.PolicyNo}>
                                                                                        {p.PolicyNo}                  
                                                                                    </span>                                                               
                                                                                    <span className="kt-user-card-v2__desc text-clip" title={p.ProductName}>{p.ProductName}</span>
                                                                                </div>
                                                                                {(p.IsNew || p.QuotationStatusID == 1 || p.QuotationStatusID == 2) && (
                                                                                    <span className='kt-badge kt-badge--success kt-badge--inline kt-badge--pill policy-badge' title={p.IsNew ? 'New Policy' : p.QuotationStatus}>
                                                                                        {p.IsNew ? 'New' : (p.QuotationStatusID == 2 ? 'QS' : 'QC')}
                                                                                    </span>  
                                                                                )}                                                        
                                                                            </div>                                                       
                                                                        </td>
                                                                        <td width="16%" title={p.DisplayName} className="kt-datatable__cell text-clip" onClick={() => !p.IsBatch && setSelectedPolicy(p)}>
                                                                            <div className="kt-user-card-v2">
                                                                                <div className="kt-user-card-v2__pic">
                                                                                    <div
                                                                                        className={`img-small-profile kt-badge kt-badge--xl kt-badge--${
                                                                                        p.Type === 'Corporate' ? 'success' : p.IsBatch ? 'info-light' : 'info'
                                                                                        }`}>
                                                                                        {p.DisplayName.slice(0, 1)}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="kt-user-card-v2__details text-clip">
                                                                                    <button className="btn btn-secondary copyButton" type='button' onClick={(e) => copyText(e, p.DisplayName)} data-clipboard-text="1" style={{}}>Copy</button>
                                                                                    <span className="kt-user-card-v2__name text-clip">{p.DisplayName}</span>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td width="15%" title={p.Type === 'Corporate' ? p.ClientCompanyName : p.PrimaryClientName} className="kt-datatable__cell text-clip" onClick={() => !p.IsBatch && setSelectedPolicy(p)}>
                                                                            <span>{p.Type === 'Corporate' ? p.ClientCompanyName : p.PrimaryClientName}</span>
                                                                        </td>
                                                                        <td width="10%" title={moment(p.ToDate).format('DD/MM/YYYY')} className="kt-datatable__cell text-clip" onClick={() => !p.IsBatch && setSelectedPolicy(p)}>
                                                                            <span className="kt-font-bold">{moment(p.ToDate).format('DD/MM/YYYY')}</span>
                                                                        </td>
                                                                        <td width="15%" title={p.InsuranceCompanyName} className="kt-datatable__cell text-clip" onClick={() => !p.IsBatch && setSelectedPolicy(p)}>
                                                                            <span>{p.InsuranceCompanyName}</span>
                                                                        </td>
                                                                        <td width="10%" title={p.BusinessType} className="kt-datatable__cell--center kt-datatable__cell text-clip" onClick={() => !p.IsBatch &&  setSelectedPolicy(p)}>
                                                                            <span>{p.BusinessType}</span>
                                                                        </td>
                                                                        <td width="13%" title={p.Status} className="kt-datatable__cell text-clip" onClick={() => !p.IsBatch && setSelectedPolicy(p)}>
                                                                            <div className={`policy-status block-badge mr-1 badge badge-${p.Status === 'Renewal Due' ? 'secondary' : (p.Status === 'Policy Issued' ? 'primary' : (p.Status === 'Policy Sent' ? 'success' : ((p.Status === 'Expired' || p.Status === 'Renewed') ? 'label-danger' : (p.Status === 'Lapsed' ? '' : 'warning'))))}`}>
                                                                                <span>{p.Status}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td width="5%" className="kt-datatable__cell">
                                                                            <div className='dropdown'>
                                                                                <a className='btn btn-sm btn-clean btn-icon btn-icon-md' data-toggle='dropdown' aria-expanded='true'>
                                                                                    <i className="flaticon-more-1"></i>
                                                                                </a>
                                                                                <div className='dropdown-menu dropdown-menu-right' x-placement='top-end'>
                                                                                    {!p.IsBatch && (
                                                                                        <React.Fragment>
                                                                                            <a className='dropdown-item' onClick={() => openFile(p)}>
                                                                                                <i className='la la-file-text'></i>
                                                                                                View
                                                                                                </a>
                                                                                            <a className='dropdown-item' onClick={() => downloadFile(p)}>
                                                                                                <i className='la la-download'></i>
                                                                                                Download
                                                                                            </a>
                                                                                            <a className='dropdown-item' onClick={() => setEmailPolicy({policyId: p.ID, clientGroupID: p.ClientGroupID, type: 'policy'})}>
                                                                                                <i className='la la-envelope'></i>
                                                                                                Email
                                                                                            </a>
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                    {!p.IsActive == 'Renewed' && (
                                                                                        <a className='dropdown-item' onClick={() => setEditPolicy(p)}>
                                                                                            <i className='la la-edit'></i>
                                                                                            Edit
                                                                                        </a>
                                                                                    )}
                                                                                    <a className='dropdown-item' onClick={() => shareInfo(p)}>
                                                                                        <i className='la la-whatsapp'></i>
                                                                                        Send Reminder
                                                                                    </a>
                                                                                    {(!p.IsActive == 'Renewed' || p.IsRenewal || (p.IsExpired && p.StatusID != 3)) && (
                                                                                        <React.Fragment>
                                                                                            <a className='dropdown-item' onClick={() => renewPolicyHandler(p)}>
                                                                                                <i className='la la-rotate-left'></i>
                                                                                                Renew
                                                                                            </a>
                                                                                            <a className='dropdown-item' onClick={() => createQuoteHandler(p)}>
                                                                                                <i className='flaticon-interface-4'></i>
                                                                                                Create Quote
                                                                                            </a>  
                                                                                        </React.Fragment>
                                                                                    )}                                                                 
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <NoData
                                                src={PolicyImg}
                                                alt="Policy"
                                                message="No Policies Found"
                                            />
                                        )
                                    )}
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary margin-0 margin-r5"
                                data-dismiss="modal"
                                // onClick={()=>{resetTime()}}
                            >   
                            Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {selectedPolicy && (
                <React.Fragment>
                    {showPolicyDetails && (
                        <PolicyDetails
                            policy={selectedPolicy}
                            policyID={selectedPolicy.ID}
                            show={showPolicyDetails}
                            onDismissModal={setShowPolicyDetails}
                        />
                    )}
                </React.Fragment>
            )}
            {emailPolicy && (
                <EmailPolicy
                    action="e"
                    groupId={emailPolicy.clientGroupID}
                    policyId={emailPolicy.policyId}                  
                    refetchPolicies={() => setRefresh((r) => !r)}
                    onDismissModal={() => setEmailPolicy(null)}
                />
            )}
            {editPolicy && (
                <React.Fragment>
                    {editPolicy.IsBatch ? (
                        <EditBatchPolicy
                            policy={editPolicy}
                            updatePolicy={updateBatchPolicyHandler}
                            action="e"
                        />
                    ) : (
                        <EditPolicy
                            from="Policy"
                            policyDetails={editPolicy}
                            refetchPolicies={() => setRefresh((r) => !r)}
                            onDismissModal={() => setEditPolicy(null)}
                            onOpenModal={props.onOpenModal}
                            policyData={props.policyData}
                        />
                    )}
                </React.Fragment>
            )}
            {renewPolicy && (
                <RenewPolicy
                    policyDetails={renewPolicy}
                    refetchPolicies={() => setRefresh((r) => !r)}
                    onDismissModal={() => setRenewPolicy(null)}                    
                />
            )}        
        </React.Fragment>
    )
}

export default SearchPolicy