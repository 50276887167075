import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import 'jquery-form'
import 'jquery-validation'
import '../../assets/scripts/jquery-validation.init'
import * as helper from '../global/helper'
import swal from "sweetalert2";
import Loading from '../global/loading';
import BannerLogoLight from '../../assets/images/InsureBox-banner.png';
import Select from 'react-select';
import {deviceDetect} from 'react-device-detect';
import ReactApexChart from "react-apexcharts";
import { setTempState, getTempState, setAuthState } from '../../assets/scripts/login-util'
import { createAgent, getAgentByEmail, updateAgentSetup, checkIfAgentExistByEmail, getStatesAndCities, completeAgentSetup } from "../api/agentAPI";
import '../../assets/styles/wizard-3.css';
import './setup.css'


const SideMenu = () =>{
    return (
        <div className="kt-aside kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop" id="kt_aside">  
          <div className="kt-aside__brand kt-grid__item " id="kt_aside_brand">
            <div className="kt-aside__brand-logo">
              <span  >
                <img id="asideBrandLogo" alt="Logo" src={BannerLogoLight} height="22"/>
              </span>
            </div>
            <div className="kt-aside__brand-tools">
              <span className="kt-aside__brand-aside-toggler" >    
                <span>
                  <svg className="nav-movment" width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><path d="M13.706 9.698a.988.988 0 0 0 0-1.407 1.01 1.01 0 0 0-1.419 0l-2.965 2.94a1.09 1.09 0 0 0 0 1.548l2.955 2.93a1.01 1.01 0 0 0 1.42 0 .988.988 0 0 0 0-1.407l-2.318-2.297 2.327-2.307z" fill="currentColor" fillRule="evenodd"></path></svg>
                </span>
                <span>
                  <svg className="nav-movment margin-l65" width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><path d="M10.294 9.698a.988.988 0 0 1 0-1.407 1.01 1.01 0 0 1 1.419 0l2.965 2.94a1.09 1.09 0 0 1 0 1.548l-2.955 2.93a1.01 1.01 0 0 1-1.42 0 .988.988 0 0 1 0-1.407l2.318-2.297-2.327-2.307z" fill="currentColor" fillRule="evenodd"></path></svg>
                </span>
              </span>
            </div>
          </div>
          <div className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid" id="kt_aside_menu_wrapper">
            <div style={{ marginTop: '0px' }} id="kt_aside_menu" className="kt-aside-menu " data-ktmenu-vertical="1" data-ktmenu-scroll="1" data-ktmenu-dropdown-timeout="500">
              <ul style={{ paddingTop: '0px' }} className="kt-menu__nav ">
                <li id="menuDashboard" className="kt-menu__item  kt-menu__item--active" aria-haspopup="true">         
                  <span  className="kt-menu__link ">             
                    <span className="kt-menu__link-icon">
                      <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon" data-toggle="kt-tooltip" data-placement="right">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                          <polygon points="0 0 24 0 24 24 0 24" />
                          <path d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z" fill="#000000" fillRule="nonzero"/>
                          <path d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z" fill="#000000" opacity="0.3"/>
                        </g>
                      </svg>
                    </span>
                    <span className="kt-menu__link-text">Dashboard</span>
                  </span>
                </li>
                <li id="menuPolicy" className="kt-menu__item " aria-haspopup="true">          
                  <span  className="kt-menu__link ">
                    <span data-original-title="Policy Stats" className="kt-menu__link-icon">
                      <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon" data-toggle="kt-tooltip" data-placement="right">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <rect fill="#000000" opacity="0.3" x="13" y="4" width="3" height="16" rx="1.5"></rect>
                          <rect fill="#000000" x="8" y="9" width="3" height="11" rx="1.5"></rect>
                          <rect fill="#000000" x="18" y="11" width="3" height="9" rx="1.5"></rect>
                          <rect fill="#000000" x="3" y="13" width="3" height="7" rx="1.5"></rect>
                        </g>
                      </svg>
                    </span>
                    <span className="kt-menu__link-text">Policy Stats</span>
                  </span>
                </li>
                <li id="menuClients" className="kt-menu__item" aria-haspopup="true">
                  <span className="kt-menu__link ">
                    <span className="kt-menu__link-icon">
                      <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon" data-toggle="kt-tooltip" data-placement="right">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                          <polygon points="0 0 24 0 24 24 0 24" />
                          <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3"/>
                          <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero"/>
                        </g>
                      </svg>
                    </span>
                    <span className="kt-menu__link-text">Clients</span>
                  </span>
                </li>
                <li id="menuTask" className="kt-menu__item" aria-haspopup="true">        
                  <span className="kt-menu__link ">
                    <span className="kt-menu__link-icon">
                      <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon" data-toggle="kt-tooltip" data-placement="right"> 
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                          <rect x="0" y="0" width="24" height="24" />
                          <path d="M7.14319965,19.3575259 C7.67122143,19.7615175 8.25104409,20.1012165 8.87097532,20.3649307 L7.89205065,22.0604779 C7.61590828,22.5387706 7.00431787,22.7026457 6.52602525,22.4265033 C6.04773263,22.150361 5.88385747,21.5387706 6.15999985,21.0604779 L7.14319965,19.3575259 Z M15.1367085,20.3616573 C15.756345,20.0972995 16.3358198,19.7569961 16.8634386,19.3524415 L17.8320512,21.0301278 C18.1081936,21.5084204 17.9443184,22.1200108 17.4660258,22.3961532 C16.9877332,22.6722956 16.3761428,22.5084204 16.1000004,22.0301278 L15.1367085,20.3616573 Z" fill="#000000"/>
                          <path d="M12,21 C7.581722,21 4,17.418278 4,13 C4,8.581722 7.581722,5 12,5 C16.418278,5 20,8.581722 20,13 C20,17.418278 16.418278,21 12,21 Z M19.068812,3.25407593 L20.8181344,5.00339833 C21.4039208,5.58918477 21.4039208,6.53893224 20.8181344,7.12471868 C20.2323479,7.71050512 19.2826005,7.71050512 18.696814,7.12471868 L16.9474916,5.37539627 C16.3617052,4.78960984 16.3617052,3.83986237 16.9474916,3.25407593 C17.5332781,2.66828949 18.4830255,2.66828949 19.068812,3.25407593 Z M5.29862906,2.88207799 C5.8844155,2.29629155 6.83416297,2.29629155 7.41994941,2.88207799 C8.00573585,3.46786443 8.00573585,4.4176119 7.41994941,5.00339833 L5.29862906,7.12471868 C4.71284263,7.71050512 3.76309516,7.71050512 3.17730872,7.12471868 C2.59152228,6.53893224 2.59152228,5.58918477 3.17730872,5.00339833 L5.29862906,2.88207799 Z" fill="#000000" opacity="0.3"/>
                          <path d="M11.9630156,7.5 L12.0475062,7.5 C12.3043819,7.5 12.5194647,7.69464724 12.5450248,7.95024814 L13,12.5 L16.2480695,14.3560397 C16.403857,14.4450611 16.5,14.6107328 16.5,14.7901613 L16.5,15 C16.5,15.2109164 16.3290185,15.3818979 16.1181021,15.3818979 C16.0841582,15.3818979 16.0503659,15.3773725 16.0176181,15.3684413 L11.3986612,14.1087258 C11.1672824,14.0456225 11.0132986,13.8271186 11.0316926,13.5879956 L11.4644883,7.96165175 C11.4845267,7.70115317 11.7017474,7.5 11.9630156,7.5 Z" fill="#000000"/>
                        </g>
                      </svg>
                    </span>
                    <span className="kt-menu__link-text">Tasks</span>
                  </span>
                </li>
                <li id="menuFile" className="kt-menu__item" aria-haspopup="true">
                  <span className="kt-menu__link ">
                    <span className="kt-menu__link-icon">
                      <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon" data-toggle="kt-tooltip" data-placement="right">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                          <polygon points="0 0 24 0 24 24 0 24" />
                          <path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fillRule="nonzero" opacity="0.3"/>
                          <rect fill="#000000" x="6" y="11" width="9" height="2" rx="1"/>
                          <rect fill="#000000" x="6" y="15" width="5" height="2" rx="1"/>
                        </g>
                      </svg>
                    </span>
                    <span className="kt-menu__link-text">Files</span>
                  </span>
                </li>
                <li id="menuReports" className="kt-menu__item" aria-haspopup="true">
                  <span className="kt-menu__link ">
                    <span className="kt-menu__link-icon">
                      <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon" data-toggle="kt-tooltip" data-placement="right">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                          <rect x="0" y="0" width="24" height="24" />
                          <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3"/>
                          <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000"/>
                          <rect fill="#000000" opacity="0.3" x="10" y="9" width="7" height="2" rx="1"/>
                          <rect fill="#000000" opacity="0.3" x="7" y="9" width="2" height="2" rx="1"/>
                          <rect fill="#000000" opacity="0.3" x="7" y="13" width="2" height="2" rx="1"/>
                          <rect fill="#000000" opacity="0.3" x="10" y="13" width="7" height="2" rx="1"/>
                          <rect fill="#000000" opacity="0.3" x="7" y="17" width="2" height="2" rx="1"/>
                          <rect fill="#000000" opacity="0.3" x="10" y="17" width="7" height="2" rx="1"/>
                        </g>
                      </svg>
                    </span>
                    <span className="kt-menu__link-text">Reports</span>
                  </span>
                </li>      
              </ul>
            </div>
          </div>
        </div>
    );
}

const Header = () => {
    return (
        <div id="kt_header" className="kt-header kt-grid__item  kt-header--fixed header-curtailed" >
            <div className="kt-header-menu-wrapper" id="kt_header_menu_wrapper">
                <div id="kt_header_menu" className="kt-header-menu kt-header-menu-mobile  kt-header-menu--layout-default">
                    <h3 className="header-title"> Dashboard </h3>
                </div>
            </div>

            <div className="kt-header__topbar">
              {/* begin: User Bar */}
                <div className="kt-header__topbar-item kt-header__topbar-item--user">
                    <div className="kt-header__topbar-wrapper" >
                        <div className="kt-header__topbar-user">
                            <span className="kt-header__topbar-welcome kt-hidden-mobile">
                                Hi,
                            </span>
                            <span className="kt-header__topbar-username kt-hidden-mobile">
                                User
                            </span>
                        </div>
                    </div>
                </div>
              {/* end: User Bar */}
            </div>
        </div>
    );
}


const SetupModal = () => {
   
    const [loading,        setLoading]          = useState(false);
    const [stepCounter,    setStepCounter]      = useState(1);
    const [isEdit,         setisEdit]           = useState(false);
    const [States,         setStates]           = useState([]);
    const [Cities,         setCities]           = useState([]);
    const [filteredCities, setFilteredCities]   = useState([]);
    const [members,        setMembers]          = useState([])
    const [agent,          setAgent]            = useState({ CategoryID : 1 });
    const [companyDetails, setCompanyDetails]   = useState({ CountryID  : 1 , EmployeeStrength:'1-5'})
    const [newMember,      setNewMember]        = useState({});
    const [selectedCity,   setSelectedCity]     = useState(null);
    const [selectedState,  setSelectedState]    = useState(null);
    const [plan,           setPlan]             = useState({ title:'Solo', ap:1499, sp:499, users:1, text: 'Per month, billed annually'});
    const [planDuration,   setplanDuration]     = useState('annual');

    useEffect(() => {
        $('#setup-modal').modal({ backdrop: 'static' });
  
        $('#setup-modal').on('hidden.bs.modal', function () {
          $('.modal').hasClass('show') && $('body').addClass('modal-open');       
        });
    }, []);

    useEffect(()=>{
        let ignore = false;
        if(!ignore){
            fetchStatesAndCities();
            checkAgentData();
        }
        return() => { ignore =  true; }
    }, [])
    
    useEffect(()=>{
        if(companyDetails){
            if(companyDetails.StateID) setSelectedState(States.find( s => s.value.ID === companyDetails.StateID));
            if(companyDetails.CityID) setSelectedCity(Cities.find( c => c.value.ID == companyDetails.CityID));
        }
    }, [companyDetails])

    useEffect(()=>{
        if(selectedState){
            const ct = Cities.filter(c => c.value.StateID === selectedState.value.ID)
            setFilteredCities(ct);
        }
    }, [selectedState])

    useEffect(() => { 
        changePlan(members.length, planDuration);
    }, [members, planDuration])
    
    const changePlan = (count, duration) =>{
       switch(duration){
            case 'monthly':
                if(count >= 5){
                    setPlan({ title:'Enterprise', ap: 4999, sp: 3999, users: 'Custom', text: 'Per month, billed monthly' })
                }else if(count >= 3){
                    setPlan({ title:'Team Plus', ap: 4999, sp: 3999, users: 5, text: 'Per month, billed monthly' })
                }else if( count >= 1) {
                    setPlan({ title:'Team', ap: 2999, sp: 2499, users: 3, text: 'Per month, billed monthly' })
                }else{
                    setPlan({ title:'Solo', ap: 1499, sp: 1199, users: 1, text: 'Per month, billed monthly' })
                }
                $(".btn-annual").removeClass('active');
                $(".btn-monthly").addClass('active');
                break;
            default:
                if(count >= 5){
                    setPlan({ title:'Enterprise', ap: 4999, sp: 3999, users: 'Custom', text: 'Per month, billed annually' })
                }else if(count >= 3){
                    setPlan({ title:'Team Plus', ap: 4999, sp: 2999, users: 5, text: 'Per month, billed annually' })
                }else if( count >= 1) {
                    setPlan({ title:'Team', ap: 2999, sp: 1999, users: 3, text: 'Per month, billed annually' })
                }else{
                    setPlan({ title:'Solo', ap: 1499, sp: 499, users: 1, text: 'Per month, billed annually' })
                }
                $(".btn-monthly").removeClass('active');
                $(".btn-annual").addClass('active');
                break;
       } 
    }

    const fetchStatesAndCities = async () => {
        try {
            const response = await getStatesAndCities();
            if(response.States.length > 0){
                const st = response.States.map( s => { return {label : s.Name, value : s }})
                setStates(st);
            }
            if(response.Cities.length > 0){
                const ct = response.Cities.map( c => { return { label : c.Name, value : c } })
                setCities(ct);
                setFilteredCities(ct);
            }
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });  
            return; 
        }
            
    }

    const checkAgentData = async() => {
        try { 
            setLoading(true);
            const userEmail = getTempState('Email')
            //Check for Existing Agent
            const existingAgentDetails = await getAgentByEmail(userEmail);
            if(existingAgentDetails){
                setAgent(existingAgentDetails.Agent);
                setCompanyDetails(existingAgentDetails.Company);
                setisEdit(true);
                setStepCounter(2)
                setLoading(false);
                return;
            }
            setAgent(cd => { 
                return { ...cd, Email: userEmail }
            });
            setCompanyDetails(cd => { return { ...cd, Email:userEmail } })
           
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });  
            return;     
        }
        setLoading(false);
    }

    const CompanyDetailsChangeHandler = (field, value) => {
        if(field === "StateID"){
            setSelectedCity(null)
            return setCompanyDetails(cd => {
                delete cd.CityID
                return { ...cd, [field]: value }
            });
        }
        setCompanyDetails(cd => { return { ...cd, [field]: value } })
    }

    const AgentDataChangeHandler = (field, value) => {
        setAgent(ad => { return { ...ad, [field]: value } })
    }
    const newMemberChangeHandler = (field, value) => setNewMember(n => { return { ...n, [field]: value } })

    const createAgentHandler = async () =>{
 
        const proceedbtn = $('#proceed-btn');
        const deviceData = deviceDetect()
        const Device = await helper.getDeviceDetails(deviceData);  

        try {            
            helper.StartProcessing(proceedbtn);
            let CompanyName = companyDetails.CompanyName ? companyDetails.CompanyName: `${agent.FirstName} ${agent.LastName}`
            const response = await createAgent(agent, { ...companyDetails, CompanyName }, Device);
            if(response){

                setAgent({ ...agent, ID: response.AgentID, AgentCompanyID: response.AgentCompanyID });
                setCompanyDetails( {...companyDetails, CompanyName })
                setisEdit(true);
                setStepCounter(2)

                //Refresh Existing Cookie -->
                setTempState();
                setTempState(response.token);

                helper.StopProcessing(proceedbtn);
            }
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
            helper.StopProcessing(proceedbtn);
        }
    }

    const updateAgentHandler = async () =>{
        const btn = $('#proceed-btn');
        try {
            helper.StartProcessing(btn);

            let CompanyName = companyDetails.CompanyName ? companyDetails.CompanyName: `${agent.FirstName} ${agent.LastName}`
            
            await updateAgentSetup(agent, companyDetails);

            setCompanyDetails({ ...companyDetails, CompanyName })
            setStepCounter(2)
            helper.StopProcessing(btn);
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
            helper.StopProcessing(btn);
        }
    }

    const addNewMember = async (e) => {
        e.preventDefault();
        const btn = $('#addMemberBtn')
        var form = $('#add-member-form');

        form.validate().destroy();

        form.validate({
            rules:{
                txtFirstName: {
                    required: true,
                },
                txtLastName: {
                    required: true,
                },
                txtEmail: {
                    required: true,
                }
            }
        });

        if (!form.valid()) {
            return;
        }
        
        if(members.findIndex(m => m.Email === newMember.Email) !== -1){
            return swal.fire({
                toast               : true,
                icon                : 'warning',
                titleText           : `Email already added`,
                position            : 'bottom-end',
                showConfirmButton   : false,
                timer               : 1500,
                animation           : true,
                customClass         : { popup: 'margin-20', },
            });
        }

        helper.StartProcessing(btn)
        try {
            await checkIfAgentExistByEmail(newMember.Email);
            //ADD USER AND SET MEMBERS 
            setMembers(m => [...m, { ...newMember, AgentCompanyID: agent.AgentCompanyID }]);   
            
            //reset New Member Form 
            setNewMember({ CategoryID:''});  
       } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
           return helper.StopProcessing(btn)
       }
       helper.StopProcessing(btn)
    }

    const removeMember = async (email) => {
        setMembers(m => {
            let filteredMembers = m.filter(m => m.Email !== email)
            return filteredMembers
        })
    }

    const CompleteSetup = async (btn) =>{
        helper.StartProcessing(btn);
        try {
            const deviceData = deviceDetect()
            const device = await helper.getDeviceDetails(deviceData);
            
            // Complete Setup API
            const response  = await completeAgentSetup(members, device);
            //delete ibAgentTemp cookie
            setTempState()
            //Create ibAgent cookie with first login flag
            setAuthState({...response, initialLogin : true }, true);
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });  
            return;
        }
        window.location.reload();
    }

    const ActionHandler = (type, e) => {
        e.preventDefault()
        const btn = $('#submit-btn')
        const form = $('#kt_form')
        if (type === 'Proceed' || type === "Update") {     
            form.validate({
                rules: {
                    FirstName: {
                        required: true
                    },
                    LastName: {
                        required: true
                    },
                    empStrength: {
                        required: true
                    },
                    State: {
                        required: true
                    },
                    
                    City: {
                        required: true
                    },
                    txtMobile: {
                        required: true
                    }
                }
            })
            if (!form.valid()) return;
            
            if(type === 'Proceed') return createAgentHandler();
            if(type === 'Update')  return updateAgentHandler();
        }


        if (type === 'Submit' && stepCounter !== 1) {
            return CompleteSetup(btn)
        }
        if (type === 'Prev') {
            setStepCounter(1)
        }
    }

    const onLogout = () => {
        const btn = $('#logout-btn');
        helper.StartProcessing(btn)
        window.open('/', '_self');
        setTempState()
        helper.StopProcessing(btn);
    }
    
    const step1 = {};
    const step2 = {};
    let currentAction
    if (stepCounter === 1) {
        step1['data-ktwizard-state'] = 'current';
        currentAction = 'first'
    } else {
        step2['data-ktwizard-state'] = 'current';
        currentAction = 'last'
    }

    const options = [
        { value: 2, label: 'Admin' },
        { value: 3, label: 'Member' },
    ]
    return(
        <div className="modal fade" id="setup-modal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        {loading ? <Loading/>:
                            <div className="kt-grid kt-wizard-v3 kt-wizard-v3--white" id="kt_wizard_v3" data-ktwizard-state={currentAction}>
                                <div className="kt-grid__item">
                                    {/* begin: Form Wizard Nav */}
                                    <div className="kt-wizard-v3__nav">
                                        <div className="row kt-wizard-v3__nav-items kt-wizard-v3__nav-items--clickable">
                                            <div className="col-6">
                                                <div className="kt-wizard-v3__nav-item" data-ktwizard-type="step" {...step1}>
                                                    <div className="kt-wizard-v3__nav-body">
                                                        <div className="kt-wizard-v3__nav-label" onClick={(e) => ActionHandler('Prev', e)} >
                                                            <span>1</span> Setup Profile
                                                        </div>
                                                        <div className="kt-wizard-v3__nav-bar"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="kt-wizard-v3__nav-item" data-ktwizard-type="step" {...step2}>
                                                    <div className="kt-wizard-v3__nav-body">
                                                        <div className="kt-wizard-v3__nav-label" onClick={(e) => ActionHandler(!isEdit? 'Proceed' : 'Update', e)}  >
                                                            <span>2</span> Add Team
                                                        </div>
                                                        <div className="kt-wizard-v3__nav-bar"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end: Form Wizard Nav */}
                                </div>
                                <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v3__wrapper">
                                    {/* begin: Form Wizard Form */}
                                    <div className="kt-form padding-t10">
                                        {/* begin: Form Wizard Step 1 */}
                                        <div id="wizard-1" className="kt-wizard-v3__content" data-ktwizard-type="step-content" {...step1}>
                                            <div className="kt-form__section kt-form__section--first">
                                                <form className="kt-wizard-v3__form" id="kt_form">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <label>First Name:</label>
                                                                <input 
                                                                    value={agent.FirstName || ""}
                                                                    onChange={({ target }) => AgentDataChangeHandler('FirstName', helper.capitalize(target.value.trim()))} 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    name="FirstName" 
                                                                    placeholder="First name"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <label>Last Name:</label>
                                                                <input 
                                                                    value={agent.LastName || ""}
                                                                    onChange={({ target }) => AgentDataChangeHandler('LastName',  helper.capitalize(target.value.trim()))} 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    name="LastName" 
                                                                    placeholder="Last name" 
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Business Name:</label>
                                                        <input 
                                                            value={companyDetails.CompanyName || ''}
                                                            onChange={({ target }) => CompanyDetailsChangeHandler('CompanyName',  helper.titleCase(target.value))} 
                                                            type="text" 
                                                            className="form-control" 
                                                            name="companyName" 
                                                            placeholder="Business name / Full Name" 
                                                        />
                                                    </div>                                                
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <label>Mobile Number:</label>
                                                                <div className="kt-input-icon kt-input-icon--left phone-input-icon" >
                                                                    <input 
                                                                        value={companyDetails.Phone || ""}
                                                                        onChange={({ target }) => CompanyDetailsChangeHandler('Phone', target.value.trim())} 
                                                                        type="tel" 
                                                                        pattern="[0-9]{10}"
                                                                        className="form-control" 
                                                                        name="txtMobile" 
                                                                        minLength="10"
                                                                        maxLength="10"
                                                                        placeholder="Company mobile number"    
                                                                    />
                                                                    <span className ="kt-input-icon__icon kt-input-icon__icon--left">
                                                                        <span>+91</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <label>Employee Strength:</label>
                                                                <select name="empStrength" value={companyDetails.EmployeeStrength || ""}  className="form-control"  onChange={({ target }) => CompanyDetailsChangeHandler('EmployeeStrength', target.value)}>
                                                                    <option value="1-5">1 - 5</option>
                                                                    <option value="6-10">6 - 10</option>
                                                                    <option value="11-25">11 - 25</option>
                                                                    <option value="26-50">26 - 50</option>
                                                                    <option value="51-100">51 - 100</option>
                                                                    <option value="100+">100+</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4 col-12 mb-0">
                                                            <div className="form-group">
                                                                <label>Country:</label>
                                                                <select name="country" disabled aria-readonly="true" className="form-control">
                                                                    <option value="India">India</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-12 mb-0">
                                                            <div className="form-group">
                                                                <label>State:</label>
                                                                <Select 
                                                                    className="State"
                                                                    id="State" 
                                                                    name="State"
                                                                    value={selectedState}
                                                                    options={States}
                                                                    placeholder="Select State"
                                                                    onChange={(state)=>{
                                                                        if(state) {
                                                                            setSelectedState(state);
                                                                            CompanyDetailsChangeHandler('StateID', state.value.ID);
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-12 mb-0">
                                                            <div className="form-group">
                                                                <label>City:</label>
                                                                <Select 
                                                                    className="City"
                                                                    id="City" name="City"
                                                                    value={selectedCity}
                                                                    options={filteredCities}
                                                                    placeholder="Select City"
                                                                    onChange={(city)=>{
                                                                        if(city){ 
                                                                            setSelectedCity(city);
                                                                            CompanyDetailsChangeHandler('CityID', city.value.ID)
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>  
                                                </form>
                                            </div>
                                        </div>
                                        {/* end: Form Wizard Step 1 */}

                                        {/* begin: Form Wizard Step 2 */}
                                        <div id="wizard-2" className="kt-wizard-v3__content" data-ktwizard-type="step-content" {...step2}>
                                            <div className="kt-form__section kt-form__section--first">
                                                <div className="kt-wizard-v3__form">
                                                    {/* Add Member Form */}
                                                    <form id="add-member-form">
                                                        <div className="row">
                                                            <div className="col-md-6 col-6 form-group">
                                                                <input value={newMember.FirstName || ''} onChange={({ target }) => newMemberChangeHandler('FirstName', helper.capitalize(target.value.trim()))} type="text" className="form-control" name="txtFirstName" placeholder=" First name" />
                                                            </div>
                                                            <div className="col-md-6 col-6 form-group">
                                                                <input value={newMember.LastName || ''} onChange={({ target }) => newMemberChangeHandler('LastName', helper.capitalize(target.value.trim()))} type="text" className="form-control" name="txtLastName" placeholder="Last name" />
                                                            </div>
                                                        </div>    
                                                        <div className="row">
                                                            <div className ="col-md-6 col-6 form-group">
                                                                <input value={newMember.Email || ''} onChange={({ target }) => newMemberChangeHandler('Email', target.value)} type="email" pattern=".*@\w+\.\w+" className="form-control" name="txtEmail" placeholder="Email" />
                                                            </div>
                                                            <div className="col-md-3 col-6 form-group">
                                                                <Select
                                                                    value={options.find((m) => m.value == newMember.CategoryID ? (m.label) : '') || '' }
                                                                    onChange={(event) => newMemberChangeHandler('CategoryID', event.value)}
                                                                    name="Type"
                                                                    id="Type"
                                                                    placeholder="Select Type"
                                                                    className="multi-custom"
                                                                    options={options}
                                                                />
                                                            </div>
                                                            <div className="col-md-3 col-12 form-group">
                                                                <button id="addMemberBtn"
                                                                    onClick={addNewMember}
                                                                    type="button"
                                                                    className="btn btn-label-brand margin-0 btn-block" >
                                                                   <i className="la la-plus"></i> Add
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form> 
                                                    {/*end : Add Member </form> */}
                                                    <hr className="mt-4 mb-0" />
                                                    {/* MEMBERS TABLE */}
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded setup-scrolTable mb-0">
                                                                <table className="kt-datatable__table table-striped teamTable">
                                                                    <tbody className="kt-datatable__body" style={{ display: "block" }}>
                                                                        <tr className="kt-datatable__row block-row clickable " >
                                                                            <td width="85%"  className="kt-datatable__cell member-name-cell text-clip ">
                                                                                <div className="kt-user-card-v2">
                                                                                    <div className="kt-user-card-v2__pic">
                                                                                        <div className="kt-badge kt-badge--xl kt-badge--unified-danger">
                                                                                            {agent.FirstName && agent.FirstName.slice(0, 1)}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="kt-user-card-v2__details text-clip">
                                                                                        <span className="kt-user-card-v2__name text-clip">
                                                                                            {agent.FirstName && agent.LastName && `${agent.FirstName} ${agent.LastName}`}
                                                                                        </span>                               
                                                                                        <span className="kt-user-card-v2__desc text-clip" >
                                                                                        {agent.Email && agent.Email}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            <td width="15%" className="kt-datatable__cell">
                                                                                <div className='kt-badge  kt-badge--unified-danger  kt-badge--inline kt-badge--pill  kt-badge-pill-width-admin'>
                                                                                    <span>Super Admin</span>
                                                                                </div>
                                                                            </td>  
                                                                            
                                                                            <td className="kt-datatable__cell text-center" width="10%"> </td>
                                                                        </tr>
                                                                        
                                                                        {members.length > 0 && members.map((m, i) => {
                                                                            return (
                                                                                <tr key={i} className="kt-datatable__row block-row clickable " >
                                                                                    <td width="85%"  className="kt-datatable__cell member-name-cell text-clip">
                                                                                        <div className="kt-user-card-v2">
                                                                                            <div className="kt-user-card-v2__pic">
                                                                                                <div className={`kt-badge kt-badge--xl kt-badge--${m.CategoryID ==  2 ? 'success' : 'info '} `}>
                                                                                                    {m.FirstName && m.FirstName.slice(0, 1)}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="kt-user-card-v2__details text-clip">
                                                                                                <span className="kt-user-card-v2__name text-clip">
                                                                                                    {`${m.FirstName} ${m.LastName}`}
                                                                                                </span>                               
                                                                                                <span className="kt-user-card-v2__desc text-clip" >
                                                                                                {m.Email}
                                                                                                </span>
                                                                                            
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td width="15%" className="kt-datatable__cell ">
                                                                                        <div className={`kt-badge kt-badge--${m.CategoryID ==  2 ? 'success' : 'info '}  kt-badge--inline kt-badge--pill kt-badge-pill-width `}>
                                                                                            <span>{ m.CategoryID == 2 ? 'Admin' : 'Member'}</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    
                                                                                    
                                                                                    <td className="kt-datatable__cell text-center"  width="10%">
                                                                                    <button  type="button" className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs"
                                                                                        onClick={()=> removeMember(m.Email)}
                                                                                    >
                                                                                        <i className="flaticon2-rubbish-bin-delete-button"></i>  
                                                                                    </button>
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* Pricing CARD */}
                                                    <div className="kt-portlet margin-t30 margin-b0 plan-portlet">
                                                        <div className="kt-portlet__body  kt-portlet__body--fit">
                                                            <div className="row row-no-padding row-col-separator-lg">
                                                                <div className="col-12">
                                                                    <div className="kt-widget24 plan-card">
                                                                        <div className="kt-widget24__details">
                                                                            <div className="kt-widget24__info">
                                                                                <h4 className="kt-widget24__title">
                                                                                   {plan.title} <i className="flaticon2-correct kt-font-success ml-2"></i> 
                                                                                </h4>
                                                                                <p className="mb-0"> <i className="fa fa-user text-muted "></i> 
                                                                                    {members.length >= 5 ? 'As per requirements':`${plan.users} Users`} 
                                                                                </p>
                                                                                <span className="">
                                                                                    {plan.text}
                                                                                </span>
                                                                                <p className="mb-0 text-info">7 Days Free Trial</p>
                                                                            </div>
                                                                               {members.length >= 5 ?
                                                                                <span className="kt-widget24__stats ">
                                                                                    <h5> Custom Plans </h5>
                                                                                </span>
                                                                                :
                                                                                <span className="kt-widget24__stats ">
                                                                                    <strike className="mr-3"> ₹{plan.ap} </strike>₹{plan.sp}
                                                                                </span>
                                                                               }
                                                                            <span className="kt-widget24__plan">
                                                                                <div className="btn-group " >
                                                                                    <span  className="btn btn-sm btn-annual active"  onClick={()=> setplanDuration('annual')} >
                                                                                        Annually
                                                                                    </span>
                                                                                    <span className="btn btn-sm btn-monthly" onClick={()=> setplanDuration('monthly')}>
                                                                                        Monthly 
                                                                                    </span>
                                                                                </div>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* end: Form Wizard Step 2 */}
                                    </div>
                                    {/* end: Form Wizard Form */}
                                </div>
                            </div>
                        }
                    </div>
                    
                    <div className="modal-footer d-block">
                        <div className="row">
                            <div className="col-2">
                                <button 
                                    id="logout-btn"
                                    type="button"
                                    className="btn btn-label-brand m-0 setup-logout-btn"
                                    onClick={onLogout}>
                                    Logout
                                </button>
                            </div>
                            <div className="col-10 d-flex justify-content-end">
                                {stepCounter != 1 ?
                                <>
                                    <button type="button"
                                        id="previous-btn"
                                        className="btn btn-secondary margin-0 margin-r5"
                                        data-ktwizard-type="action-prev"
                                        onClick={(e) => ActionHandler('Prev', e)}>
                                        Previous
                                    </button>
                                    <button 
                                        id="submit-btn"
                                        type="button"
                                        className="btn btn-brand margin-0 "
                                        data-ktwizard-type="action-submit" 
                                        onClick={(e) => ActionHandler('Submit', e)}>
                                        Complete Setup
                                    </button>
                                </>:
                                    <button 
                                        id="proceed-btn"
                                        type="button"
                                        className="btn btn-brand margin-0 "
                                        data-ktwizard-type="action-next" 
                                        onClick={(e) => ActionHandler(!isEdit? 'Proceed': 'Update', e)}>
                                        Proceed
                                    </button>
                                }
                            </div>
                        </div>   
                    </div>
                </div>
            </div>
        </div>
    )
}


const DashBoard  = () => {

    const [productChartData,    setProductChartData]        = useState(null);
    const [policyChartData,     setPolicyChartData]         = useState(null);
    const [businessReportChart, setBusinessReportChart]     = useState(null);
    const [policyIssueChart,    setPolicyIssueChart]        = useState(null);
    const [productIssueChart,   setProductIssueChart]       = useState(null);
    const [clientChart,         setClientChart]             = useState(null);

    useEffect(() => {
        $('.counter').each(function () {
            $(this).prop('Counter',0).animate({Counter: $(this).text()}, {
                duration: 1500,
                easing: 'swing',
                step: function (now) {
                    $(this).text(Math.ceil(now));
                }
            });
        });
    }, []);

    useEffect(() => {

        setBusinessReportChart({
            series: [{
                data: [11, 25, 0, 18, 21, 32, 40, 52],
            }],
            options: {
                colors: ['#00e396'],      
                tooltip: {
                    enabled: false,
                },
                chart: {
                    zoom: {
                        enabled: false,
                    },
                    type: 'area',
                    toolbar: {
                        show: false
                    }
                },
                legend: {
                    height:40,
                    width: '100px'
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth',
                    width: 0.8
                },
                grid: {
                    show: false
                },
                yaxis: {
                    show: false
                },
                xaxis: {
                    labels: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    }
                },
                fill: {
                    colors: ['#00e396']
                },
            }
        })

        setPolicyIssueChart({
            series: [{
                data: [50, 30, 15, 18, 10, 25, 40, 20]          
            }],
            options: {
                colors: ['#feb019'],      
                tooltip: {
                    enabled: false,
                },
                chart: {
                    zoom: {
                        enabled: false,
                    },
                    type: 'area',
                    toolbar: {
                        show: false
                    }
                },
                legend: {
                    height:40,
                    width: '100px'
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth',
                    width: 0.8
                },
                grid: {
                    show: false
                },
                yaxis: {
                    show: false
                },
                xaxis: {
                    labels: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    }
                },
                fill: {
                    colors: ['#feb019']
                },
            }
        })

        setProductIssueChart({
            series: [{
                data: [11, 40, 50, 45, 30, 32, 40, 52]          
            }],
            options: {
                tooltip: {
                    enabled: false,
                },
                chart: {
                    zoom: {
                        enabled: false,
                    },
                    type: 'area',
                    toolbar: {
                        show: false
                    }
                },
                legend: {
                    height:40,
                    width: '100px'
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth',
                    width: 0.8
                },
                grid: {
                    show: false
                },
                yaxis: {
                    show: false
                },
                xaxis: {
                    labels: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    }
                },
            }
        })

        setClientChart({
            series: [{
                data: [30, 0, 10, 18, 23, 10, 40, 52]          
            }],
            options: {
                colors: ['#ff4560'],
                tooltip: {
                    enabled: false,
                },
                chart: {
                    zoom: {
                        enabled: false,
                    },
                    type: 'area',
                    toolbar: {
                        show: false
                    }
                },
                legend: {
                    height:40,
                    width: '100px'
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth',
                    width: 0.8
                },
                grid: {
                    show: false
                },
                yaxis: {
                    show: false
                },
                xaxis: {
                    labels: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    }
                },
                fill: {
                    colors: ['#ff4560']
                },
            }
        })

        setPolicyChartData({
            series: [{
                name: 'Previous Month',
                data: [11, 32, 45, 32, 34, 52, 41, 60, 90, 50, 100, 88, 95, 77]          
                }, {
                name: 'Current Month',
                data: [31, 40, 28, 51, 42, 109, 100, 80, 130, 120, 150, 100, 111, 222]       
            }],
                options: {
                colors: ['#ff4560', '#9ed4fd'],      
                chart: {
                    zoom: {
                        enabled: false,
                    },
                    type: 'area',
                    toolbar: {
                        show: false
                    }
                },
                legend: {
                    show: true,
                    height:40
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth',
                    dashArray: [7, 0],
                    width: 1.5
                },
                
            
            },
        })

        setProductChartData({
            series: [60, 50, 45, 40, 30, 20],
            options: {
                labels: ['Motor', 'Health', 'Critical Illness', 'Event Cancellation', 'Marine Hull', 'Fire'],
                colors: ['#00e396', '#008ffb', '#775dd0', '#7fbdff', '#feb019', '#ff4560'],
                plotOptions: {
                    pie: {
                        expandOnClick: true,
                        donut: {
                            size: '50%',
                        },
                        customScale: 1
                    }
                },
                legend: {
                    show: true,
                    position: 'bottom',
                    height: 40,
                    itemMargin: {
                        horizontal: 7,
                        vertical: 0
                    },
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        fontWeight: '100px',
                    }
                }
            },
        });

    }, []);
    
    return (
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div className="row margin-t20">
                <div className="col-md-3">
                    <div className="kt-portlet" style={{ height: '150px' }}>
                        <div className="kt-portlet__head justify-content-center dashboard-header-title">
                            <div className="kt-portlet__head-label">
                                <h6 className="kt-portlet__head-title padding-t75 dashboard-header-content">BUSINESS REPORTS</h6>
                            </div>
                        </div>
                        <div className="kt-portlet__head justify-content-center dashboard-header-title">
                            <div className="kt-portlet__head-label">
                                <h6 className="kt-portlet__head-title padding-t150 counter" style={{ fontSize: '2.5rem' }}>10340</h6>
                            </div>
                        </div>
                        <div className="kt-portlet__head justify-content-center dashboard-header-title">
                            <div className="kt-portlet__head-label">
                                <h6 className="kt-portlet__head-title padding-t225" style={{ fontSize: '0.9rem' }}><i className=" fa fa-caret-up margin-r5" style={{ color: 'green' }}></i>21.7%</h6>
                            </div>
                        </div>
                        {businessReportChart && (
                            <ReactApexChart 
                                options={businessReportChart.options}
                                series={businessReportChart.series}
                                type="area"
                                height='153px'

                            />
                        )}
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="kt-portlet" style={{ height: '150px' }}>
                        <div className="kt-portlet__head justify-content-center dashboard-header-title">
                            <div className="kt-portlet__head-label">
                                <h6 className="kt-portlet__head-title padding-t75 dashboard-header-content">POLICY ISSUE</h6>
                            </div>
                        </div>
                        <div className="kt-portlet__head justify-content-center dashboard-header-title">
                            <div className="kt-portlet__head-label">
                                <h6 className="kt-portlet__head-title padding-t150 counter" style={{ fontSize: '2.5rem' }}>429</h6>
                            </div>
                        </div>
                        <div className="kt-portlet__head justify-content-center dashboard-header-title">
                            <div className="kt-portlet__head-label">
                                <h6 className="kt-portlet__head-title padding-t225" style={{ fontSize: '0.9rem' }}><i className=" fa fa-caret-down margin-r5" style={{ color: 'red' }}></i>11.7%</h6>
                            </div>
                        </div>
                        {policyIssueChart && (
                            <ReactApexChart 
                                options={policyIssueChart.options}
                                series={policyIssueChart.series}
                                type="area"
                                height='153px'
                            />
                        )}
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="kt-portlet" style={{ height: '150px' }}>
                        <div className="kt-portlet__head justify-content-center dashboard-header-title">
                            <div className="kt-portlet__head-label">
                                <h6 className="kt-portlet__head-title padding-t75 dashboard-header-content">POLICY ISSUE FOR PRODUCTS</h6>
                            </div>
                        </div>
                        <div className="kt-portlet__head justify-content-center dashboard-header-title">
                            <div className="kt-portlet__head-label">
                                <h6 className="kt-portlet__head-title padding-t150 counter" style={{ fontSize: '2.5rem' }}>150</h6>
                            </div>
                        </div>
                        <div className="kt-portlet__head justify-content-center dashboard-header-title">
                            <div className="kt-portlet__head-label">
                                <h6 className="kt-portlet__head-title padding-t225" style={{ fontSize: '0.9rem' }}><i className=" fa fa-caret-up margin-r5" style={{ color: 'green' }}></i>21.7%</h6>
                            </div>
                        </div>
                        {productIssueChart && (
                            <ReactApexChart 
                                options={productIssueChart.options}
                                series={productIssueChart.series}
                                type="area"
                                height='153px'
                            />
                        )}
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="kt-portlet" style={{ height: '150px' }}>
                        <div className="kt-portlet__head justify-content-center dashboard-header-title">
                            <div className="kt-portlet__head-label">
                                <h6 className="kt-portlet__head-title padding-t75 dashboard-header-content">CLIENTS</h6>
                            </div>
                        </div>
                        <div className="kt-portlet__head justify-content-center dashboard-header-title">
                            <div className="kt-portlet__head-label">
                                <h6 className="kt-portlet__head-title padding-t150 counter" style={{ fontSize: '2.5rem' }}>87</h6>
                            </div>
                        </div>
                        <div className="kt-portlet__head justify-content-center dashboard-header-title">
                            <div className="kt-portlet__head-label">
                                <h6 className="kt-portlet__head-title padding-t225" style={{ fontSize: '0.9rem' }}><i className=" fa fa-caret-down margin-r5" style={{ color: 'red' }}></i>8.5%</h6>
                            </div>
                        </div>
                        {clientChart && (
                            <ReactApexChart 
                                options={clientChart.options}
                                series={clientChart.series}
                                type="area"
                                height='153px'
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-8">
                    <div className="kt-portlet" style={{ height: '400px' }}>
                        <div className="kt-portlet__head">
                            <div className="kt-portlet__head-label">
                                <h3 className="kt-portlet__head-title">Policy Stats</h3>
                            </div>
                        </div>
                        <div className="kt-portlet__body">
                            <div className="mixed-chart">
                                {policyChartData && (
                                    <ReactApexChart 
                                        options={policyChartData.options}
                                        series={policyChartData.series}
                                        type="area"
                                        height='320px'
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="kt-portlet" style={{ height: '400px' }}>
                        <div className="kt-portlet__head">
                            <div className="kt-portlet__head-label">
                                <h3 className="kt-portlet__head-title">Products</h3>
                            </div>
                        </div>
                        <div className="kt-portlet__body">
                            <div className="mixed-chart">
                                {productChartData && (
                                    <ReactApexChart 
                                        options={productChartData.options}
                                        series={productChartData.series}
                                        type="donut"
                                        height={340}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4">
                    <div className="kt-portlet">
                        <div className="kt-portlet__head">
                            <div className="kt-portlet__head-label">
                                <h3 className="kt-portlet__head-title">Notifications</h3>
                            </div>
                        </div>
                        <div className="kt-portlet__body">
                            
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="kt-portlet">
                        <div className="kt-portlet__head">
                            <div className="kt-portlet__head-label">
                                <h3 className="kt-portlet__head-title">Task</h3>
                            </div>
                        </div>
                        <div className="kt-portlet__body">

                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="kt-portlet">
                        <div className="kt-portlet__head">
                            <div className="kt-portlet__head-label">
                                <h3 className="kt-portlet__head-title">Business</h3>
                            </div>
                        </div>
                        <div className="kt-portlet__body">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Setup = () => {
    return (
        <div className="kt-grid kt-grid--hor kt-grid--root">
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <SideMenu />
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper " id="kt_wrapper">
                    <Header/>
                        <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                        {/* begin:: Content */}
                            <DashBoard />
                            <SetupModal  />
                        {/* end:: Content */}
                    </div>
                </div>
            </div>
        </div>                                                               
    );
}

export default Setup;
