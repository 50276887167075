import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import swal from 'sweetalert2';
import Admin from './Admin';
import Login from '../components/login/login';
import Setup from '../components/setup/setup';
import { getAuthState, getTempState } from '../assets/scripts/login-util';
import { redirectToLogout } from "../components/api/common";
import OneSignal from 'react-onesignal';
import { osDevAppId, osLiveAppId } from '../env.json';
import EmailLinkModal from '../components/global/emailLinkModal';

const App = () => {

  const [auth,               setAuth]               = useState(getAuthState('bool'));
  const [setup,              setSetup]              = useState(getTempState('bool'));
  const [oneSignalInit,      setOneSignalInit]      = useState(false);
  const [showEmailLinkModal, setShowEmailLinkModal] = useState(false);

  useEffect(() => {
    const authorized = getAuthState('bool');
    if (authorized === 'Expired') redirectToLogout()
    else {
      setAuth(authorized);
      checkInitialLogin();
    }
  }, []);
 
  useEffect(() => {
    let ignore = false;
    const initializeOneSignal = async() => {
      let onesignalAppId = osDevAppId;
      
      if(process.env.NODE_ENV === 'production' && window.location.host === 'app.insurebox.co') onesignalAppId = osLiveAppId;
      try {
        const user = getAuthState('user');
        if(auth && user){
          OneSignal.init({
            appId: onesignalAppId,
            autoResubscribe: true,
          }).then(() => {
            setOneSignalInit(true);
          })
        }
      } catch (error) {
        swal.fire({
					icon                : 'error',
					title             	: "Something went wrong..!",
					text              	: 'Please Reload...',
					buttonsStyling      : false,
					confirmButtonClass  : 'btn btn-brand',
				});  
      }
    }

    if(!ignore )  initializeOneSignal();
    return () => {
      ignore = true;
    }
  },[auth])

  useEffect(() => {
    const OneSignalSetup = async() => {
      const user = getAuthState('user');
      if(user){  OneSignal.setExternalUserId(`${user.id}`)}  
    }
    if(oneSignalInit) OneSignalSetup();
  }, [oneSignalInit])

  const checkInitialLogin = () => {
    const {initialLogin} = getAuthState();
    if(initialLogin){
      setShowEmailLinkModal(true); 
    }
  }

  const authHandler = async (status) => {
    const authorized = getAuthState('bool');
    if (authorized === 'Expired') setAuth(false);
    switch (status) {
      case 'created':
        setSetup(true);
        break;
      default:
        setSetup(false);
        setAuth(authorized);
        break;
    } 
  };

  return (
    <BrowserRouter>
      {auth ? (
        <>
         {showEmailLinkModal && (
            <EmailLinkModal
              show={showEmailLinkModal}
              onDismissModal={() => setShowEmailLinkModal(false)}
            />
          )}

          <Admin onAuthStateChange={authHandler} />
        </>
      ) : setup ? (
        <Switch>
          <Route path="/setup">
            <Setup onRegistrationComplete={authHandler} />
          </Route>
          <Redirect to="/setup" />
        </Switch>
      ) : (
        <Switch>
          <Route>
            <Login onLogin={authHandler} />
          </Route>
        </Switch>
      )}
    </BrowserRouter>
  );
};

export default App;
