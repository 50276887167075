import React, { useEffect, useState } from 'react';
import '../../assets/styles/header-light.css';
import { Link, useHistory } from 'react-router-dom';
import Bolt from '../../assets/images/bolt.png';
import { redirectToLogout } from '../api/common';
import { getAuthState, setAuthState, verifyLicense } from '../../assets/scripts/login-util';
import { logoutAPI, postAgentLicenseExtension } from '../api/agentAPI';
import swal from "sweetalert2";
import './header.css';
import { dashboardPeriods } from '../../assets/scripts/periodSelection';
import NotificationPanel from './notificationPanel';
import moment from 'moment';
import LicenseChangeReminder from './LicenseChangeReminder';

export const LicenseModal = (props) => {

    const [loading,          setLoading]          = useState(false);

	useEffect(() => {
		$('#license-modal').modal({
			backdrop: 'static',
		});
		$('#license-modal').on('hidden.bs.modal', function () {
			$('.modal').hasClass('show') && $('body').addClass('modal-open');
			props.onDismissModal();   
		});
	}, [])

	const onRequestExtention = async() => {
		try {
			// setLoading(true);
			const response = await postAgentLicenseExtension();
			if(response){
				swal.fire({
					icon : 'success',
					html :
					'<p> Additional License Request Submitted </p>'+
					// '<p> Thank you for choosing <b> InsureBox </b> </p>'+
					'<p> Our Customer Success Manager will get back to you shortly.</p>',
					allowOutsideClick : false,
				})
				$('#license-modal').modal('toggle');
			}
		} catch (err) {
			// setLoading(false);
			swal.fire({
				icon                : 'error',
				titleText           : err.message,
				buttonsStyling      : false,
				confirmButtonClass  : 'btn btn-brand',
			});  
		}
	}

	return (
        <div
			className="modal fade"
			id="license-modal"
			tabIndex="-1"
			role="dialog"
			aria-hidden="true">
        
          	<div className="modal-dialog modal-md" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">
							License
						</h5>
						<button
							type="button"
							className="close"
							data-dismiss="modal"
							aria-label="Close">
						</button>
					</div>
					<div className="modal-body">
						<div className="client-container p-0">
							<div className='text-center'>
								<div className='row'>
									<div className='col'>
										<p className='text-center kt-font-brand font-70 mb-0'>
											<i className="flaticon-signs-2"></i>
										</p>
										<h5> {props.from == 'teams' ? 'License Required' : 'Minimum 3 License Required '}</h5>
										<small> </small>
									</div>
								</div>
								<div className='row mt-4'>
									<div className='col'>
										<button type='button' className="btn btn-sm btn-label-success btn-pill ml-3" onClick={onRequestExtention}>   
											Request for Additional License
										</button>
									</div>
								</div>
							</div>
						</div>
              		</div>
            	</div>
          	</div>
        </div>
	);
}

const header = (props) => {

	const { notificationsList, setrefetchNotifications } = props;

	const history                                    					= useHistory();
	const [username,            		setUsername]       	 			= useState('User');
	const [fullName,            		setFullName]       	 			= useState('');
	const [email,               		setEmail]          	 			= useState('');
	const [companyName,         		setCompanyName]    	 			= useState('');
	const [profilePhoto,        		setProfilePhoto]   	 			= useState(null);
	const [isAdmin,             		setisAdmin]       	 			= useState(false);
	const [showNotification,  			setshowNotification] 			= useState(false);
	const [newAlertCount, 				setNewAlertCount]	 			= useState(0);
	const [showLicenseModal, 			setShowLicenseModal] 			= useState(false);
	// const [showLicenseChangeReminder, 	setShowLicenseChangeReminder]   = useState(false);

	useEffect(() => {
		setuserData();
		//Add Cookie Listner
		document.addEventListener('cookieChange', (c) => {
			const d = setuserData('cookieChange')
			if(d && d.user.categoryId == 1 && d.IsExpired) {
				props.onOpenModal('subscription', true);
			} else if(d && d.user.categoryId == 1 && d.SubAgency === 'N') setShowLicenseModal(true);
			else if (d && (d.licenseReduced && d.licenseReduced.length > 0) && d.user.categoryId == 1){
				props.onOpenModal('LicenseChangeReminder', true);
			} 
		});
	},[])

	useEffect(() => {document.title = props.title}, [props.title])

	//To Set UnRead Count 
	useEffect(() => {
		if(notificationsList.length > 0){
			const unReadCount = notificationsList.filter( n =>  !n.Read ).length;
			setNewAlertCount(unReadCount);
		}
	},[notificationsList])

	const setuserData = (event) => {
		const cData = getAuthState();
		const { user } = cData;
		if (!user && event) return;
		if(!user) return redirectToLogout();

		setUsername(user.firstName);
		setFullName(user.firstName + ' ' + user.lastName);
		setEmail(user.email);
		if(user.profilePhoto != '') setProfilePhoto(user.profilePhoto);
		if(user.categoryId == 1) setisAdmin(true);
		if (user.companyName) setCompanyName(user.companyName);

		return cData;
	}

	const changePeriodHandler = ({ target }) => {
		const period = dashboardPeriods.find(p => p.id == target.value)
		props.setCurrentPeriod(period);
	}

	const logout = async () => {
		try {
			await logoutAPI()	
			setAuthState();
			window.open('/', '_self');
		} catch (err) {
			window.open('/', '_self');
			swal.fire({
				icon              : "error",
				titleText         : "Error!",
				text              :  err.message,
				buttonsStyling    : false,
				confirmButtonClass: "btn btn-brand",
			});
		}
	};

	const showQuickModal = (action) => {
		if (action === 'client')
			props.onOpenModal(action, true, '', '', '', {type: 'both'})
		else
			props.onOpenModal(action, true);
	};

	const navigateTo = (path) => {
		history.push(path);
	};

	const openSearchPolicyHandler = async () => {
		try {
		props.onOpenModal('searchPolicy', true, '', false);
		} catch (err) {
			swal.fire({
				icon              : "error",
				titleText         : "Error!",
				text              :  err.message,
				buttonsStyling    : false,
				confirmButtonClass: "btn btn-brand",
			});
		}
	}

	const closeDrawerHandler = () => {   
		setrefetchNotifications(true);     
		setTimeout(() => {
			setshowNotification(false);
		}, 180);
	};

  	let classes = 'kt-header kt-grid__item  kt-header--fixed header-curtailed';

  	if (props.type === 'full')
    classes = 'kt-header kt-grid__item  kt-header--fixed header-full';
  	else classes = 'kt-header kt-grid__item  kt-header--fixed header-curtailed';

	return (
		<React.Fragment>
		<div id="kt_header" className={classes}>
			<div className="kt-header-menu-wrapper" id="kt_header_menu_wrapper">
				<div id="kt_header_menu" className="kt-header-menu kt-header-menu-mobile  kt-header-menu--layout-default">
					<h3 className="header-title">{props.title}</h3>

            {/* begin: DashBorad Dropdown */}
            {history.location.pathname === '/dashboard' && (
              <div className="kt-header__topbar">
                <div className="kt-header__topbar-item kt-header__topbar-item--langs margin-l20">
                  <div className="kt-header__topbar-wrapper">
                    <span className="kt-header__topbar-icon dashboardHeader">
                      <div className={`kt-margin-b-20-tablet-and-mobile`}>
                        <select                                              
                          className="form-control kt-selectpicker"
                          style={{ width: '150px' }}
                          id="ddlPeriod"
                          value={props.currentPeriod.id}
                          onChange={changePeriodHandler}>                                                        
                          {dashboardPeriods.map((s) => (
                            <option value={s.id} key={s.id}>
                              {s.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {props.currentPeriod.id == 6 && (
                        <React.Fragment>
                          <div className="margin-r10 margin-l10">
                            <input
                              id="fromDate"
                              value={moment(props.fromDate).format('YYYY-MM-DD') || ''}
                              max={moment().format('YYYY-MM-DD')}
                              name="fromDate"
                              className="form-control"
                              type="date"
                              onChange={(event) => props.setFromDate(event.target.value)}
                            />
                          </div>
                          <div className="margin-r10">
                            <label>To</label>
                          </div>
                          <input
                            id="toDate"
                            value={moment(props.toDate).format('YYYY-MM-DD') || ''}
                            max={moment().format('YYYY-MM-DD')}
                            name="toDate"
                            className="form-control"
                            type="date"
                            onChange={(event) => props.setToDate(event.target.value)}
                          />
                          <button className="btn btn-brand margin-l10" onClick={() => props.fetchPolicies(true)}>
                            <i className="la la-search"></i>
                          </button>
                        </React.Fragment>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* end: DashBorad Dropdown */}
          
        </div>

			<div className="kt-header__topbar">   
				{/* begin: Notification */}
				<div className="kt-header__topbar-item kt-header__topbar-item--langs mr-2">
					<div className="kt-header__topbar-wrapper" style={{ marginTop: '11px' }}>
						<span className="kt-nav__link" onClick={() => setshowNotification(true) } >
							<span className="kt-header__topbar-icon notifcation-bell" data-toggle="kt-tooltip" data-placement="bottom" data-original-title="Notifications" >
								<i className="flaticon2-bell-3"></i>
								{newAlertCount > 0 && (
									<span className="notification-badge">
										{ newAlertCount > 9 ? <span> 9+ </span>: <span> {newAlertCount} </span>} 
									</span>
								)}
							</span>
						</span>
					</div>  
				</div>
				{/* end: Notification */}

				{/* begin: Quick Action */}
				<div className="kt-header__topbar-item kt-header__topbar-item--langs mr-2">
					<div className="kt-header__topbar-wrapper" data-toggle="dropdown" data-offset="10px,0px">
					<span className="kt-header__topbar-icon btn-quick-actions" data-toggle="kt-tooltip" data-placement="bottom" data-original-title="Quick Actions" style={{ borderRadius: '50%' }}>
						<img src={Bolt} alt="Quick-Actions" />
					</span>
					</div>
					<div className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
					<ul className="kt-nav kt-margin-t-10 kt-margin-b-10">
						<li className="kt-nav__item">
							<a onClick={() => showQuickModal('policy')} className="kt-nav__link">
								<span className="kt-nav__link-icon">
									<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon" data-toggle="kt-tooltip" data-placement="right">
										<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
											<rect x="0" y="0" width="24" height="24"></rect>
											<rect fill="#000000" opacity="0.3" x="13" y="4" width="3" height="16" rx="1.5"></rect>
											<rect fill="#000000" x="8" y="9" width="3" height="11" rx="1.5"></rect>
											<rect fill="#000000" x="18" y="11" width="3" height="9" rx="1.5"></rect>
											<rect fill="#000000" x="3" y="13" width="3" height="7" rx="1.5"></rect>
										</g>
									</svg>
								</span>
								<span className="kt-nav__link-text">Policy</span>
							</a>
						</li>
						<li className="kt-nav__item">
							<a onClick={() => showQuickModal('client')} className="kt-nav__link">
								<span className="kt-nav__link-icon">
									<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon" data-toggle="kt-tooltip" data-placement="right">
										<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
											<polygon points="0 0 24 0 24 24 0 24" />
											<path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3"/>
											<path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero"/>
										</g>
									</svg>
								</span>
								<span className="kt-nav__link-text">Client</span>
							</a>
						</li>
						<li className="kt-nav__item">
							<a onClick={() => showQuickModal('task')} className="kt-nav__link">
								<span className="kt-nav__link-icon">
									<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon" data-toggle="kt-tooltip" data-placement="right">
										<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
											<rect x="0" y="0" width="24" height="24" />
											<path d="M7.14319965,19.3575259 C7.67122143,19.7615175 8.25104409,20.1012165 8.87097532,20.3649307 L7.89205065,22.0604779 C7.61590828,22.5387706 7.00431787,22.7026457 6.52602525,22.4265033 C6.04773263,22.150361 5.88385747,21.5387706 6.15999985,21.0604779 L7.14319965,19.3575259 Z M15.1367085,20.3616573 C15.756345,20.0972995 16.3358198,19.7569961 16.8634386,19.3524415 L17.8320512,21.0301278 C18.1081936,21.5084204 17.9443184,22.1200108 17.4660258,22.3961532 C16.9877332,22.6722956 16.3761428,22.5084204 16.1000004,22.0301278 L15.1367085,20.3616573 Z" fill="#000000"/>
											<path d="M12,21 C7.581722,21 4,17.418278 4,13 C4,8.581722 7.581722,5 12,5 C16.418278,5 20,8.581722 20,13 C20,17.418278 16.418278,21 12,21 Z M19.068812,3.25407593 L20.8181344,5.00339833 C21.4039208,5.58918477 21.4039208,6.53893224 20.8181344,7.12471868 C20.2323479,7.71050512 19.2826005,7.71050512 18.696814,7.12471868 L16.9474916,5.37539627 C16.3617052,4.78960984 16.3617052,3.83986237 16.9474916,3.25407593 C17.5332781,2.66828949 18.4830255,2.66828949 19.068812,3.25407593 Z M5.29862906,2.88207799 C5.8844155,2.29629155 6.83416297,2.29629155 7.41994941,2.88207799 C8.00573585,3.46786443 8.00573585,4.4176119 7.41994941,5.00339833 L5.29862906,7.12471868 C4.71284263,7.71050512 3.76309516,7.71050512 3.17730872,7.12471868 C2.59152228,6.53893224 2.59152228,5.58918477 3.17730872,5.00339833 L5.29862906,2.88207799 Z" fill="#000000" opacity="0.3"/>
											<path d="M11.9630156,7.5 L12.0475062,7.5 C12.3043819,7.5 12.5194647,7.69464724 12.5450248,7.95024814 L13,12.5 L16.2480695,14.3560397 C16.403857,14.4450611 16.5,14.6107328 16.5,14.7901613 L16.5,15 C16.5,15.2109164 16.3290185,15.3818979 16.1181021,15.3818979 C16.0841582,15.3818979 16.0503659,15.3773725 16.0176181,15.3684413 L11.3986612,14.1087258 C11.1672824,14.0456225 11.0132986,13.8271186 11.0316926,13.5879956 L11.4644883,7.96165175 C11.4845267,7.70115317 11.7017474,7.5 11.9630156,7.5 Z" fill="#000000"/>
										</g>
									</svg>
								</span>
								<span className="kt-nav__link-text">Task</span>
							</a>
						</li>
						<li className="kt-nav__item">
							<a onClick={() => showQuickModal('file')} className="kt-nav__link">
								<span className="kt-nav__link-icon">
									<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
										<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
											<polygon points="0 0 24 0 24 24 0 24" />
											<path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fillRule="nonzero" opacity="0.3"/>
											<rect fill="#000000" x="6" y="11" width="9" height="2" rx="1"/>
											<rect fill="#000000" x="6" y="15" width="5" height="2" rx="1"/>
										</g>
									</svg>
								</span>
								<span className="kt-nav__link-text">File</span>
							</a>
						</li>
						<li className="kt-nav__item">
							<a onClick={() => navigateTo('/batch-upload/policies/upload')} className="kt-nav__link">
								<span className="kt-nav__link-icon">
									<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
										<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
											<rect x="0" y="0" width="24" height="24" />
											<path d="M2,13 C2,12.5 2.5,12 3,12 C3.5,12 4,12.5 4,13 C4,13.3333333 4,15 4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 C2,15 2,13.3333333 2,13 Z" fill="#000000" fillRule="nonzero" opacity="0.3"/>
											<rect fill="#000000" opacity="0.3" x="11" y="2" width="2" height="14" rx="1"/>
											<path d="M12.0362375,3.37797611 L7.70710678,7.70710678 C7.31658249,8.09763107 6.68341751,8.09763107 6.29289322,7.70710678 C5.90236893,7.31658249 5.90236893,6.68341751 6.29289322,6.29289322 L11.2928932,1.29289322 C11.6689749,0.916811528 12.2736364,0.900910387 12.6689647,1.25670585 L17.6689647,5.75670585 C18.0794748,6.12616487 18.1127532,6.75845471 17.7432941,7.16896473 C17.3738351,7.57947475 16.7415453,7.61275317 16.3310353,7.24329415 L12.0362375,3.37797611 Z" fill="#000000" fillRule="nonzero"/>
										</g>
									</svg>
								</span>
								<span className="kt-nav__link-text">
									Policy Data Upload
								</span>
							</a>
						</li>
						<li className="kt-nav__item">
							<a onClick={() => navigateTo('/batch-upload/clients/upload')} className="kt-nav__link">
								<span className="kt-nav__link-icon">
									<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
										<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
											<rect x="0" y="0" width="24" height="24" />
											<path d="M2,13 C2,12.5 2.5,12 3,12 C3.5,12 4,12.5 4,13 C4,13.3333333 4,15 4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 C2,15 2,13.3333333 2,13 Z" fill="#000000" fillRule="nonzero" opacity="0.3"/>
											<rect fill="#000000" opacity="0.3" x="11" y="2" width="2" height="14" rx="1"/>
											<path d="M12.0362375,3.37797611 L7.70710678,7.70710678 C7.31658249,8.09763107 6.68341751,8.09763107 6.29289322,7.70710678 C5.90236893,7.31658249 5.90236893,6.68341751 6.29289322,6.29289322 L11.2928932,1.29289322 C11.6689749,0.916811528 12.2736364,0.900910387 12.6689647,1.25670585 L17.6689647,5.75670585 C18.0794748,6.12616487 18.1127532,6.75845471 17.7432941,7.16896473 C17.3738351,7.57947475 16.7415453,7.61275317 16.3310353,7.24329415 L12.0362375,3.37797611 Z" fill="#000000" fillRule="nonzero"/>
										</g>
									</svg>
								</span>
								<span className="kt-nav__link-text">
									Client Data Upload
								</span>
							</a>
						</li>
					</ul>
					</div>
				</div>
				{/* end: Quick Action */}

				{/* begin: Search */}
				<div className="kt-header__topbar-item kt-header__topbar-item--langs mr-2">
					<div
					className="kt-header__topbar-wrapper"
					data-offset="10px,0px"
					style={{ marginTop: '10px' }}>
					<span className="kt-nav__link">
						<span className="kt-header__topbar-icon" data-toggle="kt-tooltip" data-placement="bottom" data-original-title="Search Policy" onClick={openSearchPolicyHandler}>
							<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
								<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
									<rect x="0" y="0" width="24" height="24" />
									<path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3"/>
									<path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fillRule="nonzero"/>
								</g>
							</svg>
						</span>
					</span>
					</div>
				</div>
				{/* end: Search */}

				{/* begin: User Bar */}
				<div className="kt-header__topbar-item kt-header__topbar-item--user">
					<div className="kt-header__topbar-wrapper" data-toggle="dropdown" data-offset="0px,0px">
						<div className="kt-header__topbar-user">
							<span className="kt-header__topbar-welcome kt-hidden-mobile">
								Hi,
							</span>
							<span className="kt-header__topbar-username kt-hidden-mobile">
								{username}
							</span>
							<span className="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold">
								{username[0].toUpperCase()}
							</span>
						</div>
					</div>

					<div className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
						<div className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x padding-tb15 padding-r30">           
							<div className="kt-user-card-v2">
								<div className="kt-user-card-v2__pic margin-r10">
									{profilePhoto ?
									<img src={profilePhoto} alt="profile-image"/>
									:<div className="kt-badge kt-badge--xl kt-badge--info">{username[0].toUpperCase()}</div>
									}
								</div>
								<div className="kt-user-card-v2__details">
									<span className="kt-user-card-v2__name">{fullName}</span><span className="kt-user-card-v2__desc margin-t0"><small>{email}<br/>{companyName}</small></span>
								</div>
							</div>
						</div>
					<div className="kt-notification">
						<Link to="/profile" className="kt-notification__item padding-tb10">
						<div className="kt-notification__item-icon">
							<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
								<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
									<polygon points="0 0 24 0 24 24 0 24"/>
									<path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3"/>
									<path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fillRule="nonzero"/>
								</g>
							</svg>
						</div>
						<div className="kt-notification__item-details">
							<div className="kt-notification__item-title">
							My Profile
							</div>
						</div>
						</Link>
						{/* {isAdmin &&
						(<Link to="/team" className="kt-notification__item padding-tb10">
							<div className="kt-notification__item-icon">
							<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
								<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
								<polygon points="0 0 24 0 24 24 0 24"/>
								<path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3"/>
								<path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero"/>
								</g>
							</svg>
							</div>
							<div className="kt-notification__item-details">
							<div className="kt-notification__item-title">
								My Team
							</div>
							</div>
						</Link>)
						} */}
						<Link to="/report" className="kt-notification__item padding-tb10">
						<div className="kt-notification__item-icon">
							<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
							<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
								<rect x="0" y="0" width="24" height="24"/>
								<path d="M16,15.6315789 L16,12 C16,10.3431458 14.6568542,9 13,9 L6.16183229,9 L6.16183229,5.52631579 C6.16183229,4.13107011 7.29290239,3 8.68814808,3 L20.4776218,3 C21.8728674,3 23.0039375,4.13107011 23.0039375,5.52631579 L23.0039375,13.1052632 L23.0206157,17.786793 C23.0215995,18.0629336 22.7985408,18.2875874 22.5224001,18.2885711 C22.3891754,18.2890457 22.2612702,18.2363324 22.1670655,18.1421277 L19.6565168,15.6315789 L16,15.6315789 Z" fill="#000000"/>
								<path d="M1.98505595,18 L1.98505595,13 C1.98505595,11.8954305 2.88048645,11 3.98505595,11 L11.9850559,11 C13.0896254,11 13.9850559,11.8954305 13.9850559,13 L13.9850559,18 C13.9850559,19.1045695 13.0896254,20 11.9850559,20 L4.10078614,20 L2.85693427,21.1905292 C2.65744295,21.3814685 2.34093638,21.3745358 2.14999706,21.1750444 C2.06092565,21.0819836 2.01120804,20.958136 2.01120804,20.8293182 L2.01120804,18.32426 C1.99400175,18.2187196 1.98505595,18.1104045 1.98505595,18 Z M6.5,14 C6.22385763,14 6,14.2238576 6,14.5 C6,14.7761424 6.22385763,15 6.5,15 L11.5,15 C11.7761424,15 12,14.7761424 12,14.5 C12,14.2238576 11.7761424,14 11.5,14 L6.5,14 Z M9.5,16 C9.22385763,16 9,16.2238576 9,16.5 C9,16.7761424 9.22385763,17 9.5,17 L11.5,17 C11.7761424,17 12,16.7761424 12,16.5 C12,16.2238576 11.7761424,16 11.5,16 L9.5,16 Z" fill="#000000" opacity="0.3"/>
							</g>
						</svg>
						</div>
						<div className="kt-notification__item-details">
							<div className="kt-notification__item-title">
							Support
							</div>
						</div>
						</Link>
						<div className="kt-notification__custom kt-space-between padding-tb10">
						<button
							onClick={logout}
							className="btn btn-label btn-label-brand btn-sm btn-bold">
							Sign Out
						</button>
						</div>
					</div>
					</div>
				</div>
				{/* end: User Bar */}
			</div>
		</div>
		{showNotification && (
			<NotificationPanel 
				show = {showNotification}
				onCloseDrawer = {closeDrawerHandler}
				NotificationList = {notificationsList}
				setRefetch = {(v) => setrefetchNotifications(v)}
			/>
		)}  

		{showLicenseModal && (
			<LicenseModal 
				show 		   = {showLicenseModal}
				onDismissModal = {() => setShowLicenseModal(false)}				
			/>
		)}  
		{/* {showLicenseChangeReminder && (
			<LicenseChangeReminder
				show 		   = {showLicenseChangeReminder}
				onDismissModal = {() => showLicenseChangeReminder(false)}				
			/>
		)}   */}

		</React.Fragment>
	);
};

export default header;
