import { axiosPost, axiosGet, axiosDelete, axiosPatch } from "./axiosFunctions";
import { getTokenOrLogout } from "./common";

const getTaskUrl                  = '/api/task/getTasks';
const getCompletedTaskUrl         = '/api/task/getCompletedTasks';
const addTaskUrl                  = '/api/task/addTask';
const addAssignedTaskUrl          = '/api/task/addAssignedTask';
const updateTaskUrl               = '/api/task/updateTask';
const deleteTaskUrl               = (taskId) => `/api/task/deleteTask/${taskId}`;
const deleteAssignedTaskUrl       = (assignedTaskId, agentId) => `/api/task/deleteAssignedTask/${assignedTaskId}/${agentId}`;
const completeTaskUrl             = (taskId) => `/api/task/completeTask/${taskId}`;
const pendingTaskUrl              = (taskId) => `/api/task/markTaskAsPending/${taskId}`;
const getAssignedTaskUrl          = (taskId, createdById) => `/api/task/getAssignedTask/${taskId}/${createdById}`;

export const getTask = async () => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000); 
  
    const response = await axiosGet(getTaskUrl, {
        headers: { auth_token: `bearer ${token}` },
    });
    return response;
};

export const getCompletedTask = async () => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    const response = await axiosGet(getCompletedTaskUrl, {
        headers: { auth_token: `bearer ${token}` },
    });
    return response;
};

export const getAssignedTask = async (taskId, createdById) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  
    
    const response = await axiosGet(
        getAssignedTaskUrl(taskId, createdById), 
        { headers: { auth_token: `bearer ${token}` },
    });
    return response;
}

export const addTask = async (task) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  
    
    const response = await axiosPost(
        addTaskUrl, 
        { task },
        {headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const addAssignedTask = async (assignedTask) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  

    const response = await axiosPost(
        addAssignedTaskUrl, 
        { assignedTask },
        {headers: { auth_token: `bearer ${token}` } }
    );
    return response;
}

export const updateTask = async (task) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  
    
    const response = await axiosPatch(
        updateTaskUrl, 
        { task },
        {headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const deleteTaskData = async (taskId) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  
    
    const response = await axiosDelete(
        deleteTaskUrl(taskId),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const deleteAssignedTask = async (assignedTaskId, agentId) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  
    
    const response = await axiosDelete(
        deleteAssignedTaskUrl(assignedTaskId, agentId),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const completeTask = async (taskId) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  
    
    const response = await axiosPatch(
        completeTaskUrl(taskId),
        {},
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const markTaskAsPending = async (taskId) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  
    
    const response = await axiosPatch(
        pendingTaskUrl(taskId),
        {},
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
}