import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import $ from 'jquery';
import 'jquery-form';
import 'jquery-validation';
import '../../assets/scripts/jquery-validation.init';
import * as helper from '../global/helper';
import swal from 'sweetalert2';
import { agentSignup, sendReferralCodeRequest, googleSignupAPI} from '../api/agentAPI';
import { setTempState } from '../../assets/scripts/login-util';
import { googleLogin } from './loginHelper';



const RequestCodeModal = (props) => {
  const [data, setData] = useState({});

  useEffect(() => {
      $('#requestcode-modal').modal({ backdrop: 'static' });

      $('#requestcode-modal').on('hidden.bs.modal', function () {
        $('.modal').hasClass('show') && $('body').addClass('modal-open');   
        props.onDismissModal();        
      });
  }, []);


  const handleFormInput = (key, value) => {
    setData( () => {
      return {...data, [key] : value};
    })
  }

  const requestCode = async (e) => {
    e.preventDefault();

    var form = $('#requestcode-form');
    form.validate().destroy();

    form.validate({
      rules: {
        Name: {
          required: true,
        },
        Email: {
          required: true,
        },
        ContactNo: {
          required: true,
        },
        EmployeeStrength: {
          required: true,
        }
      },
    });

    if (!form.valid()) return;

    try {
      helper.StartProcessing($('#requestCode'));
      await sendReferralCodeRequest(data);

    } catch (err) {
      swal.fire({
        icon              : 'error',
        titleText         : 'Error!',
        text              : err.message,
        buttonsStyling    : false,
        confirmButtonClass: 'btn btn-brand',
      });
      
      return helper.StopProcessing($('#requestCode'));
    }

    helper.StopProcessing($('#requestCode'));
    $('#requestcode-modal').modal('hide');
    swal.fire({
      toast               : true,
      icon                : 'success',
      titleText           : `Request Sent successfully!`,
      position            : 'bottom-end',
      showConfirmButton   : false,
      timer               : 1500,
      animation           : false,
      customClass         : {
        popup: 'margin-20',
      },
    });
  };

  return (
    <div
      className="modal fade"
      id="requestcode-modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true">
    
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
             Request Promo Code
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close">
            </button>
          </div>
          <div className="modal-body">
            <div className="client-container">
              <form id="requestcode-form" onSubmit={requestCode}>
                <div className="row">
                  <div className="col-12">
                    <input
                      id="Name"
                      value={data.Name}
                      name="Name"
                      placeholder="Name"
                      className="form-control margin-b10"
                      type="text"
                      onChange={({ target: { value } }) => handleFormInput('Name', helper.capitalize(value)) }/>          
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <input
                      id="Email"
                      value={data.Email}
                      name="Email"
                      placeholder="Email"
                      className="form-control margin-b10"
                      type="text"
                      onChange={({ target: { value } }) => handleFormInput( 'Email', helper.capitalize(value))}/>                          
                  </div>

                  <div className="col-3">
                    <input
                      id="ContactNo"
                      value={data.ContactNo}
                      name="ContactNo"
                      placeholder="Contact No"
                      className="form-control margin-b10"
                      type="text"
                      onChange={({ target: { value } }) => handleFormInput('ContactNo', value)}/>                          
                  </div>
                  <div className="col-3">
                    <input
                      id="EmployeeStrength"
                      value={data.EmployeeStrength}
                      name="EmployeeStrength"
                      placeholder="Employee Strength"
                      className="form-control margin-b10"
                      type="Number"
                      min="1"
                      max="100"
                      onChange={({ target: { value } }) => handleFormInput('EmployeeStrength', value)}/>                          
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary margin-0 margin-r5"
              data-dismiss="modal">   
              Close
            </button>
            <button
              id="requestCode"
              onClick={requestCode}
              type="button"
              className="btn btn-brand margin-0">   
              Request
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};


const SignUp = (props) => {
  const googleErrorLogs = ['auth/popup-closed-by-user', 'auth/popup-blocked', 'auth/cancelled-popup-request'];
  const history                           = useHistory();
  const [user,          setUser]          = useState({});
  const [showCodeModal, setCodeModal]     = useState(false); 
  const [showPassword,  setShowPassword]  = useState(false);
  const [loading,  setLoading]            = useState(false);

  useEffect(() => {
    document.title = "Sign Up For Free Trial | Insurebox.co"
    document.getElementsByTagName('meta')["description"].content = "Register today and get an insurance software free trial from Insurebox."
  })

  const showPasswordHandler = () => setShowPassword(!showPassword);

  const userDataHandler = (key, value) => {
    setUser( d => { return {...d, [key] : value}; })
  }
  
  const submitHandler = async (event) => {
    event.preventDefault();
    const btn = $('#kt_login_signup_submit');
    const form = $('#kt-signup-form');
    form.validate().destroy();

    form.validate({
      rules: {
        email: {
          required: true,
          email: true,
        },
        password: {
          required: true,
          minlength : 6,
        },
        promoCode: {
          required: true,
        },
      },
  
    });
    if (!form.valid()) return;
      
    helper.StartProcessing(btn);
    try {
     const response =  await agentSignup(user);

     if(!response.serverTimestamp){
        //Create a Session Cookie and Push user to Setup
        setTempState(response);
        props.onLogin('created');
     }else{
        history.push({ pathname: '/verify/email', state: {Email : user.Email, serverTimestamp : response.serverTimestamp }});
     }
    } catch (err) {
      swal.fire({
        icon               : 'warning',
        text               : err.message || 'Unkown error',
        buttonsStyling     : false,
        confirmButtonClass : 'btn btn-brand'
      });
      return helper.StopProcessing(btn);
    }
  };

  const onGoogleSignup = async(e) => {
    const form = $('#kt-signup-form');
    const btn = $('#kt_login_signin_google');
    form.validate().destroy();

    form.validate({
      rules: {
        email: {
          required: false,
        },
        password: {
          required: false,
        },
        promoCode: {
          required: true,
        },
      },
    });

    if (!form.valid()) return; 

    setLoading(true)
    helper.StartProcessing(btn)
    try {
      const IdToken = await googleLogin();
      if(IdToken){
        const params = await googleSignupAPI({
          googleTokenId : IdToken,
          referralCode  : user.ReferralCode,
        });

        if(params){
          setTempState(params);
          props.onLogin('created');
        }
      }
    } catch (err) {
      setLoading(false)
      helper.StopProcessing(btn); 
      if(googleErrorLogs.includes(err.message) ) return;
      let titleText = err.message == 'User already exist'?'Please Login' : 'Error!';
      swal.fire({
        icon              : "error",
        titleText         : titleText,
        text              : err.message,
        buttonsStyling    : false,
        confirmButtonClass: "btn btn-brand",
      });
    }
  } 

  return (
    <>
      <div className="kt-login__signup">
        <div className="kt-login__head">
          <h3 className="kt-login__title">Create an account</h3>
        </div>
        <form className="kt-form" id='kt-signup-form'>
          <div className="input-group">
            <input
              onChange={({ target }) =>userDataHandler('Email', target.value.trim())}
              className="form-control form-control-lg"
              type="text"
              placeholder="Email"
              name="email"
              value={user.Email || ''}
              autoComplete="off"
            />
          </div>
          <div className="input-group">
            <div className="kt-input-icon kt-input-icon--right">
              <input 
                name="password"
                type={showPassword ? 'text' : 'password'} 
                value = {user.Password || ''}
                className="form-control  password-input form-control-lg" 
                placeholder="Create Password"
                onChange={({target}) => userDataHandler('Password', target.value.trim())}
              />
              <span className="kt-input-icon__icon kt-input-icon__icon--right show-password-icon" onClick={showPasswordHandler} >
                <span>  <i className={showPassword?"fa fa-eye": "fa fa-eye-slash"}></i> </span>
              </span>
            </div>

          </div>
          <div className="input-group">
            <input
              onChange={({target}) => userDataHandler('ReferralCode', target.value.trim())}
              className="form-control form-control-lg"
              type="text"
              value={user.ReferralCode || ''}
              minLength="6"
              placeholder="Promo code"
              name="promoCode"
              autoComplete="off"
            />
          </div>
          <div className="row kt-login__extra mt-2">
            <div className="col text-right mr-2">
              <span id="kt_login_forgot" className="referral-code-link" onClick={()=> setCodeModal(true)} >Request Promo Code</span>
            </div>
          </div>
    

          <div className="kt-login__actions">
            <button
              type='button'
              onClick={submitHandler}
              disabled={loading}
              id="kt_login_signup_submit"
              className="btn btn-theme btn-elevate btn-block kt-login__btn-primary"
            >
              Sign Up
            </button>
          </div>
          <div className="kt-login__actions text-center margin-t0 google-Btn-lg">
          <h6 className="text-center  margin-tb10" > OR </h6>
            <button
              type="button"
              onClick={onGoogleSignup}
              disabled={loading}
              id="kt_login_signin_google"
              className="btn btn-elevate btn-block kt-login__btn btn-light"
            >
              {helper.googleBtn}<span className="ml-1" > Sign Up with Google</span>
            </button>
          </div>
          <div className="kt-login__account margin-t20">
            <span className="kt-login__account-msg">Already registered ?</span>
            &nbsp;&nbsp;
            <Link to="/signin" className="kt-login__account-link">
              Sign In!
            </Link>
          </div>
          <div className="kt-login__account margin-t10">
            <span className="kt-login__account-msg">
              By continuing you agree to our
            </span>
            &nbsp;&nbsp;
            <a href="https://insurebox.co/terms-condition" className="kt-login__account-link" target="_blank" rel="noopener noreferrer" >
              terms and conditions
            </a>
          </div>
        </form>
      </div>
      {showCodeModal && (
        <RequestCodeModal 
          onDismissModal={() => setCodeModal(false)}
        />
      )}
    </>
  );
};

export default SignUp;
