import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import 'jquery-form';
import 'jquery-validation';
import '../../assets/scripts/bootstrap-modal';
import '../../assets/scripts/jquery-validation.init';
import * as helper from '../global/helper';
import swal from 'sweetalert2';
import { addclient } from '../api/clientAPI';
import { addSubClient } from '../api/subAgencyAPI';

const AddMember = (props) => {
    const [member, setMember] = useState({});
    const [type,   setType]   = useState('Ind');

    const memberDataHandler = (key, value) =>
        setMember((m) => ({
        ...m,
        GroupID: props.groupId,
        [key]: value
        // [key]: ['FirstName', 'MiddleName', 'LastName'].includes(key)
        //     ? helper.capitalizeCamel(value)
        //     : value,
    }));

    useEffect(() => {
        let ignore = false;
        if (!ignore) {
            $('#add-member-modal').on('hidden.bs.modal', function () {
                $('.modal').hasClass('show') && $('body').addClass('modal-open');
                $(props.from).removeClass('modal-blur');
                var form = $('#add-member-form');
                form.clearForm();
                form.validate().resetForm();
                setMember({})
            });
        }
        return () => {
          ignore = true;
        };
    }, []);

    const addMember = async (e) => {
        e.preventDefault();
    
        var form = $('#add-member-form');
        form.validate().destroy();
    
        form.validate({
          rules: {
            subCompanyName: {
              required: true,
            },
            firstName: {
              required: true,
            },
            lastName: {
              required: true,
            },
            email: {
              email: {
                depends: function (element) {
                  return $(element).val() !== '';
                },
              },
            },
            mobile: {
              minlength: 10,
              maxlength: 10,
            },
          },
        });
    
        if (!form.valid()) {
          return;
        }
    
        try {
          helper.StartProcessing($('#save-member'));
          let ID = ''
          // if (props.agent)
          //   ID = await addSubClient(member, props.groupId);  
          // else
            ID = await addclient(member, props.groupId);
          props.addMemberId(ID);
        } catch (err) {
          swal.fire({
            icon                : 'error',
            titleText           : 'Error!',
            text                : err.message,
            buttonsStyling      : false,
            confirmButtonClass  : 'btn btn-brand',
          });
          helper.StopProcessing($('#save-member'));
          return;
        }
    
        helper.StopProcessing($('#save-member'));
        $('#add-member-modal').modal('hide');
        swal.fire({
          toast             : true,
          icon              : 'success',
          titleText         : 'Member Added successfully!',
          position          : 'bottom-end',
          showConfirmButton : false,
          timer             : 1500,
          animation         : false,
          customClass       : {
            popup: 'margin-20',
          },
        });
    };

    const clientTypeHandler = (type) => {
      setType(type);
    };  

    return (
        <div
          className="modal fade"
          id="add-member-modal"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">                             
                <h5 className="modal-title">Add Member</h5>              
                <button
                  type="button"
                  className="close margin-l0"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="client-container">
                  <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded">
                      {props.agent && (
                        <table className="kt-datatable__table table-striped margin-b20">
                          <tbody className="kt-datatable__body" style={{ display: 'block' }}>
                              <tr className="kt-datatable__row block-row">
                                  <td className="kt-datatable__cell text-clip">
                                      <div className="kt-user-card-v2">
                                          <div className="kt-user-card-v2__pic">
                                              <div className='kt-badge kt-badge--xl kt-badge--info'>                                                                                 
                                                {props.agent.DisplayName.slice(0, 1)}
                                              </div>
                                          </div>
                                          <div className="kt-user-card-v2__details">
                                              <span className="kt-user-card-v2__name">{props.agent.DisplayName}</span>
                                              <span className="kt-user-card-v2__desc">{props.agent.CategoryName}</span>
                                          </div>
                                      </div>
                                  </td>
                              </tr>
                          </tbody>
                        </table>
                      )}
                      <table className="kt-datatable__table table-striped">
                          <tbody className="kt-datatable__body" style={{ display: 'block' }}>
                              <tr className="kt-datatable__row block-row">
                                  <td className="kt-datatable__cell text-clip">
                                      <div className="kt-user-card-v2">
                                          <div className="kt-user-card-v2__pic">
                                              <div className={`kt-badge kt-badge--xl kt-badge--${ props.client.Type === 'Corporate' ? 'success' : 'info' }`}>                                                                                 
                                                {props.client.DisplayName.slice(0, 1)}
                                              </div>
                                          </div>
                                          <div className="kt-user-card-v2__details">
                                              <span className="kt-user-card-v2__name">{props.client.DisplayName}</span>
                                              <span className="kt-user-card-v2__desc">{props.client.Type}</span>
                                          </div>
                                      </div>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
                  {((props.client.Type === 'Corporate')) && (
                    <>
                      <div className="kt-radio-inline radio-container">
                        <div className="input-group">
                          {' '}
                          <input
                            value="Ind"
                            checked={type === 'Ind'}
                            onChange={({ target }) => clientTypeHandler(target.value)}
                            type="radio"
                            name="clientType"
                            id="ind"
                          />
                          <label htmlFor="ind">Individual</label>
                        </div>
                        <div className="input-group margin-l20">
                          {' '}
                          <input
                            value="Co"
                            checked={type === 'Co'}
                            onChange={({ target }) => clientTypeHandler(target.value)}
                            type="radio"
                            name="clientType"
                            id="co"
                          />
                          <label htmlFor="co">Organization</label>
                        </div>
                      </div>
                    </>
                  )}
                  <form id="add-member-form" onSubmit={addMember}>
                    {type === 'Co' ? (
                      <input
                        id="subCompanyName"
                        value={member.SubCompanyName || ''}
                        name="subCompanyName"
                        placeholder="Company Name"
                        className="form-control margin-b10"
                        type="text"
                        onChange={(event) =>
                          memberDataHandler(
                            'SubCompanyName',
                            event.target.value
                          )
                        }
                      />
                    ) : (
                      <div className="row">
                        <div className="col-md-4">
                          <input
                            id="firstName"
                            value={member.FirstName || ''}
                            name="firstName"
                            placeholder="First Name"
                            className="form-control margin-b10"
                            type="text"
                            onChange={(event) =>
                              memberDataHandler('FirstName', event.target.value)
                            }
                          />
                        </div>
                        <div className="col-md-4">
                          <input
                            id="middleName"
                            value={member.MiddleName || ''}
                            name="middleName"
                            placeholder="Middle Name"
                            className="form-control margin-b10"
                            type="text"
                            onChange={(event) =>
                              memberDataHandler('MiddleName', event.target.value)
                            }
                          />
                        </div>
                        <div className="col-md-4">
                          <input
                            id="lastName"
                            value={member.LastName || ''}
                            name="lastName"
                            placeholder="Last Name"
                            className="form-control margin-b10"
                            type="text"
                            onChange={(event) =>
                              memberDataHandler('LastName', event.target.value)
                            }
                          />
                        </div>
                      </div>
                    )}               
                    <div className="row">
                      <div className="col-md-6">
                        <input
                          id="email"
                          value={member.Email || ''}
                          name="email"
                          placeholder="Email"
                          className="form-control margin-b10"
                          type="text"
                          onChange={(event) =>
                            memberDataHandler('Email', event.target.value)
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          id="mobile"
                          value={member.Mobile || ''}
                          name="mobile"
                          placeholder="Contact No."
                          className="form-control margin-b10"
                          type="number"
                          onChange={(event) =>
                            memberDataHandler('Mobile', event.target.value)
                          }
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary margin-0 margin-r5"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  id="save-member"
                  onClick={addMember}
                  type="button"
                  className="btn btn-brand margin-0"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
    );
};

export default AddMember;