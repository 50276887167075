import { axiosPost, axiosGet, axiosDelete } from "./axiosFunctions";
import { getTokenOrLogout } from "./common";

const AddQuickTextUrl = '/api/quickText/addQuickText';
const GetQuickTextUrl = '/api/quickText/getQuickText';
const deleteQuickTextUrl = (quickTextId) => `/api/quickText/deleteQuickText/${quickTextId}`;
const updateQuickTextUrl = '/api/quickText/updateQuickText';

export const addQuickText = async (quickText) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  
    
    const response = await axiosPost(
        AddQuickTextUrl,
        { quickText },
        {headers: { auth_token: `bearer ${token}` } }
    );
    return response;
}

export const getQuickText = async () => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  
    
    const response = await axiosGet(GetQuickTextUrl, {
        headers: { auth_token: `bearer ${token}` },
    });
    return response;
};

export const deleteQuickText = async (quickTextId) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  
    
    const response = await axiosDelete(
        deleteQuickTextUrl(quickTextId),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const updateQuickText = async (quickText) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  
    
    const response = await axiosPost(
        updateQuickTextUrl,
        { quickText },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
}