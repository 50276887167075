import React, { useEffect, useLayoutEffect, useState } from "react";
import $ from 'jquery';
import 'jquery-form';
import 'jquery-validation';
import '../../assets/scripts/jquery-validation.init';
import Select from 'react-select';
import moment from 'moment';
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import {getStatesAndCities} from "../api/agentAPI"
import { addSubAgent, getPayoutCategory, getSubAgentCategoryByAgentID, updateSubAgent, verifySubAgentCodeByAgentCompanyID, verifySubAgentEmail } from "../api/subAgencyAPI";
import TagCategory from "./tagCategory";
import '../../assets/styles/wizard-3.css';


const AddSubAgentModal = (props) => {

    const stepList = ['AgentDetails', 'AddPayoutPlan'];

    const [agent,                            setAgent]                          = useState({TDS: 0});
    const [states,                           setStates]                         = useState([]);
    const [cities,                           setCities]                         = useState([]);
    const [filteredCities,                   setFilteredCities]                 = useState([]);
    const [selectedState,                    setSelectedState]                  = useState(null);
    const [selectedCity,                     setSelectedCity]                   = useState(null);
    const [subClientType,                    setSubClientType]                  = useState('Individual');
    const [companyName,                      setCompanyName]                    = useState();
    const [addDetailsForCategory,            setAddDetailsForCategory]          = useState({});
    const [newTargetDetails,                 setNewTargetDetails]                  = useState([]);
    const [targetDetails,                    setTargetDetails]                  = useState([]);
    const [deletedID,                        setDeletedID]                      = useState([]);
    const [step,                             setStep]                           = useState('AgentDetails');
    const [tagCategory,                      setTagCategory]                    = useState({});
    const [payoutCategories,                 setPayoutCategories]               = useState([]);
    const [editCategory,                     setEditCategory]                   = useState(false);
    const [edit,                             setEdit]                           = useState(false);



    
    useEffect(() => {
        fetchStatesAndCities();
        fetchPayoutCategory();
    }, []) 

    //Steps -> AgentDetails, PayoutPlan |
    useLayoutEffect(() => {
        if(!!step){
            
            const prevElement = document.querySelectorAll('[data-ktwizard-state = current]');
            
            prevElement.forEach( e => {
                e.removeAttribute('data-ktwizard-state');
            });

            $(`.${step}`).attr('data-ktwizard-state','current');
        }
    },[step])

    useEffect(() => {
        $('#add-subAgent-modal').modal({
            backdrop: 'static'
        });
        $('#add-subAgent-modal').on('hidden.bs.modal', function () {
            $(props.from).removeClass('modal-blur');
            props.onDismissModal();
        });

    }, [props.show]);

    useEffect(() => {
        if(props.agent){
            if(props.agent.Type == 'Corporate'){
                setSubClientType(props.agent.Type)
                setCompanyName(props.agent.CompanyName) 
            }
            setSelectedState({Name : props.agent.StateName, ID: props.agent.StateID})
            setSelectedCity({Name : props.agent.CityName, ID: props.agent.CityID, StateID: props.agent.StateID})
            setAgent(props.agent)
            fetchSubAgentCategory(props.agent.ID)  
        }
    
    }, [props.agent]);

    //Filter Cities As Per Seleceted State
    useEffect(() => {
        if(selectedState){
            setAgent((a)=>({...a, "StateID": selectedState.ID}));
            setFilteredCities(cities.filter(a => a.StateID == selectedState.ID))
            if(selectedCity && selectedCity.StateID != selectedState.ID){
                setSelectedCity(null)
            }
        }  
    }, [selectedState])

    useEffect(()=>{
        if(selectedCity){
            setAgent((a)=>({...a, "CityID": selectedCity.ID}));
            if (!selectedState) {
                setSelectedState(states.find(s => s.ID == selectedCity.StateID));
            }      
        }
        else{
            setAgent((a)=>{
                delete a['CityID'];
                return {...a}
            })
        }       
    }, [selectedCity])

    useEffect(() => {
        if (payoutCategories && payoutCategories.length > 0) {
            if (newTargetDetails && newTargetDetails.length > 0) {
                const a = newTargetDetails.map(t => {
                    const CategoryName = payoutCategories.find(p => p.ID == t.CategoryID)
                    // Category = {...t, CategoryName: CategoryName.Name}
                    return {...t, CategoryName: CategoryName.Name}
                })
                setTargetDetails(a)
            }
        }
        
    }, [payoutCategories, newTargetDetails])

    const closeHandler = () => {
        if (props.setAgent)
            props.setAgent({...props.agent, CityID: selectedCity.ID })
    }

    const addSubAgentCategoryHandler = async(e) =>{
        e.preventDefault();

        var form = $('#add-tagCategory-form');
        form.validate().destroy();

        form.validate({
            rules: {
                ddlCategory: {
                    required: true,
                },
                fromDate: {
                    required: true,
                },
                toDate: {
                    required: true,
                }
            },
        });

        if (!form.valid()) {
            return;
        }

        if(moment(tagCategory.FromDate).isAfter(tagCategory.ToDate)) {
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Invalid Date!',
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return
        }
        if(targetDetails && targetDetails.length >  0 && targetDetails.find((d) =>  moment(tagCategory.FromDate) < moment(d.FromDate) && moment(tagCategory.ToDate) > moment(d.ToDate)  || moment(tagCategory.FromDate).isBetween(d.FromDate, d.ToDate) || moment(tagCategory.ToDate).isBetween(d.FromDate, d.ToDate) || moment(tagCategory.FromDate).isSame(d.FromDate) || moment(d.FromDate).isSame(tagCategory.ToDate) || moment(tagCategory.ToDate).isSame(d.ToDate) || moment(d.ToDate ).isSame(tagCategory.FromDate)) ){
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Date Already Exist!',
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return
        }
        setTargetDetails((t) => [...t, {...tagCategory, CategoryName: payoutCategories.find(p => p.ID == tagCategory.CategoryID).Name}])
        setTagCategory({})
    }


    const UpdateTagHandler = (e) => {
        e.preventDefault();
        var form = $('#add-tagCategory-form');
        form.validate().destroy();

        form.validate({
            rules: {
                ddlCategory: {
                    required: true,
                },
                fromDate: {
                    required: true,
                },
                toDate: {
                    required: true,
                }
            },
        });

        if (!form.valid()) {
            return;
        }
        if(targetDetails.find((d) =>  moment(tagCategory.FromDate) < moment(d.FromDate) && moment(tagCategory.ToDate) > moment(d.ToDate))){
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Date Already Exist!',
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return  
        }
        if(moment(tagCategory.FromDate).isAfter(tagCategory.ToDate)) {
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Invalid Date!',
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return
        }
        let deletedData 
        let indexData 
        targetDetails.find((b, i) => tagCategory.index == i ? (targetDetails.splice(i,1,'') ) && (deletedData = b)&&(indexData = i) : '')

        if(targetDetails.find((d) =>  moment(tagCategory.FromDate).isBetween(d.FromDate, d.ToDate) || moment(tagCategory.ToDate).isBetween(d.FromDate, d.ToDate) || moment(tagCategory.FromDate).isSame(d.FromDate) || moment(d.FromDate).isSame(tagCategory.ToDate) || moment(tagCategory.ToDate).isSame(d.ToDate) || moment(d.ToDate ).isSame(tagCategory.FromDate)) ){
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Date Already Exist!',
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            targetDetails.find((b, i) => tagCategory.index == i ? (targetDetails.splice(indexData,1,deletedData)) : '')
            return
        }

        if(deletedData.ToDate !==  tagCategory.ToDate){
            if(props.agent && edit && targetDetails.find((d) => !moment().isBetween(d.FromDate, tagCategory.ToDate ))){
                swal.fire({
                    icon                  : 'warning',
                    titleText             : 'Invalid Date!',
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',    
                });
                targetDetails.find((b, i) => tagCategory.index == i ? (targetDetails.splice(indexData,1,deletedData)) : '')
                return
            }
        }

        targetDetails.find((b,i) => tagCategory.index == i ? (targetDetails.splice(i,1,{...tagCategory, CategoryName: payoutCategories.find(p => p.ID == tagCategory.CategoryID).Name})) : '')
        setEditCategory(false)
        setEdit(false)
        setTagCategory({})
    }

    const fetchSubAgentCategory = async (ID) => {
        try {
            const response = await getSubAgentCategoryByAgentID(ID)
            setNewTargetDetails(response) 
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });  
            return;     
        }
    }

    const editTagHandler = (t,i) => {
        setTagCategory({...t, index: i})
        setEditCategory(true)
        if(props.agent){
            setEdit(true)
        }
    }

    const removeTagHandler = (id, index) => {
        setDeletedID(d => [...d, id])
        setTargetDetails((a) => {
            const nA = a.filter((b, i) => index !== i);
            return nA;
        })
    }


    const tagCategoryHandler = (e) =>{
        e.preventDefault();
        var form = $('#add-agent-form');
        form.validate().destroy();

        form.validate({
            rules: {
                firstName: {
                    required: true,
                },
                lastName: {
                    required: true,
                },
                email: {
                    required: true,
                    email: {
                        depends: function (element) {
                            return $(element).val() !== '';
                        },
                    },
                },
            },
        });

        if (!form.valid())
            return;
        setAddDetailsForCategory(agent)
        $('#add-subAgent-modal').addClass('modal-blur');
        setShowTagCategory(true)
        
    }

    const fetchStatesAndCities = async () => {
        try {
            const response = await getStatesAndCities();
            if(response.States.length > 0){
                setStates(response.States);
            }
            if(response.Cities.length > 0){
                setCities(response.Cities);
                setFilteredCities(response.Cities);
            }
            if(props.agent){
                setFilteredCities(response.Cities.filter(a => a.StateID == props.agent.StateID))
            }
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });  
            return; 
        }
            
    }
      
    const agentDetailsHandler = (key, value) => {
        setAgent((d) => {
            // // if (['FirstName', 'MiddleName', 'LastName'].includes(key))
            // //     value = helper.capitalizeCamel(value);
            // // if (key != 'PayoutCategoryID')
            // //     return { ...d, [key]: value.trim() };
            // else
                return { ...d, [key]: value };
        });
    };

    const tagCategoryDetails = (key, value) => {
        setTagCategory((f) => {
            return { ...f, [key]: value };
        });
    };

    const showDatePickerHandler = (target, show) => {   
        if (show) {
            target.type = 'date';
          
        }else{
            if (!target.value) {
                target.type = 'text';
            }
        }
    };  

    const fetchPayoutCategory = async () => {
        try {
            const response = await getPayoutCategory();
            setPayoutCategories(
                response
                .sort((a, b) => 
                a.Name > b.Name
                ? 1
                : a.Name < b.Name
                ? -1
                : 0
                )
            );
        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    };

    const subClientTypeHandler = (type) => {
        setSubClientType(type);
        resetForm();
    };

    const agentAddHandler = async (e) => {
        e.preventDefault();
        var form = $('#add-agent-form');
        form.validate().destroy();

        form.validate({
            rules: {
                companyName: {
                    required: true,
                },
                firstName: {
                    required: true,
                },
                lastName: {
                    required: true,
                },
                mobile: {
                    minlength: 10,
                    maxlength: 10,
                },
                City: {
                    required: true
                },
                State:{
                    required: true
                },
                GstNo: {
                    minlength: 15,
                    maxlength: 15,
                },
            },
        });

        if (!form.valid()) {
            return;
        }
        if (targetDetails.length == 0) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : "Please Tag Category",
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return
        }  
        try {
            helper.StartProcessing($('#save-btn')); 
            if(props.from == 'subAgent'){
                await updateSubAgent({...agent, subClientType, companyName, TargetDetails: targetDetails, StateID: selectedState.ID, CityID: selectedCity.ID, deletedID:deletedID});
                props.updateSubAgentHandler({...agent,CompanyName:companyName, StateID: selectedState.ID, CityName: selectedCity.Name})
            }else{
                const ID = await addSubAgent({...agent, subClientType, companyName,TargetDetails: targetDetails});
                if(props.from == '#add-subclient-modal' || props.from == '#add-client-modal' || props.from == '#add-policy-modal' || props.from == '#edit-policy-modal'){
                    props.addSubAgentId(ID);   
                } else{
                    props.refetchSubAgent()
                }
            }   
            
            $('#add-subAgent-modal').modal('hide');
            swal.fire({
                icon              : 'success',
                titleText         : props.from == 'subAgent' ? 'Sub Agent Updated Successfully' : 'Sub Agent Added Successfully',
                showConfirmButton : false,
                timer             : 1500
            });         
        } catch (err) {
            swal.fire({
                icon              : 'error',
                titleText         : 'Error!',
                text              : err.message,
                buttonsStyling    : false,
                confirmButtonClass: 'btn btn-brand',
            });
            helper.StopProcessing($('#save-btn'));
            return;
        }
    }

    //StepHandlers ->
    const onStepChange = (currentStep) => {
        onNextHandler(currentStep);
    };

    const onNextHandler = async (steps) => {
        let currentStep = !steps ? step : steps
        //Validate Each Form -->
        const form = $('#add-agent-form');

        form.validate({
            rules:{
                subAgentCode: {
                    required: true,
                },
                firstName: {
                    required: true,
                },
                lastName: {
                    required: true,
                },
                companyName: {
                    required: true,
                },
                email: {
                    required: true,
                },
                mobile: {
                    minlength: 10,
                    maxlength: 10,
                },
                City: {
                    required: true
                },
                State:{
                    required: true
                },
                tds: {
                    required: true
                },
                GstNo: {
                    minlength: 15,
                    maxlength: 15,
                },
            }
        })

        if(!form.valid()) return;
        
        try { 
            await verifySubAgentCodeByAgentCompanyID(agent)
            if((props.agent && props.agent.Email !== agent.Email) || !props.agent){
                const email = await verifySubAgentEmail(agent)
                if (email.length > 0 && step  == 'AgentDetails') {
                    const list = `<div class="row margin-t20">
                                      <div class="col-12">
                                          <table class="table table-striped">
                                              <tbody>
                                                  ${email.map(
                                                    (m, i) =>
                                                      `<tr key=${i}>
                                                      <td>
                                                          <div class="kt-user-card-v2">
                                                              <div class="kt-user-card-v2__pic">
                                                                  <div class="kt-badge kt-badge--xl kt-badge--${
                                                                    agent.Type ===
                                                                    'Corporate'
                                                                      ? 'success'
                                                                      : 'info'
                                                                  }">     
                                                                      ${
                                                                        m.FirstName
                                                                          ? m.FirstName[0].toUpperCase()
                                                                          : ''
                                                                      }
                                                                  </div>
                                                              </div>
                                                              <div class="kt-user-card-v2__details">
                                                                  <span class="kt-user-card-v2__name text-left">
                                                                      ${m.FirstName} ${m.LastName}
                                                                  </span>
                                                                  <span class="kt-user-card-v2__desc">
                                                                      ${m.Email}
                                                                  </span>
                                                              </div>
                                                          </div>
                                                      </td>
                                                  </tr>`
                                                  )}
                                              </tbody>
                                          </table>
                                      </div>
                                  </div>`;
                    const swalResult = await swal.fire({
                        icon                : 'warning',
                        titleText           : 'Warning! Email already in use with following Agent',
                        html                : list,
                        showCancelButton    : true,
                        buttonsStyling      : false,
                        reverseButtons      : true,
                        confirmButtonClass  : 'btn btn-brand',
                        cancelButtonClass   : 'btn btn-secondary',
                        confirmButtonText   : 'Add anway',
                        cancelButtonText    : "Don't add",
                        customClass         : {
                            content           : 'swalHeight'
                        }
                    });
                    if (!swalResult.value){ 
                        return 
                    } 
                }
            }

        } catch (err) {
            swal.fire({
                icon              : 'error',
                titleText         : 'Error!',
                text              : err.message,
                buttonsStyling    : false,
                confirmButtonClass: 'btn btn-brand',
            });
            return;
        }
        if (steps) {
            const cstep = stepList.find(s => s == currentStep)
            if (cstep) setStep(cstep)
        } else {
            const cIndex  = stepList.findIndex( s => s == currentStep);
            if( (cIndex >= 0) & (cIndex != stepList.length) ) setStep( stepList[ cIndex + 1] );
        }
    }   

    const onPrevHandler = () => {
        const cIndex  = stepList.findIndex( s => s == step);
        if( (cIndex != 0) & (cIndex <= stepList.length) ) setStep( stepList[ cIndex - 1] );
    }

    const resetForm = () => {
        var form = $('#add-agent-form');
        form.clearForm();
        form.validate().resetForm();
    };

    return (
        <>
            <div className="modal fade" id="add-subAgent-modal" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-body pt-4">
                            <div className="kt-grid kt-wizard-v3 kt-wizard-v3--white" id="kt_wizard_v3" >
                                <div className="kt-grid__item">
                                    {/* begin: Form Wizard Nav */}
                                    <div className="kt-wizard-v3__nav">
                                        <div className="row kt-wizard-v3__nav-items kt-wizard-v3__nav-items--clickable">
                                            <div className="col-6">
                                                <div className="kt-wizard-v3__nav-item AgentDetails" data-ktwizard-type="step" id="AgentDetails" >
                                                    <div className="kt-wizard-v3__nav-body">
                                                        <div className="kt-wizard-v3__nav-label mb-0" onClick={(e) => onStepChange('AgentDetails')} >
                                                        {/* <div className="kt-wizard-v3__nav-label mb-0" onClick={(e) => onNextHandler('ClubDetails')} > */}
                                                            <span> 1 </span> Agent Details
                                                        </div>
                                                        <div className="kt-wizard-v3__nav-bar"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="kt-wizard-v3__nav-item AddPayoutPlan" data-ktwizard-type="step"  id="AddPayoutPlan" >
                                                    <div className="kt-wizard-v3__nav-body">
                                                        <div className="kt-wizard-v3__nav-label mb-0" onClick={(e) => onStepChange('AddPayoutPlan')} >
                                                        {/* <div className="kt-wizard-v3__nav-label mb-0" onClick={(e) => onNextHandler('MinimumRequirment')} > */}
                                                            <span> 2 </span> Add Payout Plan
                                                        </div>
                                                        <div className="kt-wizard-v3__nav-bar"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end: Form Wizard Nav */}
                                </div>
                                <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v3__wrapper">
                                    <div className="kt-form "> 
                                    
                                        <div id="wizard-1" className="kt-wizard-v3__content AgentDetails padding-0 margin-0" data-ktwizard-type="step-content" >
                                            <div className="padding-lr10">
                                            
                                                <div className="kt-radio-inline radio-container ">
                                                    <div className="input-group">
                                                        {' '}
                                                        <input
                                                            value="Individual"
                                                            defaultChecked  = {subClientType == 'Individual' ? 'checked' : '' }                                                                                       
                                                            onChange={({ target }) => subClientTypeHandler(target.value)}
                                                            type="radio"
                                                            name="agent-type"
                                                            id="individual"
                                                        />
                                                        <label htmlFor="individual">Individual</label>
                                                    </div>
                                                    <div className="input-group margin-l20">
                                                        {' '}
                                                        <input
                                                            value="Corporate"
                                                            defaultChecked  = {props.agent ? (props.agent.Type == 'Corporate' ? 'checked' : '') : '' }  
                                                            onChange={({ target }) => subClientTypeHandler(target.value)}
                                                            type="radio"
                                                            name="agent-type"
                                                            id="corporate"
                                                        />
                                                        <label htmlFor="corporate">Organization</label>
                                                    </div>
                                                </div>
                                                <form id="add-agent-form" className="kt-form pb-0" onSubmit={(e) => e.preventDefault()}>
                                                    {subClientType === 'Corporate' && (
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <input
                                                                        id="companyName"
                                                                        value={companyName || ''}
                                                                        name="companyName"
                                                                        placeholder="Company Name"
                                                                        className="form-control"
                                                                        type="text"
                                                                        onChange={({ target }) => setCompanyName(helper.camelCase(target.value))}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-6">
                                                            <div className="form-group">
                                                                <input
                                                                    value={agent.SubAgentCode || ''}
                                                                    onChange={(event) =>
                                                                        agentDetailsHandler(
                                                                        'SubAgentCode',
                                                                        event.target.value
                                                                    )
                                                                    }
                                                                    name="subAgentCode"
                                                                    id="subAgentCode"
                                                                    placeholder="Sub Agent Code"
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6">
                                                            <div className="form-group">
                                                                <input
                                                                    value={agent.FirstName || ''}
                                                                    onChange={(event) =>
                                                                        agentDetailsHandler(
                                                                        'FirstName',
                                                                        event.target.value
                                                                    )
                                                                    }
                                                                    name="firstName"
                                                                    id="firstName"
                                                                    placeholder="First Name"
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6">
                                                            <div className="form-group">
                                                                <input
                                                                    value={agent.MiddleName || ''}
                                                                    onChange={(event) =>
                                                                        agentDetailsHandler(
                                                                        'MiddleName',
                                                                        event.target.value
                                                                    )
                                                                    }
                                                                    name="middleName"
                                                                    id="middleName"
                                                                    placeholder="Middle Name"
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6">
                                                            <div className="form-group">
                                                                <input
                                                                    value={agent.LastName || ''}
                                                                    onChange={(event) =>
                                                                        agentDetailsHandler(
                                                                        'LastName',
                                                                        event.target.value
                                                                    )
                                                                    }
                                                                    name="lastName"
                                                                    id="lastName"
                                                                    placeholder="Last Name"
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                    <div className="col-lg-4 col-md-6">
                                                            <div className="form-group">
                                                                <input
                                                                    value={agent.Email || ''}
                                                                    onChange={(event) =>
                                                                        agentDetailsHandler(
                                                                        'Email', 
                                                                        event.target.value.trim()
                                                                    )
                                                                    }
                                                                    name="email"
                                                                    id="email"
                                                                    placeholder="Email"
                                                                    className="form-control"
                                                                    type="text"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-6">
                                                            <div className="form-group">
                                                                <input
                                                                    value={agent.Mobile || ''}
                                                                    onChange={(event) =>
                                                                        agentDetailsHandler(
                                                                        'Mobile', 
                                                                        event.target.value
                                                                    )
                                                                    }
                                                                    name="mobile"
                                                                    id="mobile"
                                                                    placeholder="Contact No."
                                                                    className="form-control"
                                                                    type="number"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-6">
                                                            <div className="form-group">
                                                                <input
                                                                    id="dob"
                                                                    value={agent.DOB ? moment(agent.DOB).format('YYYY-MM-DD') : ''}
                                                                    name="dob"
                                                                    placeholder="Date of birth"
                                                                    className="form-control"
                                                                    type="text"
                                                                    max={moment().format('YYYY-MM-DD')}
                                                                    onFocus={(event) => showDatePickerHandler(event.target, true)}
                                                                    onBlur={(event) => showDatePickerHandler(event.target, false)}
                                                                    onChange={(event) =>
                                                                        agentDetailsHandler(
                                                                            'DOB', event.target.value
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <textarea
                                                                    rows="3"
                                                                    placeholder="Address"
                                                                    className="form-control"
                                                                    value={agent.Address || ''}
                                                                    onChange={(event) =>
                                                                        agentDetailsHandler(
                                                                        'Address', 
                                                                        event.target.value
                                                                    )}
                                                                    id="address"
                                                                    name="address"
                                                                    type="text"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-0">
                                                                <label>State:</label>
                                                                <Select 
                                                                    className="State"
                                                                    id="State" 
                                                                    name="State"
                                                                    value={(selectedState && selectedState.Name) && {label: `${selectedState.Name}`, value: selectedState} || ''} 
                                                                    options={states.map(c => ({
                                                                        label : c.Name,
                                                                        value : c
                                                                    }))}                                                               
                                                                    placeholder="Select State"
                                                                    onChange={(state)=>{
                                                                        if(state) {
                                                                            setSelectedState(state.value);
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>City:</label>
                                                                <Select 
                                                                    className="City"
                                                                    id="City"
                                                                    name="City"
                                                                    value={(selectedCity && selectedCity.Name) && {label: `${selectedCity.Name}`, value: selectedCity} || ''}
                                                                    options={filteredCities.map(c => ({
                                                                        label : c.Name,
                                                                        value : c
                                                                    }))}                                                                
                                                                    placeholder="Select City"
                                                                    onChange={(city)=>{
                                                                        if(city){ 
                                                                            setSelectedCity(city.value);  
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className='col-md-6'>
                                                            <label htmlFor="tds">TDS</label>
                                                            <div className='input-group'>
                                                                <input
                                                                    name="tds"
                                                                    id="tds"
                                                                    value={agent.TDS}
                                                                    max='100'
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(event) => agentDetailsHandler('TDS', event.target.value)}                                                   
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text">%</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                            <label>Gst No</label>
                                                                <input 
                                                                    className="form-control" 
                                                                    type="text" 
                                                                    id="GstNo"
                                                                    name="GstNo"
                                                                    pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
                                                                    value={agent.GstNo || ''}
                                                                    onChange={({ target }) => agentDetailsHandler('GstNo', target.value.trim())}  
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div id="wizard-2" className="kt-wizard-v3__content AddPayoutPlan" data-ktwizard-type="step-content" >
                                        
                                            <div className="padding-lr10">
                                                <form id="add-tagCategory-form" className="kt-form pb-0" onSubmit={(e) => e.preventDefault()}>
                                                    <div className="kt-section kt-section--first addPayoutPlanSection">
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-6">
                                                            <div className="form-group">
                                                                <Select
                                                                    value={ tagCategory && tagCategory.CategoryID && {label: payoutCategories.find(c => c.ID == tagCategory.CategoryID ).Name, value: tagCategory.CategoryID } || ''}
                                                                    onChange={(event) => tagCategoryDetails('CategoryID', event.value)}
                                                                    name="ddlCategory"
                                                                    placeholder=" Category"
                                                                    id="ddlCategory"
                                                                    className="ddlCategory"
                                                                    options={payoutCategories.map(f => ({
                                                                        label: f.Name,
                                                                        value: f.ID
                                                                    }))}
                                                                />
                                                            </div>
                                                        </div>
                                                        {/* {props.agent && edit && tagCategory.index == 0 || props.agent && edit && moment().isBetween(tagCategory.FromDate, tagCategory.ToDate) || props.agent && edit && !moment(tagCategory.ToDate).isAfter(moment())? (
                                                            <div className='col-lg-3 col-md-6'>
                                                                <div className="form-group">
                                                                    <input
                                                                        id="fromDate"
                                                                        placeholder="Start Date"
                                                                        value={tagCategory.FromDate ? moment(tagCategory.FromDate).format('YYYY-MM-DD') : ''}
                                                                        onFocus={(event) => showDatePickerHandler(event.target, true)}
                                                                        onBlur={(event) => showDatePickerHandler(event.target, false)}
                                                                        name="fromDate"
                                                                        disabled
                                                                        className="form-control margin-b10"
                                                                        type="text"
                                                                        onChange={(event) => tagCategoryDetails('FromDate', event.target.value)}
                                                                    /> 
                                                                </div>
                                                            </div> 
                                                        ):( */}
                                                            <div className='col-lg-3 col-md-6'>
                                                                <div className="form-group">
                                                                    <input
                                                                        id="fromDate"
                                                                        placeholder="Start Date"
                                                                        value={tagCategory.FromDate ? moment(tagCategory.FromDate).format('YYYY-MM-DD') : ''}
                                                                        onFocus={(event) => showDatePickerHandler(event.target, true)}
                                                                        onBlur={(event) => showDatePickerHandler(event.target, false)}
                                                                        name="fromDate"
                                                                        className="form-control margin-b10"
                                                                        disabled={props.agent && edit && moment().isSameOrAfter(tagCategory.FromDate) ? true : false}
                                                                        type="text"
                                                                        onChange={(event) => tagCategoryDetails('FromDate', event.target.value)}
                                                                    /> 
                                                                </div>
                                                            </div> 
                                                        {/* )} */}
                                                        <div className='col-lg-3 col-md-6'>
                                                            <div className="form-group">
                                                                <input
                                                                    id="toDate"
                                                                    placeholder="End Date"
                                                                    value={tagCategory.ToDate ? moment(tagCategory.ToDate).format('YYYY-MM-DD') : ''}
                                                                    onFocus={(event) => showDatePickerHandler(event.target, true)}
                                                                    onBlur={(event) => showDatePickerHandler(event.target, false)}
                                                                    name="toDate"
                                                                    type="text"
                                                                    className="form-control margin-b10"
                                                                    onChange={(event) => tagCategoryDetails('ToDate', event.target.value)}
                                                                /> 
                                                            </div>
                                                        </div> 
                                                        <div className='col-lg-2 col-md-6'>
                                                            {editCategory ? (
                                                                <div className="btn-group btn-block" role="group">
                                                                    <button 
                                                                        className="btn btn-brand btn-block margin-0 "
                                                                        onClick={UpdateTagHandler}>
                                                                        Update
                                                                    </button>
                                                                    <button
                                                                        style={{ maxWidth: '30%' }}
                                                                        className="btn btn-secondary btn-block margin-0 padding-0"
                                                                        onClick={() => {
                                                                            setEditCategory(false)
                                                                            setTagCategory({})
                                                                            setEdit(false)
                                                                        }}
                                                                    >
                                                                        <i
                                                                            className="la la-times"
                                                                            style={{ fontSize: '1rem', marginLeft: '3px' }}
                                                                        />
                                                                    </button>
                                                                </div>
                                                            ): (
                                                                <button 
                                                                    className="btn btn-label-brand btn-icon-sm btn-block "
                                                                    onClick={addSubAgentCategoryHandler}>
                                                                    <i className="la la-plus"></i>
                                                                    Add
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {targetDetails && targetDetails.length > 0 && (
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
                                                                    <table className="kt-datatable__table">
                                                                        <thead className="kt-datatable__head" style={{ display: 'block' }}>
                                                                            <tr className="kt-datatable__row block-row">
                                                                                <th className="kt-datatable__cell text-clip" width="30%">
                                                                                    <span>Category</span>
                                                                                </th>
                                                                                <th className="kt-datatable__cell text-clip" width="30%">        
                                                                                    <span>Start Date</span>
                                                                                </th>
                                                                                <th className="kt-datatable__cell text-clip" width="30%">
                                                                                    <span>End Date</span>
                                                                                </th>
                                                                                <th className="kt-datatable__cell text-clip text-center" width="15%">
                                                                                    <span>Action</span>
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                    </table>
                                                                </div>
                                                                <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0" onScroll={helper.setStickyHeader}>
                                                                    <table className="kt-datatable__table table-striped">
                                                                        <tbody className="kt-datatable__body" style={{ display: 'block', minHeight: '5vh'}}>
                                                                            {targetDetails.sort(function(a, b) { return new Date(a.FromDate) - new Date(b.FromDate) }).map((t, i) => (
                                                                                <tr key={i} className="kt-datatable__row block-row"> 
                                                                                    <td width="30%" title={t.CategoryName} className="kt-datatable__cell text-clip">
                                                                                        <span>{t.CategoryName}</span>
                                                                                        {(t.IsNew) && (
                                                                                            <span className='kt-badge kt-badge--success kt-badge--inline kt-badge--pill tag-badge ' title='Active'>
                                                                                                Active
                                                                                            </span>  
                                                                                        )}   
                                                                                    </td>
                                                                                    <td width="30%" title={t.FromDate} className="kt-datatable__cell text-clip">
                                                                                        <span>{ moment(t.FromDate).format('DD-MM-YYYY')}</span>
                                                                                    </td>
                                                                                    <td width="30%" title={t.ToDate} className="kt-datatable__cell text-clip">
                                                                                        <span>{ moment(t.ToDate).format('DD-MM-YYYY')}</span>
                                                                                    </td>
                                                                                    <td width="15%" className='text-center'>
                                                                                        <button
                                                                                            type="button"
                                                                                            onClick={() => editTagHandler(t,i)}
                                                                                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                                                                            <i className="flaticon2-edit"></i>
                                                                                        </button>
                                                                                        {props.agent ?
                                                                                        (i > 0 && !moment().isBetween(t.FromDate, t.ToDate) && moment(t.ToDate).isAfter(moment()) ? (
                                                                                            <button
                                                                                                type="button"
                                                                                                onClick={() => removeTagHandler(t.ID, i)}
                                                                                                className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                                                                                <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                                                            </button>
                                                                                            ) :(
                                                                                                <span style={{ marginRight: '38px' }}></span>
                                                                                            )
                                                                                        ):(
                                                                                            <button
                                                                                                type="button"
                                                                                                onClick={() => removeTagHandler(t.ID, i)}
                                                                                                className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                                                                                <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                                                            </button>
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            {/* <button
                                type="button"
                                className="btn btn-secondary margin-0 margin-r5"
                                data-dismiss="modal"
                                // disabled={btnLoading}
                                >
                                Close
                            </button>
                            <button
                                    id="save-btn"
                                    onClick={agentAddHandler}
                                    type="button"
                                    className={`btn btn-brand margin-0 `}
                                    // disabled={btnLoading} 
                                >                  
                                  {props.from == 'subAgent'? 'Update' : 'Save'}
                            </button> */}

                            <span className="btn btn-secondary margin-0 margin-r5" data-dismiss="modal" 
                                onClick={() => closeHandler()}
                            > Close </span>
                        
                            {step != 'AgentDetails' &&  ( <span className="btn btn-warning margin-0 margin-r5" onClick={onPrevHandler} > Prev </span>)}
                            
                            {step != 'AddPayoutPlan' ? (
                                <span id="next-btn" className="btn btn-brand margin-0" onClick={() => onNextHandler()} > Next </span>
                            ):(
                                <span id="save-btn" className="btn btn-brand margin-0" onClick={agentAddHandler}> {props.agent ? 'Update' : 'Save'} </span>
                            )}
                          
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
export default AddSubAgentModal;