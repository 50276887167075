import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import Editor from 'ckeditor5-custom-build';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import * as helper from '../global/helper';
import { getQuickText } from '../api/quickTextAPI';

const CustomEditor = (props) => {

    const [editorData,      setEditorData]      = useState('');
    const [feeds,           setFeeds]           = useState([]);
    const [fillQuickText,   setFillQuickText]   = useState(false);
    const [fillSelection,   setFillSelection]   = useState(null);

    useEffect(() => {
        if (props.fillQuickText.trim().length > 0)
            setFillQuickText(true);  
        else
            setFillQuickText(false);  
    }, [props.fillQuickText]);

    useEffect(() => {
        if (fillQuickText){
            setEditorData((e) => {
                return e + ' ';
            });
        }
    }, [fillQuickText]);

    useEffect(() => {
        props.setData(editorData);
    }, [editorData]);

    const fetchQuickTextt = async () => {
        try {
            const response = await getQuickText();
            const feedList = response.map((q) => ({
                id: "/" + q.ShortCut,
                name: helper.extractHtml(q.Message).slice(0, 50) + '...'
            }))
            setFeeds(response);
            return feedList;
        } catch (err) {
            console.error(err);
        }
    }

    const getFeedItems = async (queryText) => {
        try {
            const feedList = await fetchQuickTextt();
            const itemsToDisplay = feedList.filter((f) => f.id.toLowerCase().includes(queryText.toLowerCase()));
            return itemsToDisplay;
        } catch (err) {
            swal.fire({
                icon              : 'error',
                titleText         : 'Error!',
                text              : err.message,
                buttonsStyling    : false,
                confirmButtonClass: 'btn btn-brand',
            });
        }
    }

    const customItemRenderer =  (item) => {
        
        const itemElement = document.createElement( 'div' );        
        itemElement.innerHTML = `
                                    <span class="title-label">${item.id}</span><br>
                                    <span class="desc-label text-clip">${item.name}</span>
                                `;
        return itemElement;
    }

    const updateQuickTextHandler = (event, editor) => {  

        let tempObj         = document.createElement( 'div' );
        tempObj.innerHTML   = editor.getData();
        const mentionList   = tempObj.getElementsByClassName('mention');

        if (mentionList.length > 0 && mentionList[0].hasAttribute('data-mention')) {
            const shortCut = mentionList[0].getAttribute('data-mention');
            const feed = feeds.find(f => '/' + f.ShortCut === shortCut);
            if (feed) {   
                
                const mentionRemoved = new Promise((resolve, reject) => {

                    const childNode         = editor.model.document.selection.getFirstPosition().parent._children;
                    const nodeList          = childNode._nodes;
                    const mentionNode       = nodeList.find(n => n.data === shortCut);

                    editor.model.change( writer => {
                        writer.remove(mentionNode.nextSibling) 
                        writer.remove(mentionNode);                                    
                        resolve();                 
                    });     
                    
                });

                mentionRemoved.then(() => {
                    const htmlString        = feed.Message;
                    const htmlDP            = editor.data.processor;
                    const viewFragment      = htmlDP.toView(htmlString);
                    const modelFragment     = editor.data.toModel(viewFragment);
                    editor.model.insertContent(modelFragment);
                });            
            }
        }

        if (fillQuickText) {

            const contentInserted = new Promise((resolve, reject) => {
                const htmlString        = props.fillQuickText;
                const htmlDP            = editor.data.processor;
                const viewFragment      = htmlDP.toView(htmlString);
                const modelFragment     = editor.data.toModel(viewFragment);
                let isFocus           = false;
                if (fillSelection){
                    editor.model.change( writer => {
                        editor.model.insertContent(modelFragment, fillSelection);
                        writer.setSelection(fillSelection);      
                        isFocus = true;     
                    })
                }            
                else{
                    editor.model.change( writer => {

                        if (editorData.trim().length > 0){
                            writer.setSelection(editor.model.document.getRoot(), 'end');
                            isFocus = true;    
                        }
                               
                        editor.model.insertContent(modelFragment);  
                        
                    })
                }

                setFillQuickText(false);
                setFillSelection(null);
                props.setFillQuickText('');

                resolve(isFocus);
                
            });

            contentInserted.then((value, error) => {
               if (value) editor.editing.view.focus();            
            }); 
    
        }

        setEditorData(editor.getData());

    }

    const addQuickTextHandler = (editor) => {  

        $('.ck-toolbar__items').first()
        .prepend(`<button id="customButton" class="ck ck-button ck-off" type="button" tabindex="-1">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24"/>
                            <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>
                            <path d="M12.4208204,17.1583592 L15.4572949,11.0854102 C15.6425368,10.7149263 15.4923686,10.2644215 15.1218847,10.0791796 C15.0177431,10.0271088 14.9029083,10 14.7864745,10 L12,10 L12,7.17705098 C12,6.76283742 11.6642136,6.42705098 11.25,6.42705098 C10.965921,6.42705098 10.7062236,6.58755277 10.5791796,6.84164079 L7.5427051,12.9145898 C7.35746316,13.2850737 7.50763142,13.7355785 7.87811529,13.9208204 C7.98225687,13.9728912 8.09709167,14 8.21352549,14 L11,14 L11,16.822949 C11,17.2371626 11.3357864,17.572949 11.75,17.572949 C12.034079,17.572949 12.2937764,17.4124472 12.4208204,17.1583592 Z" fill="#000000"/>
                        </g>
                    </svg>
                    <span class="ck ck-tooltip ck-tooltip_s">
                        <span class="ck ck-tooltip__text">
                            Add Quick Text
                        </span>
                    </span>
                    <span class="ck ck-button__label">Add Quick Text</span>
                </button>`)
                                                    
        $('#customButton').on('click', () => {
            props.addQuickText(true);
        });

        editor.editing.view.document.on('enter', ( evt, data ) => {
            if (data.isSoft) editor.execute('enter');        
            else editor.execute('shiftEnter');               
            data.preventDefault();
            evt.stop();
            editor.editing.view.scrollToTheSelection();
        }, {priority: 'high'});

    }

    const fillSelectionHandler = (event, editor) => {  
        editor.model.change( writer => {
            const range = writer.createRange(editor.model.document.selection.getFirstPosition(), editor.model.document.selection.getFirstPosition()); 
            const selection = writer.createSelection(range);
            setFillSelection(selection);
        })
    }

    return(
        <React.Fragment>
            <CKEditor 
                editor={ Editor }
                config={{
                            toolbar: ["Bold", "Italic", "RemoveFormat", "|", "Undo", "Redo"],
                            placeholder: props.placeholder,
                            mention:{
                                feeds: [
                                    {
                                        marker: '/',
                                        feed: getFeedItems,
                                        itemRenderer: customItemRenderer
                                    }
                                ]
                            }
                        }}  
                data={editorData || ''}              
                onChange={updateQuickTextHandler}
                onReady={addQuickTextHandler}
                onBlur={fillSelectionHandler}
            />
        </React.Fragment>
        
    );

}

export default CustomEditor;