import { axiosPost, axiosGet, axiosDelete, axiosPatch } from './axiosFunctions';
import { getTokenOrLogout } from './common';

const getInsuranceCompaniesUrl            = '/api/policy/getInsuranceCompaniesByAgentCompany';
const getInsuranceCompaniesByAgentIDUrl   = '/api/policy/getInsuranceCompaniesByAgentID';
const getProductsByAgentIDUrl             = '/api/policy/getProductsByAgentID';
const getProductsUrl                      = '/api/policy/getProductsByAgentCompany';
const addInsuranceCompanyUrl              = '/api/policy/addInsuranceCompany';
const addProductUrl                       = '/api/policy/addProduct';
const renewPolicyUrl                      = '/api/policy/renewPolicy';
const addPolicyUrl                        = '/api/policy/addPolicy';
const updatePolicyUrl                     = '/api/policy/updatePolicy';
const postMotorPolicyUrl                  = '/api/policy/postMotorPolicy';
const postMarinePolicyUrl                 = '/api/policy/postMarinePolicy';
const addPolicyDocumentsUrl               = '/api/policy/addPolicyDocuments';
const updatePolicyDocumentUrl             = '/api/policy/updatePolicyDocument';
const deletePolicyDocumentUrl             = '/api/policy/deletePolicyDocument';
const getQuotationStatusListUrl           = '/api/policy/getQuotationStatusList';
const getPolicyStatusListUrl              = '/api/policy/getPolicyStatusList';
const getPolicyRegexUrl                   = '/api/policy/getPolicyRegex';
const getMotorMakeUrl                     = '/api/policy/getMotorMake';
const getMotorModelUrl                    = (makeId) => `/api/policy/getMotorModel/${makeId}`;
const getPolicyUrl                        = (policyId) => `/api/policy/getPolicy/${policyId}`;
const getMotorPolicyUrl                   = (policyId) => `/api/policy/getMotorPolicy/${policyId}`;
const getMarinePolicyUrl                  = (policyId) => `/api/policy/getMarinePolicy/${policyId}`;
const getPolicyTimelineUrl                = (policyId) => `/api/policy/getPolicyTimeline/${policyId}`;
const getPolicyDocumentsUrl               = (policyId) => `/api/policy/getPolicyDocuments/${policyId}`;
const getPolicyStatsForQuotationUrl       = (clientId) => `/api/policy/getPolicyStatsForQuotation/${clientId}`;
const getQuotationDocumentsUrl            = `/api/policy/getQuotationDocuments`;
const postQuotationsUrl                   = '/api/policy/postQuotations';
const getQuotationStatsUrl                = '/api/policy/getQuotationStats';
const getPolicyStatsUrl                   = '/api/policy/getPolicyStats';
const getSearchPolicyStatsUrl             = '/api/policy/getSearchPolicyStats';
const getExpiredPolicyStatsUrl            = '/api/policy/getExpiredPolicyStats'
const getBatchPoliciesUrl                 = '/api/policy/getBatchPolicies';
const sendQuotationEmailUrl               = '/api/policy/sendQuotationEmail';
const sendMultiQuotationEmailUrl          = '/api/policy/sendMultiQuotationEmail';
const sendPolicyEmailUrl                  = '/api/policy/sendPolicyEmail';
const sendMultiPolicyEmailUrl              = '/api/policy/sendMultiPolicyEmail';
const addQuotationUrl                     = (groupId) => `/api/policy/addQuotation/${groupId}`;
const deleteQuotationUrl                  = (quotationId) => `/api/policy/deleteQuotation/${quotationId}`;
const convertQuotationStatusUrl           = (quotationId, policyId) => `/api/policy/convertQuotationStatus/${quotationId}/${policyId}`;
const updateQuotationUrl                  = `/api/policy/updateQuotation`;
const verifyPolicyNoUrl                   = `/api/policy/verifyPolicyNo`;
const postBatchPoliciesUrl                = `/api/policy/postBatchPolicies`;
const updateBatchPolicyUrl                = `/api/policy/updateBatchPolicy`;
const deleteBatchPolicyUrl                = (policyId) => `/api/policy/deleteBatchPolicy/${policyId}`;
const deleteBatchPolicyGroupUrl           = (batchNo) => `/api/policy/deleteBatchPolicyGroup/${batchNo}`;
const getPolicyStatsForPrimaryClientUrl   = (clientId) => `/api/policy/getPolicyStatsForPrimaryClient/${clientId}`;
const getPolicyHistoryUrl                 = (groupId) => `/api/policy/getPolicyHistory/${groupId}`;
const getQuotationsByPolicyGroupUrl       = (groupId) => `/api/policy/getQuotationsByPolicyGroup/${groupId}`;
const getQuotationsByPolicyUrl            = (policyId, isBatch) => `/api/policy/getQuotationsByPolicy/${policyId}/${isBatch}`;
const getPolicyByPrevPolicyUrl            = (prevPolicyId) => `/api/policy/getPolicyByPrevPolicy/${prevPolicyId}`;
const getBusinessReportsUrl               = '/api/policy/getBusinessReports';
const getBusinessReportsByAgentIDUrl      = '/api/policy/getBusinessReportsByAgentID';
const addRevenueMasterUrl                 = '/api/policy/addRevenueMaster';
const updateRevenueMasterUrl              = '/api/policy/updateRevenueMaster';
const getRevenueMasterUrl                 = '/api/policy/getRevenueMaster';
const getAllIncentiveTargetUrl            = '/api/policy/getAllIncentiveTarget';
const getIncentiveTargetUrl               = (revenueMasterId) => `/api/policy/getIncentiveTarget/${revenueMasterId}`;
const deleteRevenueMasterUrl              = (revenueMasterId) => `/api/policy/deleteRevenueMaster/${revenueMasterId}`;
const deletePolicyRequestUrl              = '/api/policy/deletePolicyRequest';
const deactivatePolicyUrl                 = '/api/policy/deactivatePolicy';
const getQuotationStatsForPrimaryClientUrl = (clientId) => `/api/policy/getQuotationStatsForPrimaryClient/${clientId}`;

export const getInsuranceCompanies = async () => {

  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosGet(getInsuranceCompaniesUrl, {
    headers: { auth_token: `bearer ${token}` },
  });
  return response;
};

export const getInsuranceCompaniesByAgentID = async () => {

  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosGet(getInsuranceCompaniesByAgentIDUrl, {
    headers: { auth_token: `bearer ${token}` },
  });
  return response;
};

export const getProducts = async () => {
  const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosGet(getProductsUrl, {
    headers: { auth_token: `bearer ${token}` },
  });
  return response;
};

export const getProductsByAgentID = async () => {
  const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosGet(getProductsByAgentIDUrl, {
    headers: { auth_token: `bearer ${token}` },
  });
  return response;
};

export const addInsuranceCompany = async (Name, FullName) => {
  const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosPost(
    addInsuranceCompanyUrl,
    { Name, FullName },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const addProduct = async (Name, FullName) => {
  const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosPost(
    addProductUrl,
    { Name, FullName },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const renewPolicy = async (policy) => {
  const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosPost(
    renewPolicyUrl,
    { policy : policy },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const addPolicy = async (policy) => {
  const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosPost(
    addPolicyUrl,
    { policy : policy },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const updatePolicy = async (policy) => {
  const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosPost(
    updatePolicyUrl,
    { policy : policy },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};


export const postMotorPolicy = async (policy) => {
  const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosPost(
    postMotorPolicyUrl,
    { policy : policy },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const postMarinePolicy = async (policy) => {
  const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
  const response = await axiosPost(
    postMarinePolicyUrl,
    { policy : policy },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const addPolicyDocuments = async (policyDocuments) => {
  const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosPost(
    addPolicyDocumentsUrl,
    { policyDocuments : policyDocuments },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const getQuotationDocuments = async (quotationIds) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);
  const response = await axiosPost(
    getQuotationDocumentsUrl,
    { quotationIds },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const deletePolicyDocument = async (policyDocument) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);
  const response = await axiosPost(
    deletePolicyDocumentUrl,
    { policyDocument : policyDocument },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const getQuotationStatusList = async () => {
  const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosGet(getQuotationStatusListUrl, {
    headers: { auth_token: `bearer ${token}` },
  });
  return response;
};

export const updatePolicyDocument = async (policyDocument) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);
  const response = await axiosPost(
    updatePolicyDocumentUrl,
    { policyDocument : policyDocument },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const getPolicyStatusList = async () => {
  const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosGet(getPolicyStatusListUrl, {
    headers: { auth_token: `bearer ${token}` },
  });
  return response;
};

export const getPolicyRegex = async () => {
  const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosGet(getPolicyRegexUrl, {
    headers: { auth_token: `bearer ${token}` },
  });
  return response;
};

export const getMotorMake = async () => {
  const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosGet(getMotorMakeUrl, {
    headers: { auth_token: `bearer ${token}` },
  });
  return response;
};

export const getMotorModel = async (makeId) => {
  const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
  const response = await axiosGet(
    getMotorModelUrl(makeId), 
    { headers: { auth_token: `bearer ${token}` },
  });
  return response;
};


export const getPolicy = async (policyId) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);
  const response = await axiosGet(
    getPolicyUrl(policyId),
    { headers: { auth_token: `bearer ${token}` }
    
  });
  return response;
};

export const getMotorPolicy = async (policyId) => {
  const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosGet(
    getMotorPolicyUrl(policyId),
    { headers: { auth_token: `bearer ${token}` }
    
  });
  return response;
};

export const getMarinePolicy = async (policyId) => {
  const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosGet(
    getMarinePolicyUrl(policyId),
    { headers: { auth_token: `bearer ${token}` }
    
  });
  return response;
};

export const getPolicyTimeline = async (policyId) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);
  const response = await axiosGet(
    getPolicyTimelineUrl(policyId),
    { headers: { auth_token: `bearer ${token}` }
    
  });
  return response;
};

export const getPolicyDocuments = async (policyId) => {
  const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosGet(
    getPolicyDocumentsUrl(policyId),
    { headers: { auth_token: `bearer ${token}` }
    
  });
  return response;
};

export const getPolicyStatsForQuotation = async (clientId) => {
  const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    
  const response = await axiosGet(
    getPolicyStatsForQuotationUrl(clientId),
    { headers: { auth_token: `bearer ${token}` }
    
  });
  return response;
};

export const postQuotations = async (quotations) => {
  const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosPost(
    postQuotationsUrl,
    { quotations : quotations },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const getQuotationStats = async (FromDate, ToDate) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosPost(
    getQuotationStatsUrl,
    { FromDate, ToDate },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const getPolicyStats = async (FromDate, ToDate) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);
  const response = await axiosPost(
    getPolicyStatsUrl,
    { FromDate, ToDate },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const getSearchPolicyStats = async (FromDate, ToDate, SearchString) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);
  const response = await axiosPost(
    getSearchPolicyStatsUrl,
    { FromDate, ToDate, SearchString },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const getExpiredPolicyStats = async (FromDate, ToDate) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);
  const response = await axiosPost(
    getExpiredPolicyStatsUrl,
    { FromDate, ToDate },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const getBatchPolicies = async (FromDate, ToDate) => {
  const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosPost(
    getBatchPoliciesUrl,
    { FromDate, ToDate },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const sendQuotationEmail = async (users, quotationIds, documentIds, cc, bcc) => {
  const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
  const response = await axiosPost(
    sendQuotationEmailUrl,
    { users, quotationIds, documentIds, cc, bcc },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const sendMultiQuotationEmail = async (users, quotationIds, documentIds, cc, bcc) => {
  const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
  const response = await axiosPost(
    sendMultiQuotationEmailUrl,
    { users, quotationIds, documentIds, cc, bcc },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const sendPolicyEmail = async (policyId, users, documentIds, cc, bcc, from) => {
  const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    
  const response = await axiosPost(
    sendPolicyEmailUrl,
    { policyId, users, documentIds, cc, bcc, from },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const sendMultiPolicyEmail = async (policyId, users, cc, bcc) => {
  const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    
  const response = await axiosPost(
    sendMultiPolicyEmailUrl,
    { policyId, users, cc, bcc },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const deleteQuotation = async (quotationId) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosDelete(
    deleteQuotationUrl(quotationId),
    { headers: { auth_token: `bearer ${token}` } } 
  );
  return response;
};

export const convertQuotationStatus = async (quotationId, policyId) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);
  const response = await axiosPatch(
    convertQuotationStatusUrl(quotationId, policyId), {},
    { headers: { auth_token: `bearer ${token}` } } 
  );
  return response;
};

export const addQuotation = async (quotation, groupId) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosPost(
     addQuotationUrl(groupId),
     { quotation : quotation },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const updateQuotation = async (quotation) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosPost(
     updateQuotationUrl,
     { quotation : quotation },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const verifyPolicyNo = async (policyList) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosPost(
    verifyPolicyNoUrl,
    { policyList },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const postBatchPolicies = async (policyList) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosPost(
    postBatchPoliciesUrl,
    { policyList },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const updateBatchPolicy = async (policy) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosPost(
     updateBatchPolicyUrl,
     { policy : policy },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const deleteBatchPolicy = async (policyId) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosDelete(
    deleteBatchPolicyUrl(policyId),
    { headers: { auth_token: `bearer ${token}` } } 
  );
  return response;
};

export const deleteBatchPolicyGroup = async (batchNo) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosDelete(
    deleteBatchPolicyGroupUrl(batchNo),
    { headers: { auth_token: `bearer ${token}` } } 
  );
  return response;
};

export const getPolicyStatsForPrimaryClient = async (clientId, FromDate, ToDate) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosPost(
    getPolicyStatsForPrimaryClientUrl(clientId),
    { FromDate, ToDate },
    { headers: { auth_token: `bearer ${token}` }
    
  });
  return response;
};

export const getPolicyHistory = async (groupId) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);
  const response = await axiosPost(
    getPolicyHistoryUrl(groupId),
    {},
    { headers: { auth_token: `bearer ${token}` }
  });
  return response;
};

export const getPolicyByPrevPolicy = async (prevPolicyId) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);
  const response = await axiosGet(
    getPolicyByPrevPolicyUrl(prevPolicyId),
    { headers: { auth_token: `bearer ${token}` }  
  });
  return response;
};

export const getQuotationsByPolicyGroup = async (groupId) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);
  const response = await axiosPost(
    getQuotationsByPolicyGroupUrl(groupId),
    {},
    { headers: { auth_token: `bearer ${token}` }
  });
  return response;
};

export const getQuotationsByPolicy = async (policyId, isBatch) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);
  const response = await axiosPost(
    getQuotationsByPolicyUrl(policyId, isBatch),
    {},
    { headers: { auth_token: `bearer ${token}` }
  });
  return response;
};

export const getBusinessReports = async (FromDate, ToDate) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);
  const response = await axiosPost(
    getBusinessReportsUrl,
    { FromDate, ToDate },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const getBusinessReportsByAgentID = async (FromDate, ToDate) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);
  const response = await axiosPost(
    getBusinessReportsByAgentIDUrl,
    { FromDate, ToDate },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const addRevenueMaster = async (revenue) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);
  const response = await axiosPost(
    addRevenueMasterUrl,
    { revenue },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const updateRevenueMaster = async (revenue) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);
  const response = await axiosPost(
    updateRevenueMasterUrl,
    { revenue },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const getRevenueMaster = async (from) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);
  const response = await axiosPost(getRevenueMasterUrl, { from }, {
      headers: { auth_token: `bearer ${token}` },
  });
  return response;
};

export const getAllIncentiveTarget = async () => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);  

  const response = await axiosPost(getAllIncentiveTargetUrl, {}, {
      headers: { auth_token: `bearer ${token}` },
  });
  return response;
};

export const getIncentiveTarget = async (revenueMasterId) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);  

  const response = await axiosPost(getIncentiveTargetUrl(revenueMasterId), { }, {
      headers: { auth_token: `bearer ${token}` },
  });
  return response;
};

export const deleteRevenueMaster = async (revenueMasterId) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);  
  
  const response = await axiosDelete(
      deleteRevenueMasterUrl(revenueMasterId),
      { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const deletePolicyRequest = async (email) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);  
  
  const response = await axiosPost(`${deletePolicyRequestUrl}/${email}`, { },{
    headers: { auth_token: `bearer ${token}` },
  });
  return response;
}

export const deactivatePolicy = async (data) => {   
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);  
  
  const response = await axiosPost(deactivatePolicyUrl, {data}, {
    headers: { auth_token: `bearer ${token}` },
  });
  return response;
}

export const getQuotationStatsForPrimaryClient = async (clientId, FromDate, ToDate) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosPost(
    getQuotationStatsForPrimaryClientUrl(clientId),
    { FromDate, ToDate },
    { headers: { auth_token: `bearer ${token}` }
    
  });
  return response;
};
