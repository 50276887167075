import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import 'jquery-form';
import 'jquery-validation';
import '../../assets/scripts/bootstrap-modal';
import '../../assets/scripts/jquery-validation.init';
import MemberTable from './member-table';
import swal from 'sweetalert2';
import './addClient.css';
import { postClients } from '../api/clientAPI';
import AddSubAgentModal from '../subAgency/addSubAgentModal';
import { postSubClients } from '../api/subAgencyAPI';

const AddClient = (props) => {
  const [btnLoading,          setBtnLoading]          = useState(false);
  const [clientType,          setClientType]          = useState('Individual');
  const [membersArray,        setMembersArray]        = useState([]);
  const [memberSaved,         setMemberSaved]         = useState(false);
  const [addedClientId,       setAddedClientId]       = useState(null);
  const [showSubAgentModal,   setShowSubAgentModal]   = useState(false);
  const [addedSubAgent,       setAddedSubAgent]       = useState(null);
  const [type,                setType]                = useState(props.clientType.type)

  const setMembersHandler = (mArray) => {
    if (JSON.stringify(mArray) !== JSON.stringify(membersArray))
      setMembersArray(mArray);
  };

  useEffect(() => {
    $('#add-client-modal').modal({
      backdrop: 'static',
    });
    $('#add-client-modal').on('hidden.bs.modal', function () {
      $('.modal').hasClass('show') && $('body').addClass('modal-open');
      $('#add-policy-modal').removeClass('modal-blur');
      $('#add-quotation-modal').removeClass('modal-blur');
      $('#add-task-modal').removeClass('modal-blur');
      $('#edit-task-modal').removeClass('modal-blur');
      $('#edit-policy-modal').removeClass('modal-blur');
      $('#add-teamMember-modal').removeClass('modal-blur');
      props.onDismissModal('client', false, props.from);
    });
    $('#add-client-modal').modal('toggle');
  }, [props.show]);

  const clientTypeHandler = (type) => {
    setClientType(type);
    resetForm();
  };

  useEffect(() => {
    if (memberSaved) {
      $('#add-client-modal').modal('hide');
      props.onDismissModal('client', false, props.from, memberSaved, { addedClientId });
    }
  }, [memberSaved]);

  const addClientHandler = async (e) => {
    e.preventDefault();

    var form = $('#add-member-form');
    form.validate().destroy();

    form.validate({
      rules: {
        companyName: {
          required: true,
        },
      },
    });

    if (!form.valid()) {
      return;
    }

    if (membersArray.length === 0)
      return swal.fire({
        icon                : 'error',
        titleText           : 'Add at least one client',
        buttonsStyling      : false,
        confirmButtonClass  : 'btn btn-brand',
      });
    try {
      setBtnLoading(true);
      const ID = await postClients(
        membersArray.map((m) => ({
          ...m,
          CompanyName: $('#companyName').val(),
        })),
        clientType
      )
      // const ID = (type == 'directClient' || type == 'both') ? await postClients(
      //   membersArray.map((m) => ({
      //     ...m,
      //     CompanyName: $('#companyName').val(),
      //   })),
      //   clientType
      // ) : await postSubClients(
      //   membersArray.map((m) => ({
      //       ...m,
      //       CompanyName: $('#companyName').val()          
      //     })),
      //     clientType,
      //     membersArray[0].SubAgentID
      //   );
      setAddedClientId(ID);
      setMemberSaved(true);
      swal.fire({
        icon              : 'success',
        titleText         : 'Client Added Successfully',
        showConfirmButton : false,
        timer             : 1500,
      });
    } catch (err) {
      setBtnLoading(false);
      swal.fire({
        icon                : 'error',
        titleText           : 'Error!',
        text                : err.message,
        buttonsStyling      : false,
        confirmButtonClass  : 'btn btn-brand',
      });
    }
  };

  const resetForm = () => {
    var form = $('#add-member-form');
    form.clearForm();
    form.validate().resetForm();
  };

  return (
    <>
      <div
        className="modal fade"
        id="add-client-modal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Client</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                disabled={btnLoading}
              ></button>
            </div>
            <div className="modal-body">
              <div className="kt-radio-inline radio-container">
                <div className="input-group">
                  {' '}
                  <input
                    value="Individual"
                    defaultChecked
                    onChange={({ target }) => clientTypeHandler(target.value)}
                    type="radio"
                    name="client-type"
                    id="indv"
                  />
                  <label htmlFor="indv">Individual</label>
                </div>
                <div className="input-group margin-l20">
                  {' '}
                  <input
                    value="Corporate"
                    onChange={({ target }) => clientTypeHandler(target.value)}
                    type="radio"
                    name="client-type"
                    id="corp"
                  />
                  <label htmlFor="corp">Organization</label>
                </div>
              </div>

              <MemberTable
                setType={setType}
                type={type}
				        clientType={props.clientType}
                addedSubAgent={addedSubAgent}
                setAddedSubAgent={setAddedSubAgent}
                setShowSubAgentModal={setShowSubAgentModal}
                setMembersHandler={setMembersHandler}
                client={{ Members: membersArray, Type: clientType }}
                action="a"
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary margin-0 margin-r5"
                data-dismiss="modal"
                disabled={btnLoading}
              >
                Close
              </button>
              {membersArray.length > 0 && (<button
                id="save-btn"
                onClick={addClientHandler}
                type="button"
                className={`btn btn-brand margin-0 ${
                  btnLoading ? 'kt-spinner kt-spinner--sm kt-spinner--light' : ''
                }`}
                disabled={btnLoading}
              >
                Save
              </button>)}
            </div>
          </div>
        </div>
      </div>
      {showSubAgentModal && (
        <AddSubAgentModal
          show           = {showSubAgentModal}
          from           = {"#add-client-modal"}
          addSubAgentId  = {(v) => setAddedSubAgent(v)}
          onDismissModal = {() => setShowSubAgentModal(false)}
          onOpenModal    = {() => setShowSubAgentModal(true)}

        />
      )}
    </>
  );
};

export default AddClient;
