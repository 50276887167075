import React, { useState, useEffect } from 'react'
import $ from 'jquery';
import 'jquery-form';
import 'jquery-validation';
import '../../assets/scripts/jquery-validation.init';
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import { resendAgentOTP, VerifyAndUpdateAgentEmail, agentEmailChangeRequest} from '../api/agentAPI';
import { setAuthState, getAuthState } from '../../assets/scripts/login-util';
import { deviceDetect } from "react-device-detect";
import './emailVerificationModal.css'


const EmailVerificationModal = (props) => {

    const [Email,           setEmail]           = useState('');
    const [OTP,             setOtp]             = useState('')
    const [timer,           setTimer]           = useState(20)
    const [otpTimer,        setOtpTimer]        = useState(180)
    const [counter,         setCounter ]        = useState({m:3, s: 0})
    const [intervalId,      setIntervalId]      = useState({});
    const [OtpRequest,      setOtpRequest]      = useState(false);
    const [serverTimeStamp, setServerTimeStamp] = useState();

    useEffect(() => {
        $('#email-verification-modal').modal({ backdrop: 'static',});

        $('#email-verification-modal').on('hidden.bs.modal', function () {
            props.onDismissModal('verifyEmail', false);
        });
        $('#email-verification-modal').modal('toggle');
    }, [props.show]);

    useEffect(() => {
        if(serverTimeStamp){
            const OTPExpirationSeconds = moment().diff(serverTimeStamp, 'seconds')
            showTimer(180 - OTPExpirationSeconds);
        }
    }, [serverTimeStamp])

    useEffect(()=>{
        let ignore = false;

        const updateCounter = () =>{   
            let d = otpTimer % (60 * 60 );
            let ds = Math.ceil(d % 60);
            let t = {m : Math.floor(d/ 60), s: ds < 10 ?`0${ds}`:ds}
            setCounter(t)
        }

        if(!ignore) updateCounter();
        return () =>{
            ignore = true;
        }
    }, [otpTimer])

    const showTimer = (secondsToExpire) => {
        if(secondsToExpire < 0) {
            setOtpTimer(0);
            setTimer(0)
            return
        } 
        //OTP RESEND TIMER
        setTimer(20)
        const resendInterval = setInterval(() => {
            setTimer(t =>{
                if(t > 0){
                    return (t-1)
                }else{
                    clearInterval(resendInterval)
                    return 0
                }
            })
        }, 1000);
        
        //OTP EXP TIMER
        let otpInterval;
        if(secondsToExpire <= 180 && secondsToExpire > 0){
            setOtpTimer(secondsToExpire);
            otpInterval = setInterval(() => {
                setOtpTimer(t =>{
                    if(t > 0){
                        return (t-1)
                    }else{
                        clearInterval(otpInterval)
                        return 0
                    }
                })
            }, 1000);
        }
        //SET IntervalIds for Resetting
        setIntervalId({ resendInterval, otpInterval });        
    }

    const codeChangeHandler = (e) => setOtp(e.target.value.trim());
    const emailChangeHandler = (e) => setEmail(e.target.value.trim());

    const resetTime = () => {
        clearInterval(intervalId.resendInterval);
        clearInterval(intervalId.otpInterval);
    }

    const resendHandler = async () => {
        try {
            let response = await resendAgentOTP({ email : Email, reason: 'email verification' });
            if(response){
                //Clear Inputs
                setOtp('');
                resetTime();
                setServerTimeStamp(response.serverTimestamp);
            }
        } catch (err) {
            swal.fire({
                icon        : 'error',
                titleText   : err.message,
                animation   : true,
                customClass : { popup: 'margin-20', },
            });
        }
    }

    const verifyEmailHandler = async () => {
        const btn = $("#verifyEmail-btn");
        const form = $("#email-verification-form");
        form.validate({
            rules: {
                Code: { required: true, minlength: 6 },
            }
        })

        if (!form.valid()) return;
     
        helper.StartProcessing(btn)
        try {
            const deviceData = deviceDetect();
            const device = await helper.getDeviceDetails(deviceData);

        //     // VERIFY AGENT OTP API  
           const response = await VerifyAndUpdateAgentEmail({ OTP, Email : Email, device });
           if(response){
               //replace Cookie with new one
               setAuthState(response, true);
               //Clear All intervals
                resetTime();

                swal.fire({
                    toast               : true,
                    icon                : 'success',
                    titleText           : `Email verified successfully!`,
                    position            : 'bottom-end',
                    showConfirmButton   : false,
                    timer               : 2500,
                    animation           : true,
                    customClass         : { popup: 'margin-20', },
                });
               
                $('#email-verification-modal').modal('hide');
                window.location.reload();
           }
        } catch (err) {
            if(err.message === 'OTP Expired') setTimer(0)
            swal.fire({
                icon         : 'error',
                titleText    : err.message,
                animation    : true,
                customClass  : { popup: 'margin-20', },
            });
            return helper.StopProcessing(btn);
        }
    }

    const getOtpHandler = async (e) => {
        const currentEmail = getAuthState('user').email
        e.preventDefault();
        const btn = $("#getOTP-btn");
        const form = $("#email-verification-form");
        form.validate({
            rules: {
                Email: { required: true }
            }
        })
        if (!form.valid()) return;
        form.validate().destroy();

        if(Email.toLowerCase() == currentEmail.toLowerCase()) return;
        
        swal.fire({
                icon              : 'warning',
                title             : 'Request Email Change',
                text              : 'Are You Sure ..!',
                confirmButtonText : 'Yes',
                showCancelButton  : true,
        }).then( async (result) => {
            if (result.isConfirmed) {
                try {
                    helper.StartProcessing(btn);
                    const response = await agentEmailChangeRequest(Email);
                    if(response){
                        setServerTimeStamp(response.serverTimestamp);
                        setOtpRequest(true);
                    }
                    helper.StopProcessing(btn);
                } catch (err) {
                    swal.fire({
                        icon              : "error",
                        titleText         : "Error!",
                        text              :  err.message,
                        buttonsStyling    : false,
                        confirmButtonClass: "btn btn-brand",
                    });
                    return helper.StopProcessing(btn);
                }
            }
        })   
    }

    return (
        <div
          className="modal fade"
          id="email-verification-modal"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true">
        
          <div className="modal-dialog modal-md" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                    Change Email 
                </h5>
                <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close">
            </button>
              </div>
              <div className="modal-body">
                <div className="client-container">
                    <div className="row margin-b20">
                        <div className="col text-center">
                        {OtpRequest &&
                            <>
                                <div className="kt-login__desc"> 
                                    OTP has been sent to <b>{Email}</b>.
                                   <br/>
                                    <small>( Check your spam or update folder in case you haven't received OTP)</small>
                                </div>
                                <h5 className="text-center margin-t10"> {(counter.m == 0 && counter.s == 0)?' OTP Expired ':`${counter.m}:${counter.s}`} </h5>
                            </>
                        }
                        </div>
                    </div>

                    <div >
                        <form id="email-verification-form" >
                            <input 
                                name="Email" 
                                value={Email || ''}
                                className="form-control password-input"  
                                type='email'
                                disabled={OtpRequest}
                                onChange={emailChangeHandler} 
                                placeholder="Enter a new email"
                            />

                            {OtpRequest &&
                                <input value={OTP} 
                                    type="number" 
                                    name="Code" 
                                    className="form-control " 
                                    placeholder="Enter OTP" 
                                    autoComplete="off"  
                                    onChange={codeChangeHandler} 
                                />
                            }
                        </form>
                    </div>
                    { OtpRequest &&
                        <div className="row margin-t20">
                            <div className="col text-center">                                                
                                <span className="kt-login__account-msg">
                                    Didn't get it ?
                                </span>
                                &nbsp;&nbsp;
                                {timer > 0 ? `Please wait for ${timer} seconds to Resend OTP `
                                    :<span id="kt_login_otp_resend" className="kt-login__account-link" onClick={resendHandler} > Resend OTP </span>
                                }
                            </div>
                        </div>
                    }      
                </div>
              </div>
              <div className="modal-footer">
              {OtpRequest ?
                <button
                    id="verifyEmail-btn"
                    onClick={verifyEmailHandler}
                    disabled={otpTimer === 0} 
                    type="button"
                    className="btn btn-brand margin-0">   
                    Verify Email & Update
                </button>
              : <button
                    type="button"
                    id="getOTP-btn"
                    onClick={getOtpHandler}
                    className="btn btn-success margin-0">   
                    Request OTP
                </button>
              }
              </div>
            </div>
          </div>
        </div>
      );
}

export default EmailVerificationModal