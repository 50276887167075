import React, { useState, useEffect } from 'react';
import NoData from '../global/noData';
import Loading from '../global/loading';
import QuotationImg from '../../assets/images/no-policy.svg'
import Select from 'react-select';
import QuotationTable from './quotationTable';
import { getQuotationsByPolicy, getQuotationsByPolicyGroup } from '../api/policyAPI';

const PolicyQuotation = (props) => {

    const [loading,                  setLoading]                  = useState(false);
    const [policyQuotations,         setPolicyQuotations]         = useState([]);
    const [searchString,             setSearchString]             = useState('');
    const [filteredPolicyQuotations, setFilteredPolicyQuotations] = useState([]);
    const [policyNoList,             setPolicyNoList]             = useState([]);
    const [currentPolicy,            setCurrentPolicy]            = useState(null);
    const [isReset,                  setIsReset]                  = useState(false);

    useEffect(() => {
        fetchPolicyQuotation();  
    }, [props.refresh]);

    useEffect(() => {
        const quote = policyQuotations.filter((t, i, tr) => tr.findIndex(s => s.PolicyNo == t.PolicyNo) == i)
        .sort((a, b) =>
            (a.PolicyNo === null) 
            ? 1 
            : (b.PolicyNo === null) 
            ? -1 
            : (a.ExpiryDate < b.ExpiryDate) 
            ? 1 
            : (a.ExpiryDate > b.ExpiryDate) 
            ? -1  
            : 0
        ).map(p => {
            const policyNo = p.PolicyNo || 'New';  
            return {label: policyNo, value: { PolicyNo: policyNo}}
        })

        if (quote.length != policyNoList.length)
            setPolicyNoList(quote);   

        if (policyQuotations.length > 0)
            filterPolicyQuotation();
        else
            setFilteredPolicyQuotations([]);

    }, [policyQuotations]);

    useEffect(() => {   
        if (policyQuotations.length > 0)
            filterPolicyQuotation();
        else
            setFilteredPolicyQuotations([]);
    }, [currentPolicy, searchString]);


    useEffect(() => {   
        if (policyNoList.length > 0) {
            setCurrentPolicy(policyNoList[0].value);
            setSearchString('');
        }
    }, [policyNoList]);

    useEffect(() => {   
        if (isReset) {
            setCurrentPolicy(policyNoList[0].value);
            setSearchString('');
            setIsReset(false);
        }
    }, [isReset]);

    const fetchPolicyQuotation = async () => {
        try {
            setLoading(true)
            const response = props.isBatch ? await getQuotationsByPolicy(props.policyID, 'Y') : await getQuotationsByPolicyGroup(props.policyGroupID);
            if (policyQuotations.length > 0 && response.length > policyQuotations.length) setIsReset(true);
            else setIsReset(false);
            setPolicyQuotations(response);     
            setLoading(false);
        } catch (err) {
            swal.fire({
                icon              : 'error',
                titleText         : 'Error!',
                text              : err.message,
                buttonsStyling    : false,
                confirmButtonClass: 'btn btn-brand',
            });
        }
    }

    const filterPolicyQuotation = async () => {

        let quotationList = [];

        if (currentPolicy) 
            quotationList = policyQuotations.filter(p => (p.PolicyNo || 'New') === currentPolicy.PolicyNo);       

        if (searchString) {
            const matchedQuotations = quotationList.filter((c) => {
                let matchString = `${c.InsuranceCompanyName} ${c.SumInsured} ${c.Premium}`;
                let matchFound = 0;
                const searchArr = searchString.split(' ');
                searchArr.forEach((term) => {
                    matchFound += matchString.toLowerCase().includes(term.toLowerCase())
                        ? 1
                        : 0;
                });
                return matchFound === searchArr.length;
            });
            setFilteredPolicyQuotations(matchedQuotations);
        }else{
            setFilteredPolicyQuotations(quotationList);
        }  
    }

    const editQuotationHandler = (index) => {     
        const quotation = filteredPolicyQuotations[index];
        props.setEditingQuote(quotation);
    }

    const removeQuotationHandler = (index) => {

        const q = filteredPolicyQuotations[index];
        const nA = policyQuotations.filter(f => f.ID != q.ID);
        setPolicyQuotations(nA);
        props.setDoRefresh(true);
    }

    const emailQuotationHandler = (index, isEmail) => {

        const quotation = filteredPolicyQuotations[index];

        if (isEmail) {
            props.setEmailList((a) => {
                const newA = [...a];
                newA.push({
                    ID: quotation.ID,
                    IsEmail: true
                });          
                return newA;
            });  
            props.setEmailClientQuotation(quotation.ClientGroupID)
        }else{
            props.setEmailList((a) => {
                const nA = a.filter((q) => q.ID !== quotation.ID);
                return nA;
            })    
        }                           
    }

    const convertQuotationHandler = (index) => {     
        const quotation = filteredPolicyQuotations[index];
        props.setConvertingQuote(quotation);
    }

    return (
        <React.Fragment>
            <div className="kt-form kt-form--label-right kt-margin-b-10">
                <div className="row align-items-center stickyFilter">
                    <div className="col-xl-12 order-2 order-xl-1">
                        <div className="row align-items-center">
                        <div className="col-md-5 margin-b0 kt-margin-b-20-tablet-and-mobile">
                            <div className="kt-input-icon kt-input-icon--left">
                                <input                                      
                                    type="text"
                                    className="form-control"
                                    placeholder="Search Quotation..."
                                    id="generalSearch"
                                    value={searchString || ''}
                                    onChange={(e) => setSearchString(e.target.value)}
                                    >
                                </input>
                                <span className="kt-input-icon__icon kt-input-icon__icon--left">
                                    <span>
                                        <i className="la la-search"></i>
                                    </span>
                                </span>
                                {searchString && (
                                    <span className="kt-input-icon__icon kt-input-icon__icon--right">
                                        <span>
                                            <i className="la la-close" onClick={() => setSearchString('')}></i>
                                        </span>
                                    </span>
                                )}
                            </div>
                        </div>
                            {(policyQuotations && policyQuotations.length > 0 && !props.isBatch) && (
                                <div className="col-md-6 margin-sm-b20 margin-b0 kt-margin-b-20-tablet-and-mobile">
                                    <div className="kt-input-icon kt-input-icon--left">
                                        <div className="kt-form__group kt-form__group--inline">   
                                            <div className="kt-form__label">
                                                <label htmlFor="ddlPolicyNo">Policy:</label>
                                            </div>
                                            <div className="kt-form__control">
                                                <Select
                                                    value={currentPolicy && {label: currentPolicy.PolicyNo, value: currentPolicy}}
                                                    onChange={(event) => setCurrentPolicy(event.value)}
                                                    name="ddlPolicyNo"
                                                    id="ddlPolicyNo"
                                                    className="ddlPolicyNo"
                                                    options={policyNoList}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>        
                            )}              
                        </div>
                    </div>
                </div>
            </div>
            {loading ? <Loading/> : (
                filteredPolicyQuotations.length > 0 ? (
                    <QuotationTable 
                        action="e"
                        quotations={filteredPolicyQuotations}
                        removeQuotation={removeQuotationHandler}
                        emailQuotation={emailQuotationHandler} 
                        editQuotation={editQuotationHandler}
                        setDocumentQuoteID={props.setDocumentQuoteID}     
                        convertQuotation={convertQuotationHandler}
                    />
                ) : (
                    <NoData
                        src={QuotationImg}
                        alt="Quoatation"
                        message="No Quotations Found"
                    />
                )
            )}
        </React.Fragment>
    );
};

export default PolicyQuotation;