import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import 'jquery-form';
import 'jquery-validation';
import '../../assets/scripts/jquery-validation.init';
import * as helper from '../global/helper';
import swal from 'sweetalert2';
import moment from 'moment';
import {
  deactivateClient,
  updateClient,
  verifyClientEmail,
  addclient,
  deactivateMemberByOTPVerification,
} from '../api/clientAPI';
import NoData from '../global/noData';
import ClientImg from '../../assets/images/no-client.svg'
import Select from 'react-select';
import { getSubAgentsByAgentCompany, getSubAgentsByAgentID, verifySubClientEmail } from '../api/subAgencyAPI';
import AddSubAgentModal from '../subAgency/addSubAgentModal';
import { getAuthState } from '../../assets/scripts/login-util';
import { getAgentSubscription, resendAgentOTP } from '../api/agentAPI';
import { deletePolicyRequest } from '../api/policyAPI';

const MemberTable = (props) => {
  const [members, setMembers] = useState(
    props.client.Members ? props.client.Members : []
  );
  const [clientType,          setClientType]          = useState(props.client.Type);
  const [type,                setType]                = useState('Ind');
  const [companyName,         setCompanyName]         = useState(props.client.CompanyName);
  const [selectedMember,      setSelectedMember]      = useState({});
  const [editingXid,          setEditingXid]          = useState(null);
  const [subAgents,           setSubAgents]           = useState([]);
  const [currentSubAgent,     setCurrentSubAgent]     = useState(null);
  const [isSubAgency,         setIsSubAgency]         = useState(false)

  const userDetails = getAuthState();

  const fillMemberTable = () => {
    setMembers(props.client.Members ? props.client.Members : []);  
  };

  useEffect(() => {
    setMembers([])
    if (props.type === 'subClient') {
      fetchSubAgents()
    } else {
      fetchSubAgencyData()
      setCurrentSubAgent(null)
    }
  }, [props.type])

  useEffect(() => {
    if (subAgents.length > 0) {
      if (props.addedSubAgent){
        setCurrentSubAgent(subAgents.find(i => i.ID == props.addedSubAgent));
        props.setAddedSubAgent(null);
      }             
    }  
  }, [subAgents]);

  useEffect(() => {   
    if (props.addedSubAgent)
      fetchSubAgents();        
  }, [props.addedSubAgent]);

  useEffect(() => {   
    if (props.clientType)
      setCurrentSubAgent(props.clientType.currentSubAgent)  
  }, [props.clientType]);

  useEffect(() => {
    if (props.setMembersHandler) props.setMembersHandler(members);
  }, [members]);

  useEffect(() => {
    fillMemberTable();
  }, [props.client.Members]);

  useEffect(() => {
    setClientType(props.client.Type);
  }, [props.client.Type]);

  const fetchSubAgencyData = async () => {
    try {
      const { SubAgency } = await getAgentSubscription();
      setIsSubAgency(SubAgency == 'Y' ? true : false)
    } catch (err) {
      swal.fire({
        icon                : 'error',
        titleText           : 'Error!',
        text                : err.message,
        buttonsStyling      : false,
        confirmButtonClass  : 'btn btn-brand',
      });
    }
  }

  const memberDetailsHandler = (key, value) => {
    setSelectedMember((d) => {
      // if (['FirstName', 'MiddleName', 'LastName'].includes(key))
      //   value = helper.capitalizeCamel(value);
      return { ...d, [key]: value };
    });
  };

  const showDatePickerHandler = (target, show) => {   
    if (show) {
      target.type = 'date';
      
    }else{
      if (!target.value) {
        target.type = 'text';
      }
    }
  };

  const updateMemberHandler = (index) => {
    const editingMember = members[index];
    const { XID } = editingMember;
    setEditingXid(XID);
    if (!editingMember.LastName) {
      setType('Co');
      setSelectedMember({SubCompanyName: editingMember.FirstName, ...editingMember});
    } else {
      setType('Ind')
      setSelectedMember(editingMember);
    }
  };

  const removeMemberHandler = async (index) => {
    const removingMember = members[index];
    const { XID, ID } = removingMember;

    if (props.action === 'e') {
      const swalResult = await swal.fire({
        title               : 'Are you sure?',
        text                : "You won't be able to revert this!",
        icon                : 'warning',
        showCancelButton    : true,
        buttonsStyling      : false,
        reverseButtons      : true,
        showLoaderOnConfirm : true,
        confirmButtonClass  : 'btn btn-brand',
        cancelButtonClass   : 'btn btn-secondary',
        confirmButtonText   : 'Delete',
      }).then(async(result) => {
        if(result.isConfirmed) {
          try {
              const response = await deletePolicyRequest(props.user.Email)
              if(response){
                  props.setServerTimeStamp(response.serverTimestamp);
                  props.setPasswordRequest({ClientId:ID, MemberType:'Member'});
              }
              if (XID === editingXid) {
                setEditingXid(null);
                setSelectedMember({});
              }
              setMembers((a) => {
                const nA = a.filter((b, i) => index !== i);
                return nA;
              });
          } catch (err) {
              swal.fire({
                  icon              : "error",
                  titleText         : "Error!",
                  text              :  err.message,
                  buttonsStyling    : false,
                  confirmButtonClass: "btn btn-brand",
              });
          }
        }
      })
    }
    if (props.action == 'a') {
      setMembers((a) => {
        const nA = a.filter((b, i) => index !== i);
        return nA;
      })
    }
  };

  const newAgentAddHandler = (e) =>{
    e.preventDefault();
    $('#add-client-modal').addClass('modal-blur');
    $('#add-member-modal').modal('toggle');
    props.setShowSubAgentModal(true)
  }

  const removeSubAgentHandler = (e) => {
    e.preventDefault()
    setCurrentSubAgent(null);
    setMembers([])
  }

  const fetchSubAgents = async () => {
    try {
      const response = userDetails.user.categoryId != 3 ? await getSubAgentsByAgentCompany() : await getSubAgentsByAgentID();
      setSubAgents( [
        ...response.map((c) => ({
          ...c,
          DisplayName:
            c.Type === 'Corporate' && c.Category === 'Primary'
              ? c.CompanyName
              : `${c.FirstName} ${c.MiddleName} ${c.LastName}`
        }))
        .sort((a, b) =>
          a.DisplayName > b.DisplayName
            ? 1
            : a.DisplayName < b.DisplayName
            ? -1
            : 0
        )
      ]);
    } catch (err) {
      swal.fire({
        icon                  : 'error',
        titleText             : 'Error!',
        text                  : err.message,
        buttonsStyling        : false,
        confirmButtonClass    : 'btn btn-brand',
      });
    }
  };

  const memberAddHandler = async (e) => {
    e.preventDefault();
    var form = $('#add-member-form');
    form.validate().destroy();

    form.validate({
      rules: {
        companyName: {
          required: true,
        },
        subCompanyName: {
          required: true,
        },
        firstName: {
          required: true,
        },
        lastName: {
          required: true,
        },
        email: {
          required: {
            depends: function (element) {
              if (
                (props.type == 'directClient') && (members.findIndex(({ XID }) => XID === selectedMember.XID) === 0)
              ) 
              return true;
              return members.length === 0 && (props.type == 'directClient' || props.type == 'both');
            },
          },
          email: {
            depends: function (element) {
              return $(element).val() !== '';
            },
          },
        },
        mobile: {
          minlength: 10,
          maxlength: 10,
        },
      },
    });

    if (!form.valid()) {
      return;
    }

    if (props.type === 'subClient' && !currentSubAgent) {
      swal.fire({
        icon                  : 'error',
        titleText             : 'Error!',
        text                  : "Please select sub agent",
        buttonsStyling        : false,
        confirmButtonClass    : 'btn btn-brand',   
      });
      return;
    }

    // let isEmailExist = false;
    // members.forEach((member) => {
    //   if (
    //     selectedMember.Email &&
    //     member.Email === selectedMember.Email &&
    //     member.XID !== selectedMember.XID
    //   ) {
    //     isEmailExist = true;
    //     return;
    //   }
    // });

    // if (isEmailExist) {
    //   swal.fire({
    //     icon                : 'error',
    //     titleText           : selectedMember.Email + ' already added!',
    //     buttonsStyling      : false,
    //     confirmButtonClass  : 'btn btn-brand',
    //   });
    //   return;
    // }

    if (selectedMember.Email) {
      try {
        helper.StartProcessing($('#btnAdd'));
        const response = (props.type === 'directClient' || props.type == 'both' || props.client.cType == 'Direct Client') ? await verifyClientEmail(
          [selectedMember.Email],
          clientType
        ) : await verifySubClientEmail(
          [selectedMember.Email],
          clientType,
          props.from == '#add-policy-modal' || props.from == '#edit-policy-modal' ? props.agent.ID : currentSubAgent.ID
          
        );
        const otherEmails = response.filter(
          (m) => m.ID !== selectedMember.ID
        );
        if (otherEmails.length > 0) {
          const list = `<div class="row margin-t20">
                            <div class="col-12">
                                <table class="table table-striped">
                                    <tbody>
                                        ${otherEmails.map(
                                          (m, i) =>
                                            `<tr key=${i}>
                                            <td>
                                                <div class="kt-user-card-v2">
                                                    <div class="kt-user-card-v2__pic">
                                                        <div class="kt-badge kt-badge--xl kt-badge--${
                                                          clientType ===
                                                          'Corporate'
                                                            ? 'success'
                                                            : 'info'
                                                        }">     
                                                            ${
                                                              m.DisplayName
                                                                ? m.DisplayName[0].toUpperCase()
                                                                : ''
                                                            }
                                                        </div>
                                                    </div>
                                                    <div class="kt-user-card-v2__details">
                                                        <span class="kt-user-card-v2__name text-left">
                                                            ${m.DisplayName}
                                                        </span>
                                                        <span class="kt-user-card-v2__desc">
                                                            ${m.Email}
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>`
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>`;

          // if (clientType === 'Corporate') {
          //   const swalResult = await swal.fire({
          //     icon                : 'warning',
          //     titleText           : 'Warning! Email alredy in use with following clients',
          //     html                : list,
          //     showCancelButton    : true,
          //     buttonsStyling      : false,
          //     reverseButtons      : true,
          //     confirmButtonClass  : 'btn btn-brand',
          //     cancelButtonClass   : 'btn btn-secondary',
          //     confirmButtonText   : 'Add anway',
          //     cancelButtonText    : "Don't add",
          //   });
          //   if (!swalResult.value){
          //     helper.StopProcessing($('#btnAdd'));
          //     return;
          //   } 
          // } else {
          //   swal.fire({
          //     icon                : 'error',
          //     titleText           : 'Error! Email already in use',
          //     html                : list,
          //     buttonsStyling      : false,
          //     confirmButtonClass  : 'btn btn-brand',
          //   });
          //   helper.StopProcessing($('#btnAdd'));
          //   return;
          // }
          const swalResult = await swal.fire({
            icon                : 'warning',
            titleText           : 'Warning! Email already in use with following clients',
            html                : list,
            showCancelButton    : true,
            buttonsStyling      : false,
            reverseButtons      : true,
            confirmButtonClass  : 'btn btn-brand',
            cancelButtonClass   : 'btn btn-secondary',
            confirmButtonText   : 'Add anway',
            cancelButtonText    : "Don't add",
            customClass         : {
              content           : 'swalHeight'
            }
          });
          if (!swalResult.value){
            helper.StopProcessing($('#btnAdd'));
            return;
          } 
        }
      } catch (err) {
        swal.fire({
          icon                  : 'error',
          titleText             : 'Error!',
          text                  : err.message,
          buttonsStyling        : false,
          confirmButtonClass    : 'btn btn-brand',
        });
        helper.StopProcessing($('#btnAdd'));
        return;
      }
    }
    let clientID = Math.random();
    if (props.action === 'e') {
      try {
        if (editingXid) await updateClient(selectedMember);
        else
          clientID = await addclient(selectedMember, members[0].GroupID);
      } catch (err) {
        swal.fire({
          icon                : 'error',
          titleText           : 'Error!',
          text                : err.message,
          buttonsStyling      : false,
          confirmButtonClass  : 'btn btn-brand',
        });
        helper.StopProcessing($('#btnAdd'));
        return;
      }
    }

    setMembers((a) => {
      const newA = [...a];
      if (editingXid) {
        const foundIndex = newA.findIndex(({ XID }) => XID === editingXid);
        newA[foundIndex] = {
          ...newA[foundIndex],
          ...selectedMember,
          SubAgentID: props.type == 'subClient' ? currentSubAgent.ID : 0,
          CompanyName: companyName,
        };
        setEditingXid(null);
      } else {
        newA.push({
          ...selectedMember,
          SubAgentID: props.type == 'subClient' ? currentSubAgent.ID : 0,
          CompanyName: companyName,
          XID: clientID,
          ID: clientID
        });
      }
      return newA;
    });

    if (editingXid === props.client.ID) {
      props.changeSelectedClientHandler({
        ...props.client,
        FirstName   : selectedMember.FirstName,
        MiddleName  : selectedMember.MiddleName || '',
        LastName    : selectedMember.LastName,
        Email       : selectedMember.Email,
        Mobile      : selectedMember.Mobile,
        DOB         : selectedMember.DOB,
      });
    }

    setSelectedMember({});

    form.clearForm();
    form.validate().resetForm();

    if (props.action === 'e') {
      swal.fire({
        toast             : true,
        icon              : 'success',
        titleText         : `Client ${editingXid ? 'updated' : 'added'} successfully!`,
        position          : 'bottom-end',
        showConfirmButton : false,
        timer             : 1500,
        animation         : false,
        customClass       : {
          popup: 'margin-20',
        },
      });
      props.doRefreshHandler(true);
    }

    helper.StopProcessing($('#btnAdd'));
  };

  const clientTypeHandler = (type) => {
    setType(type);
  };

  return (
    <React.Fragment>
      <div className="client-container">
        <form id="add-member-form" onSubmit={memberAddHandler}>
          {clientType === 'Corporate' && props.action === 'a' && (
            <input
              id="companyName"
              value={companyName || ''}
              name="companyName"
              placeholder="Company Name"
              className="form-control margin-b10"
              type="text"
              onChange={({ target }) => setCompanyName(helper.camelCase(target.value))}
            />
          )}
          {props.action === 'a' && (
            <div className='row margin-b10 margin-t20'>
              {props.clientType.type === 'both' ? (
                <div className={`${(props.type === 'directClient' || props.type == 'both') ? 'col-lg-12' : 'col-lg-2'}`}>
                  <select
                    className="form-control kt-selectpicker"
                    id="ddlType"
                    disabled={!isSubAgency}
                    value={props.type}
                    onChange={(event) => props.setType(event.target.value)}>
                    <option value='directClient'>Direct Client</option>
                    <option value='subClient'>Sub Client</option>
                  </select>
                </div>
              ) : (
                ''
              )}
              {(props.type === 'subClient' && !props.clientType.currentSubAgent) ? (
                <>
                  <div className='col-lg-10'>
                    <div className='row'>
                      <div className="col-lg-9">
                        <Select
                          value={currentSubAgent && {label: currentSubAgent.DisplayName, value: currentSubAgent}}
                          onChange={(event) => setCurrentSubAgent(event.value)}
                          name="ddlSubClient"
                          placeholder="Select Sub Agent"
                          id="ddlSubClient"
                          className="ddlSubClient"
                          options={subAgents.map(c => ({
                            label: c.DisplayName,
                            value: c
                          }))}
                        />
                      </div>
                      <div className="col-lg-3">
                        <button
                          id="btnAdd"
                          className="btn btn-label-brand btn-block margin-0"
                          onClick={newAgentAddHandler} 
                        >
                          <i className="la la-plus" />
                          New Agent
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
              {props.clientType.type === 'subClient' ? (
                <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded">
                  <table className="kt-datatable__table table-striped">
                    <tbody className="kt-datatable__body" style={{ display: 'block' }}>
                      <tr className="kt-datatable__row block-row">
                        <td className="kt-datatable__cell text-clip">
                          <div className="kt-user-card-v2">
                            <div className="kt-user-card-v2__pic">
                              <div className={`kt-badge kt-badge--xl kt-badge--${ props.clientType.currentSubAgent.Type === 'Corporate' ? 'success' : 'info' }`}>                                                                                 
                                {props.clientType.currentSubAgent.DisplayName.slice(0, 1)}
                              </div>
                            </div>
                            <div className="kt-user-card-v2__details">
                              <span className="kt-user-card-v2__name">{props.clientType.currentSubAgent.DisplayName}</span>
                              <span className="kt-user-card-v2__desc">{props.clientType.currentSubAgent.CategoryName}</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                ''
              )}
            </div>
          )}
          {currentSubAgent && !props.clientType.currentSubAgent && (
            <div className="row margin-t20 margin-b0">
              <div className="col-md-12">
                <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded">
                  <table className="kt-datatable__table table-striped">
                    <tbody className="kt-datatable__body" style={{ display: 'block' }}>
                      <tr className="kt-datatable__row block-row">
                        <td className="kt-datatable__cell text-clip">
                          <div className="kt-user-card-v2">
                            <div className="kt-user-card-v2__pic">
                              <div className={`kt-badge kt-badge--xl kt-badge--${ currentSubAgent.Type === 'Corporate' ? 'success' : 'info' }`}>                                                                                 
                                {currentSubAgent.DisplayName.slice(0, 1)}
                              </div>
                            </div>
                            <div className="kt-user-card-v2__details">
                              <span className="kt-user-card-v2__name" title={currentSubAgent.DisplayName}>{currentSubAgent.DisplayName}</span>
                              <span className="kt-user-card-v2__desc" title={`${currentSubAgent.Type === 'Corporate' ? 'Organization' : currentSubAgent.Type} ${currentSubAgent.Email}`}>{currentSubAgent.Type === 'Corporate' ? 'Organization | ' : currentSubAgent.Type + " " + "|" + "  "}{currentSubAgent.Email}</span>
                            </div>
                          </div>
                        </td>
                        <td className="kt-datatable__cell text-clip">
                          <span>
                            {currentSubAgent.CityName}, {currentSubAgent.StateName}
                          </span> 
                        </td>
                        <td className="text-right padding-r20">
                          <button 
                            type="button"                    
                            onClick={removeSubAgentHandler}
                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">     
                            <i className="flaticon2-rubbish-bin-delete-button"></i>
                          </button>                           
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          {clientType === 'Corporate' && members.length > 0 && (
            <>
              <div className="kt-radio-inline radio-container margin-t20 margin-b20">
                <div className="input-group">
                  {' '}
                  <input
                    value="Ind"
                    checked={type === 'Ind'}
                    onChange={({ target }) => clientTypeHandler(target.value)}
                    type="radio"
                    name="clientType"
                    id="ind"
                  />
                  <label htmlFor="ind">Individual</label>
                </div>
                <div className="input-group margin-l20">
                  {' '}
                  <input
                    value="Co"
                    checked={type === 'Co'}
                    onChange={({ target }) => clientTypeHandler(target.value)}
                    type="radio"
                    name="clientType"
                    id="co"
                  />
                  <label htmlFor="co">Organization</label>
                </div>
              </div>
            </>
          )}
          {(type === 'Co' && clientType === 'Corporate' && members.length > 0) && (
            <input
              id="subCompanyName"
              value={selectedMember.SubCompanyName || ''}
              name="subCompanyName"
              placeholder="Company Name"
              className="form-control margin-b10"
              type="text"
              onChange={(event) =>
                memberDetailsHandler(
                  'SubCompanyName',
                  event.target.value
                )
              }
            />
          )}
          {(type !== 'Co') && (
            <div className="row margin-b10">
              <div className="col-lg-4 col-md-6">
                <input
                  value={selectedMember.FirstName || ''}
                  onChange={(event) =>
                    memberDetailsHandler(
                      'FirstName',
                      event.target.value
                    )
                  }
                  name="firstName"
                  id="firstName"
                  placeholder="First Name"
                  className="form-control"
                />
              </div>
              <div className="col-lg-4 col-md-6">
                <input
                  value={selectedMember.MiddleName || ''}
                  onChange={(event) =>
                    memberDetailsHandler(
                      'MiddleName',
                      event.target.value
                    )
                  }
                  name="middleName"
                  id="middleName"
                  placeholder="Middle Name"
                  className="form-control"
                />
              </div>
              <div className="col-lg-4 col-md-6">
                <input
                  value={selectedMember.LastName || ''}
                  onChange={(event) =>
                    memberDetailsHandler(
                      'LastName',
                      event.target.value
                    )
                  }
                  name="lastName"
                  id="lastName"
                  placeholder="Last Name"
                  className="form-control"
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <input
                value={selectedMember.Email || ''}
                onChange={(event) =>
                  memberDetailsHandler('Email', event.target.value.trim())
                }
                name="email"
                id="email"
                placeholder="Email"
                className="form-control"
                type="text"
              />
            </div>
            <div className="col-lg-4 col-md-6">
              <input
                value={selectedMember.Mobile || ''}
                onChange={(event) =>
                  memberDetailsHandler('Mobile', event.target.value)
                }
                name="mobile"
                id="mobile"
                placeholder="Contact No."
                className="form-control"
                type="number"
              />
            </div>
            <div className="col-lg-4 col-md-6">
                <div className='row'>
                  {type !== 'Co' && (
                    <div className='col-lg-7'>
                      <input
                        id="dob"
                        value={selectedMember.DOB ? moment(selectedMember.DOB).format('YYYY-MM-DD') : ''}
                        name="dob"
                        placeholder="Date of birth"
                        className="form-control"
                        type="text"
                        max={moment().format('YYYY-MM-DD')}
                        onFocus={(event) => showDatePickerHandler(event.target, true)}
                        onBlur={(event) => showDatePickerHandler(event.target, false)}
                        onChange={(event) =>
                        memberDetailsHandler('DOB', event.target.value)}/>
                    </div>
                  )}
                  <div className={`${type !== 'Co' ? 'col-lg-5' : 'col-lg-12' }`}>
                    {editingXid ? (
                      <div className="btn-group btn-block" role="group">
                        <button
                          id="btnAdd"
                          className="btn btn-brand btn-block margin-0 margin-t10"
                          style={{ maxWidth: '70%' }}
                          onClick={memberAddHandler}
                        >
                          Update
                        </button>
                        <button
                          style={{ maxWidth: '30%' }}
                          className="btn btn-secondary btn-block margin-0 margin-t10 padding-0"
                          onClick={() => {
                            setEditingXid(null);
                            setSelectedMember({});
                          }}
                        >
                          <i
                            className="la la-times"
                            style={{ fontSize: '1rem', marginLeft: '3px' }}
                          />
                        </button>
                      </div>
                    ) : (
                      <button
                        id="btnAdd"
                        className="btn btn-label-brand btn-block margin-0 margin-t10"
                        onClick={memberAddHandler}
                      >
                        <i className="la la-plus" />
                        Add
                      </button>
                    )}
                  </div>
                </div>
            </div>
          </div>
        </form>

        {members && members.length > 0 ? (
          <div className="row margin-t20 scrolTable">
            <div className="col-12">
              <table className="table table-striped">
                <tbody>
                  {members.map((m, i) => (
                    <tr key={i}>
                      <td className="padding-l20">
                        <div className="kt-user-card-v2">
                          <div className="kt-user-card-v2__pic">
                            <div
                              className={`img-small-profile kt-badge kt-badge--xl kt-badge--${
                                clientType === 'Corporate' ? 'success' : 'info'
                              }`}
                            >
                              {m.SubCompanyName ? m.SubCompanyName[0].toUpperCase() : m.FirstName ? m.FirstName[0].toUpperCase() : ''}
                            </div>
                          </div>
                          <div className="kt-user-card-v2__details">
                            <span className="kt-user-card-v2__name">
                              {m.SubCompanyName ? `${m.SubCompanyName}` : `${m.FirstName} ${m.MiddleName || ''} ${m.LastName}`}
                            </span>
                            <span className="kt-user-card-v2__desc">
                              {m.Email}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td width="15%">
                        <span>
                          {i > 0
                            ? 'Member'
                            : m.Type === 'Corporate'
                            ? 'Admin'
                            : 'Primary'}
                        </span>
                      </td>
                      <td width="15%">
                        <span>{m.Mobile || ''}</span>
                      </td>
                      <td width="15%">
                        <span>{m.DOB ?  moment(m.DOB).format('DD-MMM-YYYY') : ''}</span>
                      </td>
                      <td width="15%" className="text-right">
                        <button
                          type="button"
                          className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                          onClick={() => updateMemberHandler(i)}
                        >
                          <i className="flaticon2-edit"></i>
                        </button>
                        {i > 0 ? (
                          <button
                            type="button"
                            onClick={() => removeMemberHandler(i)}
                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                          >
                            <i className="flaticon2-rubbish-bin-delete-button"></i>
                          </button>
                        ) : (
                          <span style={{ marginRight: '38px' }}></span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : props.action === 'e' ? (
          <NoData
            src={ClientImg}
            alt="Member"
            message="No Members Found"
          />
        ) : (
          ''
        )}
      </div>
    </React.Fragment>
  );
};

export default MemberTable;
