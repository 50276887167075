import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import 'jquery-form';
import 'jquery-validation';
import 'bootstrap/js/dist/popover';
import '../../assets/scripts/bootstrap-modal';
import '../../assets/scripts/jquery-validation.init';
import * as helper from '../global/helper';
import swal from 'sweetalert2';
import Select from 'react-select';
import Dropzone from '../global/dropzone';
import { getInsuranceCompanies, getInsuranceCompaniesByAgentID, getProducts, getProductsByAgentID } from '../api/policyAPI';
import { addAgentFile, getFileCategory } from '../api/fileManagementAPI';
import { uploadFile } from '../api/fileAPI';
import AddCompany from '../policy/addCompany';
import AddFileCategory from './addFileCategory';
import { getAuthState } from '../../assets/scripts/login-util';

const AddFile = (props) => {

    const [btnLoading,          setBtnLoading]          = useState(false);
    const [fileType,            setFileType]            = useState('Public');
    const [insuranceCompanies,  setInsuranceCompanies]  = useState([]);
    const [products,            setProducts]            = useState([]);
    const [fileCategories,      setFileCategories]      = useState([]);
    const [addModalType,        setAddModalType]        = useState('Company');
    const [addedCompanyId,      setAddedCompanyId]      = useState(null);
    const [addedProductId,      setAddedProductId]      = useState(null);
    const [addedFileCategoryId, setAddedFileCategoryId] = useState(null);
    const [file,                setFile]                = useState(null); 
    const [fileDetails,         setFileDetails]         = useState({});
    const [fileSaved,           setFileSaved]           = useState(false);

    const userDetails = getAuthState();

    useEffect(() => {
        $('#add-file-modal').modal({
            backdrop: 'static'
        });
        $('#add-file-modal').on('hidden.bs.modal', function () {
            props.onDismissModal('file', false);
        });
        $('#add-file-modal').modal('toggle');

        fetchInsuranceCompanies();
        fetchProducts();
        fetchFileCategory();

    }, [props.show]);

    useEffect(() => {
        if(fileSaved) {
            $('#add-file-modal').modal('hide');
            props.onDismissModal('file', false, '', fileSaved);
        }
    }, [fileSaved]);

    useEffect(() => {

        var form = $('#add-file-form')
        form.validate().resetForm();
        setFileDetails({});

        if (fileType === 'Private')
            updateFileDetails('AccessType', 'Personal');
       
    }, [fileType])

    useEffect(() => {
        if(addedCompanyId)
            fetchInsuranceCompanies();
    }, [addedCompanyId]);

    useEffect(() => {
        if(addedProductId)
            fetchProducts();
    }, [addedProductId]);

    useEffect(() => {
        if(addedFileCategoryId)
            fetchFileCategory();
    }, [addedFileCategoryId]);

    useEffect(() => {
        if(insuranceCompanies.length > 0 && addedCompanyId) {
            updateFileDetails('InsuranceCompanyID', addedCompanyId);
            setAddedCompanyId(null);
        }        
    }, [insuranceCompanies]);

    useEffect(() => {  
        if (products.length > 0 && addedProductId) {
            updateFileDetails('ProductID', addedProductId);
            setAddedProductId(null);
        }    
    }, [products]);

    useEffect(() => {
        if(fileCategories.length > 0 && addedFileCategoryId) {
            updateFileDetails('CategoryID', addedFileCategoryId);
            setAddedFileCategoryId(null);
        }
    }, [fileCategories]);

    const updateFileDetails = (field, value) => {
        setFileDetails((f) => {
            return { ...f, [field]: value };
        });
    };

    const fileTypeHandler = (type) => {
        setFileType(type);
    }

    const fetchInsuranceCompanies = async () => {
        try {
            const response = userDetails.user.categoryId != 3 ? await getInsuranceCompanies() : await getInsuranceCompaniesByAgentID();
            setInsuranceCompanies(
                response
                .sort((a, b) => 
                a.Name > b.Name
                ? 1
                : a.Name < b.Name
                ? -1
                : 0
                )
            );
        } catch (err) {
            swal.fire({
                icon              : 'error',
                titleText         : 'Error!',
                text              : err.message,
                buttonsStyling    : false,
                confirmButtonClass: 'btn btn-brand',
            });
        }
    };

    const fetchProducts = async () => {
        try {
            const response = userDetails.user.categoryId != 3 ? await getProducts() : await getProductsByAgentID();
            setProducts(
                response
                .sort((a, b) => 
                a.Name > b.Name
                ? 1
                : a.Name < b.Name
                ? -1
                : 0
                )
            );
        } catch (err) {
            swal.fire({
                icon              : 'error',
                titleText         : 'Error!',
                text              : err.message,
                buttonsStyling    : false,
                confirmButtonClass: 'btn btn-brand',
            });
        }
    };

    const fetchFileCategory = async () => {
        try {
            const response = await getFileCategory();
            setFileCategories(
                response
                .sort((a, b) => 
                a.Name > b.Name
                ? 1
                : a.Name < b.Name
                ? -1
                : 0
                )
            );
        } catch (err) {
            swal.fire({
                icon              : 'error',
                titleText         : 'Error!',
                text              : err.message,
                buttonsStyling    : false,
                confirmButtonClass: 'btn btn-brand',
            });
        }
    };

    const companyAddHandler = (e) => {
        e.preventDefault();
        setAddModalType('Company');
        $('#add-file-modal').addClass('modal-blur');
        $('#add-company-modal').modal('toggle');
    };

    const productAddHandler = (e) => {
        e.preventDefault();
        setAddModalType('Product');
        $('#add-file-modal').addClass('modal-blur');
        $('#add-company-modal').modal('toggle');
    };

    const fileCategoryHandler = (e) => {
        e.preventDefault();
        $('#add-file-modal').addClass('modal-blur');
        $('#add-fileCategory-modal').modal('toggle');
    };

    const fileHandler = async (f) => {
        setFile(f)
    }

    const removeFileHandler = (e) => {
        e.preventDefault();
        setFile(null);
    }

    const addFileHandler = async (e) => {
        e.preventDefault();

        var form = $('#add-file-form');
        form.validate().destroy();

        form.validate({
            rules: {
                documentName: {
                    required: true,
                },
            },
            
        });
        if (!form.valid()) {
            return;
        }

        try {
            setBtnLoading(true);

            if(!file) {
                swal.fire({
                    icon                  : 'error',
                    titleText             : 'Error!',
                    text                  : "Please upload a file",
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',   
                });
                setBtnLoading(false);
                return;
            }

            const fileName = await uploadFile(file, "AgentFile");
                   
            await addAgentFile({
                InsuranceCompanyID : fileDetails.InsuranceCompanyID || 0,
                ProductID          : fileDetails.ProductID || 0,
                CategoryID         : fileDetails.CategoryID || 5,
                FileName           : fileName,
                DocumentName       : fileDetails.DocumentName,
                FileType           : fileType,
                AccessType         : fileDetails.AccessType || 'All'
            });

            setFileSaved(true);
            swal.fire({
                icon              : 'success',
                titleText         : 'File Added Successfully',
                showConfirmButton : false,
                timer             : 1500,
            });
        } catch (err) {
            swal.fire({
              icon                  : 'error',
              titleText             : 'Error!',
              text                  : err.message,
              buttonsStyling        : false,
              confirmButtonClass    : 'btn btn-brand',
            });
            setBtnLoading(false);
            return;
        }
    };

    return (
        <React.Fragment>
            <div
                className="modal fade"
                id="add-file-modal"
                tabIndex="-1"
                role="dialog"
                aria-hidden="true">

                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add File</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                disabled={btnLoading}>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="kt-radio-inline radio-container">
                                <div className="input-group">
                                    {' '}
                                    <input
                                        value="Public"
                                        defaultChecked
                                        onChange={({ target }) => fileTypeHandler(target.value)}
                                        type="radio"
                                        name="file-type"
                                        id="public"
                                    />
                                    <label htmlFor="public">Public</label>
                                </div>
                                <div className="input-group margin-l20">
                                    {' '}
                                    <input
                                        value="Private"
                                        onChange={({ target }) => fileTypeHandler(target.value)}
                                        type="radio"
                                        name="file-type"
                                        id="private"
                                    />
                                    <label htmlFor="private">Private</label>
                                </div>
                            </div>
                            <form id="add-file-form" className="kt-form" onSubmit={(e) => e.preventDefault()}>
                                {fileType === 'Public' && (
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="row">
                                                <div className={`${userDetails.user.categoryId != 3 ? 'col-8' : 'col-12'}`}>
                                                    <div className="form-group">
                                                        <label htmlFor="ddlInsuranceCompany">Insurance Company</label>
                                                        <Select
                                                            value={fileDetails && fileDetails.InsuranceCompanyID && {label: insuranceCompanies.find(c => c.ID == fileDetails.InsuranceCompanyID).Name, value: fileDetails.InsuranceCompanyID}}
                                                            onChange={(event) => updateFileDetails('InsuranceCompanyID', event.value)}
                                                            name="ddlInsuranceCompany"
                                                            id="ddlInsuranceCompany"
                                                            placeholder="Select Company"
                                                            options={insuranceCompanies.map(c => ({
                                                                label: c.Name,
                                                                value: c.ID
                                                            }))}
                                                        />
                                                    </div>
                                                </div>
                                                {userDetails.user.categoryId != 3 && (
                                                    <div className="col-4">
                                                        <button
                                                            id="btnNewCompany"
                                                            className="btn btn-label-brand btn-block margin-0 margin-t24"
                                                            data-toggle="kt-popover"
                                                            data-trigger="hover"
                                                            data-placement="bottom"
                                                            data-content="Add Insurance Company"         
                                                            onClick={companyAddHandler}>
                                                            <i className="la la-plus" />
                                                            New
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="row">
                                                <div className={`${userDetails.user.categoryId != 3 ? 'col-8' : 'col-12'}`}>
                                                    <div className="form-group">
                                                        <label htmlFor="ddlProduct">Product</label>
                                                        <Select
                                                            value={fileDetails && fileDetails.ProductID && {label: products.find(c => c.ID == fileDetails.ProductID).Name, value: fileDetails.ProductID}}
                                                            onChange={(event) => updateFileDetails('ProductID', event.value)}
                                                            name="ddlProduct"
                                                            id="ddlProduct"
                                                            placeholder="Select Product"
                                                            options={products.map(c => ({
                                                                label: c.Name,
                                                                value: c.ID
                                                            }))}
                                                        />
                                                    </div>
                                                </div>
                                                {userDetails.user.categoryId != 3 && (
                                                    <div className="col-4">
                                                        <button
                                                            id="btnNewProduct"
                                                            className="btn btn-label-brand btn-block margin-0 margin-t24"
                                                            data-toggle="kt-popover"
                                                            data-trigger="hover"
                                                            data-placement="bottom"
                                                            data-content="Add Product" 
                                                            onClick={productAddHandler}>   
                                                            <i className="la la-plus"/>
                                                            New
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="row">
                                    {fileType === 'Private' ? (
                                        <div className="col-lg-4">
                                            <div className="form-group">
                                                <label htmlFor="ddlShareWith">File Access</label>
                                                <select 
                                                    className="form-control kt-selectpicker"
                                                    id="ddlShareWith"
                                                    onChange={(event) => updateFileDetails('AccessType', event.target.value)}>
                                                    <option value="Personal">Personal</option>
                                                    <option value="Team">Team</option>
                                                </select>
                                            </div>
                                        </div>

                                    ) : (
                                        <div className="col-lg-6">
                                            <div className="row">
                                                <div className={`${userDetails.user.categoryId != 3 ? 'col-8' : 'col-12'}`}>
                                                    <div className="form-group">
                                                        <label htmlFor="ddlFileCategory">File Type</label>
                                                        <Select
                                                            value={fileDetails && fileDetails.CategoryID && {label: fileCategories.find(c => c.ID == fileDetails.CategoryID).Name, value: fileDetails.CategoryID}}
                                                            onChange={(event) => updateFileDetails('CategoryID', event.value)}
                                                            name="ddlFileCategory"
                                                            id="ddlFileCategory"
                                                            placeholder="Select Product"
                                                            options={fileCategories.map(f => ({
                                                                label: f.Name,
                                                                value: f.ID
                                                            }))}
                                                        />
                                                    </div>
                                                </div>
                                                {userDetails.user.categoryId != 3 && (
                                                    <div className="col-4">
                                                        <button
                                                            id="btnNewFileCategory"
                                                            className="btn btn-label-brand btn-block margin-0 margin-t24"
                                                            data-toggle="kt-popover"
                                                            data-trigger="hover"
                                                            data-placement="bottom"
                                                            data-content="Add File Type" 
                                                            onClick={fileCategoryHandler}>   
                                                            <i className="la la-plus"/>
                                                            New
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}                           
                                    <div className={`col-lg-${fileType === 'Private' ? '8' : '6'}`}>
                                        <div className="form-group">
                                            <label htmlFor="documentName">File Name</label>
                                            <input
                                                className="form-control"
                                                value={fileDetails.DocumentName || ''}
                                                onChange={(event) => updateFileDetails('DocumentName', helper.titleCase(event.target.value))}
                                                id="documentName"
                                                name="documentName"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {!file &&(<Dropzone fileHandler={fileHandler} accept="image/* pdf" />)}
                                {file && (
                                    <React.Fragment>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="kt-notification margin-b30" style={{backgroundColor: '#f7f8fa'}}>
                                                    <a className="kt-notification__item">
                                                        <div className="kt-notification__item-icon margin-r10" onClick={() => helper.openFileHandler(file)}>
                                                            <img height="30px" width="30px" src={helper.getFileLogo(file.name)} />
                                                        </div>
                                                        <div className="kt-notification__item-details" onClick={() => helper.openFileHandler(file)}>
                                                            <div className="kt-notification__item-title kt-font-bold">
                                                                {file.name}
                                                            </div>
                                                        </div>
                                                        <button 
                                                            type="button"                    
                                                            onClick={removeFileHandler}
                                                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                                            <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                        </button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}
                            </form> 
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary margin-0 margin-r5"
                                data-dismiss="modal"
                                disabled={btnLoading}
                            >
                                Close
                            </button>
                            <button
                                id="save-btn"
                                onClick={addFileHandler}
                                type="button"
                                className={`btn btn-brand margin-0 ${
                                    btnLoading ? 'kt-spinner kt-spinner--sm kt-spinner--light' : ''
                                }`}
                                disabled={btnLoading}>
                                Upload
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <React.Fragment>
                <AddCompany
                    type={addModalType}
                    addInsuranceCompanyId={setAddedCompanyId}
                    addProductId={setAddedProductId}
                    from="#add-file-modal"
                />
                <AddFileCategory
                    addFileCategoryId={setAddedFileCategoryId}
                    from="#add-file-modal"
                />
            </React.Fragment>
        
        </React.Fragment>
    );
};

export default AddFile;