import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import $ from 'jquery';
import 'jquery-form'
import 'jquery-validation'
import '../../assets/scripts/jquery-validation.init'
import * as helper from '../global/helper'
import swal from "sweetalert2";
import { changePasswordRequest } from "../api/agentAPI";

const Forgot = () => {
    const history           = useHistory();
    const location          = useLocation();
    const [email, setEmail] = useState('');

    const emailChangeHandler = (e) => setEmail(e.target.value);

    const submitHandler = async (e) => {
        e.preventDefault();
        const btn = $("#kt_login_forgot_submit");
        const form = $("#kt_login_forgot_submit").closest('form');
        form.validate({
            rules: {
                email: {
                    required: true,
                    email: true
                }
            }
        });
        if (!form.valid()) return;

        helper.StartProcessing(btn);
        try {
           //Check Email & Send Verification Otp 
            const response = await changePasswordRequest(email);
            history.push({ pathname: '/verify/change-password', state: { Email : email, ...response } });
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
            return helper.StopProcessing(btn)
        }
    }

    return (
        <div className="kt-login__forgot">
            <div className="kt-login__head">
                <h3 className="kt-login__title"> {location.state && location.state.createPassword ? 'Create Password' : 'Forgot Password ?'}</h3>
                <div className="kt-login__desc">Enter your email to {location.state && location.state.createPassword ? 'create' : 'reset'} your password:</div>
            </div>
            <form className="kt-form" action="">
                <div className="input-group">
                    <input onChange={ emailChangeHandler } className="form-control form-control-lg" type="text" placeholder="Email" name="email" autoComplete="off" />
                </div>
                <div className="kt-login__actions">
                    <button onClick={ submitHandler } id="kt_login_forgot_submit" className="btn btn-theme btn-elevate btn-block kt-login__btn-primary">Request</button>
                </div>
                <div className="kt-login__account margin-t20">
                    <span className="kt-login__account-msg">
                        Don't have an account yet ?
                    </span>
                    &nbsp;&nbsp;
                    <Link to="/signup" className="kt-login__account-link">Sign Up!</Link>
                </div>
            </form>
        </div>
    )
};

export default Forgot;