import React, { useState, useEffect } from 'react'
import $ from 'jquery';
import 'jquery-form';
import 'jquery-validation';
import '../../assets/scripts/jquery-validation.init';
import swal from 'sweetalert2';
// import * as helper from '../global/helper';
import { extendTempSubscription, logoutAPI, sendAgentSubscriptionRequest } from '../api/agentAPI';
import Loading from './loading';
import { getAuthState, setAgentRefreshedCookie, setAuthState } from '../../assets/scripts/login-util';
import subscription from '../../assets/images/subscription-img.png'

const SubscriptionModal = (props) => {

    const [IsExtended,       setIsExtended]       = useState(false);
    const [loading,          setLoading]          = useState(false);

    useEffect(() => {
      setLoading(true);
      $('.modal').modal('toggle');
      $('#subscription-modal').modal({ backdrop: 'static', keyboard: false});
      $('#subscription-modal').on('hidden.bs.modal', function () {
        props.onDismissModal('subscription', false);
      });
      $('#subscription-modal').modal('toggle');
      const ck = getAuthState();
      if(ck.IsExtended && ck.IsExtended == 'Y') setIsExtended(true);
      setTimeout(() => { setLoading(false) }, 500)
    }, []);

    const logout = async () => {
      try {
        await logoutAPI()	
        setAuthState();
        window.open('/', '_self');
      } catch (err) {
        window.open('/', '_self');
        swal.fire({
          icon              : "error",
          titleText         : "Error!",
          text              :  err.message,
          buttonsStyling    : false,
          confirmButtonClass: "btn btn-brand",
        });
      }
    };

    const onExtendHandler = async() => {
      try {
        setLoading(true);
        const response =  await extendTempSubscription();
        if(response){
          const token = getAuthState('token');
          setAgentRefreshedCookie({ headers: { auth_token: `bearer ${token}` }})
          //Update the new Cookie
          swal.fire({
           icon                : 'success',
            html:
            '<p> Subcription Extended </p>'+
            '<p> Thank you for choosing <b> InsureBox </b> </p>',
            showConfirmButton   : false,
            timer               : 1500,
            animation           : true,
            customClass         : { popup: 'margin-20', },
          });
          props.onDismissModal('subscription', false);
          setTimeout(() => {
            window.location.reload()
          }, 600)
        }
      } catch (err) {
        setLoading(false)
        swal.fire({
					icon                : 'error',
          titleText           : err.message,
					buttonsStyling      : false,
					confirmButtonClass  : 'btn btn-brand',
				});  
      }
    }

    const onRequestExtention = async() => {
      try {
        setLoading(true);
        const response = await sendAgentSubscriptionRequest();
        if(response){
          swal.fire({
            icon : 'success',
            html :
            '<p> Subcription Request Submitted </p>'+
            '<p> Thank you for choosing <b> InsureBox </b> </p>'+
            '<p> Our Customer Success Manager will get back to you shortly.</p>',
            allowOutsideClick : false,
          })
          $('#subscription-modal').modal('toggle');
        }
      } catch (err) {
        setLoading(false);
        swal.fire({
					icon                : 'error',
          titleText           : err.message,
					buttonsStyling      : false,
					confirmButtonClass  : 'btn btn-brand',
				});  
      }
    }

    return (
        <div
          className="modal fade"
          id="subscription-modal"
          tabIndex="-1"
          role="dialog"
          data-backdrop="static"
          aria-hidden="true">
        
          <div className="modal-dialog modal-md" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  Subscription
                </h5>
                {/* <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close">
                </button> */}
              </div>
              <div className="modal-body">
                {loading ? (<Loading/>)
                :(
                  <div className="client-container p-0">
                    <div className='text-center'>
                      <div className='row'>
                        <div className='col'>
                          <p className='text-center kt-font-brand font-70 mb-0'>
                            {/* <i className="fa flaticon-gift"></i> */}
                            <img src={subscription} width='15%'/>
                          </p>
                          <h5> Your Subscription has expired </h5>
                          <small> </small>
                        </div>
                      </div>
                      <div className='row mt-4'>
                        <div className='col'>
                          {!IsExtended && (<>
                            <button type='button' className="btn btn-sm btn-label-brand btn-pill" onClick={onExtendHandler}>   
                              Extend free for 7 days
                            </button>
                          </>)}
                          <button type='button' className="btn btn-sm btn-label-success btn-pill ml-3" onClick={onRequestExtention}>   
                            Renew or buy subscription
                          </button>
                          <button type='button' className="btn btn-sm btn-label-warning btn-pill ml-3" onClick={logout}>   
                            Logout
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
}

export default SubscriptionModal