import React, { useState, useEffect } from 'react';
import swal from 'sweetalert2';
import Select from 'react-select';
import $ from 'jquery';
import * as helper from '../global/helper';
import CustomEditor from '../global/editor';
import AddQuickText from '../global/quickText';
import { 
    addQuotation,
    getInsuranceCompanies, 
    getInsuranceCompaniesByAgentID, 
    getQuotationDocuments, 
    updateQuotation
} from '../api/policyAPI';  
import AddCompany from './addCompany';
import AddDocument from './addDocument';
import { uploadFileList } from '../api/fileAPI';
import { getAuthState } from '../../assets/scripts/login-util';

const EditQuotation = (props) => {

    const [btnLoading,          setBtnLoading]          = useState(false); 
    const [documents,           setDocuments]           = useState([]);
    const [deleteDocuments,     setDeleteDocuments]     = useState([]);
    const [addDocument,         setAddDocument]         = useState(false);
    const [insuranceCompanies,  setInsuranceCompanies]  = useState([]);
    const [addedCompanyId,      setAddedCompanyId]      = useState(null);
    const [quotationDetails,    setQuotationDetails]    = useState({});
    const [addQuickText,        setAddQuickText]        = useState(false);
    const [fillQuickText,       setFillQuickText]       = useState('');

    const userDetails = getAuthState();

    useEffect(() => {
        $('#edit-quotation-modal').modal({
          backdrop: 'static',
        });
        $('#edit-quotation-modal').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            props.onDismissModal(false);            
        });
  
        fetchInsuranceCompanies();

        if (props.action === 'e') {
            setQuotationDetails(props.quotation);            
            setFillQuickText(props.quotation.AdditionalData);

            if (props.quotation.DocumentCount > 0)
                fetchDocuments();    
                
        }
    }, [props.show]);
    
    useEffect(() => {
        $('[data-toggle="kt-popover"]').popover();
    })
  
    useEffect(() => {       
        if (addedCompanyId)
            fetchInsuranceCompanies();  
    }, [addedCompanyId]);

    useEffect(() => {
        if (insuranceCompanies.length > 0 && addedCompanyId) { 
            const company = insuranceCompanies.find(c => c.ID == addedCompanyId);
            changeCompanyHandler({
                label: company.Name,
                value: company.ID
            });         
        }        
    }, [insuranceCompanies]);

    useEffect(() => {       
        setQuotationDetails((d) => {
            return { ...d, DocumentCount: documents.length };
        });
    }, [documents]);

    const changeCompanyHandler = (e) => {
        updateQuotationDetails('InsuranceCompanyID', e.value);
        updateQuotationDetails('InsuranceCompanyName', e.label);
        setAddedCompanyId(null);
    }

    const fetchInsuranceCompanies = async () => {
        try {
            const response = userDetails.user.categoryId != 3 ? await getInsuranceCompanies() : await getInsuranceCompaniesByAgentID();
            setInsuranceCompanies(
                response
                .sort((a,b) =>
                    a.Name > b.Name
                    ? 1
                    : a.Name < b.Name
                    ? -1
                    : 0    
                    
                )
            );
        } catch (err) {
            swal.fire({
                icon              : 'error',
                titleText         : 'Error!',
                text              : err.message,
                buttonsStyling    : false,
                confirmButtonClass: 'btn btn-brand',
            });
        }
    };

    const fetchDocuments = async () => {
        try {
            setBtnLoading(true)
            const response = await getQuotationDocuments([props.quotation.ID]);
            setDocuments(response.map(d => ({...d, action: 'e'})));
            setBtnLoading(false);
        } catch (err) {
            swal.fire({
                icon              : 'error',
                titleText         : 'Error!',
                text              : err.message,
                buttonsStyling    : false,
                confirmButtonClass: 'btn btn-brand',
            });
        }
    }

    const setDocumentHandler = (d) => {
        setDocuments((a) => {
            const newA = [...a];
            newA.push({...d, action: 'a'});
            return newA;
        });    
    };

    const removeDocumentHandler = (index) => {
        const document = documents[index];
        if (document.action === 'e') {
            setDeleteDocuments((a) => {
                const newA = [...a];
                newA.push({ ID: document.ID });
                return newA;
            }); 
        }
        setDocuments((a) => {
            const nA = a.filter((b, i) => index !== i);
            return nA;
        })  
    }

    const openDocumentHandler = (index) => {
        const document = documents[index];

        if (document.action === 'a') {
            const { DocumentFile } = document;
            if (document.DocumentType === 'AgentFile') {

                const ext       = helper.getFileExt(DocumentFile.name);
                const name      = (document.DocumentName + '.' + ext).replace(/ /g, '_');
                helper.downloadAndOpenFileHandler("AgentFile", DocumentFile.name, name)
        
            }else{       
                helper.openFileHandler(DocumentFile);
            }
        }else{
            const ext       = helper.getFileExt(document.FileName);
            const name      = (document.DocumentName + '.' + ext).replace(/ /g, '_');
            helper.downloadAndOpenFileHandler(document.DocumentType, document.FileName, name)
        } 
    }

    const companyAddHandler = (e) => {
        e.preventDefault();
        $('#edit-quotation-modal').addClass('modal-blur');
        $('#add-company-modal').modal('toggle');
    };

    const updateQuotationDetails = (field, value) => {
        setQuotationDetails((d) => {
        return { ...d, [field]: value };
      });
    };

    const setEditorData = (html) => {
        updateQuotationDetails("AdditionalData", html) ;
    };

    const addQuotationHandler = async (e) => {
        e.preventDefault();

        var form = $('#edit-quotation-form');
        form.validate().destroy();

        form.validate({
            rules: {
              ddlInsuranceCompany: {
                required: true,
              },
              sumInsured: {
                required: true,
              },
              premium: {
                required: true,
              }
            }
        }); 

        if (!form.valid()) {
            return;
        }

        try {
            setBtnLoading(true);

            const docs = documents.filter(d => d.DocumentType === 'Upload' && d.action === 'a').map(m => m.DocumentFile);

            let documentList = [];
            if (docs.length > 0) {
                documentList = await uploadFileList(docs, "QuotationDocument");
            }

            if (props.action === 'e') {
                await updateQuotation({
                    ...quotationDetails,
                    Documents: (documents && documents.length > 0) ? documents.filter(d => d.action === 'a').map(d => ({
                        FileName        : d.DocumentType === 'Upload' ? documentList.find(f => f.originalname === d.DocumentFile.name).filename : d.DocumentFile.name,
                        DocumentName    : d.DocumentName,
                        DocumentType    : d.DocumentType === 'Upload' ? 'QuotationDocument' : d.DocumentType
                    })) : [],
                    DeleteDocuments: deleteDocuments
                });
                props.refetchQuotations('update', quotationDetails);
                swal.fire({
                    toast             : true,
                    icon              : 'success',
                    titleText         : 'Quotation updated successfully!',
                    position          : 'bottom-end',
                    showConfirmButton : false,
                    timer             : 1500,
                    animation         : false,
                    customClass       : {
                      popup: 'margin-20',
                    },
                });
            }else{

                const docs = documents.filter(d => d.DocumentType === 'Upload' && d.action === 'a').map(m => m.DocumentFile);

                let documentList = [];
                if (docs.length > 0) {
                    documentList = await uploadFileList(docs, "QuotationDocument");
                }

                const ID = await addQuotation({
                    ...quotationDetails, 
                    Documents: (documents && documents.length > 0) ? documents.filter(d => d.action === 'a').map(d => ({
                        FileName        : d.DocumentType === 'Upload' ? documentList.find(f => f.originalname === d.DocumentFile.name).filename : d.DocumentFile.name,
                        DocumentName    : d.DocumentName,
                        DocumentType    : d.DocumentType === 'Upload' ? 'QuotationDocument' : d.DocumentType
                    })) : []
                }, props.groupId);
                props.refetchQuotations('add', {
                    ...quotationDetails,
                    ID          : ID,
                    StatusID    : 1,
                    Status      : 'Quote Created'
                });
                swal.fire({
                    toast             : true,
                    icon              : 'success',
                    titleText         : 'Quotation added successfully!',
                    position          : 'bottom-end',
                    showConfirmButton : false,
                    timer             : 1500,
                    animation         : false,
                    customClass       : {
                      popup: 'margin-20',
                    },
                });
            }
            
            setBtnLoading(false);
            $('#edit-quotation-modal').modal('hide');

        } catch (err) {
            setBtnLoading(false);
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        }
    };

    return (
        <React.Fragment>
            <div 
                className="modal fade"
                id="edit-quotation-modal"
                tabIndex="-1"
                role="dialog"
                aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                {props.action === 'e' ? 'Edit' : 'Add'} Quotation
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body">
                            <form id="edit-quotation-form" className="kt-form" onSubmit={(e) => e.preventDefault()}>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="row">
                                            <div className={`${userDetails.user.categoryId != 3 ? 'col-8' : 'col-12'}`}>
                                                <div className="form-group">
                                                    <label htmlFor="ddlInsuranceCompany">Insurance Company</label>       
                                                    <Select
                                                        value={quotationDetails && quotationDetails.InsuranceCompanyID && {label: quotationDetails.InsuranceCompanyName, value: quotationDetails.InsuranceCompanyID}}
                                                        onChange={changeCompanyHandler}
                                                        name="ddlInsuranceCompany"
                                                        id="ddlInsuranceCompany"
                                                        placeholder="Select Company"
                                                        options={insuranceCompanies.map(c => ({
                                                            label: c.Name,
                                                            value: c.ID
                                                        }))}
                                                    />
                                                </div>  
                                            </div>
                                            {userDetails.user.categoryId != 3 && (
                                                <div className="col-4">
                                                    <button
                                                        id="btnNewCompany"
                                                        className="btn btn-label-brand btn-block margin-0 margin-t24"
                                                        data-toggle="kt-popover"
                                                        data-trigger="hover"
                                                        data-placement="bottom"
                                                        data-content="Add Insurance Company"
                                                        onClick={companyAddHandler}>
                                                        <i className="la la-plus" />
                                                        New
                                                    </button>
                                                </div>
                                            )}
                                        </div>  
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label htmlFor="sumInsured">Sum Insured</label>
                                                    <input
                                                        id="sumInsured"
                                                        value={quotationDetails.SumInsured || ''}
                                                        name="sumInsured"
                                                        className="form-control margin-b10"
                                                        type="number"
                                                        onChange={(event) => updateQuotationDetails('SumInsured', event.target.value)}                                                   
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label htmlFor="premium">Premium</label>
                                                    <input
                                                        id="premium"
                                                        value={quotationDetails.Premium || ''}
                                                        name="premium"
                                                        className="form-control margin-b10"
                                                        type="number"
                                                        onChange={(event) => updateQuotationDetails('Premium', event.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <CustomEditor
                                                placeholder="Additional Data"
                                                addQuickText={setAddQuickText}
                                                fillQuickText={fillQuickText}
                                                setFillQuickText={setFillQuickText}
                                                setData={setEditorData}
                                            />     
                                        </div>
                                    </div>
                                </div>

                                {documents && documents.length > 0 && (                        
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="kt-notification margin-b30">
                                                {
                                                    documents.map((d, i) =>
                                                    <a key={i} className="kt-notification__item">
                                                        <div className="kt-notification__item-icon margin-r10" onClick={() => openDocumentHandler(i)}>
                                                            <img height="30px" width="30px" src={helper.getFileLogo(d.action === 'a' ? d.DocumentFile.name : d.FileName)} />
                                                        </div>
                                                        <div className="kt-notification__item-details" onClick={() => openDocumentHandler(i)}>
                                                            <div className="kt-notification__item-title kt-font-bold">
                                                                {d.DocumentName}
                                                            </div>
                                                        </div>
                                                        <button 
                                                            type="button"                    
                                                            onClick={() => removeDocumentHandler(i)}
                                                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                                            <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                        </button>
                                                    </a>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )}

                              
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary margin-0 margin-r5"
                                data-dismiss="modal"
                                disabled={btnLoading}>
                                Close
                            </button>
                            <button
                                className="btn btn-label-brand d-inline margin-0 margin-r10"
                                onClick={() => setAddDocument(true)}>
                                <i className="flaticon-doc" />
                                Attach Documents
                            </button>
                            <button
                                id="save-btn"
                                onClick={addQuotationHandler}
                                type="button"
                                className={`btn btn-brand margin-0 ${
                                btnLoading
                                    ? 'kt-spinner kt-spinner--sm kt-spinner--light'
                                    : ''
                                }`}
                                disabled={btnLoading}>                  
                                {props.action === 'e' ? 'Update' : 'Add'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <AddCompany 
                type="Company"
                addInsuranceCompanyId={setAddedCompanyId}
                from="#edit-quotation-modal"
            />

            {addQuickText && (
                <AddQuickText
                    fillQuickText={setFillQuickText}
                    addQuickText={setAddQuickText}
                    from="#edit-quotation-modal"
                />
            )}     

            {addDocument && (
                <AddDocument
                    setDocumentHandler={setDocumentHandler}
                    addDocument={setAddDocument}
                    from="#edit-quotation-modal"  
                />
            )}  
        </React.Fragment>
    );
};

export default EditQuotation;