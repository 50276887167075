import React, { useEffect, useState } from 'react';
import '../../containers/App.css'
import { NavLink, useHistory, useParams } from 'react-router-dom'
import moment from 'moment';

const AgeModal = (props) => {

    const [DOB,         setDOB]         = useState('');
    const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'));
    const [ages,        setAges]        = useState({});

    useEffect(() => {
        $('#age-modal').modal({
            backdrop: 'static',
        });
        $('#age-modal').on('hidden.bs.modal', function () {
            props.onDismissModal();
        });
    }, [props.show])

    const calculateAge = () => {

        var form = $('#age-form');
        form.validate().destroy();

        form.validate({
            rules: {
                currentDate: {
                    required: true,
                },
                dob: {
                    required: true,
                }
            },
        })

        if (!form.valid()) {
            return;
        }

        const dobYear = moment(DOB).year();
        const dobMonth = moment(DOB).month();
        const dobDate = moment(DOB).date();

        const cYear = moment(currentDate).year();
        const cMonth = moment(currentDate).month();
        const cDate = moment(currentDate).date();

        let age = {};

        var yearAge = cYear - dobYear;

        if (cMonth >= dobMonth)
            //get months when current month is greater
            var monthAge = cMonth - dobMonth;
        else {
            yearAge--;
            var monthAge = 12 + cMonth - dobMonth;
        }

        //get days
        if (cDate >= dobDate)
            //get days when the current date is greater
            var dateAge = cDate - dobDate;
        else {
            monthAge--;
            var dateAge = 31 + cDate - dobDate;

            if (monthAge < 0) {
                monthAge = 11;
                yearAge--;
            }
        }
        //group the age in a single variable
        age = {
            years: yearAge,
            months: monthAge,
            days: dateAge
        };
        setAges(age)
    }

    return (
        <>
            <div
                className="modal fade"
                id="age-modal"
                tabIndex="-1"
                role="dialog"
                aria-hidden="true">
            
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Calculate Age</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body">
                            <form id="age-form" className="kt-form" onSubmit={(e) => e.preventDefault()}>
                                <div className='row'>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="currentDate">Current Date</label>
                                            <input
                                                id="currentDate"
                                                value={currentDate}
                                                name="currentDate"
                                                className="form-control"
                                                type="date"
                                                onChange={(event) => setCurrentDate(event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="dob">Date of Birth</label>
                                            <input
                                                id="dob"
                                                value={DOB}
                                                max={moment().format('YYYY-MM-DD')}
                                                name="dob"
                                                className="form-control"
                                                type="date"
                                                onChange={(event) => setDOB(event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 margin-t15">
                                        <button className='btn btn-primary' onClick={calculateAge}>Calculate</button>
                                    </div>
                                    <div className="col-lg-1">
                                        <div className="form-group">
                                            <label htmlFor="currentDate">Years</label>
                                            <p className='margin-t10 text-center' style={{ fontWeight : '600' }}>{ages.years}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-1">
                                        <div className="form-group">
                                            <label htmlFor="currentDate">Months</label>
                                            <p className='margin-t10 text-center' style={{ fontWeight : '600' }}>{ages.months}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-1">
                                        <div className="form-group">
                                            <label htmlFor="currentDate">Days</label>
                                            <p className='margin-t10 text-center' style={{ fontWeight : '600' }}>{ages.days}</p>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const DayModal = (props) => {

    const [toDate,   setToDate]     = useState('');
    const [fromDate, setFromDate]   = useState('');
    const [dayLeft,  setDayLeft]    = useState('');

    useEffect(() => {
        $('#day-modal').modal({
            backdrop: 'static',
        });
        $('#day-modal').on('hidden.bs.modal', function () {
            props.onDismissModal();
        });
    }, [props.show]);

    const calculateDay = () => {

        var form = $('#day-form');
        form.validate().destroy();

        form.validate({
            rules: {
                toDate: {
                    required: true,
                },
                fromDate: {
                    required: true,
                }
            },
        })

        if (!form.valid()) {
            return;
        }
        
        setDayLeft(moment(toDate).diff(fromDate, 'days') + 1)
    }

    return (
        <>
            <div
                className="modal fade"
                id="day-modal"
                tabIndex="-1"
                role="dialog"
                aria-hidden="true">
            
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Calculate Days</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body">
                            <form id="day-form" className="kt-form" onSubmit={(e) => e.preventDefault()}>
                                <div className='row'>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="fromDate">From Date</label>
                                            <input
                                                id="fromDate"
                                                value={fromDate}
                                                name="fromDate"
                                                className="form-control"
                                                type="date"
                                                onChange={(event) => setFromDate(event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="toDate">To Date</label>
                                            <input
                                                id="toDate"
                                                value={toDate}
                                                name="toDate"
                                                className="form-control"
                                                type="date"
                                                onChange={(event) => setToDate(event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 margin-t15">
                                        <button className='btn btn-primary' onClick={calculateDay}>Calculate</button>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            {/* <label htmlFor="currentDate">Total Days</label> */}
                                            {/* <input
                                                value={ages.months}
                                                disabled
                                                className="form-control"
                                            /> */}
                                            <p className='margin-t30' style={{ fontSize : '20px', fontWeight : '600' }}>{dayLeft} {dayLeft && <span style={{ fontSize: '15px' }}>Days</span>}</p>

                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const Tools = (props) => {

    const [showAgeModal, setShowAgeModal] = useState(false)
    const [showDayModal, setShowDayModal] = useState(false)
    const param = useParams();
    
    useEffect(() => {
        props.title('Tools', 'menuTool')
    }, []);

    return (
        <>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                <div className="kt-portlet kt-portlet--mobile" >
                    <div className="row margin-t50">
                        <div className='col-12 text-center'>
                            <div className="text-center kt-widget kt-widget--user-profile-3 clickable" onClick={() => setShowDayModal(true)}>
                                <div className="kt-widget__top">
                                    <div className={`kt-widget__pic kt-widget__pic--success kt-font-primary kt-font-boldest kt-hidden-`} style={{ height: '100px', maxWidth: '100px' }}> 
                                        <i className='flaticon-clock-2' style={{ fontSize: '50px' }}></i> 
                                    </div>
                                </div>
                                <p style={{ fontSize: '15px', fontWeight: '600' }} className="margin-t20">Calculate Days</p>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 text-center margin-t50 margin-b50'>
                            <div className="text-center kt-widget kt-widget--user-profile-3 clickable"  onClick={() => setShowAgeModal(true)}>
                                <div className="kt-widget__top">
                                    <div className={`kt-widget__pic kt-widget__pic--success kt-font-primary kt-font-boldest kt-hidden-`} style={{ height: '100px', maxWidth: '100px' }}> 
                                        <i className='flaticon-profile' style={{ fontSize: '50px' }}></i> 
                                    </div>
                                </div>
                                <p style={{ fontSize: '15px', fontWeight: '600' }} className="margin-t20">Calculate Age</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showAgeModal && (
                <AgeModal
                    show={showAgeModal}
                    onDismissModal={() => setShowAgeModal(false)}
                />
            )}
            {showDayModal && (
                <DayModal
                    show={showDayModal}
                    onDismissModal={() => setShowDayModal(false)}
                />
            )}
        </>
    )
}

export default Tools;