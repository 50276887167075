
import $ from 'jquery';
import * as XLSX from 'xlsx';
import swal from 'sweetalert2';
import pdfLogo from '../../assets/images/ic_pdf.png';
import docLogo from '../../assets/images/ic_doc.png';
import excelLogo from '../../assets/images/ic_excel.png';
import imageLogo from '../../assets/images/ic_image.png';
import { downloadFile } from '../api/fileAPI';



let lastScroll = 0;

export function clearUserItemsFromLocalStorage() {
  localStorage.removeItem('auth_params');
  localStorage.removeItem('constants');
}

export function ShowMsg(form, type, msg) {
  const alert = $(
    '<div class="alert alert-' +
      type +
      ' alert-dismissible" role="alert"><div class="alert-text">' +
      msg +
      '</div><div class="alert-close" ><i class="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i></div></div>'
  );

  form.find('.alert').remove();
  alert.prependTo(form);
  alert.find('span').html(msg);
}

export const RemoveMsg = (form) => form.find('.alert').remove();

export function StartProcessing(btn) {
  btn
    .addClass(
      `kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light ${btn[0].id == 'kt_login_signin_google' && ' kt-spinner--primary' }`
    )
    .attr('disabled', true);
}

export function StopProcessing(btn) {
  btn
    .removeClass(
      `kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light ${btn[0].id == 'kt_login_signin_google' && ' kt-spinner--primary' }`
    )
    .attr('disabled', false);
}


export function SetScrollHeight() {

  const container = $('.scrolTable')[0];
  const header = $('.kt-portlet__head')[0];
  const filter = $('.stickyFilter')[0];

  const windowHeight = $(window).height();
  let headerHeight = 0;
  let footerHeight = 0;
  let navHeight = 0;
  let stickyFilterHeight = 0;
  let tableHeadHeight = 0;

  if (
    $('#kt_header').height() !== null &&
    $('#kt_header').height() !== undefined
  ) {
    headerHeight = $('#kt_header').height();
  }

  if (
    $('#kt_footer').height() !== null &&
    $('#kt_footer').height() !== undefined
  ) {
    footerHeight = $('#kt_footer').height();
  }

  if (
    $(header).height() !== null &&
    $(header).height() !== undefined &&
    !$(header).hasClass('scroll-down')
  ) {
    navHeight = $(header).height() + 25;
  }

  if (
    $(filter).height() !== null &&
    $(filter).height() !== undefined &&
    !$(filter).hasClass('scroll-down')
  ) {
    stickyFilterHeight = $(filter).height();
  }

  if (
    $(header).height() !== null &&
    $(header).height() !== undefined
  ) {
    tableHeadHeight = $(header).height();
  }

  const totalReduceHeight =
    headerHeight +
    footerHeight +
    navHeight +
    stickyFilterHeight +
    tableHeadHeight +
    (footerHeight > 0 ? 120 : 80);
  const scrollHeight = windowHeight - totalReduceHeight;

  $(container).css('max-height', `${scrollHeight}px`);
}

export function setStickyHeader() { 
  const container = $('.scrolTable')[0];
  const header = $('.kt-portlet__head')[0];
  const filter = $('.stickyFilter')[0];

  const currentScroll = $(container).scrollTop();
  if (currentScroll <= 0) {
    $(header).removeClass('scroll-up');
    $(filter).removeClass('scroll-up');
    $(header).removeClass('scroll-down');
    $(filter).removeClass('scroll-down');
    return;
  }

  if (currentScroll > lastScroll + 10 && !$(header).hasClass('scroll-down')) {
    // down
    $(header).removeClass('scroll-up');
    $(header).addClass('scroll-down');
    $(filter).removeClass('scroll-up');
    $(filter).addClass('scroll-down');
  } else if (currentScroll < lastScroll && $(header).hasClass('scroll-down')) {
    // up
    $(header).addClass('scroll-up');
    $(header).removeClass('scroll-down');
    $(filter).addClass('scroll-up');
    $(filter).removeClass('scroll-down');
  }
  lastScroll = currentScroll;
  SetScrollHeight();
}

export const camelCase = (data) =>
  !data || data === ''
    ? ''
    : data
        .split(' ')
        .map((d) => capitalizeCamel(d))
        .join(' ');

export const capitalizeCamel = (name) =>
  !name || name === ''
    ? ''
    : `${name[0].toUpperCase()}${name.slice(1).toLowerCase()}`;

export const titleCase = (data) =>
  !data || data === ''
    ? ''
    : data
        .split(' ')
        .map((d) => capitalize(d))
        .join(' ');

export const capitalize = (name) =>
  !name || name === ''
    ? ''
    : `${name[0].toUpperCase()}${name.slice(1)}`;

export const openFileHandler = (f) => {

    const url = URL.createObjectURL(f);
    const type = getFileType(f.name);

    if (type === 'excel') {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        link.setAttribute('href', url);
        link.setAttribute('download', f.name);
        link.style.visibility = 'hidden';
        link.click();
      }   
    }else{
 
      /*--with iframe--*/
      // const iframe = `
      // <html>
      //   <head>
      //     <title>File</title>
      //   </head>
      //   <body height="100%" width="100%">
      //     <iframe width='100%' height='100%' src=${url}></iframe>
      //   </body>
      // </html>`;       
      // const x = window.open();
      // x.document.open();
      // x.document.write(iframe);
      // x.document.close();

      /*--without iframe--*/
      window.open(url);
    }
    
}

export const downloadFileToClientHandler = async (fileType, fileName, displayName) => {
  try {
    swal.fire({
      titleText           : 'Downloading',
      text                : 'Please Wait...',
      showConfirmButton   : false,
      onOpen: () => {
        swal.showLoading();
      }
    });
    
    const file =  await downloadFileHandler(fileType, fileName, displayName); 
    if (file) {
      await swal.close();
      setTimeout(() => {
        const url = URL.createObjectURL(file);

        const link = document.createElement('a');
        if (link.download !== undefined) {
          link.setAttribute('href', url);
          link.setAttribute('download', file.name);
          link.style.visibility = 'hidden';
          link.click();
        }
      }, 50);
    }    
  } catch (err) {
    swal.fire({
      icon                : 'error',
      titleText           : 'Error!',
      text                : err.message,
      buttonsStyling      : false,
      confirmButtonClass  : 'btn btn-brand',
    });
  } 
}

export const downloadFileHandler = async (fileType, fileName, displayName) => {

  try {
    const file = await downloadFile(fileType, fileName);
    const type = getMimeType(fileName);
    const blob = new Blob([new Uint8Array(file.Body.data)], { type });
    blob.name = displayName;
    return blob;      
  } catch (err) {
    swal.close();
    swal.fire({
        icon                : 'error',
        titleText           : 'Error!',
        text                : err.message,
        buttonsStyling      : false,
        confirmButtonClass  : 'btn btn-brand',
    });
  }
}

export const downloadAndOpenFileHandler = async (fileType, fileName, displayName) => {
  try {
    swal.fire({
      titleText           : 'Loading',
      text                : 'Please Wait...',
      showConfirmButton   : false,
      onOpen: () => {
          swal.showLoading();
      }
    });
    const file =  await downloadFileHandler(fileType, fileName, displayName);
    if (file) {
      await swal.close();
      setTimeout(() => {
          openFileHandler(file);
      }, 50);
    } 
  } catch (err) {
    swal.fire({
      icon                : 'error',
      titleText           : 'Error!',
      text                : err.message,
      buttonsStyling      : false,
      confirmButtonClass  : 'btn btn-brand',
    });
  }
}

export const getMimeType = (fileName) => {
  const ext       = getFileExt(fileName);
  let mimeType    = 'Unknown';
  switch (ext) {
        case 'pdf':
            mimeType = 'application/pdf'
            break
        case 'jpg':
            mimeType = 'image/jpg'
            break
        case 'jpeg':
            mimeType = 'image/jpeg'
            break
        case 'png':
            mimeType = 'image/png'
            break
        case 'xlsx':
            mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            break
        case 'sheet':
            mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            break
        case 'xls':
            mimeType = 'application/vnd.ms-excel'
            break
        case 'ms-excel':
            mimeType = 'application/vnd.ms-excel'
            break
        case 'csv':
            mimeType = 'text/csv'
            break
        case 'doc':
            mimeType = 'application/msword'
            break
        case 'docx':
            mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            break
        default:
          throw new Error('File not supported');
  }
  return mimeType;
}

export const getFileExt = (fileName) => {
  const mtype     = fileName.split('.');
  const ext       = mtype[mtype.length - 1];
  return ext.toLowerCase();
}

export const getFileType = (fileName) => {
  const ext       = getFileExt(fileName);
  let type        = 'Unknown';
  switch (ext) {
        case 'sheet':
            type = 'excel'
            break
        case 'ms-excel':
            type = 'excel'
            break
        case 'xlsx':
            type = 'excel'
            break
        case 'xls':
            type = 'excel'
            break
        case 'csv':
            type = 'excel'
            break
        case 'jpg':
            type = 'image'
            break
        case 'jpeg':
            type = 'image'
            break
        case 'png':
            type = 'image'
            break
        case 'doc':
            type = 'word'
            break
        case 'docx':
            type = 'word'
            break
        default:
            type = ext
  }
  return type;
}

export const getFileLogo = (fileName) => {
  const type = getFileType(fileName);
  let logo = imageLogo;
  switch (type) {
    case 'excel':
        logo = excelLogo
        break
    case 'pdf':
        logo = pdfLogo
        break
    case 'word':
        logo = docLogo
        break
    default:
        logo = imageLogo
}
  return logo;
}

export const extractHtml = (html) => {
  let temporalDivElement = document.createElement("div");
  temporalDivElement.innerHTML = html;
  return temporalDivElement.textContent || temporalDivElement.innerText || "";
}

export const extractExcel = (data) => {

  const readedData    = XLSX.read(data, {type: 'binary'});
  const wsname        = readedData.SheetNames[0];
  const ws            = readedData.Sheets[wsname];
  const dataParse     = XLSX.utils.sheet_to_csv(ws, {header:1});

  return dataParse;

}

export const getFormatedDate = (date) => {

  const refDate = moment(date);

  const today     = refDate.isSame(moment().startOf('day'), 'd');
  const yesterday = refDate.isSame(moment().subtract(1, 'days').startOf('day'), 'd');
  const weekOld   = refDate.isAfter(moment().subtract(7, 'days').startOf('day'), 'd');

  if (today)
    return `Today ${refDate.format('hh:mm A')}`
  else if(yesterday)
    return `Yesterday ${refDate.format('hh:mm A')}`
  else if(weekOld)
    return refDate.format('dddd hh:mm A');
  else
    return refDate.format('DD/MM/YYYY - hh:mm A');
}

export const arrayReducer = (inputArray, perChunk) => inputArray.reduce((all, one, i) => {
    const ch = Math.floor(i / perChunk);
    all[ch] = [].concat((all[ch] || []), one);
    return all
}, [])

export const getDeviceDetails = async(deviceData) => {

  if(deviceData && (deviceData.isMobile || deviceData.isTablet)){
    return `${deviceData.os} ${deviceData.osVersion}`
  }else{
    return`${deviceData.osName} ${deviceData.osVersion}-${deviceData.browserName}`
  }

}


export const googleBtn = 
<svg viewBox="0 0 24 24" width="20" height="20">
  <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
    <path fill="#4285F4" d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"/>
    <path fill="#34A853" d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"/>
    <path fill="#FBBC05" d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"/>
    <path fill="#EA4335" d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"/>
  </g>
</svg>


export const getNotificationIcon = ( type ) => {
	switch( type ){
		case 'Task':
			return (
				<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-svg-icon--brand">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"/>
            <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3"/>
            <path d="M10.875,15.75 C10.6354167,15.75 10.3958333,15.6541667 10.2041667,15.4625 L8.2875,13.5458333 C7.90416667,13.1625 7.90416667,12.5875 8.2875,12.2041667 C8.67083333,11.8208333 9.29375,11.8208333 9.62916667,12.2041667 L10.875,13.45 L14.0375,10.2875 C14.4208333,9.90416667 14.9958333,9.90416667 15.3791667,10.2875 C15.7625,10.6708333 15.7625,11.2458333 15.3791667,11.6291667 L11.5458333,15.4625 C11.3541667,15.6541667 11.1145833,15.75 10.875,15.75 Z" fill="#000000"/>
            <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000"/>
          </g>
        </svg>
			)
		case 'Policy':
			return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-svg-icon--brand">
           <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <polygon points="0 0 24 0 24 24 0 24"/>
            <path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fillRule="nonzero" opacity="0.3"/>
            <rect fill="#000000" x="6" y="11" width="9" height="2" rx="1"/>
            <rect fill="#000000" x="6" y="15" width="5" height="2" rx="1"/>
          </g>
        </svg> 
			)
		default:
			return (
				<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-svg-icon--brand">
					<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
						<rect x="0" y="0" width="24" height="24"></rect>
						<path d="M12.9835977,18 C12.7263047,14.0909841 9.47412135,11 5.5,11 C4.98630124,11 4.48466491,11.0516454 4,11.1500272 L4,7 C4,5.8954305 4.8954305,5 6,5 L20,5 C21.1045695,5 22,5.8954305 22,7 L22,16 C22,17.1045695 21.1045695,18 20,18 L12.9835977,18 Z M19.1444251,6.83964668 L13,10.1481833 L6.85557487,6.83964668 C6.4908718,6.6432681 6.03602525,6.77972206 5.83964668,7.14442513 C5.6432681,7.5091282 5.77972206,7.96397475 6.14442513,8.16035332 L12.6444251,11.6603533 C12.8664074,11.7798822 13.1335926,11.7798822 13.3555749,11.6603533 L19.8555749,8.16035332 C20.2202779,7.96397475 20.3567319,7.5091282 20.1603533,7.14442513 C19.9639747,6.77972206 19.5091282,6.6432681 19.1444251,6.83964668 Z" fill="#000000"></path>
						<path d="M8.4472136,18.1055728 C8.94119209,18.3525621 9.14141644,18.9532351 8.89442719,19.4472136 C8.64743794,19.9411921 8.0467649,20.1414164 7.5527864,19.8944272 L5,18.618034 L5,14.5 C5,13.9477153 5.44771525,13.5 6,13.5 C6.55228475,13.5 7,13.9477153 7,14.5 L7,17.381966 L8.4472136,18.1055728 Z" fill="#000000" fillRule="nonzero" opacity="0.3"></path>
					</g>
				</svg> 
			)
	}
}

