import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import 'jquery-form';
import 'jquery-validation';
import '../../assets/scripts/bootstrap-modal';
import '../../assets/scripts/jquery-validation.init';
import * as helper from '../global/helper';
import swal from 'sweetalert2';
import Dropzone from '../global/dropzone';
import Loading from '../global/loading';
import { getAgentFile } from '../api/fileManagementAPI';
import NoData from '../global/noData';
import FileImg from '../../assets/images/no-file.svg'
import { uploadFile } from '../api/fileAPI';
import { addPolicyDocuments } from '../api/policyAPI';
import { addSubAgentPolicyDocuments } from '../api/subAgencyAPI';

const AddDocument = (props) => {

  const [btnLoading,    setBtnLoading]    = useState(false);
  const [documentName,  setDocumentName]  = useState('');
  const [document,      setDocument]      = useState(null);
  const [navState,      setNavState]      = useState('Upload');
  const [searchString,  setSearchString]  = useState('');
  const [loading,       setLoading]       = useState(false);
  const [files,         setFiles]         = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [attachedFiles, setAttachedFiles] = useState([]);

  useEffect(() => {
    let ignore = false;

    $('#add-document-modal').modal({
      backdrop: 'static'
    })

    $('#add-document-modal').on('hidden.bs.modal', function () {
      $('.modal').hasClass('show') && $('body').addClass('modal-open');
      $(props.from).removeClass('modal-blur');
      props.addDocument(false);
    });
    $(props.from).addClass('modal-blur');  

    const fetchAgentFiles = async () => {
      try {
        setLoading(true);
        const response = await getAgentFile();
        setFiles(response.filter(f => f.FileType === 'Public'));
        setLoading(false); 
      } catch (err) {
        setLoading(false);
        swal.close();
        swal.fire({
            icon                : 'error',
            titleText           : 'Error!',
            text                : err.message,
            buttonsStyling      : false,
            confirmButtonClass  : 'btn btn-brand',
        });
      }
    };

    if (!ignore) {        
      fetchAgentFiles();
    }
    return () => {
      ignore = true;
    };

  }, []);

  useEffect(() => {
    helper.SetScrollHeight();
  });

  useEffect(() => {
    if (files.length > 0)
      filterFiles();
    else
        setFilteredFiles([]);
  },[files, searchString])

  const filterFiles = () => {
    if (searchString === '') {
      setFilteredFiles(files);
    } else {
        const matchedFiles = files.filter((f) => {
          let matchString = `${f.CategoryName} ${f.DocumentName} ${f.InsuranceCompanyName} ${f.ProductName}`;
            let matchFound = 0;
            const searchArr = searchString.split(' ');
            searchArr.forEach((term) => {
                matchFound += matchString.toLowerCase().includes(term.toLowerCase())
                    ? 1
                    : 0;
            });
            return matchFound === searchArr.length;
        });
        setFilteredFiles(matchedFiles);
    }
  };

  const documentHandler = async (d) => {
    setDocument(d);
  };  
  
  const openFile = async (index) => {
    const file      = filteredFiles[index];
    const ext       = helper.getFileExt(file.FileName);
    const name      = (file.DocumentName + '.' + ext).replace(/ /g, '_');
    helper.downloadAndOpenFileHandler("AgentFile", file.FileName, name)
  }

  const selectFileHandler = (ID, IsChecked) => {
    if (IsChecked) {
      setAttachedFiles((f) => {
        const newA = [...f];
        const d = filteredFiles.find(d => d.ID == ID);
        newA.push({
          DocumentName: d.DocumentName, 
          DocumentFile: {name: d.FileName},
          DocumentType: 'AgentFile'
        });
        return newA;
      });  
    }else{
      setAttachedFiles((f) => {
        const nA = f.filter(a => a.ID != ID);
        return nA;
      })   
    }
  }

  const removeDocumentHandler = (e) => {
      e.preventDefault();
      setDocument(null);
  }

  const addDocument = async (e) => {

    if (navState === 'Upload') {
      var form = $('#add-document-form');
      form.validate().destroy();
      form.validate({
        rules: {
          documentName: {
            required: true,
          }
        }
      });
      if (!form.valid()) {
        return;
      }
      if (!document) {
        return;   
      }
      if (props.action === 'e') {
        try {
          const postDocuments = [];
          setBtnLoading(true);
          // const fileName = props.from == 'subAgentPolicyStats' ? await uploadFile(document, "SubAgentPolicyDocument") : await uploadFile(document, "PolicyDocument")
          const fileName = await uploadFile(document, "PolicyDocument")
          postDocuments.push({
            PolicyID    : props.policyID,
            FileName    : fileName,
            DocumentName: documentName,
            // DocumentType: props.from == 'subAgentPolicyStats' ?'SubAgentPolicyDocument' : 'PolicyDocument'
            DocumentType: 'PolicyDocument'
          });
          // props.from == 'subAgentPolicyStats' ?  await addSubAgentPolicyDocuments(postDocuments) : await addPolicyDocuments(postDocuments);
          props.from == await addPolicyDocuments(postDocuments);
          props.refetchDocuments();
          $('#add-document-modal').modal('hide');
          swal.fire({
            icon              : 'success',
            titleText         : 'Document Added Successfully',
            showConfirmButton : false,
            timer             : 1500,
          });
        } catch (err) {
          swal.fire({
            icon                  : 'error',
            titleText             : 'Error!',
            text                  : err.message,
            buttonsStyling        : false,
            confirmButtonClass    : 'btn btn-brand',
          });
          setBtnLoading(false);
          return;
        }
      } else {
        props.setDocumentHandler({
          DocumentName: documentName, 
          DocumentFile: document,
          DocumentType: navState
        });
      }
      
    }else{
      const postDocuments =[];
      if (props.action === 'e') {
        try {
          setBtnLoading(true);
          attachedFiles.forEach(document => {
            postDocuments.push({
              PolicyID    : props.policyID,
              FileName    : document.DocumentFile.name,
              DocumentName: document.DocumentName,
              DocumentType: 'AgentFile'
            });
          });
          // props.from == 'subAgentPolicyStats' ?  await addSubAgentPolicyDocuments(postDocuments) : await addPolicyDocuments(postDocuments);
          await addPolicyDocuments(postDocuments);
          props.refetchDocuments();
          $('#add-document-modal').modal('hide');
          swal.fire({
            icon              : 'success',
            titleText         : 'Document Added Successfully',
            showConfirmButton : false,
            timer             : 1500,
          });
        } catch (err) {
          swal.fire({
            icon                  : 'error',
            titleText             : 'Error!',
            text                  : err.message,
            buttonsStyling        : false,
            confirmButtonClass    : 'btn btn-brand',
          });
          setBtnLoading(false);
          return;
        }
      }else{
        attachedFiles.forEach(file => {
          props.setDocumentHandler(file);
        });
      }     
    }

    $('#add-document-modal').modal('hide');

  };

  return (
    <div
      className="modal fade"
      id="add-document-modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      disabled={btnLoading}
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Attach Document</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body padding-0">
            <div className="kt-portlet kt-portlet--mobile">
              <div className="kt-portlet__head kt-portlet__head--lg padding-lr0" style={{minHeight: 0}}>
                  <div className="kt-portlet__head-label w-100">
                      <nav className="file-nav navbar navbar-top w-100 justify-content-center padding-t5">
                          <div className="row w-100">                                  
                              <div className="col-6 text-center padding-0">
                                  <a className={`d-block w-100 text-clip margin-l0 padding-b15 ${navState === 'Upload' ? 'top-nav-active' : ''}`} tabIndex="-1" onClick={() => setNavState('Upload')}>                                                                                                                                                                                                                                               
                                      Upload Document
                                  </a>
                              </div>
                              <div className="col-6 text-center padding-0">
                                  <a className={`d-block w-100 text-clip margin-l0 padding-b15 ${navState === 'Select' ? 'top-nav-active' : ''}`} tabIndex="-1" onClick={() => setNavState('Select')}>
                                      Attach From Files
                                  </a>
                              </div>
                          </div>                                                                              
                      </nav>
                  </div>
              </div>
            </div>
            {navState === 'Upload' ? (
              <div className="client-container padding-lr20">
                <form id="add-document-form" onSubmit={addDocument}>
                  <div className="row">
                    <div className="col-12">
                      <input
                        id="documentName"
                        value={documentName}
                        name="documentName"
                        placeholder="Document Name"
                        className="form-control margin-b10"
                        type="text"
                        onChange={(event) =>
                          setDocumentName(event.target.value)
                        }
                      />
                    </div>
                  </div>
                  {document ? 
                    (
                      <div className="row margin-t20">
                        <div className="col-12">
                            <div className="kt-notification margin-b30" style={{backgroundColor: '#f7f8fa'}}>
                                <a className="kt-notification__item">
                                    <div className="kt-notification__item-icon margin-r10" onClick={() => helper.openFileHandler(document)}>
                                      <img height="30px" width="30px" src={helper.getFileLogo(document.name)} />
                                    </div>
                                    <div className="kt-notification__item-details" onClick={() => helper.openFileHandler(document)}>
                                        <div className="kt-notification__item-title kt-font-bold">
                                            {document.name}
                                        </div>
                                    </div>
                                    <button 
                                        type="button"                    
                                        onClick={removeDocumentHandler}
                                        className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                        <i className="flaticon2-rubbish-bin-delete-button"></i>
                                    </button>
                                </a>
                            </div>
                        </div>
                      </div> 
                    ) : (<Dropzone fileHandler={documentHandler} />)}
                </form>
              </div>
            ) : (
              <React.Fragment>
                <div className="row align-items-center stickyFilter">
                    <div className="col-xl-12 order-1 order-xl-1">
                        <div className="row">
                            <div className="col-md-6 margin-b0">
                                <div className="kt-input-icon kt-input-icon--left">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search File..."
                                        id="generalSearch"
                                        value={searchString || ''}
                                        onChange={(event) => setSearchString(event.target.value)}>
                                    </input>
                                    <span className="kt-input-icon__icon kt-input-icon__icon--left">
                                        <span>
                                            <i className="la la-search"></i>
                                        </span>
                                    </span>
                                    {searchString && (
                                      <span className="kt-input-icon__icon kt-input-icon__icon--right">
                                          <span>
                                              <i className="la la-close" onClick={() => setSearchString('')}></i>
                                          </span>
                                      </span>
                                  )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {loading ? (
                  <Loading />
                ) : (
                  <React.Fragment>
                    {filteredFiles.length > 0 ? (
                      <div className="row">
                        <div className="col-12">
                          <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
                            <table className="kt-datatable__table">
                              <thead className="kt-datatable__head" style={{ display: 'block' }}>
                                <tr className="kt-datatable__row block-row">
                                  <th className="kt-datatable__cell text-clip padding-l30"  width='30%'>
                                    <span>File</span>
                                  </th>
                                  <th className="kt-datatable__cell text-clip" width="20%">
                                    <span>Product</span>
                                  </th>
                                  <th className="kt-datatable__cell text-clip" width="20%">
                                    <span>Company</span>
                                  </th>
                                  <th className="kt-datatable__cell text-clip" width="20%">
                                    <span>Type</span>
                                  </th>
                                  <th className="kt-datatable__cell text-clip" width="10%">
                                    <span>Select</span>
                                  </th>
                                </tr>
                              </thead>
                            </table>
                          </div>
                          <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded scrolTable" onScroll={helper.setStickyHeader}>
                            <table className="kt-datatable__table table-striped">
                              <tbody className="kt-datatable__body" style={{ display: 'block' }}>
                                {filteredFiles.map((f, i) => (
                                  <tr key={f.ID} className="kt-datatable__row block-row">
                                    <td title={f.DocumentName} className="kt-datatable__cell" width='30%'>
                                      <div className="kt-notification text-clip">
                                        <a className="kt-notification__item padding-0" onClick={() => openFile(i)}>
                                          <div className="kt-notification__item-icon margin-r10">
                                            <img height="30px" width="30px" src={helper.getFileLogo(f.FileName)} />
                                          </div>
                                          <div className="kt-notification__item-details text-clip">
                                            <div className="kt-notification__item-title kt-font-bold text-clip">
                                              {f.DocumentName}
                                            </div>
                                          </div>
                                        </a>
                                      </div>
                                    </td>
                                    <td title={f.ProductName} className="kt-datatable__cell text-clip" width="20%">
                                      <span>{f.ProductName}</span>
                                    </td>
                                    <td title={f.InsuranceCompanyName} className="kt-datatable__cell text-clip" width="20%">
                                      <span>{f.InsuranceCompanyName}</span>
                                    </td>
                                    <td title={f.CategoryName} className="kt-datatable__cell text-clip" width="20%">
                                      <span>{f.CategoryName}</span>
                                    </td>
                                    <td className="kt-datatable__cell text-clip" width="10%">
                                      <label className="kt-checkbox kt-checkbox--brand">
                                        <input type="checkbox" 
                                            onClick={(event) => selectFileHandler(f.ID, event.target.checked)}/>
                                        &nbsp;<span></span>
                                      </label>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <NoData
                        src={FileImg}
                        alt="File"
                        message="No Files Found"
                      />
                    )}
                  </React.Fragment>
                )}             
              </React.Fragment>
            )} 
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary margin-0 margin-r5"
              disabled={btnLoading}
              data-dismiss="modal">    
              Close
            </button>
            <button
              id="save-document"
              onClick={addDocument}
              type="button"
              disabled={btnLoading}
              className={`btn btn-brand margin-0 ${props.action === 'e' && btnLoading
              ? 'kt-spinner kt-spinner--sm kt-spinner--light'
              : '' }`}> 
              Attach
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDocument;