import { axiosGet } from "./axiosFunctions";
import { getTokenOrLogout } from "./common";

const verifyEmailUrl                           = '/api/email/verifyEmail';
const checkEmailVerificationForActivatationUrl = '/api/email/checkEmailVerificationForActivatation';
const checkEmailVerificationUrl                = '/api/email/checkEmailVerification';
const deleteEmailUrl                           = '/api/email/deleteEmail';

export const getVerifyEmail = async () => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
  
    const response = await axiosGet(
        verifyEmailUrl, 
        { headers: { auth_token: `bearer ${token}` },
    });
    return response;
};

export const getCheckEmailVerificationForActivatation = async () => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
  
    const response = await axiosGet(
        checkEmailVerificationForActivatationUrl, 
        { headers: { auth_token: `bearer ${token}` },
    });
    return response;
};

export const getCheckEmailVerification = async () => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
  
    const response = await axiosGet(
        checkEmailVerificationUrl, 
        { headers: { auth_token: `bearer ${token}` },
    });
    return response;
};

export const getDeleteEmail = async () => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
  
    const response = await axiosGet(
        deleteEmailUrl, 
        { headers: { auth_token: `bearer ${token}` },
    });
    return response;
};