import React, { useState, useEffect } from 'react';
import { axiosGet } from '../api/axiosFunctions';
import { googleApiKey } from '../../env.json'
import NoData from '../global/noData';
import FileImg from '../../assets/images/no-file.svg'

import './rightSideDetails.css';

const { $ } = window;

const VideoTutorials = (props) => {

  const [videoList,      setVideoList]      = useState([]);
  const [videos,         setVideos]         = useState([]);
  const [video,          setVideo]          = useState(null); 
  const [searchString,   setSearchString]   = useState('');

  useEffect(() => {
    fetchVideos();
  }, []);

  useEffect(() => {
    if (videoList.length > 0){
      if (searchString === '') {
        setVideos(videoList);
      }else{
          const matchedVideos = videoList.filter((v) => {
              let matchString = `${v.title}`;
              let matchFound = 0;
              const searchArr = searchString.split(' ');
              searchArr.forEach((term) => {
                  matchFound += matchString.toLowerCase().includes(term.toLowerCase())
                      ? 1
                      : 0;
              });
              return matchFound === searchArr.length;
          });
          setVideos(matchedVideos);
      }
    }
    else{
      setVideos([]);
    }     
}, [videoList, searchString]);

  useEffect(() => {
    if (video){
      $('#video-modal').on('hidden.bs.modal', function () {
        setVideo(null);
      });
      $('#video-modal').modal('show');
    }     
  }, [video]);

  const fetchVideos = async () => {
    try {
      const response = await axiosGet(`https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${props.playlistId}&key=${googleApiKey}`);
      if (response && response.items && response.items.length > 0) {
        setVideoList(response.items.map(video => ({
          id          : video.snippet.resourceId.videoId,
          title       : video.snippet.title,
          description : video.snippet.description,
          thumbnail   : video.snippet.thumbnails.maxres.url,
        })));
      }
    } catch (err) {
      swal.fire({
          icon              : 'error',
          titleText         : 'Error!',
          text              : err.message,
          buttonsStyling    : false,
          confirmButtonClass: 'btn btn-brand',
      });
    }
  }

  const closeDrawer = () => {
    $('#panel-right').addClass('closing');   
    $('#right-panel-backdrop').removeClass('show');  
    setTimeout(() => {       
      $('#panel-right').removeClass('show');   
      $('#panel-right').removeClass('closing');
    }, 200);
    if (props.onCloseDrawer) props.onCloseDrawer();
  };

    
    return (
        <div>
          <div
            id="right-panel-backdrop"
            className={`backdrop ${props.show ? 'show' : ''}`}
            onClick={closeDrawer}
          />
          <div
            id="panel-right"
            className={`panel-right ${props.show ? 'show' : ''}`}
            style={{width: '450px'}}>
          
            <div className="kt-header" style={{left: '0px'}}>
              <div className="kt-header-menu-wrapper">
                <div className="kt-header-menu kt-header-menu-mobile  kt-header-menu--layout-default ">
                  <h3 className="header-title">{props.title}</h3>
                </div>
              </div>
    
              <div className="kt-header__topbar">
                <button onClick={closeDrawer} type="button" className="btn btn-close"> <i className="flaticon2-delete kt-icon-sm" data-dismiss="alert"></i></button>
              </div>
    
            </div>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-b0">
              <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row margin-b20">
                  <div className="col-md-12">
                    <div className="kt-input-icon kt-input-icon--left">
                      <input                                      
                          type="text"
                          className="form-control"
                          placeholder="Search Video..."
                          value={searchString || ''}
                          onChange={(e) => setSearchString(e.target.value)}>  
                      </input>
                      <span className="kt-input-icon__icon kt-input-icon__icon--left">
                          <span>
                              <i className="la la-search"></i>
                          </span>
                      </span>
                      {searchString && (
                          <span className="kt-input-icon__icon kt-input-icon__icon--right">
                              <span>
                                  <i className="la la-close" onClick={() => setSearchString('')}></i>
                              </span>
                          </span>
                      )}
                    </div>
                  </div>
                </div>
                {videos && videos.length > 0 ? (
                  videos.map((v, i) => 
                    <div key={i} className="row">
                      <div className="col-md-12">
                          <div className="kt-portlet video-card" onClick={() => setVideo(v.id)}>
                              <div className="kt-portlet__body">
                                <div className="row">
                                  <div className="col-5">
                                    <img className="img-thumbnail rounded img-fluid" src={v.thumbnail}></img>
                                  </div>
                                  <div className="col-7 align-self-center margin-b10">
                                    <span className="title-label font-14 d-block">{v.title}</span>
                                  </div>
                                </div>   
                              </div>
                          </div>
                      </div>
                  </div>
                )) : (
                  <NoData
                      src={FileImg}
                      from="video"
                      alt="video"
                      message="No Videos Found"
                      show="N"
                  />
                )}         
              </div>
            </div>
          </div>
          {video && (
            <div
              className="modal fade margin-t50"
              id="video-modal"
              tabIndex="-1"
              role="dialog"
              aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                  <div className="modal-content no-border">
                    <iframe 
                        src={`https://www.youtube.com/embed/${video}?rel=0&autoplay=1`}
                        height="450px"
                        width="100%"
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen>        
                      </iframe>
                  </div>
                </div>
            </div>
          )}       
        </div>
    );

};

export default VideoTutorials;