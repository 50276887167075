import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import SignIn from './signin';
import SignUp from './signup';
import OTP from './otp';
import Forgot from './forgot';
import SetPassword from './setPassword'
import bannerLogo from '../../assets/images/banner-logo.png';
import '../../assets/styles/login.css';

const Login = props => {

    return (
        <div className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v3" id="kt_login">
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" style={{ backgroundColor: '#f7f8fa' }}>
                <div className="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
                    <div className="kt-login__container">
                        <div className="kt-login__logo">
                            <img height="38" src={bannerLogo} alt="logo" />
                        </div>
                        <Switch>
                            <Route path="/signin" exact> <SignIn onLogin={props.onLogin} /> </Route>
                            <Route path="/signup" exact> <SignUp onLogin={props.onLogin} /> </Route>
                            <Route path="/verify/:action" exact> <OTP onLogin={props.onLogin} /> </Route>
                            <Route path="/forgot-password" exact> <Forgot /> </Route>
                            <Route path="/set-password/:uid" > <SetPassword /> </Route>
                            <Redirect to="/signin" />
                        </Switch>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Login);