import React, {useState, useEffect} from "react";
import { getIncentiveTarget, updateRevenueMaster } from "../api/policyAPI";
import * as helper from '../global/helper';
import 'bootstrap/js/dist/popover';
import swal from 'sweetalert2';
import moment from 'moment';
import $ from 'jquery';
import Select from 'react-select';
import { getInsuranceCompanies, getProducts } from '../api/policyAPI';
import AddCompany from "../policy/addCompany";
import '../../assets/scripts/jquery-validation.init';
import 'jquery-form';
import 'jquery-validation';

const EditRevenueMaster = (props) => {
    const [btnLoading,          setBtnLoading]          = useState(false);
    const [insuranceCompanies,  setInsuranceCompanies]  = useState([]);
    const [products,            setProducts]            = useState([]);
    const [revenueDetails,      setRevenueDetails]      = useState(props.revenue);
    const [addModalType,        setAddModalType]        = useState('Company');
    const [addedCompanyId,      setAddedCompanyId]      = useState(null);
    const [addedProductId,      setAddedProductId]      = useState(null);
    const [commissionType,      setCommissionType]      = useState(props.revenue.CommissionType);
    const [incentiveType,       setIncentiveType]       = useState(props.revenue.IncentiveType);
    const [targetDetail,        setTargetDetail]        = useState({});
    const [targetDetails,       setTargetDetails]       = useState([]);

    useEffect(() => {
        $('#edit-revenue-modal').modal('toggle');
        $('#edit-revenue-modal').on('hidden.bs.modal', function () {
            props.onDismissModal(null);
        });
        fetchInsuranceCompanies();
        fetchProducts();
    }, [props.show]);

    useEffect(() => {
        if (props.revenue.IncentiveType === 'Target') {
            fetchIncentiveTarget();
        }
    }, [props.revenue])

    useEffect(() => {
        if (props.revenue.IncentiveType === null && commissionType === 'Incentive')
            setIncentiveType('Flat')

        if (commissionType === 'Standard')
            setTargetDetails([]);
    }, [commissionType]);

    useEffect(() => {
        if (incentiveType === 'Flat') {
            setTargetDetails([]);
        }
    }, [incentiveType])

    useEffect(() => {
        if(addedCompanyId)
            fetchInsuranceCompanies();
    }, [addedCompanyId]);

    useEffect(() => {
        if (addedProductId)
            fetchProducts();        
    }, [addedProductId]);

    useEffect(() => {
        if (insuranceCompanies.length > 0 && addedCompanyId) { 
            const company = insuranceCompanies.find(c => c.ID == addedCompanyId);
            changeCompanyHandler({
                label: company.Name,
                value: company.ID
            });  
        }          
    }, [insuranceCompanies]);

    useEffect(() => {  
        if (products.length > 0 && addedProductId) {
            const product = products.find(c => c.ID == addedProductId);
            changeProductHandler({
                label: product.Name,
                value: product.ID
            });   
        }    
    }, [products]);

    const updateTargetDetails = (field, value) => {
        setTargetDetail(t => {
            return {
                ...t,
                [field]: value
            }
        })
    }

    const updateRevenueDetails = (field, value) => {
        setRevenueDetails(r => {
            return { ...r, [field]: value }
        });
    };

    const changeCompanyHandler = (e) => {
        updateRevenueDetails('InsuranceCompanyID', e.value);
        updateRevenueDetails('InsuranceCompanyName', e.label);
        setAddedCompanyId(null);
    }

    const changeProductHandler = (e) => {
        updateRevenueDetails('ProductID', e.value);
        updateRevenueDetails('ProductName', e.label);
        setAddedProductId(null);
    };

    const companyAddHandler = (e) => {
        e.preventDefault();
        setAddModalType('Company');   
        $('#edit-revenue-modal').addClass('modal-blur');
        $('#add-company-modal').modal('toggle');
    };
    
    const productAddHandler = (e) => {
        e.preventDefault();
        setAddModalType('Product');
        $('#edit-revenue-modal').addClass('modal-blur');
        $('#add-company-modal').modal('toggle');
    };

    const fetchInsuranceCompanies = async () => {
        try {
            const response = await getInsuranceCompanies();
            setInsuranceCompanies(
            response
            .sort((a, b) =>
                    a.Name > b.Name
                        ? 1
                        : a.Name < b.Name
                        ? -1
                        : 0
                )
            );   
        } catch (err) {
            swal.fire({
                icon              : 'error',
                titleText         : 'Error!',
                text              : err.message,
                buttonsStyling    : false,
                confirmButtonClass: 'btn btn-brand',
            });
        }  
    };

    const fetchIncentiveTarget = async () => {
        try {
            const response = await getIncentiveTarget(props.revenue.ID);
            setTargetDetails(response);
        } catch (err) {
            swal.close();
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        }
    }

    const fetchProducts = async () => {
        try {
            const response = await getProducts();
            setProducts(
            response
            .sort((a, b) =>
                    a.Name > b.Name
                        ? 1
                        : a.Name < b.Name
                        ? -1
                        : 0
                )
            );     
        } catch (err) {
            swal.fire({
                icon              : 'error',
                titleText         : 'Error!',
                text              : err.message,
                buttonsStyling    : false,
                confirmButtonClass: 'btn btn-brand',
            });
        }
    };

    const rangeAddHandler = async (e) => {
        e.preventDefault();
        const form = $('#edit-revenue-form');
    
        form.validate().destroy();
        form.validate({
            rules: {
                startRange: {
                    required: true,
                },
                endRange: {
                    required: true,
                },
                percent: {
                    required: true,
                },
            },
        });

        if (!form.valid()) {
        return;
        }
        if (parseInt(targetDetail.StartRange) > parseInt(targetDetail.EndRange)) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : "Invalid Range",
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return
        } else if (targetDetails.length == 0) {
            setTargetDetails((t) => [...t, targetDetail]);
            setTargetDetail({})
        } else {
            const t = targetDetails.find(t => {
                if((parseInt(targetDetail.StartRange) >= parseInt(t.StartRange) && parseInt(targetDetail.EndRange) <= parseInt(t.EndRange)) 
                    || (parseInt(targetDetail.StartRange) <= parseInt(t.StartRange) && parseInt(targetDetail.EndRange) >= parseInt(t.StartRange) && parseInt(targetDetail.EndRange) <= parseInt(t.EndRange))
                    || (parseInt(targetDetail.StartRange) >= parseInt(t.StartRange) && parseInt(targetDetail.StartRange) <= parseInt(t.EndRange))
                    || (parseInt(targetDetail.StartRange) <= parseInt(t.StartRange) && parseInt(targetDetail.EndRange) >= parseInt(t.EndRange)))
                {
                    return t
                }
            });
            if (t) {
                swal.fire({
                    icon                  : 'error',
                    titleText             : 'Error!',
                    text                  : "Range already Exist",
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',   
                });
                return;
            }

            const invalidPercent = targetDetails.find(t => {
                if (targetDetail.TargetPercent <= t.TargetPercent)
                    return t
            });

            if (invalidPercent) {
                swal.fire({
                    icon                  : 'error',
                    titleText             : 'Error!',
                    text                  : "Percent should greater than previous percent",
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',   
                });
                return;
            }

            setTargetDetails((td) => [...td, targetDetail]);
            setTargetDetail({})
        }
    }

    const removeRangeHandler = (index) => {
        setTargetDetails((a) => {
            const nA = a.filter((b, i) => index !== i);
            return nA;
        })
    }

    const updateRevenueHandler = async (e) => {
        e.preventDefault();
        const form = $('#edit-revenue-form');
        form.validate().destroy();

        form.validate({
            rules: {
                ddlInsuranceCompany: {
                    required: true,
                },
                ddlProduct: {
                    required: true,
                },
                fromDate: {
                    required: true,
                },
                toDate: {
                    required: true,
                },
                startRange: {
                    required: {
                        depends: function (element) {
                            if (targetDetails.length > 0)
                                return false;
                            else 
                                return true;
                        }
                    }
                },
                endRange: {
                    required: {
                        depends: function (element) {
                            if (targetDetails.length > 0)
                                return false;
                            else 
                                return true;
                        }
                    }
                },
                percent: {
                    required: {
                        depends: function (element) {
                            if (targetDetails.length > 0) return false;
                            else return true;
                        }
                    }
                },
                commission: {
                    required: true,
                },
            },
        });
        

        if (!form.valid()) {
            return;
        }

        try {
            setBtnLoading(true);
            if (commissionType === 'Incentive' && incentiveType === 'Target' && targetDetails.length == 0) {
                swal.fire({
                    icon                  : 'error',
                    titleText             : 'Error!',
                    text                  : "Please add atleast one range",
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',   
                });
                setBtnLoading(false);
                return;
            }
            await updateRevenueMaster({
                ID                   : revenueDetails.ID,
                InsuranceCompanyID   : revenueDetails.InsuranceCompanyID,
                ProductID            : revenueDetails.ProductID,
                CommissionType       : commissionType,
                FromDate             : moment(revenueDetails.FromDate).format('YYYY-MM-DD'),
                ToDate               : moment(revenueDetails.ToDate).format('YYYY-MM-DD'),
                IncentiveType        : incentiveType,
                Commission           : commissionType === 'Incentive' ? 0 : revenueDetails.Commission,
                TDS                  : revenueDetails.TDS,
                FlatPercent          : commissionType === 'Standard' ? 0  : revenueDetails.FlatPercent,
                TargetDetails        : targetDetails
            })
            $('#edit-revenue-modal').modal('hide');
            props.refetchRevenues();
            swal.fire({
                icon              : 'success',
                titleText         : 'Revenue Updated Successfully',
                showConfirmButton : false,
                timer             : 1500
            });
        }  catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            setBtnLoading(false);
            return;
        }
    }

    return (
        <>
            <div
                className="modal fade"
                id="edit-revenue-modal"
                tabIndex="-1"
                role="dialog"
                aria-hidden="true">
            
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Edit Revenue</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                disabled={btnLoading}>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form id="edit-revenue-form" className="kt-form" onSubmit={(e) => e.preventDefault()}>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-10">
                                                <div className="form-group">
                                                    <label htmlFor="ddlInsuranceCompany">Insurance Company</label>       
                                                    <Select
                                                        value={revenueDetails && revenueDetails.InsuranceCompanyID && {label: revenueDetails.InsuranceCompanyName, value: revenueDetails.InsuranceCompanyID}}
                                                        onChange={changeCompanyHandler}
                                                        name="ddlInsuranceCompany"
                                                        id="ddlInsuranceCompany"
                                                        placeholder="Select Company"
                                                        options={insuranceCompanies.map(c => ({
                                                            label: c.Name,
                                                            value: c.ID
                                                        }))}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <button
                                                    id="btnNewCompany"
                                                    className="btn btn-label-brand btn-block margin-0 margin-t24"
                                                    data-toggle="kt-popover"
                                                    data-trigger="hover"
                                                    data-placement="bottom"
                                                    data-content="Add Insurance Company"
                                                    onClick={companyAddHandler}>
                                                    <i className="la la-plus" />
                                                    New
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-10">
                                                <div className="form-group">
                                                    <label htmlFor="ddlProduct">Product</label>
                                                    <Select
                                                        
                                                        value={revenueDetails && revenueDetails.ProductID && {label: revenueDetails.ProductName, value: revenueDetails.ProductID}}
                                                        onChange={changeProductHandler}
                                                        name="ddlProduct"
                                                        id="ddlProduct"
                                                        placeholder="Select Product"
                                                        options={products.map(c => ({
                                                            label: c.Name,
                                                            value: c.ID
                                                        }))}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <button
                                                    id="btnNewProduct"
                                                    className="btn btn-label-brand btn-block margin-0 margin-t24"
                                                    data-toggle="kt-popover"
                                                    data-trigger="hover"
                                                    data-placement="bottom"
                                                    data-content="Add Product"
                                                    onClick={productAddHandler}>           
                                                    <i className="la la-plus" />
                                                    New
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className="form-group">
                                            <label htmlFor="type">Commission Type</label> 
                                            <select
                                                className="form-control kt-selectpicker"
                                                id="ddlType"
                                                value={commissionType || ''}
                                                onChange={(event) => setCommissionType(event.target.value)}>                                                 
                                                <option value="Standard">Standard</option>
                                                <option value="Incentive">Incentive</option>
                                            </select>
                                        </div>
                                    </div> 
                                    <div className='col-3'>
                                        <div className="form-group">
                                            <label htmlFor="type">Start Date</label>
                                            <input
                                                id="fromDate"
                                                value={moment(revenueDetails.FromDate).format('YYYY-MM-DD') || ''}
                                                name="fromDate"
                                                className="form-control margin-b10"
                                                type="date"
                                                onChange={(event) => updateRevenueDetails('FromDate', event.target.value)}
                                            /> 
                                        </div>
                                    </div> 
                                    <div className='col-3'>
                                        <div className="form-group">
                                            <label htmlFor="type">End Date</label>
                                            <input
                                                id="toDate"
                                                value={moment(revenueDetails.ToDate).format('YYYY-MM-DD') || ''}
                                                name="toDate"
                                                className="form-control margin-b10"
                                                type="date"
                                                onChange={(event) => updateRevenueDetails('ToDate', event.target.value)}
                                            /> 
                                        </div>
                                    </div> 
                                </div>
                                {commissionType === 'Incentive' ? (
                                    <div className='row'>
                                        <div className={`${incentiveType === 'Target' ? 'col-2' : 'col-4'}`}>
                                            <label htmlFor="tds">TDS</label>
                                            <div className='input-group'>
                                                <input
                                                    name="tds"
                                                    id="tds"
                                                    value={revenueDetails.TDS}
                                                    max='100'
                                                    className="form-control"
                                                    type="number"
                                                    onChange={(event) => updateRevenueDetails('TDS', event.target.value)}                                                   
                                                />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">%</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${incentiveType === 'Target' ? 'col-2' : 'col-4'}`}>
                                            <label htmlFor="type">Incentive Type</label> 
                                            <select
                                                className="form-control kt-selectpicker"
                                                id="ddlType"
                                                value={incentiveType || ''}
                                                onChange={(event) => setIncentiveType(event.target.value)}>                                                 
                                                <option value="Flat">Flat</option>
                                                <option value="Target">Target</option>
                                            </select>
                                        </div> 
                                        {incentiveType === 'Target' && (
                                            <>
                                                <div className='col-2'>
                                                    <label htmlFor="type">Start Range</label>
                                                    <input
                                                        id="startRange"
                                                        value={targetDetail.StartRange || ''}
                                                        name="startRange"
                                                        className="form-control margin-b10"
                                                        type="number"
                                                        onChange={(event) => updateTargetDetails('StartRange', event.target.value)}                                                   
                                                    />
                                                </div>
                                                <div className='col-2'>
                                                    <label htmlFor="type">End Range</label>
                                                    <input
                                                        id="endRange"
                                                        value={targetDetail.EndRange || ''}
                                                        name="endRange"
                                                        className="form-control margin-b10"
                                                        type="number"
                                                        onChange={(event) => updateTargetDetails('EndRange', event.target.value)}                                                   
                                                    />
                                                </div>
                                            </>
                                        )}
                                        <div className={`col-${incentiveType === 'Flat' ? '4' : '2'}`}>
                                            <label htmlFor="type">Percent</label>
                                            <div className="input-group revenue-percent">
                                                <input 
                                                    id="percent"
                                                    value={incentiveType === 'Flat' ? revenueDetails.FlatPercent || '' : targetDetail.TargetPercent || ''}
                                                    name="percent"
                                                    className="form-control"
                                                    max='100'
                                                    type="number"
                                                    onChange={(event) => incentiveType === 'Flat' ? updateRevenueDetails('FlatPercent', event.target.value) : updateTargetDetails('TargetPercent', event.target.value)}                                                   
                                                />
                                                <div className="input-group-append revenue-icon">
                                                    <span className="input-group-text">%</span>
                                                </div>
                                            </div>
                                        </div>
                                        {incentiveType === 'Target' && (
                                            <div className='col-2'>
                                                <button 
                                                    className="btn btn-label-brand btn-icon-sm btn-block margin-t24"
                                                    onClick={rangeAddHandler}
                                                >
                                                    <i className="la la-plus"></i>
                                                    Add
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className='row'>
                                        <div className='col-6'>
                                            <label htmlFor="type">Commission</label>
                                            <div className='input-group'>
                                                <input
                                                    id="commission"
                                                    value={revenueDetails.Commission || ''}
                                                    name="commission"
                                                    className="form-control"
                                                    max='100'
                                                    type="number"
                                                    onChange={(event) => updateRevenueDetails('Commission', event.target.value)}                                                   
                                                />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">%</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <label htmlFor="type">TDS</label>
                                            <div className='input-group'>
                                                <input
                                                    id="tds"
                                                    value={revenueDetails.TDS || 0}
                                                    name="tds"
                                                    className="form-control"
                                                    max='100'
                                                    type="number"
                                                    onChange={(event) => updateRevenueDetails('TDS', event.target.value)}                                                   
                                                />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {targetDetails && targetDetails.length > 0 && (
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
                                                <table className="kt-datatable__table">
                                                    <thead
                                                        className="kt-datatable__head"
                                                        style={{ display: 'block' }}>
                                                        <tr className="kt-datatable__row block-row">
                                                            <th className="kt-datatable__cell text-clip" width="30%">
                                                                <span>Start Range</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip" width="30%">        
                                                                <span>End Range</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip" width="25%">
                                                                <span>Percentage</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip text-center" width="15%">
                                                                <span>Action</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </div>
                                            <div 
                                                className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0"
                                                onScroll={helper.setStickyHeader}>
                                                <table className="kt-datatable__table table-striped">
                                                    <tbody
                                                        className="kt-datatable__body"
                                                        style={{ display: 'block', minHeight: '5vh'}}>
                                                        {targetDetails.map((t, i) => (
                                                            <tr
                                                                key={i}
                                                                className="kt-datatable__row block-row"> 
                                                                <td width="30%" title={t.StartRange} className="kt-datatable__cell text-clip">
                                                                    <span><i className='la la-rupee'/>{t.StartRange}</span>
                                                                </td>
                                                                <td width="30%" title={t.EndRange} className="kt-datatable__cell text-clip">
                                                                    <span><i className='la la-rupee'/>{t.EndRange}</span>
                                                                </td>
                                                                <td width="25%" title={t.TargetPercent} className="kt-datatable__cell text-clip">
                                                                    <span>{t.TargetPercent + '%'}</span>
                                                                </td>
                                                                <td width="15%" className='text-center'>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => removeRangeHandler(i)}
                                                                        className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                                                                    >
                                                                        <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary margin-0 margin-r5"
                                data-dismiss="modal"
                                disabled={btnLoading}>
                                Close
                            </button>
                            <button
                                id="save-btn"
                                onClick={updateRevenueHandler}
                                type="button"
                                className={`btn btn-brand margin-0 ${
                                btnLoading
                                    ? 'kt-spinner kt-spinner--sm kt-spinner--light'
                                    : ''
                                }`}
                                disabled={btnLoading} >                  
                                Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <AddCompany
                type={addModalType}
                addInsuranceCompanyId={setAddedCompanyId}
                addProductId={setAddedProductId}
                from="#edit-revenue-modal"
            />
        </>
    )
}

export default EditRevenueMaster;