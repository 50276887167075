import { axiosPost, axiosGet, axiosPatch } from './axiosFunctions';
import { getTokenOrLogout } from './common';

const getAllClientsUrl                           = '/api/client/getAllClientsByAgentCompany';
const getMainClientsUrl                          = '/api/client/getMainClientsByAgentCompany';
const postClientsUrl                             = '/api/client/postClients';
const verifyEmailUrl                             = (type) => `/api/client/verifyClientEmail/${type}`;
const updateClientUrl                            = (clientId, email) => `/api/client/updateClient/${clientId}/${email}`;
const updateClientWithoutEmailUrl                = (clientId) => `/api/client/updateClient/${clientId}`;
const deactivateClientUrl                        = (clientId) => `/api/client/deactivateClient/${clientId}`;
const deactivateClientGroupUrl                   = (groupId) => `/api/client/deactivateClientGroup/${groupId}`;
const updateClientCompanyUrl                     = (groupId) => `/api/client/updateClientCompany/${groupId}`;
const updateClientGroupUrl                       = (groupId, subAgentId, prevsubAgentId) => `/api/client/updateClientGroup/${groupId}/${subAgentId}/${prevsubAgentId}`;
const updateClientEmailUrl                       = (id, email) => `/api/client/updateClientEmail/${id}/${email}`;
const addClientUrl                               = (groupId, email) => `/api/client/addClient/${groupId}/${email}`;
const getClientsByGroupIDAndCompanyIDUrl         = (groupId) => `/api/client/getClientsByGroupIDAndCompanyID/${groupId}`;
const postBatchClientsUrl                        = '/api/client/postBatchClients';
const getPrimaryClientDataByCompanyIDUrl         = '/api/client/getPrimaryClientDataByCompanyID';
const getAllClientsByAgentIDUrl                  = '/api/client/getAllClientsByAgentID';
const getMainClientsByAgentIDUrl                 = '/api/client/getMainClientsByAgentID';
const deactivateClientByOTPVerificationUrl       = '/api/client/deactivateClientByOTPVerification';


export const getAllClientsByAgentID = async () => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);
  const response = await axiosGet(getAllClientsByAgentIDUrl, {
    headers: { auth_token: `bearer ${token}` },
  });
  return response;
};

export const getMainClientsByAgentID = async () => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);
  const response = await axiosGet(getMainClientsByAgentIDUrl, {
    headers: { auth_token: `bearer ${token}` },
  });
  return response;
};

export const getAllClients = async () => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);
  
  const response = await axiosGet(getAllClientsUrl, {
    headers: { auth_token: `bearer ${token}` },
  });
  return response;
};

export const getMainClients = async () => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);
  
  const response = await axiosGet(getMainClientsUrl, {
    headers: { auth_token: `bearer ${token}` },
  });
  return response;
};

export const getClientsByGroupIDAndCompanyID  = async (groupId) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosGet(
    getClientsByGroupIDAndCompanyIDUrl(groupId), 
    { headers: { auth_token: `bearer ${token}` },
  });
  return response;
};

export const postClients = async (clients, type) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);
  
  const response = await axiosPost(
    postClientsUrl,
    { clients : clients , type : type },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const verifyClientEmail = async (emails, type) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosGet(
    `${verifyEmailUrl(type)}?emails=["${emails.join('","')}"]`,
    { headers: { auth_token: `bearer ${token}` } }   
  );
  return response;
};

export const deactivateClient = async (clientId) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosPatch(
    deactivateClientUrl(clientId),
    null,
    { headers: { auth_token: `bearer ${token}` } }   
  );
  return response;
};

export const deactivateClientGroup = async (groupId) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosPatch(
    deactivateClientGroupUrl(groupId),
    null,
    { headers: { auth_token: `bearer ${token}` } }   
  );
  return response;
};

export const updateClient = async (client) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosPatch(
    client.Email ? updateClientUrl(client.ID, client.Email) : updateClientWithoutEmailUrl(client.ID),
    { ...client },
    { headers: { auth_token: `bearer ${token}` } }   
  );
  return response;
};

export const updateClientCompany = async (groupId, companyName) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosPatch(
    updateClientCompanyUrl(groupId),
    { companyName },
    { headers: { auth_token: `bearer ${token}` } } 
  );
  return response;
};

export const updateClientGroup = async (groupId, subAgentId, prevsubAgentId) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosPatch(
    updateClientGroupUrl(groupId, subAgentId, prevsubAgentId), {},
    { headers: { auth_token: `bearer ${token}` } } 
  );
  return response;
};

export const updateClientEmail = async (id, email) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosPatch(
    updateClientEmailUrl(id, email), {},
    { headers: { auth_token: `bearer ${token}` } } 
  );
  return response;
};

export const addclient = async (client, groupId) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);
  
  const response = await axiosPost(
    addClientUrl(groupId, client.Email),
    { ...client },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const postBatchClients = async (clientList, Type) => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);

  const response = await axiosPost(
    postBatchClientsUrl,
    { clientList, Type },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};
  
export const getPrimaryClientDataByCompanyID = async () => {
  const token = getTokenOrLogout();
  if (!token) setTimeout(() => { return }, 2000);

  const response = await axiosGet(
    getPrimaryClientDataByCompanyIDUrl,
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const deactivateClientByOTPVerification = async (data) => {   
  const token = getTokenOrLogout();
  if (!token) setTimeout(()=> { return }, 2000);  
  
  const response = await axiosPost(`${deactivateClientByOTPVerificationUrl}/${data.Email}`, {data}, {
    headers: { auth_token: `bearer ${token}` },
  });
  return response;
}