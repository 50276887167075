import { axiosPost, axiosGet, axiosDelete, axiosPatch } from "./axiosFunctions";
import { getTokenOrLogout } from "./common";
import { getTempState, getAuthState } from "../../assets/scripts/login-util";

const loginUrl                                              = '/api/agent/login';
const logoutUrl                                             = '/api/agent/logout';
const getAgentsByAgentCompanyIDUrl                          = '/api/agent/getAgentsByAgentCompanyID';
const getAllAgentsByAgentCompanyIDUrl                       = '/api/agent/getAllAgentsByAgentCompanyID';
const agentSignupUrl                                        = '/api/agent/signup';
const resendSignupOTPUrl                                    = '/api/agent/resendSignupOtp';
const referralCodeRequestUrl                                = '/api/agent/referralCodeRequest';
const checkIfAgentExistByEmailUrl                           = '/api/agent/checkIfAgentExistByEmail';
const getStatesAndCitiesUrl                                 = '/api/agent/getStatesAndCities';
const getAgentByTempPasswordUrl                             = '/api/agent/getAgentByTempPassword';
const confirmAgentAccountUrl                                = '/api/agent/confirmAgentAccount';
const changePasswordRequestOtpUrl                           = '/api/agent/changePasswordRequest';
const resendAgentOTPUrl                                     = '/api/agent/resendAgentOTP';
const resetAgentPasswordUrl                                 = '/api/agent/resetAgentPassword';
const verifyAgentEmailUrl                                   = '/api/agent/verifyAgentEmail';
const createAgentUrl                                        = '/api/authRoute/createAgent';
const updateAgentSetupUrl                                   = '/api/agent/updateAgentSetup';
const updateAgentUrl                                        = '/api/agent/updateAgent';
const completeSetupUrl                                      = '/api/agent/completeAgentSetup';
const getAgentByEmailUrl                                    = '/api/authRoute/getAgentByEmail';
const getServerTsUrl                                        = '/api/authRoute/getServerTs';
const updateAgentTeamMemberUrl                              = '/api/agent/updateAgentTeamMember';
const deactivateAgentTeamMemberUrl                          = '/api/agent/deactivateAgentTeamMember';
const addAgentTeamMemberUrl                                 = '/api/agent/addAgentTeamMember';
const updateAgentCompanyUrl                                 = '/api/agent/updateAgentCompany';
const agentEmailChangeRequestUrl                            = '/api/agent/agentEmailChangeRequest';
const VerifyAndUpdateAgentEmailUrl                          = '/api/agent/verifyAndUpdateAgentEmail';
const getAgentSubscriptionUrl                               = '/api/agent/getAgentSubscription';
const getSuperAdminByAgentCompanyUrl                        = '/api/agent/getSuperAdminByAgentCompany';
const getIncentiveCategoryUrl                               = '/api/agent/getIncentiveCategory';
const addIncentiveCategoryUrl                               = '/api/agent/addIncentiveCategory';
const addIncentivePlanUrl                                   = '/api/agent/addIncentivePlan';
const updateIncentivePlanUrl                                = '/api/agent/updateIncentivePlan';
const deleteIncentivePlanUrl                                = (incentivePlanId) => `/api/agent/deleteIncentivePlan/${incentivePlanId}`;
const getIncentivePlanUrl                                   = '/api/agent/getIncentivePlan';
const getIncentiveTargetUrl                                 = (incentivePlanId) => `/api/agent/getIncentivePlanIncentiveTargetByPayoutPlanID/${incentivePlanId}`;
const verifyAgentTeamMemberEmailUrl                         = '/api/agent/verifyAgentTeamMemberEmail';
const getAgentTeamCategoryByAgentIDUrl                      = (agentId) => `/api/agent/getAgentTeamCategoryByAgentID/${agentId}`;
const getAllIncentivePlanIncentiveTargetByAgentCompanyIDUrl = '/api/agent/getAllIncentivePlanIncentiveTargetByAgentCompanyID';
const getAgentCountByAgentCompanyIDUrl                      = '/api/agent/getAgentCountByAgentCompanyID';
const updateAgentsLicenseActiveUrl                          = '/api/agent/updateAgentsLicenseActive';

const googleLoginUrl                                        = '/api/agent/googleLogin';
const googleSignupUrl                                       = '/api/agent/googleSignup';
const extendTempSubscriptionUrl                             = '/api/agent/extendAgentSubscription';
const agentSubscriptionRequestUrl                           = '/api/agent/agentSubscriptionRequest';
const postAgentLicenseExtensionUrl                          = '/api/agent/postAgentLicenseExtension';
const addInsuranceCompanyAccessUrl                          = '/api/agent/addInsuranceCompanyAccess';
const deleteInsuranceCompanyAccessUrl                       = '/api/agent/deleteInsuranceCompanyAccess';
const addProductAccessUrl                                   = '/api/agent/addProductAccess';
const deleteProductAccessUrl                                = '/api/agent/deleteProductAccess';
const addClientAccessUrl                                    = '/api/agent/addClientAccess';
const deleteClientAccessUrl                                 = '/api/agent/deleteClientAccess';
const addSubAgentAccessUrl                                  = '/api/agent/addSubAgentAccess';
const deleteSubAgentAccessUrl                               = '/api/agent/deleteSubAgentAccess';
const getInsuranceCompanyAccessByAdminIDUrl                 = (agentId) => `/api/agent/getInsuranceCompanyAccessByAdminID/${agentId}`;
const getProductAccessByAdminIDUrl                          = (agentId) => `/api/agent/getProductAccessByAdminID/${agentId}`;
const getClientAccessByAdminIDUrl                           = (agentId) => `/api/agent/getClientAccessByAdminID/${agentId}`;
const getSubAgentAccessByAdminIDUrl                         = (agentId) => `/api/agent/getSubAgentAccessByAdminID/${agentId}`;
const getPolicyAccessByAgentIDUrl                           = (agentId) => `/api/agent/getPolicyAccessByAgentIDData/${agentId}`;
const addAllInsuranceCompanyAccessUrl                       = '/api/agent/addAllInsuranceCompanyData';
const deleteAllInsuranceCompanyAccessUrl                    = '/api/agent/deleteAllInsuranceCompanyData';
const addAllProductAccessUrl                                = '/api/agent/addAllProductData';
const deleteAllProductAccessUrl                             = '/api/agent/deleteAllProductData';
const addAllClientsAccessUrl                                = '/api/agent/addAllClientsData';
const deleteAllClientsAccessUrl                             = '/api/agent/deleteAllClientsData';
const addAllSubAgentAccessUrl                               = '/api/agent/addAllSubAgentData';
const deleteAllSubAgentAccessUrl                            = '/api/agent/deleteAllSubAgentData';
const addPolicyAccessUrl                                    = '/api/agent/addPolicyAccessData';
const deletePolicyAccessUrl                                 = '/api/agent/deletePolicyAccessData';
const getSubscriptionUrl                                    = (companyId) => `/api/agent/getSubscription/${companyId}`;
const deleteLicenseReminderByAgentCompanyIDUrl              = '/api/agent/deleteLicenseReminderByAgentCompanyID';
const activateAgentLicenseActiveUrl                         = '/api/agent/activateAgentLicenseActive';


export const googleLoginAPI = async (param) => {
    const response = await axiosPost(googleLoginUrl, param);
    return response;
}
export const googleSignupAPI = async (param) => {
    const response = await axiosPost(googleSignupUrl, param);
    return response;
}

export const loginAPI = async (param) => {
    const response = await axiosPost(
        `${loginUrl}/${param.email}`, 
        { ...param }, {headers: ''});
    return response;
}

export const logoutAPI = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(()=> { return window.open('/', '_self'); }, 2000);
    
    const response = await axiosDelete(logoutUrl, {
        headers: { auth_token: `bearer ${token}` }
    });
    return response;
}

export const getAgentsByAgentCompanyID = async () => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosGet(getAgentsByAgentCompanyIDUrl, {
        headers: { auth_token: `bearer ${token}` }
    });
    return response;
};

export const getAllAgentsByAgentCompanyID = async () => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosGet(getAllAgentsByAgentCompanyIDUrl, {
        headers: { auth_token: `bearer ${token}` }
    });
    return response;
};

export const getStatesAndCities = async () => {
    const response = await axiosGet(getStatesAndCitiesUrl, {headers: ''})
    return response;
}

export const agentSignup = async (agent) => {
    const response = await axiosPost(
        `${agentSignupUrl}/${agent.Email}`, 
        { agent });
    return response;
}

export const verifyAgentEmail = async (agent) => {    
    const response = await axiosPost(
        `${verifyAgentEmailUrl}/${agent.Email}`,
         { agent });
    return response;
}

export const resendSignupOTP = async (email) => {    
    const response = await axiosPost(resendSignupOTPUrl, { email });
    return response;
}

export const checkIfAgentExistByEmail = async (email) => {
    const response = await axiosGet(`${checkIfAgentExistByEmailUrl}/${email}`)
    return response;
}

export const sendReferralCodeRequest = async (sender) => {    
    const response = await axiosPost( referralCodeRequestUrl, { sender });
    return response;
}

export const changePasswordRequest = async (email) => {
    const response  = await axiosPost(changePasswordRequestOtpUrl, { email } );
    return response;
}

export const resendAgentOTP = async (data) => {    
    const response = await axiosPost(resendAgentOTPUrl, { data });
    return response;
}

export const resetAgentPassword = async (data) => {    
    const response = await axiosPost(resetAgentPasswordUrl, { data });
    return response;
}

export const getAgentByTempPassword= async (uid)=>{
    const response = await axiosGet(`${getAgentByTempPasswordUrl}/${uid}`)
    return response;
}

export const confirmAgentAccount =  async (data) => {
    const response = await axiosPost(confirmAgentAccountUrl,{ data } );
    return response;
}

export const getAgentByEmail = async (email, skipTemp) => {
    let token ;
    
    if( !skipTemp ){
        token = getTempState('token');
        if (!token) return;
    }else{
        token = getTokenOrLogout();
        if(!token) setTimeout(() => { return}, 2000);
    }
    
    const response = await axiosGet(
        `${getAgentByEmailUrl}/${email}`,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
 }

export const createAgent = async (agent, agentCompany, Device) => {
    const token = getTempState('token');
    if (!token) return;

    const response  = await axiosPost( 
        `${createAgentUrl}/${agent.Email}`, 
        { agent, agentCompany, Device },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
}

export const updateAgentSetup =  async (agent, agentCompany) => {
    const token = getTempState('token');
    if (!token) return;
    
    const response = await axiosPatch(
        updateAgentSetupUrl,
        { agent, agentCompany },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
}

export const completeAgentSetup = async ( agents, device) => {
    const token = getTempState('token');
    if (!token) return;

    const response = await axiosPost(
        completeSetupUrl, 
        { agents, device },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
}

export const getServerTs = async () => {
    const response = await axiosGet(getServerTsUrl, {headers: ''});
    return response;
}

export const updateAgentTeamMember = async (agent) => {
    const token = getTokenOrLogout();
    if(!token) setTimeout(() => { return}, 2000);

    const response = await axiosPatch(
        `${updateAgentTeamMemberUrl}/${agent.ID}/${agent.Email}`,
        { ...agent },
        { headers: { auth_token: `bearer ${token}` } }   
    );
    return response;
}

export const deactivateAgentTeamMember = async (agentId) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);

    const response = await axiosPatch(
     `${deactivateAgentTeamMemberUrl}/${agentId}`,
      null,
      { headers: { auth_token: `bearer ${token}` } }   
    );
    return response;
};

export const addAgentTeamMember = async (agent) => {
    const token = getTokenOrLogout();
    if(!token) setTimeout(() => { return }, 2000);

    const response = await axiosPost(
        `${addAgentTeamMemberUrl}/${agent.Email}`, 
        { agent },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
}

export const updateAgent =  async (agent) => {
    const token = getTokenOrLogout();
    if(!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPatch(
        updateAgentUrl,
        { agent },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
}

export const updateAgentCompany =  async (agentCompany) => {
    const token = getTokenOrLogout();
    if(!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPatch(
        updateAgentCompanyUrl,
        { agentCompany },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
}

export const agentEmailChangeRequest =  async (email) => {
    const token = getTokenOrLogout();
    if(!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        agentEmailChangeRequestUrl,
        { email },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
}
export const VerifyAndUpdateAgentEmail =  async (data) => {
    const token = getTokenOrLogout();
    if(!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        VerifyAndUpdateAgentEmailUrl,
        { data },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
}

export const getAgentSubscription = async () => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosGet(getAgentSubscriptionUrl, {
        headers: { auth_token: `bearer ${token}` }
    });
    return response;
};

export const sendAgentSubscriptionRequest = async () => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosPost(agentSubscriptionRequestUrl,{},{
        headers: { auth_token: `bearer ${token}` }
    });
    return response;
};

export const postAgentLicenseExtension = async () => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosPost(postAgentLicenseExtensionUrl,{},{
        headers: { auth_token: `bearer ${token}` }
    });
    return response;
};

export const extendTempSubscription = async () => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(() => { return }, 2000);

    const response = await axiosPatch(
        extendTempSubscriptionUrl,
        {},
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const addInsuranceCompanyAccess = async (agentId, insuranceCompanyId) => {
    const token = getTokenOrLogout();
    if(!token) setTimeout(() => { return }, 2000);

    const response = await axiosPost(
        addInsuranceCompanyAccessUrl, 
        { agentId, insuranceCompanyId },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
}

export const deleteInsuranceCompanyAccess = async (agentId, insuranceCompanyId) => {
    const token = getTokenOrLogout();
    if(!token) setTimeout(() => { return }, 2000);

    const response = await axiosPost(
        deleteInsuranceCompanyAccessUrl, 
        { agentId, insuranceCompanyId },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
}

export const addProductAccess = async (agentId, ProductID) => {
    const token = getTokenOrLogout();
    if(!token) setTimeout(() => { return }, 2000);

    const response = await axiosPost(
        addProductAccessUrl, 
        { agentId, ProductID },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
}

export const deleteProductAccess = async (agentId, ProductID) => {
    const token = getTokenOrLogout();
    if(!token) setTimeout(() => { return }, 2000);

    const response = await axiosPost(
        deleteProductAccessUrl, 
        { agentId, ProductID },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
}

export const addClientAccess = async (agentId, ClientGroupID) => {
    const token = getTokenOrLogout();
    if(!token) setTimeout(() => { return }, 2000);

    const response = await axiosPost(
        addClientAccessUrl, 
        { agentId, ClientGroupID },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
}

export const deleteClientAccess = async (agentId, ClientGroupID) => {
    const token = getTokenOrLogout();
    if(!token) setTimeout(() => { return }, 2000);

    const response = await axiosPost(
        deleteClientAccessUrl, 
        { agentId, ClientGroupID },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
}

export const addSubAgentAccess = async (agentId, SubAgentID) => {
    const token = getTokenOrLogout();
    if(!token) setTimeout(() => { return }, 2000);

    const response = await axiosPost(
        addSubAgentAccessUrl, 
        { agentId, SubAgentID },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
}

export const deleteSubAgentAccess = async (agentId, SubAgentID) => {
    const token = getTokenOrLogout();
    if(!token) setTimeout(() => { return }, 2000);

    const response = await axiosPost(
        deleteSubAgentAccessUrl, 
        { agentId, SubAgentID },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
}

export const getInsuranceCompanyAccessByAdminID = async (agentId) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    const response = await axiosGet(
        getInsuranceCompanyAccessByAdminIDUrl(agentId), 
        { headers: { auth_token: `bearer ${token}` },
    });
    return response;
}; 

export const getProductAccessByAdminID = async (agentId) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    const response = await axiosGet(
        getProductAccessByAdminIDUrl(agentId), 
        { headers: { auth_token: `bearer ${token}` },
    });
    return response;
};   

export const getClientAccessByAdminID = async (agentId) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    const response = await axiosGet(
        getClientAccessByAdminIDUrl(agentId), 
        { headers: { auth_token: `bearer ${token}` },
    });
    return response;
}; 

export const getSubAgentAccessByAdminID = async (agentId) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    const response = await axiosGet(
        getSubAgentAccessByAdminIDUrl(agentId), 
        { headers: { auth_token: `bearer ${token}` },
    });
    return response;
};    

export const getPolicyAccessByAgentID = async (agentId) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    const response = await axiosGet(
        getPolicyAccessByAgentIDUrl(agentId), 
        { headers: { auth_token: `bearer ${token}` },
    });
    return response;
};    

export const addAllInsuranceCompanyAccess = async (agentId) => {
    const token = getTokenOrLogout();
    if(!token) setTimeout(() => { return}, 2000);
    const response = await axiosPatch(
        `${addAllInsuranceCompanyAccessUrl}/${agentId}`,
        {},
        { headers: { auth_token: `bearer ${token}` } }   
    );
    return response;
}

export const deleteAllInsuranceCompanyAccess = async (agentId) => {
    const token = getTokenOrLogout();
    if(!token) setTimeout(() => { return}, 2000);
    const response = await axiosPatch(
        `${deleteAllInsuranceCompanyAccessUrl}/${agentId}`,
        {},
        { headers: { auth_token: `bearer ${token}` } }   
    );
    return response;
} 

export const addAllProductAccess = async (agentId) => {
    const token = getTokenOrLogout();
    if(!token) setTimeout(() => { return}, 2000);
    const response = await axiosPatch(
        `${addAllProductAccessUrl}/${agentId}`,
        {},
        { headers: { auth_token: `bearer ${token}` } }   
    );
    return response;
}

export const deleteAllProductAccess = async (agentId) => {
    const token = getTokenOrLogout();
    if(!token) setTimeout(() => { return}, 2000);
    const response = await axiosPatch(
        `${deleteAllProductAccessUrl}/${agentId}`,
        {},
        { headers: { auth_token: `bearer ${token}` } }   
    );
    return response;
}

export const addAllClientsAccess = async (agentId) => {
    const token = getTokenOrLogout(); 
    if(!token) setTimeout(() => { return}, 2000);
    const response = await axiosPatch(
        `${addAllClientsAccessUrl}/${agentId}`,
        {},
        { headers: { auth_token: `bearer ${token}` } }   
    );
    return response;
}

export const deleteAllClientsAccess = async (agentId) => {
    const token = getTokenOrLogout();
    
    if(!token) setTimeout(() => { return}, 2000);
    const response = await axiosPatch(
        `${deleteAllClientsAccessUrl}/${agentId}`,
        {},
        { headers: { auth_token: `bearer ${token}` } }   
    );
    return response;
}

export const deletePolicyAccess = async (agentId) => {
    const token = getTokenOrLogout();
    
    if(!token) setTimeout(() => { return}, 2000);
    const response = await axiosPatch(
        `${deletePolicyAccessUrl}/${agentId}`,
        {},
        { headers: { auth_token: `bearer ${token}` } }   
    );
    return response;
}

export const addAllSubAgentAccess = async (agentId) => {
    const token = getTokenOrLogout();
    
    if(!token) setTimeout(() => { return}, 2000);
    const response = await axiosPatch(
        `${addAllSubAgentAccessUrl}/${agentId}`,
        {},
        { headers: { auth_token: `bearer ${token}` } }   
    );
    return response;
}

export const deleteAllSubAgentAccess = async (agentId) => {
    const token = getTokenOrLogout();
    
    if(!token) setTimeout(() => { return}, 2000);
    const response = await axiosPatch(
        `${deleteAllSubAgentAccessUrl}/${agentId}`,
        {},
        { headers: { auth_token: `bearer ${token}` } }   
    );
    return response;
}

export const addPolicyAccess = async (agentId) => {
    const token = getTokenOrLogout();
    
    if(!token) setTimeout(() => { return}, 2000);
    const response = await axiosPatch(
        `${addPolicyAccessUrl}/${agentId}`,
        {},
        { headers: { auth_token: `bearer ${token}` } }   
    );
    return response;
}

export const getSubscription = async (companyId) => {
    const response = await axiosGet(getSubscriptionUrl(companyId));
    return response;
};

export const getSuperAdminByAgentCompany = async () => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosGet(getSuperAdminByAgentCompanyUrl, {
        headers: { auth_token: `bearer ${token}` }
    });
    return response;
};

export const getIncentiveCategory = async () => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  
    
    const response = await axiosGet(getIncentiveCategoryUrl, {
        headers: { auth_token: `bearer ${token}` },
    });
    return response;
};

export const addIncentiveCategory = async (Name) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  
    
    const response = await axiosPost(
        addIncentiveCategoryUrl,
        { Name },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
    
};

export const addIncentivePlan = async (incentive) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    const response = await axiosPost(
        addIncentivePlanUrl,
        { incentive },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const updateIncentivePlan = async (incentive) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    const response = await axiosPost(
        updateIncentivePlanUrl,
        { incentive },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const deleteIncentivePlan = async (incentivePlanId) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  
    
    const response = await axiosDelete(
        deleteIncentivePlanUrl(incentivePlanId),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getIncentivePlan = async (from) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  
  
    const response = await axiosPost(getIncentivePlanUrl, { from }, {
        headers: { auth_token: `bearer ${token}` },
    });
    return response;
};  


export const getIncentivePlanIncentiveTargetByPayoutPlanID = async (incentivePlanId) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  
  
    const response = await axiosPost(getIncentiveTargetUrl(incentivePlanId), { }, {
        headers: { auth_token: `bearer ${token}` },
    });
    return response;
};

export const verifyAgentTeamMemberEmail = async (agent) => {
    const token = getTokenOrLogout();
    if(!token) setTimeout(() => { return }, 2000);

    const response = await axiosPost(
        `${verifyAgentTeamMemberEmailUrl}/${agent.ID}/${agent.Email}`, {},
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
}

export const getAgentTeamCategoryByAgentID = async (agentId) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000); 
    const response = await axiosGet(
        getAgentTeamCategoryByAgentIDUrl(agentId),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getAllIncentivePlanIncentiveTargetByAgentCompanyID = async () => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  
  
    const response = await axiosPost(getAllIncentivePlanIncentiveTargetByAgentCompanyIDUrl, {}, {
        headers: { auth_token: `bearer ${token}` },
    });
    return response;
};  

export const getAgentCountByAgentCompanyID = async () => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosGet(getAgentCountByAgentCompanyIDUrl, {
        headers: { auth_token: `bearer ${token}` }
    });
    return response;
};

export const deleteLicenseReminderByAgentCompanyID = async () => {
    const token = getTokenOrLogout();
    if(!token) setTimeout(() => { return }, 2000);

    const response = await axiosPost(
        deleteLicenseReminderByAgentCompanyIDUrl,{ } ,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
}

export const activateAgentLicenseActive = async (agentId) => {
    const token = getTokenOrLogout(); 
    if(!token) setTimeout(() => { return}, 2000);
    const response = await axiosPatch(
        `${activateAgentLicenseActiveUrl}/${agentId}`,
        {},
        { headers: { auth_token: `bearer ${token}` } }   
    );
    return response;
}

export const updateAgentsLicenseActive =  async (agent) => {
    const token = getTokenOrLogout();
    if(!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPatch(
        updateAgentsLicenseActiveUrl,
        { agent },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
}