import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { deletePolicyDocument, getPolicyDocuments } from '../api/policyAPI';
import NoData from '../global/noData';
import FileImg from '../../assets/images/no-file.svg'
import * as helper from '../global/helper';
import Loading from '../global/loading';
import swal from 'sweetalert2';
import { deleteSubAgentPolicyDocument, getSubAgentPolicyDocuments } from '../api/subAgencyAPI';

const PolicyDocuments = (props) => {

    const [loading,         setLoading]         = useState(false);
    const [document,        setDocument]        = useState([]);

    useEffect(() => {
        fetchdocument();
    }, [props.refresh]);

    useEffect(() => {
        $('[data-toggle="kt-popover"]').popover();
        helper.SetScrollHeight();
    });

    const fetchdocument = async () => {
        try {
            setLoading(true)
            // const response = props.from == 'subAgentPolicyStats' ? await getSubAgentPolicyDocuments(props.policyDetails.ID) : await getPolicyDocuments(props.policyDetails.ID)
            const response = await getPolicyDocuments(props.policyDetails.ID)
            setDocument(response);
            setLoading(false);
        } catch (err) {
            swal.fire({
                icon              : 'error',
                titleText         : 'Error!',
                text              : err.message,
                buttonsStyling    : false,
                confirmButtonClass: 'btn btn-brand',
            });
        }
    }

    const openDocument = async (d) => {
        const ext = helper.getFileExt(d.FileName)
        const name = (d.DocumentName + '.' + ext).replace(/ /g, '_');
        helper.downloadAndOpenFileHandler(d.DocumentType, d.FileName, name);
    }

    const deleteDocumentHandler = async (d) => {
        const swalResult = await swal.fire({
            title               : 'Are you sure?',
            text                : "You won't be able to revert this!",
            icon                : 'warning',
            showCancelButton    : true,
            buttonsStyling      : false,
            reverseButtons      : true,
            showLoaderOnConfirm : true,
            confirmButtonClass  : 'btn btn-brand',
            cancelButtonClass   : 'btn btn-secondary',
            confirmButtonText   : 'Delete',
            preConfirm: async () => {
              try {
                props.from == 'subAgentPolicyStats' ? await deletePolicyDocument({
                    ID              : d.ID,
                    PolicyID        : d.SubAgentPolicyID,
                }) : await deletePolicyDocument({
                    ID              : d.ID,
                    PolicyID        : d.PolicyID,
                })
                return;
              } catch (err) {
                swal.fire({
                  icon                : 'error',
                  titleText           : 'Error!',
                  text                : err.message,
                  buttonsStyling      : false,
                  confirmButtonClass  : 'btn btn-brand',
                });
                return;
              }
            },
        });
        if (!swalResult.value) return;
        setDocument((a) => {
            const nA = a.filter((b) => b.ID != d.ID)
            return nA
        });
        swal.fire({
            icon              : 'success',
            titleText         : 'Document Deleted Successfully',
            showConfirmButton : false,
            timer             : 1500
        });
    }

    return (
        <React.Fragment>
            {loading ? <Loading/> : (
                document.length > 0 ? (
                    <div className="row">
                        <div className="col-12">
                            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded scrolTable" onScroll={helper.setStickyHeader}>
                                <table className="kt-datatable__table table-striped">
                                    <tbody className="kt-datatable__body" style={{ display: 'block' }}>
                                        {document.map((d, i) => {
                                            return(
                                                <tr key={d.ID} className="kt-datatable__row block-row">
                                                    <td title={d.DocumentName} className="kt-datatable__cell" width='80%' onClick={() => openDocument(d)}>
                                                        <div className="kt-notification text-clip">
                                                            <a className="kt-notification__item padding-0">
                                                                <div className="kt-notification__item-icon margin-r10">
                                                                    <img height="30px" width="30px" src={helper.getFileLogo(d.FileName)} />
                                                                </div>
                                                                <div className="kt-notification__item-details text-clip">
                                                                    <div className="kt-notification__item-title kt-font-bold text-clip">
                                                                        {d.DocumentName}
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </td>
                                                    <td className="kt-datatable__cell text-clip text-right" width="20%">
                                                        {d.DocumentType != 'AgentFile' && (
                                                            <button 
                                                                type="button"
                                                                className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                                                                data-toggle="kt-popover"
                                                                data-trigger="hover"
                                                                data-placement="left"
                                                                data-content="Edit Document"                
                                                                onClick={() => props.setEditDocument(d)}>  
                                                                <i className="flaticon2-edit"></i>
                                                            </button>
                                                        )}
                                                        <button
                                                            type="button"
                                                            onClick={() => deleteDocumentHandler(d)}
                                                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                                                            data-toggle="kt-popover"
                                                            data-trigger="hover"
                                                            data-placement="left"
                                                            data-content="Delete Document">
                                                            <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                ) : (
                    <NoData
                        src={FileImg}
                        alt="File"
                        message="No Documents Found"
                    />
                )
            )}
        </React.Fragment>
    );
};

export default PolicyDocuments;