import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import $ from 'jquery';
import 'jquery-form';
import 'jquery-validation';
import '../../assets/scripts/jquery-validation.init';
import * as helper from '../global/helper';
import swal from 'sweetalert2';
import { setAuthState, setTempState } from '../../assets/scripts/login-util';
import { checkVersion } from '../../assets/scripts/versionComparer';
import { deviceDetect } from "react-device-detect";
import { loginAPI, googleLoginAPI, getSubscription } from  "../api/agentAPI";
import { googleLogin } from './loginHelper';


const SignIn = (props) => {
  const googleErrorLogs = ['auth/popup-closed-by-user', 'auth/popup-blocked', 'auth/cancelled-popup-request'];
  const history                           = useHistory();
  const [loginData,     setLoginData]     = useState({ isremember: true });
  const [showPassword,  setShowPassword]  = useState(false);
  const [loading,  setLoading]            = useState(false);
  
  useEffect(() => {
    document.title = "Sign In | Insurebox.co"
    document.getElementsByTagName('meta')["description"].content = "Login to insurebox insurance agency management software dashboard to manage the data."
  })

  const showPasswordHandler = () => setShowPassword(!showPassword);

  const loginDataHandler = (key, value)=>{
    setLoginData((d)=> { return {...d, [key] : value} });
  }

  const submitHandler = async (e) => {
    e.preventDefault();

    const btn = $('#kt_login_signin_submit');
    const form = $('#kt_login_signin');

    form.validate({
      rules: {
        email: {
          required: true,
          email: true,
        },
        password: {
          required: true,
        },
      },
    });

    if (!form.valid()) return;

    helper.StartProcessing(btn);
    try {
      const deviceData = deviceDetect();
      loginData.device = await helper.getDeviceDetails(deviceData);

      // Call Login API
      const params = await loginAPI(loginData);
      const subscriptionDetail = await getSubscription(params.user.agentCompanyId);
      if (params.user.categoryId != 1 && subscriptionDetail.Team == 'N') {
        swal.fire({
          icon                  : 'error',
          titleText             : 'Error!',
          text                  : "Minimum 3 License Required",
          buttonsStyling        : false,
          confirmButtonClass    : 'btn btn-brand',   
        });
        // setBtnLoading(false);
        helper.StopProcessing(btn);
        return;
      }
      if(params.emailVerification){
        const stateObj = {
          Email     : loginData.email,
          isRemember: loginData.isremember,
          device    : loginData.device,
          ...params
        }

        history.push({ pathname: '/verify/email', state: stateObj });
        return;
      }
      
      if(params.user){
        //Set Auth State
        setAuthState(params, loginData.isremember);
        
        //Call CustomFunction to Register CookieChange Event ->
      
        window.cookieChangeHandler();

        checkVersion(params.FE_VERSION);
        props.onLogin();
      }else{
        // Create a Session Cookie 
        setTempState(params); 
        props.onLogin('created');
      }

    } catch (err) {
      helper.StopProcessing(btn);
      if(err.message == 'Invalid password'){
        swal.fire({ 
          icon              : "warning",
          titleText         : "Create a password",
          text              : "Since you've signed up with Google, you don't have a password.",
          confirmButtonText: 'Create Password',
          showCancelButton:true,
          confirmButtonClass: "btn btn-brand mt-0",
        }).then((result)=>{
          if (result.isConfirmed) {
            history.push('/forgot-password', {createPassword : true});
          } 
        })
      }else{
        swal.fire({
          icon              : "error",
          titleText         : "Error!",
          text              : err.message,
          buttonsStyling    : false,
          confirmButtonClass: "btn btn-brand",
        })
      }

      return; 
    }
  };

  const onGoogleLogin = async() => {
    const deviceData = deviceDetect();
    const btn = $('#kt_login_signin_google');
    try {
      setLoading(true)
      helper.StartProcessing(btn)
      const IdToken = await googleLogin();
      if(IdToken){
        const device = await helper.getDeviceDetails(deviceData);
        const params = await googleLoginAPI({
          googleTokenId : IdToken,
          device,
          isremember : loginData.isremember,
        });

        if(params.user){
          //Set Auth State
          setAuthState(params, loginData.isremember);
          checkVersion(params.FE_VERSION);
          props.onLogin();
        }else{
          // Create a Session Cookie for temp user
          setTempState(params); 
          props.onLogin('created');
        }
      }
    } catch (err) {
      setLoading(false)
      helper.StopProcessing(btn); 
      if(googleErrorLogs.includes(err.message) ) return;
      let titleText = err.message == 'User does not exist'?'Please SignUp' : 'Error!';
      swal.fire({
        icon              : "error",
        titleText         : titleText,
        text              : err.message,
        buttonsStyling    : false,
        confirmButtonClass: "btn btn-brand",
      });
    } 
  }

  return (
    <div className="kt-login__signin">
      <div className="kt-login__head">
        <h3 className="kt-login__title">Sign In</h3>
      </div>
      <form className="kt-form" id="kt_login_signin" >
        <div className="input-group">
          <input
            onChange={({ target }) => loginDataHandler('email', target.value)}
            value={ loginData.email || '' }
            className="form-control form-control-lg"
            type="email"
            placeholder="Email"
            name="email"
            autoComplete="off"
          />
        </div>
        <div className="input-group">
          <div className="kt-input-icon kt-input-icon--right">
            <input
              onChange={({ target }) => loginDataHandler('password', target.value)}
              value={loginData.password || ''}
              className="form-control form-control-lg"
              minLength="6"
              type={ showPassword ? 'text' : 'password' } 
              placeholder="Password"
              name="password"
            />
            <span className="kt-input-icon__icon kt-input-icon__icon--right show-password-icon" onClick={showPasswordHandler} >
              <span>  <i className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}></i> </span>
            </span>
          </div>
        </div>
        <div className="margin-t20">	
          <label className="kt-checkbox kt-checkbox--tick kt-checkbox--blue">
            <input type="checkbox" 
              name="rememberme"
              defaultChecked={ loginData.isremember ? "checked" : "" }
              onChange={() => loginDataHandler("isremember", !loginData.isremember)}
            /> Remember Me 
            <span></span>
          </label>
        </div>
        
        <div className="kt-login__actions">
          <button
            type="button"
            onClick={submitHandler}
            disabled={loading}
            id="kt_login_signin_submit"
            className="btn btn-theme btn-elevate btn-block kt-login__btn-primary"
          >
            Sign In
          </button>
        </div>
        <div className="kt-login__actions text-center margin-t0 google-Btn-lg">
        <h6 className="text-center  margin-tb10" > OR </h6>
          <button
            type="button"
            onClick={onGoogleLogin}
            disabled={loading}
            id="kt_login_signin_google"
            className="btn btn-elevate btn-block kt-login__btn btn-light"
          >
            {helper.googleBtn}<span className="ml-1" > Sign In with Google</span>
          </button>
        </div>
        <div className="kt-login__account margin-t20">
          <Link
            to="/forgot-password"
            id="kt_login_forgot"
            className="kt-login__account-link"
          >
            Forget Password ?
          </Link>
        </div>
        <div className="kt-login__account margin-t10">
          <span className="kt-login__account-msg">
            Don't have an account yet ?
          </span>
          &nbsp;&nbsp;
          <Link
            to="/signup"
            id="kt_login_signup"
            className="kt-login__account-link"
          >
            Sign Up!
          </Link>
        </div>
      </form>
    </div>
  );
};

export default SignIn;
