import { useState } from "react";
import { deleteLicenseReminderByAgentCompanyID, getAgentSubscription, getAllAgentsByAgentCompanyID, updateAgentsLicenseActive } from "../api/agentAPI";
import { useEffect } from "react";
import swal from 'sweetalert2';

const LicenseChangeReminder = (props) =>{

    const [members,                 setMembers]                 = useState([]);
    const [btnLoading,              setBtnLoading]              = useState(false);
    const [availableLicense,        setAvailableLicense]        = useState();



    useEffect(() => {
        fetchAgentMembers();
		$('#licenseChangeReminder-modal').modal({
			backdrop: 'static',
		});
		$('#licenseChangeReminder-modal').on('hidden.bs.modal', function () {
			$('.modal').hasClass('show') && $('body').addClass('modal-open');
            props.onDismissModal('LicenseChangeReminder', false);
		});
	}, [])

    const onEditHandler = (value, checked) => {
        if(checked){
            setAvailableLicense(availableLicense - 1)
        }else{
            setAvailableLicense(availableLicense + 1)
        }
        setMembers(members.map(m => {
            if (m.ID == value) {
                m.Active = checked ? 'Y' : 'N';
                m.LicenseActive = checked ? 'Y' : 'N';
            }
            return {...m}
        }))
    }
    
    const updateTeamMember = async(e) =>{
        // e.preventDefault();
        // if(addMemberData){

        // }
        try {
            setBtnLoading(true)
            const agentSubscriptionData = await getAgentSubscription();
            if((agentSubscriptionData.License - 1) < members.filter((a) => a.Active == 'Y' && a.LicenseActive == 'Y').length){
                swal.fire({
                    icon                  : 'warning',
                    titleText             : `You can add only ${agentSubscriptionData.License - 1} Members`,
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',   
                });
                setBtnLoading(false)
                return
            }
            await updateAgentsLicenseActive(members)
            await deleteLicenseReminderByAgentCompanyID()
            $('#licenseChangeReminder-modal').modal('hide'); 
            window.location.reload(true)
            setBtnLoading(false)
        } catch (err) {
            setBtnLoading(false)
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        }
    }

    const fetchAgentMembers = async () => {
        try {
            const memberList = await getAllAgentsByAgentCompanyID();
            setMembers(memberList.filter((f) => f.CategoryID !==1));
            const activeUser = memberList.filter((a) =>   a.Active == 'Y' && a.LicenseActive == 'Y').length
            const agentSubscriptionData = await getAgentSubscription();
            setAvailableLicense(agentSubscriptionData.License - activeUser)
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });  
            return; 
        }
    }
    return(
        <>
            <div className="modal fade" id="licenseChangeReminder-modal" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Update Team Members</h5>
                            {/* <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                // disabled={btnLoading}
                            >
                            </button> */}
                             <a className=" margin-r20 margin-t5" style={{fontSize:'12px',fontWeight: '600'}}>
                                <i className="flaticon-file-2 margin-r5"></i>
                                Available License : {availableLicense}
                            </a>
                        </div>
                        <div className="modal-body pt-4">
                            <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v3__wrapper">
                                <p style={{ fontWeight: '20px' }}>
                                    Your License has been reduced kindly select active Team Members.
                                </p>
                            </div>
                            <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v3__wrapper">
                                <table className="kt-datatable__table table-striped">
                                    <tbody className="kt-datatable__body" style={{display: 'block'}}>
                                        {(members && members.length > 0) && members.map((c,i) => {
                                            return(
                                                <tr key={c.ID} 
                                                    className="kt-datatable__row block-row">
                                                    <td width="77%"  className={`kt-datatable__cell text-clip ${availableLicense  == 0 && c.Active == 'N' && c.LicenseActive == 'N' && 'rowdisabled' }`}>
                                                        <div className="kt-user-card-v2">
                                                            <div className="kt-user-card-v2__pic">
                                                                <div className={`kt-badge kt-badge--xl kt-badge--${c.CategoryID ==  2 ? 'success' : 'info '} `}>
                                                                    {c.FirstName && c.FirstName.slice(0, 1)}
                                                                </div>
                                                            </div>
                                                            <div className="kt-user-card-v2__details text-clip">
                                                                <span className="kt-user-card-v2__name text-clip">
                                                                {`${c.FirstName} ${c.LastName}`}
                                                                </span>
                                                                <span className="kt-user-card-v2__desc text-clip" >
                                                                    {c.Email}
                                                                </span>     
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td width={ "25%"  } className={`kt-datatable__cell ${availableLicense  == 0 && c.Active == 'N' && c.LicenseActive == 'N' && 'rowdisabled' }`}>
                                                        <div className={`kt-badge kt-badge--${c.CategoryID ==  2 ? 'success' : 'info '}  kt-badge--inline kt-badge--pill kt-badge-pill-width `}>
                                                            <span>{ c.CategoryID == 2 ? 'Admin' : 'Member'}</span>
                                                        </div>
                                                    </td>
                                                    <td
                                                        className={`kt-datatable__cell--right kt-datatable__cell ${availableLicense  == 0 && c.Active == 'N' && c.LicenseActive == 'N' && 'rowdisabled' }`}
                                                        width="15%" >
                                                        <span className="kt-switch kt-switch--icon margin-t10">
                                                            <label>
                                                                <input                                                                                  
                                                                    id                      = 'checkBoxActive'
                                                                    type                    = "checkbox"
                                                                    className               = "checkbox"
                                                                    name                    = {`active${c.ID}`}
                                                                    defaultChecked          = {c.LicenseActive == 'Y' && c.Active == 'Y' ? 'checked' : '' }                                                                                       
                                                                    onClick                 ={({target}) => onEditHandler(target.value, target.checked)}
                                                                    value                   = {c.ID || '' }
                                                                /> 
                                                                <span></span>
                                                            </label>        
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            {/* <button
                                type="button"
                                className="btn btn-secondary margin-0 margin-r5"
                                data-dismiss="modal"
                                disabled={btnLoading}
                                >
                                Close
                            </button> */}
                            <button
                                id="save-btn"
                                onClick={updateTeamMember}
                                type="button"
                                className={`btn btn-brand margin-0 ${
                                btnLoading
                                    ? 'kt-spinner kt-spinner--sm kt-spinner--light'
                                    : ''
                                }`}
                                disabled={btnLoading} 
                                >                  
                                Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LicenseChangeReminder;