import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import 'jquery-form';
import 'jquery-validation';
import '../../assets/scripts/bootstrap-modal';
import '../../assets/scripts/jquery-validation.init';
import * as helper from '../global/helper';
import swal from 'sweetalert2';
import { 
  addInsuranceCompany,
  addProduct
} from '../api/policyAPI';

const AddCompany = (props) => {
    const [data, setData] = useState({ Name: '', FullName: '' });

    useEffect(() => {
        let ignore = false;
        if (!ignore) {
          $('#add-company-modal').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
                $(props.from).removeClass('modal-blur');
                var form = $('#add-company-form');
                form.clearForm();
                form.validate().resetForm();
                setData({ Name: '', FullName: '' })
          });
        }
        return () => {
          ignore = true;
        };
    }, []);

    const onClose = () => {
      $(props.from).removeClass('modal-blur');
    }

    const addCompany = async (e) => {
      e.preventDefault();
  
      var form = $('#add-company-form');
      form.validate().destroy();
  
      form.validate({
        rules: {
          shortName: {
            required: true,
          },
          fullName: {
            required: true,
          }
        },
      });
  
      if (!form.valid()) {
        return;
      }
  
      try {
        helper.StartProcessing($('#save-company'));
  
        if (props.type === 'Company') {
          const ID = await addInsuranceCompany(data.Name, data.FullName);
          props.addInsuranceCompanyId(ID);
        } else {
          const ID = await addProduct(data.Name, data.FullName);
          props.addProductId(ID);
        }
  
      } catch (err) {
        swal.fire({
          icon              : 'error',
          titleText         : 'Error!',
          text              : err.message,
          buttonsStyling    : false,
          confirmButtonClass: 'btn btn-brand',
        });
        helper.StopProcessing($('#save-company'));
        return;
      }
  
      helper.StopProcessing($('#save-company'));
      $('#add-company-modal').modal('hide');
      swal.fire({
        toast               : true,
        icon                : 'success',
        titleText           : `${props.type} Added successfully!`,
        position            : 'bottom-end',
        showConfirmButton   : false,
        timer               : 1500,
        animation           : false,
        customClass         : {
          popup: 'margin-20',
        },
      });
    };
  
    return (
      <div
        className="modal fade"
        id="add-company-modal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true">
      
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Add {props.type === 'Company' ? 'Insurance Company' : 'Product'}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close" onClick={onClose}>
              </button>
            </div>
            <div className="modal-body">
              <div className="client-container">
                <form id="add-company-form" onSubmit={addCompany}>
                  <div className="row">
                    <div className="col-12">
                      <input
                        id="shortName"
                        value={data.Name}
                        name="shortName"
                        placeholder="Display Name"
                        className="form-control margin-b10"
                        type="text"
                        onChange={({ target: { value } }) =>
                          setData((d) => ({
                            ...d,
                            Name: helper.capitalize(value),
                        }))}/>          
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <input
                        id="fullName"
                        value={data.FullName}
                        name="fullName"
                        placeholder="Full Name / Description"
                        className="form-control margin-b10"
                        type="text"
                        onChange={({ target: { value } }) =>
                          setData((d) => ({
                            ...d,
                            FullName: helper.capitalize(value),
                        }))}/>                     
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary margin-0 margin-r5"
                data-dismiss="modal" onClick={onClose}>   
                Close
              </button>
              <button
                id="save-company"
                onClick={addCompany}
                type="button"
                className="btn btn-brand margin-0">   
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
};

export default AddCompany;