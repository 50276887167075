import React, { useState, useEffect } from "react";
import 'jquery-form';
import 'jquery-validation';
import '../../assets/scripts/bootstrap-modal';
import '../../assets/scripts/jquery-validation.init';
import * as helper from '../global/helper';
import swal from 'sweetalert2';
import 'select2';
import $ from 'jquery';
import Editor from 'ckeditor5-custom-build';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { addQuickText, deleteQuickText, getQuickText, updateQuickText } from "../api/quickTextAPI";
import { getAuthState } from "../../assets/scripts/login-util";
import FileImg from '../../assets/images/no-file.svg'
import NoData from "./noData";

const AddQuickText = (props) => {

    const [btnLoading,        setBtnLoading]        = useState(false); 
    const [navState,          setNavState]          = useState('Add');
    const [quickTextDetails,  setQuickTextDetails]  = useState({});
    const [quickText,         setQuickText]         = useState([]);
    const [refresh,           setRefresh]           = useState(false);
    const [searchString,      setSearchString]      = useState('');
    const [filteredQuickText, setFilteredQuickText] = useState([]);
    const [editQuickText,     setEditQuickText]     = useState(false);

    const user = getAuthState('user');

    useEffect(() => {
        let ignore = false;
        $('#add-quickText-modal').modal({
            backdrop: 'static'
        })
        $('#add-quickText-modal').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            $(props.from).removeClass('modal-blur');
            props.addQuickText(false);
        });
        $(props.from).addClass('modal-blur');
    
        const fetchQuickText = async () => {
            try {
                setBtnLoading(true);
                const response = await getQuickText();
                setQuickText(response);
                setBtnLoading(false);
            } catch (err) {
                setBtnLoading(false);
                swal.close();
                swal.fire({
                    icon                : 'error',
                    titleText           : 'Error!',
                    text                : err.message,
                    buttonsStyling      : false,
                    confirmButtonClass  : 'btn btn-brand',
                });
            }
        }

        if (!ignore) {
            fetchQuickText();
        }
        return () => {
            ignore = true;
        };
    }, [refresh]);

    useEffect(() => {
        helper.SetScrollHeight();
    });

    useEffect(() => {
        if (quickText.length > 0)
            filterQuickText();
        else
            setFilteredQuickText([]);
    },[quickText, searchString])

    useEffect(() => {
        if (navState === 'Select') {
            registerPopover(); 
            setQuickTextDetails({});
            setEditQuickText(false);        
        }
    }, [navState]);


    useEffect(() => {
        if(filteredQuickText.length > 0) registerPopover();
    },[filteredQuickText]);

    const registerPopover = () => {
        $('[data-toggle="kt-popover"]').popover({ html: true});
        $('[data-toggle="kt-tooltip"]').tooltip();
        $('body').on('click', function (e) {
            $('[data-toggle="kt-popover"]').each(function () {
                // hide any open popovers when the anywhere else in the body is clicked
                if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                    $(this).popover('hide');
                }
            });
        }); 
    };

    const shortCutHandler = (q) => {
        props.fillQuickText(q);
        $('#add-quickText-modal').modal('hide');
    };

    const editQuickTextHandler = (id) => {
        setQuickTextDetails(quickText.find((q) => q.ID == id))
        setEditQuickText(true);
        setNavState('Add');
    };

    const refetchQuickText = () => setRefresh((r) => !r);

    const updateQuickTextDetails = (field, value) => {

        let val = value
      
        if (field === "ShortCut")
            val =  value.replace(/ /g, '');

        setQuickTextDetails((q) => {
            return {...q, [field]: val};
        });
        
    }

    const filterQuickText = () => {
        let quickTextList = quickText;
        if (searchString === '') {
            setFilteredQuickText(quickTextList);
        } else {
            const matchedQuickText = quickTextList.filter((q) => {
                let matchString = `${'/'+q.ShortCut} ${q.AgentName} ${q.AccessType}`;
                let matchFound = 0;
                const searchArr = searchString.split(' ');
                searchArr.forEach((term) => {
                    matchFound += matchString.toLowerCase().includes(term.toLowerCase())
                        ? 1
                        : 0;
                });
                return matchFound === searchArr.length;
            });
            setFilteredQuickText(matchedQuickText);
        }
    };

    const initQuickTextHandler = (editor) => {  

        editor.editing.view.document.on('enter', ( evt, data ) => {
            if (data.isSoft) editor.execute('enter');        
            else editor.execute('shiftEnter');               
            data.preventDefault();
            evt.stop();
            editor.editing.view.scrollToTheSelection();
        }, {priority: 'high'});

    }


    const removeQuickTextHandler = async (id) => {
        const swalResult = await swal.fire({
            title               : 'Are you sure?',
            text                : "You won't be able to revert this!",
            icon                : 'warning',
            showCancelButton    : true,
            buttonsStyling      : false,
            reverseButtons      : true,
            showLoaderOnConfirm : true,
            confirmButtonClass  : 'btn btn-brand',
            cancelButtonClass   : 'btn btn-secondary',
            confirmButtonText   : 'Delete',
            preConfirm: async () => {
                try {
                    await deleteQuickText(id);
                    return;
                } catch (err) {
                    swal.fire({
                        icon               : 'error',
                        titleText          : 'Error!',
                        text               : err.message,
                        buttonsStyling     : false,
                        confirmButtonClass : 'btn btn-brand'
                    });
                    return;
                }
            },
        });
        if(!swalResult.value) return;
        swal.fire({
            toast             : true,
            icon              : 'success',
            titleText         : 'Quick Text deleted successfully!',
            position          : 'bottom-end',
            showConfirmButton : false,
            timer             : 1500,
            animation         : false,
            customClass       : {
            popup: 'margin-20',
            },
        }); 
        refetchQuickText();
        setNavState('Select');
    }

    const addQuickTextHandler = async (e) => {
        e.preventDefault();

        var form = $('#add-quickText-form');
        form.validate().destroy();

        form.validate({
            rules: {
                shortCut: {
                    required: true
                },
                quickText: {
                    required: true
                }
            }
        });
        if (!form.valid()) {
            if($('#shortCut-error').text() !== '') {
                $('#shortCut').addClass('is-invalid');
            }
            if($('#quickText-error').text() !== '') {
                $('#quickText').addClass('is-invalid');
            }
            return;
        }

        try {
            if(!quickTextDetails.Message) {
                return swal.fire({
                    icon                  : 'error',
                    titleText             : 'Error!',
                    text                  : "Please Enter Quick Text",
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',   
                });
            }else {
                if (quickTextDetails.Message.length > 2000) {
                    return swal.fire({
                        icon                  : 'error',
                        titleText             : 'Error!',
                        text                  : "Maximum character limit exceeded",
                        buttonsStyling        : false,
                        confirmButtonClass    : 'btn btn-brand',   
                    });
                }
            }
            setBtnLoading(true);

            if (editQuickText) {
                await updateQuickText({
                    ID         : quickTextDetails.ID,
                    ShortCut   : quickTextDetails.ShortCut,
                    Message    : quickTextDetails.Message,
                    AccessType : quickTextDetails.AccessType
                })             
            } else {
                await addQuickText({
                    ShortCut   : quickTextDetails.ShortCut,
                    Message    : quickTextDetails.Message,
                    AccessType : quickTextDetails.AccessType || 'Personal'
                })             
            }
        } catch (err) {
            swal.fire({
              icon                  : 'error',
              titleText             : 'Error!',
              text                  : err.message,
              buttonsStyling        : false,
              confirmButtonClass    : 'btn btn-brand',
            });
            setBtnLoading(false);
            return;
        }  

        if (editQuickText) {
            swal.fire({
                toast             : true,
                icon              : 'success',
                titleText         : 'Quick Text updated successfully!',
                position          : 'bottom-end',
                showConfirmButton : false,
                timer             : 1500,
                animation         : false,
                customClass       : {
                popup: 'margin-20',
                },
            });
            refetchQuickText();
            setNavState('Select');
            setBtnLoading(false);
        }else{
            swal.fire({
                icon              : 'success',
                titleText         : 'Quick Text added successfully!',
                showConfirmButton : false,
                timer             : 1500
            });
            shortCutHandler(quickTextDetails.Message);
        }
    }

    return (
        <div
            className="modal fade"
            id="add-quickText-modal"
            tabIndex="-1"
            role="dialog"
            aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">
                            Quick Text
                        </h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close">
                        </button>
                    </div>
                    <div className="modal-body padding-0">
                        <div className="kt-portlet kt-portlet--mobile">
                            <div className="kt-portlet__head kt-portlet__head--lg padding-lr0" style={{minHeight: 0}}>
                                <div className="kt-portlet__head-label w-100">
                                    <nav className="file-nav navbar navbar-top w-100 justify-content-center padding-t5">
                                        <div className="row w-100">                                  
                                            <div className="col-6 text-center padding-0">
                                                <a className={`d-block w-100 text-clip margin-l0 padding-b15 ${navState === 'Add' ? 'top-nav-active' : ''}`} tabIndex="-1" onClick={() => setNavState('Add')}>                                                                                                                                                                                                                                               
                                                    {!editQuickText ? 'Add' :  'Edit'} Quick Text
                                                </a>
                                            </div>
                                            <div className="col-6 text-center padding-0">
                                                <a className={`d-block w-100 text-clip margin-l0 padding-b15 ${navState === 'Select' ? 'top-nav-active' : ''}`} tabIndex="-1" onClick={() => setNavState('Select')}>
                                                    All Quick Texts
                                                </a>
                                            </div>
                                        </div>                                                                              
                                    </nav>
                                </div>
                            </div>
                        </div>
                        {navState === 'Select' ?
                            (<React.Fragment>
                                <div className="row align-items-center stickyFilter">
                                    <div className="col-xl-12 order-1 order-xl-1">
                                        <div className="row">
                                            <div className="col-md-6 margin-b0">
                                                <div className="kt-input-icon kt-input-icon--left">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search Quick Text..."
                                                        id="generalSearch"
                                                        value={searchString || ''}
                                                        onChange={(event) => setSearchString(event.target.value)}>
                                                    </input>
                                                    <span className="kt-input-icon__icon kt-input-icon__icon--left">
                                                        <span>
                                                            <i className="la la-search"></i>
                                                        </span>
                                                    </span>
                                                    {searchString && (
                                                        <span className="kt-input-icon__icon kt-input-icon__icon--right">
                                                            <span>
                                                                <i className="la la-close" onClick={() => setSearchString('')}></i>
                                                            </span>
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {filteredQuickText.length > 0 ? (
                                        <div className="col-12">
                                            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
                                                <table className="kt-datatable__table">
                                                    <thead className="kt-datatable__head" style={{ display: 'block' }}>
                                                        <tr className="kt-datatable__row block-row">
                                                            <th className="kt-datatable__cell text-clip"  width="35%">
                                                                <span>Short Cut</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip"  width="25%">
                                                                <span>Created By</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip"  width="20%">
                                                                <span>Access</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip text-right padding-r50" width="20%">
                                                                <span>Action</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </div>
                                            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded scrolTable" onScroll={helper.setStickyHeader}>
                                                <table className="kt-datatable__table table-striped">
                                                    <tbody className="kt-datatable__body" style={{ display: 'block' }}>
                                                        {filteredQuickText.map((q, i) => {
                                                            return(
                                                                <tr key={q.ID} className="kt-datatable__row block-row">
                                                                    <td title={q.ShortCut} className="kt-datatable__cell text-clip clickable" onClick={() => shortCutHandler(q.Message)} width="35%">
                                                                        <span>/{q.ShortCut}</span>
                                                                    </td>
                                                                    <td title={q.AgentName} className="kt-datatable__cell text-clip clickable" onClick={() => shortCutHandler(q.Message)} width="25%">
                                                                        <span>{q.AgentName}</span>
                                                                    </td>
                                                                    <td title={q.AccessType} className="kt-datatable__cell text-clip clickable" onClick={() => shortCutHandler(q.Message)} width="20%">
                                                                        <span>{q.AccessType}</span>
                                                                    </td>
                                                                    <td className="kt-datatable__cell text-clip" width="20%">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                                                                            data-toggle="kt-popover"
                                                                            data-trigger="hover click"
                                                                            data-placement="left"
                                                                            data-original-title="Quick Text"
                                                                            data-content={q.Message}>                           
                                                                            <i className="fa fa-info"></i>
                                                                        </button>
                                                                        {(user.categoryId == 1 || q.AgentID === user.id) && (
                                                                            <React.Fragment>
                                                                                <button 
                                                                                    type="button"
                                                                                    className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"             
                                                                                    onClick={() => editQuickTextHandler(q.ID)}>
                                                                                    <i className="flaticon2-edit"></i>
                                                                                </button>
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={() => removeQuickTextHandler(q.ID)}
                                                                                    className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                                                                    <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                                                </button>
                                                                            </React.Fragment>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    ) : (
                                        <NoData
                                            src={FileImg}
                                            alt="QuickText"
                                            message="No Quick Text Found"
                                            from="quickText"
                                        />
                                    )}
                                </div>
                            </React.Fragment>) :
                            (<form id="add-quickText-form" className="padding-lr20">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="shortCut">Short Cut</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">/</span>
                                                </div>
                                                <input 
                                                    className="form-control"
                                                    value={quickTextDetails.ShortCut || ''}
                                                    onChange={(event) => updateQuickTextDetails('ShortCut', event.target.value)}
                                                    id="shortCut"
                                                    name="shortCut"
                                                    type="text"
                                                />
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="ddlShareWith">Access</label>
                                            <select 
                                                className="form-control kt-selectpicker"
                                                onChange={(event) => updateQuickTextDetails('AccessType', event.target.value, event)}
                                                id="ddlShareWith"
                                                value={quickTextDetails.AccessType}>
                                                <option value="Personal">Personal</option>
                                                <option value="Team">Team</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <CKEditor 
                                                editor={ Editor }
                                                config={{
                                                            toolbar: ["Bold", "Italic", "RemoveFormat", "|", "Undo", "Redo"],
                                                            placeholder: "Quick Text",
                                                        }}  
                                                data={quickTextDetails.Message || ''}              
                                                onChange={(event, editor) => updateQuickTextDetails('Message', editor.getData())}
                                                onReady={initQuickTextHandler}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>)
                        }
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary margin-0 margin-r5"
                            data-dismiss="modal">   
                            Close
                        </button>
                        {navState === 'Add' &&
                            (<button
                                id="save-btn"
                                onClick={addQuickTextHandler}
                                type="button"
                                className={`btn btn-brand margin-0 ${
                                btnLoading
                                    ? 'kt-spinner kt-spinner--sm kt-spinner--light'
                                    : ''
                                }`}
                                disabled={btnLoading}>                  
                                {editQuickText ? 'Update' : 'Add'}
                            </button>)
                        }
                    </div>    
                </div>
            </div>
        </div>
    );
};

export default AddQuickText;