import $ from 'jquery';
import React, { useState, useEffect } from 'react';
import swal from 'sweetalert2';
import 'bootstrap/js/dist/popover';
import 'bootstrap/js/dist/tooltip';
import * as helper from '../global/helper';
import { deleteQuotation, getPolicyByPrevPolicy, getQuotationDocuments } from '../api/policyAPI';


const QuotationTable = (props) => {

    const [quotations,  setQuotations] = useState([]);

    useEffect(() => {
        filterQuotationsHandler();
    }, [props.quotations]);

    useEffect(() => {
        $('[data-toggle="kt-popover"]').popover({ html: true })
        $('body').on('click', function (e) {
            $('[data-toggle="kt-popover"]').each(function () {
                // hide any open popovers when the anywhere else in the body is clicked
                if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                    $(this).popover('hide');
                }
            });
        });
    })

    useEffect(() => {
        helper.SetScrollHeight();
    },[]);

    const filterQuotationsHandler = async () => {

        try {
            const convertedList = props.quotations.filter(q => q.StatusID == 3);
            const renewedList = [{GroupID: 0}];

            for await (const q of props.quotations){
                const r = renewedList.find(f => f.GroupID == q.GroupID);
                if (q.PolicyID != 0 && !r) {
                    const p = await getPolicyByPrevPolicy(q.PolicyID);
                    if (p) renewedList.push(q);           
                }
            };
            
            const quotationList = props.quotations.map((q) => {
                let isConvert = true;

                const converted = convertedList.find(f => f.GroupID == q.GroupID);
                if (converted) isConvert = false;
            
                if (q.PolicyID != 0) {
                    const renewed = renewedList.find(f => f.GroupID == q.GroupID);
                    if (renewed) isConvert = false;               
                }    
                return {...q, IsConvert: isConvert}
            })
            setQuotations(quotationList);

        } catch (err) {
            swal.fire({
                icon              : 'error',
                titleText         : 'Error!',
                text              : err.message,
                buttonsStyling    : false,
                confirmButtonClass: 'btn btn-brand',
            });
        }

    };

    const removeQuotationHandler = async (index) => {

        if (props.action === 'e') {

            swal.fire({
                title               : 'Are you sure?',
                text                : "You won't be able to revert this!",
                icon                : 'warning',
                showCancelButton    : true,
                buttonsStyling      : false,
                reverseButtons      : true,
                showLoaderOnConfirm : true,
                confirmButtonClass  : 'btn btn-brand',
                cancelButtonClass   : 'btn btn-secondary',
                confirmButtonText   : 'Delete',
                preConfirm: async () => {
                try {
                    const quotation = quotations[index];
                    await deleteQuotation(quotation.ID);
                    props.removeQuotation(index);
                    if (quotations.length > 1) {
                        swal.fire({
                            toast             : true,
                            icon              : 'success',
                            titleText         : 'Quotation deleted successfully!',
                            position          : 'bottom-end',
                            showConfirmButton : false,
                            timer             : 1500,
                            animation         : false,
                            customClass       : {
                            popup: 'margin-20',
                            },
                        });
                    }else{
                        swal.fire({
                            icon              : 'success',
                            titleText         : 'Quotation Deleted Successfully',
                            showConfirmButton : false,
                            timer             : 1500,
                        });
                    }
                    
                } catch (err) {
                    swal.fire({
                    icon                : 'error',
                    titleText           : 'Error!',
                    text                : err.message,
                    buttonsStyling      : false,
                    confirmButtonClass  : 'btn btn-brand',
                    });
                }
                },
            });       
            
        }else{
            props.removeQuotation(index);
        }
             
    }

    const editQuotationHandler = (index) => {
        props.editQuotation(index);                            
    }

    const convertQuotationHandler = async (index) => {
        props.convertQuotation(index);                            
    }

    const emailQuotationHandler = (index, isEmail) => {
        props.emailQuotation(index, isEmail);                         
    }

    const openDocumentHandler = async (index) => {

        try {
            const quotation = quotations[index];

            if (quotation.DocumentCount > 1) {
                props.setDocumentQuoteID(quotation.ID);
            }else{        
                const response  = await getQuotationDocuments([quotation.ID]);
                const ext       = helper.getFileExt(response[0].FileName);
                const name      = (response[0].DocumentName + '.' + ext).replace(/ /g, '_');
                helper.downloadAndOpenFileHandler(response[0].DocumentType, response[0].FileName, name)                           
            }  
        } catch (err) {
            swal.fire({
                icon              : 'error',
                titleText         : 'Error!',
                text              : err.message,
                buttonsStyling    : false,
                confirmButtonClass: 'btn btn-brand',
            });
        }                                
    }

    return(
        <React.Fragment>
            <div className="row scrolTable" style={props.action === 'a' ? {minHeight: '15vh'} : {minHeight: '25vh'}}>
                <div className="col-12">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th className="padding-l40">Company</th>
                                <th>Sum Insured</th>
                                <th>Premium</th>
                                <th className="text-center">Email</th>
                                {props.action === 'e' && (<th className="text-center">Status</th>)}
                                <th className="text-right padding-r50">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                quotations.map((q, i) =>
                                    <tr key={i}>
                                        <td className="padding-l20">
                                            <div className="kt-user-card-v2">
                                                <div className="kt-user-card-v2__pic">
                                                    <div className="img-small-profile kt-badge kt-badge--xl kt-badge--success">{q.InsuranceCompanyName.slice(0, 1)}</div>
                                                </div>
                                                <div className="kt-user-card-v2__details">
                                                    <span className="kt-user-card-v2__name">
                                                        {q.InsuranceCompanyName}
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <span>{q.SumInsured || ''}</span>
                                        </td>
                                        <td>
                                            <span>{q.Premium || ''}</span>
                                        </td>
                                        <td className="text-center">
                                            <label className="kt-checkbox kt-checkbox--brand">
                                                <input type="checkbox" defaultChecked={q.IsEmail} onClick={(event) => emailQuotationHandler(i, event.target.checked)}/>
                                                &nbsp;<span></span>
                                            </label>
                                        </td>
                                        {props.action === 'e' && (
                                            <td 
                                                className="text-center"
                                                width="15%"
                                                title={q.Status}>
                                                <div className={`policy-status block-badge mr-1 badge badge-${q.StatusID == 3 ? 'success' : (q.StatusID == 2 ? 'primary' : 'secondary')}`}>
                                                    <span>{q.Status}</span>
                                                </div>
                                            </td>
                                        )}
                                        <td className="text-right">
                                            {q.AdditionalData && (
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                                                    data-toggle="kt-popover"
                                                    data-trigger="hover click"
                                                    data-placement="left"
                                                    data-original-title="Additional Data"
                                                    data-content={q.AdditionalData}>                           
                                                    <i className="fa fa-info"></i>
                                                </button>
                                            )}                                  
                                            {props.action === 'e' ? (
                                                <React.Fragment>
                                                    {q.DocumentCount > 0 && (
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                                                            data-toggle="kt-popover"
                                                            data-trigger="hover"
                                                            data-placement="left"
                                                            data-content="Quotation Documents"
                                                            onClick={() => openDocumentHandler(i)}>                                 
                                                            <i className="flaticon-doc"></i>
                                                        </button>          
                                                    )}    
                                                    <div className='dropdown d-inline'>
                                                        <a className='btn btn-sm btn-clean btn-icon btn-icon-md' data-toggle='dropdown' aria-expanded='true'>
                                                            <i className="flaticon-more-1"></i>
                                                        </a>
                                                        <div className='dropdown-menu dropdown-menu-right' x-placement='top-end'>
                                                            {q.IsConvert && (
                                                                <a className='dropdown-item' onClick={() => convertQuotationHandler(i)}>
                                                                    <i className='la la-rotate-left'></i>
                                                                    Convert To Policy
                                                                </a>
                                                            )}
                                                            <a className='dropdown-item' onClick={() => editQuotationHandler(i)}>
                                                                <i className='la la-edit'></i>
                                                                Edit
                                                            </a>
                                                            <a className='dropdown-item' onClick={() => removeQuotationHandler(i)}>
                                                                <i className='la la-trash'></i>
                                                                Delete
                                                            </a>                                                              
                                                        </div>
                                                    </div>                                                                                                         
                                                </React.Fragment>                                             
                                            ) : (
                                                <React.Fragment>
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"               
                                                        onClick={() => editQuotationHandler(i)}>   
                                                        <i className="flaticon2-edit"></i>
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => removeQuotationHandler(i)}
                                                        className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                                        <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                    </button>
                                                </React.Fragment>
                                            )}  
                                                                                                                                                                                             
                                        </td>
                                    </tr>
                                )
                            }                       
                        </tbody>
                    </table>
                </div>
            </div> 
          
        </React.Fragment>

        
    );
    
};

export default QuotationTable;
