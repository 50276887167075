import PolicyExtractor from '../../assets/scripts/policyExtractor';
import swal from 'sweetalert2';

const pdfjsLib = window['pdfjs-dist/build/pdf'];
if (pdfjsLib) {
  pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js';
}

export const extractData = async (file) => {
  try {

    if (!pdfjsLib) throw new Error('Library not loaded');

    const pdf   = await pdfjsLib.getDocument(URL.createObjectURL(file)).promise;
    const pages = pdf._pdfInfo.numPages;
    let data    = [];

    for (let pageNo = 0; pageNo < pages; pageNo++) {
      const page = await pdf.getPage(pageNo + 1);   
      const rows = await page.getTextContent();
      rows.items.map((r) => data.push(r.str));
    }

    const extractor = new PolicyExtractor(data.join(' '));
    const details   = await extractor.getPolicyDetails();
    return details;
  } catch (err) {
    swal.fire({
      icon              : 'error',
      titleText         : 'Error!',
      text              : err.message,
      buttonsStyling    : false,
      confirmButtonClass: 'btn btn-brand',
    });
  }
};


